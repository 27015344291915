import { notification } from "antd";

import { store } from '../index';

import * as Const from './Const';
import { BaseProfile, ICommand, MenuItemDesc, SResponseDTO, ResponseStatusesEnum, PeriodsEnum, EventTypesEnum, Point, USER_RIGHTS, SPointTaskDTO } from '../decl';
import { getHeaders, BACKEND_URL } from './AuthUtils';
import { showColumnsSelector } from '../components/Page/ColumnsSelector';
import { showImageLoader } from '../components/Page/ModalIMageLoader';

let message_key = 0;


//{content: cmd.Params.message, duration: duration, key: key, onClick:()=>message.destroy(key)}


const translateMessageToNotification = (props:any, duration? : number, onClose?: any) => {
    const propsIsConfig = (typeof props !== 'string');
  return {
    message:propsIsConfig ? props.content : props,
    placement: 'top' as any,
    duration: propsIsConfig ? props.duration : duration,
    onClose: onClose
};
}

export const message: any = {
    setZIndex: ()=>{
        const noteRooot: any = document.getElementsByClassName('ant-notification');
        if(noteRooot.length > 0) noteRooot[0].style.zIndex = getModalZIndex();
    },

    success: (props:any, duration? : number, onClose?: any) => {
        notification.success(translateMessageToNotification(props, duration, onClose));
        message.setZIndex();
    },
    loading: (props:any, duration? : number, onClose?: any) => {
        notification.success(translateMessageToNotification(props, duration, onClose));
        message.setZIndex();
    },
    info: (props:any, duration? : number, onClose?: any) => {
        notification.info(translateMessageToNotification(props, duration, onClose));
        message.setZIndex();
    },
    warning: (props:any, duration? : number, onClose?: any) => {
        notification.warning( translateMessageToNotification(props, duration, onClose));
        message.setZIndex();
    },
    warn: (props:any, duration? : number, onClose?: any) => {
        notification.warning(translateMessageToNotification(props, duration, onClose));
        message.setZIndex();
    },
    error: (props:any, duration? : number, onClose?: any) => {
        notification.error(translateMessageToNotification(props, duration, onClose));
        message.setZIndex();
    },
    destroy: () => notification.destroy()
    
}


export function getProperty<ObjectType, KeyType extends keyof ObjectType>(object: ObjectType,  property: KeyType): ObjectType[KeyType] {
    return object[property];
}
export function stringToNumberArray(iValue: string){
    return (iValue && iValue != '') ?
        iValue.split(',').filter(x=>x.trim() !== "").map(Number).filter(x=>!isNaN(x)) :
        [];
}

export function stringToArray(iValue: string | undefined | null) {
    return (iValue && iValue != '') ?
        iValue.split(',').filter(x => x.trim() !== "") :
        [];
}

export function arrayToString(iValue: any) {
    if(typeof iValue === 'string')
        return iValue;
    else
        return iValue ? iValue.join(',') : '';
}

export function netiExecute(cmd: ICommand, userProfile: any, setProfile: any, setIsLoading: any, setActiveQueries: any, onCommand: any, showModal: any) {
    topNetiExecute(cmd, userProfile, setProfile, setIsLoading, setActiveQueries, onCommand, showModal, false);
}

export function topNetiExecute(cmd: ICommand, userProfile: any, setProfile: any, setIsLoading: any, setActiveQueries: any, onCommand: any, showModal: any, isTop: boolean) {
    const getProfile = (group: string) => {
        if (userProfile) {
            //Взять копию.
            const profile: any = { ...userProfile.profile[group as keyof BaseProfile] };
            return profile;
        }
        else {
            return {};
        }
    }

    if (cmd.Name === Const.PROFILE_GET_COMMAND){
        if(!Array.isArray(cmd.Params.Group)){
            const profile: any = getProfile(cmd.Params.Group);
            const values: any = { ...profile };
            cmd.Callback(values);
        }
        else{
            let values: any = {};
            cmd.Params.Group.forEach((group:string) => {
                const profile: any = getProfile(group);
                values = {...values, ...profile};
            });
            cmd.Callback(values);
        }
    }
    else if (cmd.Name === Const.PROFILE_SET_COMMAND  && getProfile  && setProfile){
        const profile: any = getProfile(cmd.Params.Group);
        const values:any = {...profile, ...cmd.Params.Values}; 
        setProfile(cmd.Params.Group, values);
    }
    else if (cmd.Name === Const.RIGHTS_GET_COMMAND) {
        const rights = (userProfile) ? userProfile.rights : [];
        cmd.Callback(rights);
    }
    else if (cmd.Name === Const.SET_IS_LOADING_COMMAND && setIsLoading){
        setIsLoading(cmd.Params.on, cmd.Params.time, cmd.Params.waitMessage);
    }
    else if (cmd.Name === Const.MESSAGE_SHOW_COMMAND){
        const type = cmd.Params.type;
        const key = message_key++;
        if (message_key >= 9000) message_key = 0;

        const duration: number = cmd.Params.duration ? cmd.Params.duration : 5; 
        switch(cmd.Params.type){
            case 0:
                message.success({content: cmd.Params.message, duration: duration, key: key, onClick:()=>message.destroy(key)});
                break;
            case 1:
                message.warning({content: cmd.Params.message, duration: duration, key: key, onClick:()=>message.destroy(key)});
                break;
            case 2:
                message.error({content: cmd.Params.message, duration: duration, key: key, onClick:()=>message.destroy(key)});
                break;
            default:
                message.success({content: cmd.Params.message, duration: duration, key: key, onClick:()=>message.destroy(key)});
                break;
        }
    }
    else if (cmd.Name === Const.SET_ACTIVE_QUERIES_COMMAND && setActiveQueries){
        setActiveQueries(cmd.Params);
    }
    else if(cmd.Name === Const.ON_KADRCOLUMNS_REORDER_COMMAND){
        const profileCommon: any = getProfile(Const.PROFILE_COMMON);
        const profileKadr: any = getProfile(Const.PROFILE_KADR);
        const period = profileCommon?.ArchivePeriod;
        const value: string = JSON.stringify(cmd.Params);
        if(period !== undefined){
            switch (period) {
                case 0:
                    setProfile(Const.PROFILE_KADR, {CurrentColumnsLayout: value});
                    break;
                case 1:
                    setProfile(Const.PROFILE_KADR, {HourColumnsLayout: value});
                    break;
                case 2:
                    setProfile(Const.PROFILE_KADR, {DayColumnsLayout: value});
                    break;
            }
        }
    }
    else if (cmd.Name === Const.ON_COLUMNS_SELECT_COMMAND && showModal) {
        showColumnsSelector(cmd.Params.columns, cmd.Params.type === 2, 7, showModal, cmd.Callback);
    }
    else if (cmd.Name === Const.IMAGE_LOADER_SHOW_COMMAND && showModal) {
        const pointId = cmd.Params as string;
        const point: Point = userProfile.allPoints[pointId];
        showImageLoader(showModal, setIsLoading, cmd.Callback, point);
    }
    else if (cmd.Name === Const.POINTEDITOR_SHOW_COMMAND && showModal) {
        const pointId = cmd.Params as string;
        const point: Point = userProfile.allPoints[pointId];
        showPointEditor(showModal, cmd.Callback, point);
    }
    //else if (cmd.Name === Const.SHOW_TASK_LOG_COMMAND) {
    //    const pointId = cmd.Params as string;
    //    const point: Point = userProfile.allPoints[pointId];
    //    addPointLog(point);
    //}
    else if (onCommand) {
        onCommand(cmd);
    }
    else if(!isTop){
         window.call_execute(cmd);
    }
}

export const showPointEditor = (showModal: any, callback: any, point: Point) => {
    store.dispatch({ type: 'EDIT_POINT', point: point });
}

export const addPointLog = (point: Point) => {
    store.dispatch({ type: 'ADD_POINT_LOG', point: point });
}

export function filterDictionary(dic: any, filter: any){
    const result: any = {};
    const keys: string[] = Object.keys(dic);
    const filterKeys = keys.filter(key=> filter(dic[key]));
    filterKeys.forEach(key => result[key] = dic[key]);

    return result;
}

export function getInitialPage(profile: BaseProfile, menu: MenuItemDesc[]){
    let path: string = '/';
    if(profile !== undefined && profile.Common){
        const displayMenuItems: number[] | null = profile.Common.DisplayMenuItems ? stringToNumberArray(profile.Common.DisplayMenuItems) : null;
        const enterPage: string | null = profile.Common.EnterPage ? profile.Common.EnterPage : null;
        const lastPage: string | null = profile.Common.LastPage ? profile.Common.LastPage : null;

        let firstEnabledPath: string | null = null;
        let okEnter: boolean = false;
        let okLast: boolean = false;
        menu.forEach(menuItem => {
            const found =  displayMenuItems === null || displayMenuItems.indexOf(menuItem.id) >= 0;
            if(found){
                if(firstEnabledPath === null) firstEnabledPath = menuItem.path;

                if(menuItem.path === enterPage)  okEnter = true;
                
                if(menuItem.path === lastPage) okLast = true;
            }
        });

        if (okEnter && enterPage !== null) {
            path = enterPage;
        }
        else if (okLast && lastPage !== null) {
            path = lastPage;
        }
        else if(firstEnabledPath !== null){
            path = firstEnabledPath;
        }
    }
    return path;
} 

//Получение настраиваемой страницы с сервера.
export function getPage(iCode: string, resolve: any){
    const requestOptions = {
        method: 'POST',
        headers: getHeaders(),
        body: JSON.stringify(iCode)
    };

    fetch(BACKEND_URL + 'common/getpage', requestOptions)
        .then(response => response.json() as Promise<SResponseDTO>)
        .then(data => {
            if (data.bodyStatus && data.bodyStatus == ResponseStatusesEnum.Ok) {
                resolve(data.body);
            }
            else if (data.bodyStatus && data.bodyStatus == ResponseStatusesEnum.Error) {
                message.error(data.message);
                resolve();
            }
            else {
                message.error('Ответ не получен.');
                resolve();
            }
        })
        .catch(error => {
            message.error('Ошибка:' + error);
            resolve();
        });
}

export const PeriodEnumToString = (et: PeriodsEnum): string => {
    let res: string = '';
    if (et === PeriodsEnum.current) {
        res = 'Текущие';
    } else if (et === PeriodsEnum.day) {
        res = 'Суточные';
    } else if (et === PeriodsEnum.hour) {
        res = 'Часовые';
    } else if (et === PeriodsEnum.month) {
        res = 'Месячные';
    } else {
        res = PeriodsEnum[et];
    }
    return res;
}

export const EventTypeToString = (et: EventTypesEnum): string => {
    let res: string = '';
    if (et === EventTypesEnum.Alarm) {
        res = 'Авария';
    } else if (et === EventTypesEnum.Checking) {
        res = 'Поверка';
    } else if (et === EventTypesEnum.Message) {
        res = 'Сообщение';
    } else if (et === EventTypesEnum.Unstate) {
        res = 'НС';
    } else if (et === EventTypesEnum.Warning) {
        res = 'Предупреждение';
    } else if (et === EventTypesEnum.Work) {
        res = 'ТО';
    } else {
        res = EventTypesEnum[et];
    }
    return res;
}

//Возвращает массив названий ключей в перечислении
export function enumToArray(enum1: any): number[] {
    return Object.keys(enum1)
        .filter(value => isNaN(Number(value)) === false)
        .map(key => Number(key));
}

export function layoutLeftSide(heightWindow: number, showWeather: boolean | undefined, allCount: number, pathname: string): any{
        //Планирование высоты левой части.
        const hMin = 450;       //Минимальная высота меню. 
        const hH = 68;          //Высота заголовка.
        const hF = 54;          //Высота подвала.
        const hTop = 25;        //Смещение для размещения элементов меню.
        const hItem = 48;       //Высота элемента меню.
        const hSubMenu = 44;    //Высота элемента 'Дополнительно'.
        const hWeather = 100;    //Высота погодного блока
        const hIselected = 60;  //Высота блока 'Выбрано точек учёта.    
        const hIall = 60;       //Высота блока 'Всего точек учёта.

        let hC = heightWindow - hH - hF;
        if(hC < hMin) hC = hMin;

        let showAll = allCount > 1;
        let showSelected = showAll && (pathname === '/reports' || pathname === '/kadr' || pathname === '/maps' || pathname ===  '/analysis');

        //Приоритет отображения: 5 элементов меню. Погода. Информация о точках учёта.
        showSelected = showSelected && hC - hTop - 5 * hItem - hSubMenu > hIselected;
        showAll = showAll && hC - hTop - 5 * hItem - hSubMenu > hIselected + hIall;
        let hMenu = hC - hTop;
        if(showWeather) hMenu -=  hWeather;
        if(showSelected) hMenu -= hIselected;
        if(showAll) hMenu -=hIall;
 
        const maxItems = Math.floor((hMenu - hSubMenu)/hItem);
        return {showSelected, showAll, maxItems};
}

//Берет данные для ant design table и превращает их в CSV. Если не все колонки нужны можно отфильтровать их через columns.filter
export function makeCSVfromTable(dataSource: any[], columns: {title: string, dataIndex: string, render?(val: any): any}[]) {
    let res: string[] = [];
    res.push('');
    columns.forEach(x => res[0] += x.title + ';');

    dataSource.forEach((r => {
        let row = '';
        columns.forEach(c => {
            let col: any = r[c.dataIndex];
            if (c.render != undefined) {
                col = c.render(r[c.dataIndex]) ?? '';
            }
            let colstr = getTextContent(col);
            row += colstr.replace(';', '_') + ';';
        })
        res.push(row);
    }))

    return res.join('\r\n'); //data.toString('windows-1251', 0, data.length)
}

export function getTextContent(elem: React.ReactElement | string): string {
    if (!elem) {
        return '';
    }
    if (typeof elem === 'string' || typeof elem === 'number') {
        return elem.toString();
    }

    const children = elem.props && elem.props.children;
    if (children instanceof Array) {
        return children.map(getTextContent).join('');
    }
    return getTextContent(children);
}

const DMap: any = {1027: 129, 8225: 135, 1046: 198, 8222: 132, 1047: 199, 1168: 165, 1048: 200, 1113: 154, 1049: 201, 1045: 197, 1050: 202, 1028: 170, 160: 160, 1040: 192, 1051: 203, 164: 164, 166: 166, 167: 167, 169: 169, 171: 171, 172: 172, 173: 173, 174: 174, 1053: 205, 176: 176, 177: 177, 1114: 156, 181: 181, 182: 182, 183: 183, 8221: 148, 187: 187, 1029: 189, 1056: 208, 1057: 209, 1058: 210, 8364: 136, 1112: 188, 1115: 158, 1059: 211, 1060: 212, 1030: 178, 1061: 213, 1062: 214, 1063: 215, 1116: 157, 1064: 216, 1065: 217, 1031: 175, 1066: 218, 1067: 219, 1068: 220, 1069: 221, 1070: 222, 1032: 163, 8226: 149, 1071: 223, 1072: 224, 8482: 153, 1073: 225, 8240: 137, 1118: 162, 1074: 226, 1110: 179, 8230: 133, 1075: 227, 1033: 138, 1076: 228, 1077: 229, 8211: 150, 1078: 230, 1119: 159, 1079: 231, 1042: 194, 1080: 232, 1034: 140, 1025: 168, 1081: 233, 1082: 234, 8212: 151, 1083: 235, 1169: 180, 1084: 236, 1052: 204, 1085: 237, 1035: 142, 1086: 238, 1087: 239, 1088: 240, 1089: 241, 1090: 242, 1036: 141, 1041: 193, 1091: 243, 1092: 244, 8224: 134, 1093: 245, 8470: 185, 1094: 246, 1054: 206, 1095: 247, 1096: 248, 8249: 139, 1097: 249, 1098: 250, 1044: 196, 1099: 251, 1111: 191, 1055: 207, 1100: 252, 1038: 161, 8220: 147, 1101: 253, 8250: 155, 1102: 254, 8216: 145, 1103: 255, 1043: 195, 1105: 184, 1039: 143, 1026: 128, 1106: 144, 8218: 130, 1107: 131, 8217: 146, 1108: 186, 1109: 190};
export function unicodeToWin1251(data: string): Uint8Array {
    let uint8 = new Uint8Array(data.length);
    let x: number;
    for(var i = 0; i < data.length; i++) {
        x = data.charCodeAt(i);
        if (x > 127) {
            if (!(x in DMap))
                throw "Character " + i + " isn't supported by win1251!";
            x = DMap[x];
        }
        uint8[i] = x;
    }
    return uint8;
}

export function convertBase64ToBinary(base64: string): Uint8Array {
    const raw = window.atob(base64);
    const rawLength = raw.length;
    let array = new Uint8Array(new ArrayBuffer(rawLength));

    for (let i = 0; i < rawLength; i++) {
        array[i] = raw.charCodeAt(i);
    }
    return array;
}

export function getMimeType(fileName: string): string {
    const ext = fileName?.substring(fileName.lastIndexOf('.') + 1, fileName.length) || fileName;
    let res: string = '';
    if (ext === 'pdf') {
        res = 'application/pdf';
    } else if (ext === 'xls') {
        res = 'application/vnd.ms-excel';
    } else if (ext === 'xlsx') {
        res = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
    } else if (ext === 'doc' || ext === 'dot') {
        res = 'application/msword';
    } else if (ext === 'zip') {
        res = 'application/zip';
    } else {
        res = 'text/plain';
    }

    return res;
}

export function saveFile(fileName: string, data: Uint8Array) {
    const blob = new Blob([data], {
        type: getMimeType(fileName),
    });

    let url = window.URL.createObjectURL(blob);
    let a = document.createElement('a');
    a.href = url;
    a.download = fileName;
    a.click();
}

export function stringSorter(a: any, b: any) {
    const result = ((a === '' || isNaN(a)) && (b === '' || isNaN(b)) ? (a || '').localeCompare(b || '') : a - b);
    return result;
}

export function daysCaption(n: number){
    //1 день, 2-4 дня, 5 дней
    const remainder = n % 10;
    const quotient = Math.floor(n/10);
    let caption = '';
    if(quotient === 1 || remainder >= 5){
        caption = " дней";
    }
    else if(remainder === 1){
        caption = " день"; 
    }
    else if(remainder >= 2 && remainder <= 4){
        caption = " дня";
    }
    return n.toString() + caption;
}

export function getModalZIndex():number{
    const topZIndex = window.call_get_top_zindex();
    return topZIndex ? topZIndex + 1 : 1020;
}

export function toDateString(inputDate: Date) {
    let date, month, year;
  
    date = inputDate.getDate();
    month = inputDate.getMonth() + 1;
    year = inputDate.getFullYear();
  
      date = date
          .toString()
          .padStart(2, '0');
  
      month = month
          .toString()
          .padStart(2, '0');
  
    return `${date}.${month}.${year}`;
}

export function toTimeString(inputTime: Date) {
    let hour, min, sec;

    hour = inputTime.getHours();
    min = inputTime.getMinutes();
    sec = inputTime.getSeconds();

    return `${hour.toString().padStart(2, '0')}:${min.toString().padStart(2, '0')}.${sec.toString().padStart(2, '0')}`;
}

export function toFullTimeString(inputTime: Date | null | undefined) {
    if(inputTime) return toDateString(inputTime) + ' ' + toTimeString(inputTime)
    else return '';
}

export function emailValidate(email: string) {
    var validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    return email.match(validRegex)
}

export function makeTime(date: Date, hours: number, min: number, sec: number): Date {
    let d = new Date();
    d.setTime(date.getTime());
    d.setHours(hours, min, sec, 0);
    return d;
}

export function toDate(date: Date): Date {
    let d = new Date();
    d.setTime(date.getTime());
    d.setHours(0, 0, 0, 0);
    return d;
}

//Из бекенда для типа Date приходит строка, данная функция упрощает инициализацию дат
export function initDates(obj: any, arr: string[]): void {
    for (let k of arr) {
        if (obj[k] != null) {
            obj[k] = new Date(obj[k]);
        }
    }
}

//Копирование объектов двух объектов по простым типам рекурсивно
export function deepCopyObj(obj: any) {
    var copy: any;

    // Handle the 3 simple types, and null or undefined
    if (null == obj || "object" != typeof obj) return obj;

    // Handle Date
    if (obj instanceof Date) {
        copy = new Date();
        copy.setTime(obj.getTime());
        return copy;
    }

    if (obj instanceof Uint8Array) {
        copy = new Uint8Array(obj);
        return copy;
    }

    // Handle Array
    if (obj instanceof Array) {
        copy = [];
        for (var i = 0, len = obj.length; i < len; i++) {
            copy[i] = deepCopyObj(obj[i]);
        }
        return copy;
    }

    // Handle Object
    if (obj instanceof Object) {
        copy = {};
        for (var attr in obj) {
            if (obj.hasOwnProperty(attr)) copy[attr] = deepCopyObj(obj[attr]);
        }
        return copy;
    }

    throw new Error("Unable to copy obj! Its type isn't supported.");
}

//Сравнение двух объектов по простым типам рекурсивно. Положение в массиве тоже играет роль
export function compareObj(p1: any, p2: any): boolean {
    if (p1 == undefined) return p1 === p2;
    if (typeof p1 !== typeof p2) return false;
    if ("object" != typeof p1) return p1 === p2;

    // Handle Date
    if (p1 instanceof Date) {
        if (p2 instanceof Date) {
            if ((p1 as Date).getTime() === (p2 as Date).getTime()) {
                return true;
            } else {
                return false;
            }
        } else {
            return false;
        }
    }

    if (p1 instanceof Uint8Array) {
        if (p2 instanceof Uint8Array) {
            if (p1.length !== p2.length) return false;

            for(let i = 0; i < p1.length; i++) {
                if (p1[i] !== p2[i]) return false;
            }
            
            return true;
        } else {
            return false;
        }
    }

    // Handle Array
    if (p1 instanceof Array) {
        if (p2 instanceof Array && p1.length === p2.length) {
            for (var i = 0; i < p1.length; i++) {
                if (compareObj(p1[i], p2[i]) === false) return false; 
            }
            return true;
        } else {
            return false;
        }
    }

    // Handle Object
    if (p1 instanceof Object) {
        if (p2 instanceof Object) {
            for (var attr in p1) {
                if (p2.hasOwnProperty(attr) === false) return false;
                if (compareObj(p1[attr], p2[attr]) === false) return false; 
            }
            return true;
        } else {
            return false;
        }
    }

    throw new Error("Unable to compare obj! Its type isn't supported.");
}

//Проверка наличия права для чтения данных заданного периода.
export function canReadData(rights: string[] | undefined, period: PeriodsEnum): boolean {
    let result: boolean = false;
    if (rights) {
        switch (period) {
            case PeriodsEnum.current:
                result = rights.includes(USER_RIGHTS.CanReadCurrents);
                break;
            case PeriodsEnum.hour:
                result = rights.includes(USER_RIGHTS.CanReadTodayArchives);
                break;
            case PeriodsEnum.day:
                result = rights.includes(USER_RIGHTS.CanReadTodayArchives) || rights.includes(USER_RIGHTS.CanReadDayArchives);
                break;
            default:
                result = false;
                break;
        }
    }
    return result;
}

//Проверка изменения состава активных задач.
export function isActiveTasksChanged(newActiveTasks: SPointTaskDTO[], oldActiveTasks: SPointTaskDTO[]) {
    let result: boolean = false;
    if (newActiveTasks && oldActiveTasks) {
        result = newActiveTasks.length !== oldActiveTasks.length;
        if (!result) {

            for (let i = 0; i < newActiveTasks.length; i++) {
                const oldT = oldActiveTasks[i];
                const newT = newActiveTasks[i];
                if (newT.pointId !== oldT.pointId || newT.queryId !== oldT.queryId || newT.taskId !== oldT.taskId) {
                    result = true;
                    break;
                }
            }
        }
    }
    return result;
}
