import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { Tree, Dropdown, Menu, Modal, TreeNodeProps, Button, Space, MenuProps } from 'antd';
import type { DataNode, TreeProps } from 'antd/es/tree';
import 'ant-design-draggable-modal/dist/index.css'
import { HomeOutlined, GroupOutlined } from '@ant-design/icons';

import { Group, Point, SResponseDTO, ResponseStatusesEnum, POINT_SERIALNUMBER, Command } from "../decl";
import { createTree, getId, getGroupTreeIndex, changeTreeValue, getGroupsByPoints, getAllSelectedPoints,
    removeValue, getPointGroups, NodeTypesEnum, SelectedNodeInfo, getNodeType, getGroupPath, getAllSelectedGroups,
    isNodeAllowedCheck
} from "../utils/TreeUtils";
import * as Const from '../utils/Const';
import {message} from '../utils/Common';

import styles from '../resources/Tree.module.less';
import { point } from 'leaflet';
import { ItemType } from 'antd/lib/menu/hooks/useItems';
import { AntTreeNodeProps } from 'antd/lib/tree';
import { Key } from 'antd/lib/table/interface';

//Свойства для дерева точек с контекстным меню.
interface IPointsControlTreeProps{
    groups: Group[];                            //Все группы пользователя.
    groupPoints: {[groupId: string] : Point[]}; //Точки учёта по группам.
    selectedPoint: Point | undefined;           //Выбранная точка учёта, используется только при инициализации компонента
                                                //В процессе работы выбранная точка или группа сохраняется в состоянии компонента.
    getPoints: any;                             //Метод получения точек учёта по группам (UserProfile.requestGroupPoints).
    onSelect?: (ob: Point | Group | null, nodeType: NodeTypesEnum)=>void;   //Выбор нового узла, передаётся группа или точка учёта или null и тип узла.
    height?: number | undefined;                //Высота компонента, при превышении размеров появляется горизонтальный скроллбар.
                                                //По ширине компонент занимает всё пространство родителя.
    commands?: {[nodeType in NodeTypesEnum] : Command[]};    //Описание команд контекстного меню.
    draggable: boolean;                         //Возможность перемещения узлов.
    cancelDropMessage?: string | undefined;                 //Если задано перемещение отменяется с заданным сообщением.                    
    changePointPosition?: any;                  //Функция изменения позиции точки учёта в группе.
    changeGroupPosition?: any;                  //Функция изменения позиции группы в группе.
    movePoint?: any;                            //Функция перемещения точки учёта из группы в группу.
    moveGroup?: any;                            //Функция перемещения группы из группы в группу.
    needReloadGroupIds: string[];
    setIsLoading?: any;
    adminMode?: boolean;
    fullLoading?: boolean;
    filter?: ((ob: Point | Group) => boolean) | string | undefined,
    additionalTitle?: ((ob: Point | Group | null) => string),
    containerStyle?: any
}

type MoveConfirmInfoType = 
{
    isOpen: boolean,
    title?: string,
    content?: any,
    onMove?: any,
    onCopy?: any,
    onCancel?: any
}

interface IPointsControlTreeState{
    isGroupsLoading: boolean,
    selected: SelectedNodeInfo | undefined,
    moveConfirmInfo: MoveConfirmInfoType,
    treeData: any,
    expandedKeys: string[],
    treeValue: string[]
}

export {NodeTypesEnum} from "../utils/TreeUtils";

//Дерево точек учёта с контекстным меню для выполнения операций для точек учёта и групп.
export default class PointsControlTree extends React.PureComponent<IPointsControlTreeProps, IPointsControlTreeState> {
    _isMounted: boolean;
    _waitGroupIds: string[];
    _treeRef: any;
    _divRef: any;
    constructor(props: IPointsControlTreeProps) {
        super(props);

        this.state = {
            isGroupsLoading: false,
            selected: undefined,// { parentOb: null, selectedOb: null, nodeType: NodeTypesEnum.Root },
            moveConfirmInfo: { isOpen: false },
            treeData: undefined,
            expandedKeys: [],
            treeValue: []
        }

        this._isMounted = false;
        this._waitGroupIds = [];
        this._treeRef = React.createRef();
        this._divRef = React.createRef();
    }
    componentDidMount() {
        this._isMounted = true;
        this._waitGroupIds = [];
        this.loadTree([], undefined);
        //this.setPoint();
        //this.createTree(undefined);
    }
    componentDidUpdate(prevProps: IPointsControlTreeProps, prevState: IPointsControlTreeState) {
        //this.loadTree([]);
        //Изменилась глобально выбранная точка учёта.
        const selectedPointId = this.props.selectedPoint?.id;
        const prevSelectedPointId = prevProps.selectedPoint?.id;

        if (selectedPointId != prevSelectedPointId) {
            this.loadTree([], selectedPointId);
            this.setPoint();
            //this.createTree(selectedPointId);
        }
        else if (prevProps.fullLoading !== this.props.fullLoading && this.props.fullLoading) {
            this.loadTree([], this.state.selected?.selectedOb?.id);
            //this.createTree(this.state.selected?.selectedOb?.id);
        }
        else if (prevProps.filter != this.props.filter) {
            this.createTree(this.state.selected?.selectedOb?.id);
        }
    }

    loadTree = (openGroups: Group[], selectedPointId: string | undefined) => {
        //Нужно обеспечить предварительную подгрузку групп с выбранными точками учёта и верхней единственной группы.
        if(this.props.groups.length > 0 && !this.state.isGroupsLoading){
            const groupIds: string[] = this.props.fullLoading ? getAllSelectedGroups(this.props.groups, [Const.ALL_GROUP_ID]).map(g => g.id) : [];
            const pointId = this.props.selectedPoint?.id;
            const pointIds: string[] = [];
            const _this = this;

            //Запрос на получение точек учёта для группы.
            const checkGroupPoints = (groupId: string) => {
                if(_this._waitGroupIds.indexOf(groupId) < 0){
                    _this._waitGroupIds.push(groupId);
                    _this.props.getPoints(groupId, ()=>{
                        //console.log('WaitGroups: ', groupId, this._waitGroupIds);
                        removeValue(_this._waitGroupIds, groupId);
                        if(_this._waitGroupIds.length === 0){
                            _this.setState({ isGroupsLoading: false });
                            //console.log('Done: ', _this.state.isGroupsLoading);
                            _this.createTree(selectedPointId);
                        }
                    });
                }
            }

            //Проверка наличия единственной корневой группы.
            if(this.props.groups.length === 1){
                //В корне одна группа, которая раскрывается при создании дерева.
                const groupId = this.props.groups[0].id; 
                groupIds.push(groupId);
            }

            //Взять идентификаторы открытых групп.
            for(let openGroup of openGroups){
                if(openGroup){
                    groupIds.push(openGroup.id);
                }
            }

            //Проверка наличия выбранной точки учёта.
            if (pointId) {
                //Все прародители точки учёта.
                const pointGroups = getPointGroups(
                    this.props.groups,          //Корневые группы пользователя, содержащие дочерние группы.
                    this.props.groupPoints,     //Точки учёта по группам {[groupId: string] : Point[]}.
                    pointId);                   //Идентификатор точки учёта.

                //Не найдено ни одной группы. !!!!! М.б не все группы подгружены.
                if(pointGroups.length === 0){
                    //В системе нет данных о группах, в которых находится данная точка учёта.
                    if (pointIds.indexOf(pointId) < 0) {
                        pointIds.push(pointId);
                    }
                }
                else{
                    //Проверить необходимость загрузки точек для найденных групп.
                    for(let group of pointGroups){
                        const path = getGroupPath(this.props.groups, group.id);
                        for(let parentGroup of path){
                            if(groupIds.indexOf(parentGroup.id) < 0){
                                groupIds.push(parentGroup.id);
                            }
                        }
                    }
                }

                //Проверить наличие точек учёта с неизвестными группами.
                if(pointIds.length > 0){
                    //Получить группы по точкам учёта.
                    getGroupsByPoints(pointIds, (groupsByPoints: any) => {
                        //console.log('groupByPoints', groupsByPoints);
                        if (_this._isMounted && groupsByPoints) {
                            const pointIds = Object.keys(groupsByPoints);
                            for (let pointId of pointIds) {
                                const loadedPointGroups: Group[] = groupsByPoints[pointId];
                                for(let group of loadedPointGroups){
                                    checkGroupPoints(group.id);
                                }
                            }
                        }
                    });
                }
            }
            if (groupIds.length > 0 || pointIds.length > 0) {
                console.log('Подгрузка точек', groupIds)
                this.setState({ isGroupsLoading: true });

                for (let groupId of groupIds) {
                    checkGroupPoints(groupId);
                }
            }
            else {
                this.createTree(selectedPointId);
            }
        }
    }

    setPoint = () => {
        if (this.props.selectedPoint) {
            const pointGroups = getPointGroups(this.props.groups, this.props.groupPoints, this.props.selectedPoint.id);
            let point: Point | undefined = this.props.selectedPoint;
            let group: Group | undefined;
            if (pointGroups && pointGroups.length > 0) {
                group = pointGroups[0];
            }

            this.setState({ selected: { selectedOb: point ?? null, parentOb: group ?? null, nodeType: NodeTypesEnum.Point } });
            if (this.props.onSelect) {
                this.props.onSelect(point ?? null, NodeTypesEnum.Point);
            }
        }
        else {
            this.setState({ selected: undefined });
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    onLoadData = (node: any): any => {
        const group = node.group;
        const getGroupPoints = this.props.getPoints;
        return new Promise<void>((resolve) => {
            if(group && group.numberOfPoints > 0){
                getGroupPoints(group.id, () => {
                    resolve();
                    this.createTree(this.state.selected?.selectedOb?.id);
                });
            }
            else{ 
                resolve();
                this.createTree(this.state.selected?.selectedOb?.id);
                return;
            }
        });
    };
    
    getExpandedGroupIds = ()=>{
        const result: string[] = [];
        if (this.state.selected && !this.state.isGroupsLoading){
            switch(this.state.selected.nodeType){
                case NodeTypesEnum.Root:
                    break;
                case NodeTypesEnum.Group:
                    if(this.state.selected.selectedOb){
                        const groupGroups = getGroupPath(this.props.groups, this.state.selected.selectedOb.id);
                        for (let group of groupGroups) {
                            if(result.indexOf(group.id) < 0){
                                result.push(group.id);
                            }         
                        }
                    }
                    break;
                case NodeTypesEnum.Point:
                    if(this.state.selected.selectedOb){
                        const pointGroups = getPointGroups(this.props.groups, this.props.groupPoints, this.state.selected.selectedOb.id);
                        for (let group of pointGroups) {
                            if(result.indexOf(group.id) < 0){
                                result.push(group.id);
                            }         
                        }
                    }
                    break;
            }
            if(this.props.selectedPoint){
                const pointGroups = getPointGroups(this.props.groups, this.props.groupPoints, this.props.selectedPoint.id);
                for (let group of pointGroups) {
                    if(result.indexOf(group.id) < 0){
                        result.push(group.id);
                    }         
                }
                //console.log('Expanded: ', result);
            }
        }
        return result;
    }

    onSelect = (selectedKeys: any, e: any) => {
        this.selectNode(e.node);
    };

    onRightClick = (params: any) => {
        this.selectNode(params.node);
    }

    onDoubleClick = (params: any, node: any) => {
        const selected: SelectedNodeInfo = this.selectNode(node);
        if(this.props.commands){
            const commands: any = this.props.commands[selected.nodeType];
            if(commands.length > 0){
                commands[0].action(selected.selectedOb, selected.parentOb);
            }
        }
    }

    selectNode = (node: any) => {
        const group: Group = node.group;
        const point: Point = node.point;
        const parent: Group = node.parent; 
        
        const selected: SelectedNodeInfo = {parentOb: null, selectedOb: null, nodeType: NodeTypesEnum.Root};
        if(point){
            selected.nodeType = NodeTypesEnum.Point;
            selected.selectedOb = point;
            selected.parentOb = group;
        }
        else if(group){
            selected.nodeType = NodeTypesEnum.Group;
            selected.selectedOb = group;
            selected.parentOb = parent;

        }

        let changed = (this.state.selected === undefined) ||
            (this.state.selected.nodeType !== selected.nodeType) ||
            (this.state.selected.selectedOb != null && selected.selectedOb == null) ||
            (this.state.selected.selectedOb == null && selected.selectedOb != null) ||
                 (this.state.selected.selectedOb != null &&  selected.selectedOb != null &&  selected.selectedOb.id !== this.state.selected.selectedOb.id) ||
                 (this.state.selected.parentOb?.treeId != selected.parentOb?.treeId);
                 
        if(changed){
            if(this.props.onSelect){
                this.props.onSelect(selected.selectedOb, selected.nodeType);
            }

            this.setState({selected: selected});
        }
        return selected;
    } 

    onMenuClick = (info: any) => {
        if (this.props.commands && this.state.selected){
            const commands: any = this.props.commands[this.state.selected.nodeType];
            const command = commands.find((c:Command)=>c.key===info.key);
            if(command){
                command.action(this.state.selected.selectedOb, this.state.selected.parentOb);
            }
        }
    }

    menuItemsCreate = (commands: any) => {
        if(commands){
            const result = commands.map( (c:Command)=>({label: c.caption, key: c.key}));
            return result;
        }
    }

    //Модальное окно для подтверждения копирования/перемещения групп или точек учёта.
    moveConfirm = () => {
        const handleMove = ()=>{
            const moveConfirmInfo = {...this.state.moveConfirmInfo};
            this.setState({moveConfirmInfo: {isOpen: false}});
            if(moveConfirmInfo.onMove){
                moveConfirmInfo.onMove();
            }
        }
        const handleCopy = ()=>{
            const moveConfirmInfo = {...this.state.moveConfirmInfo};
            this.setState({moveConfirmInfo: {isOpen: false}});
            if(moveConfirmInfo.onCopy){
                moveConfirmInfo.onCopy();
            }
        }
        const handleCancel = ()=>{
            const moveConfirmInfo = {...this.state.moveConfirmInfo};
            this.setState({moveConfirmInfo: {isOpen: false}});
            if(moveConfirmInfo.onCancel){
                moveConfirmInfo.onCancel();
            }
        }

        return <Modal 
            title={this.state.moveConfirmInfo.title} 
            open={this.state.moveConfirmInfo.isOpen}
            onCancel={()=>this.setState({moveConfirmInfo: {isOpen: false}})}
            footer={[
                <Button key="back" onClick={handleCancel}>
                  Отмена
                </Button>,
                <Button key="move" onClick={handleMove}>
                  Переместить
                </Button>,
                <Button key="copy" type="primary" onClick={handleCopy}>
                  Копировать
                </Button>,
              ]}>
            {this.state.moveConfirmInfo.content}
        </Modal>
    }

    getContextMenu = () => {
        let result: MenuProps | null = null;
        if (this.props.commands && this.state.selected) {
            const items: any = this.menuItemsCreate(this.props.commands[this.state.selected.nodeType]);
            if (items && items.length > 0) {
                result = (
                    { items:items,onClick:this.onMenuClick}
                );
            }
        }
        return result;
    }

    onDrop = (info: any) => {
        if (this.props.cancelDropMessage) {
            console.log(this.props.cancelDropMessage);
            message.warning(this.props.cancelDropMessage);
        }
        else if(info){
            if(info.dragNode.point){
                //Точка учёта.
                const point: Point = info.dragNode.point;
                //Группа точки учёта.
                const group: Group = info.dragNode.group;

                //Выбранная под точку учёта.
                const afterPoint = info.node.point;

                if(info.node.group == null){
                    message.warning('Точка учёта не может быть вставлена в корневую группу пользователя.');
                }
                else if(this.props.changePointPosition && info.node.group.id === group.id){
                    //Изменение позиции точки учёта.
                    this.props.changePointPosition(group, point, afterPoint);
                }
                else if(this.props.movePoint && info.node.group.id !== group.id){
                    //Перемещение точки учёта.
                    this.setState({moveConfirmInfo: {
                        isOpen: true,
                        title: 'Перемещение точки учёта',
                        content: "Точка учёта '" + point.number + "' перемещается из группы '" + group.name +
                        ((info.node.group) ? "' в группу '" + info.node.group.name +"'." : "'."),
                        onCopy: ()=>{
                            const fromGroup = group;
                            const toGroup = info.node.group;
                            this.props.movePoint(point, fromGroup, toGroup, false);
                            //this.loadTree([fromGroup, toGroup]);
                        },
                        onMove: ()=>{
                            const fromGroup = group;
                            const toGroup = info.node.group;
                            this.props.movePoint(point, fromGroup, toGroup, true);
                            //this.loadTree([fromGroup, toGroup]);
                        }
                    }});
                }
            }
            else if(info.dragNode.group){
                //Группа.
                const group = info.dragNode.group;
                //Родительская группа.                
                const parent = info.dragNode.parent;
                if(info.node.point){
                        //Вставляется после точки учёта.
                        message.warning('Для перемещения/копирования группы перетащите её под название группы, внутрь которой Вы хотите её переместить/скопировать.');
                }
                else{
                    //Вставляется после группы или корневого узла.
                    const moveInsideRoot = (parent == null && info.node.parent == null);
                    const moveInsideGroup = ((parent && info.node.parent) && (parent.id === info.node.parent.id) ||
                         (parent && info.node.group && (info.node.group.id === parent.id)));
                    const moveIntoRoot = parent && (info.node.group == null); 
                    const moveIntoGroup = (parent && info.node.group && parent.id !== info.node.group.id && (info.node.parent == null || info.node.parent.id !== parent.id)) ||
                                        (parent == null && info.node.group); 
                    if((moveInsideRoot || moveInsideGroup) && this.props.changeGroupPosition && !((moveIntoRoot || moveIntoGroup) && this.props.moveGroup )){
                        //console.log('X',moveInsideGroup, moveInsideRoot);
                        //Вставляется после группы или корневого узла.
                        const afterGroup = ((parent && info.node.group && info.node.group.id === parent.id) || (parent == null && info.node.group == null)) ?
                            undefined : 
                            info.node.group;
                        this.props.changeGroupPosition(parent, group, afterGroup);
                    }

                    if((moveIntoRoot || moveIntoGroup) && this.props.moveGroup ){
                            const canSimpleMove = (info.node.group && (moveInsideRoot || moveInsideGroup) && this.props.changeGroupPosition);
                            //Перемещение группы из группы в группу.
                                const fromName = parent ? "' перемещается из группы '" + parent.name + "'": " перемещается из корня";
                                const toName = info.node.group ? " в группу '" + info.node.group.name +"'." : " в корень.";
                                //const cancelName =  canSimpleMove ?  " По кнопке 'Отмена' группа '" + group.name + "' будет перенесена под группу '" + info.node.group.name +"'." : '';
                                const cancelName =  canSimpleMove ?  " По кнопке 'Отмена' будет изменён порядок групп." : '';
                                this.setState({moveConfirmInfo: {
                                    isOpen: true,
                                    title: 'Перемещение группы',
                                    content:<Space direction='vertical'> {"Группа '" + group.name + fromName + toName} {cancelName}</Space>,
                                    onCopy: ()=>{
                                        this.props.moveGroup(group, parent, info.node.group, false);
                                        //this.loadTree([group, parent, info.node.group]);
                                    },
                                    onMove: ()=>{
                                        this.props.moveGroup(group, parent, info.node.group, true);
                                        //this.loadTree([group, parent, info.node.group]);
                                    },
                                    onCancel: ()=>{
                                        if(canSimpleMove){
                                            //console.log('X',moveInsideGroup, moveInsideRoot);
                                            //Вставляется после группы или корневого узла.
                                            const afterGroup = ((parent && info.node.group && info.node.group.id === parent.id) || (parent == null && info.node.group == null)) ?
                                                undefined : 
                                                info.node.group;
                                            this.props.changeGroupPosition(parent, group, afterGroup);
                                        }
                    
                                        //this.props.moveGroup(group, parent, info.node.group, true);
                                        //this.loadTree([group, parent, info.node.group]);
                                    }
                                }});
                    }
                }
            }
        }
    }
    onDragStart = (info: any) => {
        console.log('DragStart: ', info);
        info.event.stopPropagation();
        this.selectNode(info.node);
    }
    
    onDragLeave = (info: any) => {
        //console.log('DragLeave: ', info);
    }
    onDragEnter = (info: any) => {
    //console.log(info);
    }

    onDragOver = (info: any) => {
        const ref = this._treeRef.current;
        const divRef = this._divRef.current;
        const outRect = divRef.getBoundingClientRect();
        if(info.event.clientY < outRect.top + 50){
            setTimeout(() => {
                this._treeRef.current.scrollTo({ key: info.node.key, align: "top", offset: 60 });
            }, 300);
        }
        else if(info.event.clientY > outRect.top + outRect.height - 50){
            setTimeout(() => {
                this._treeRef.current.scrollTo({ key: info.node.key, align: "bottom", offset: 60 });
            }, 300);
        }
    }

    draggable = (node:any)=>{
        return true;
    }

    createTree = (selectedId: string | undefined) => {
        const ids: string[] = [];

        const selectedIds: string[] = [];
        //Идентификаторы выбранных узлов, наполняется при выполненнии функции createTree.
        let value: string[] = [];
        //Рскрытые узлы, наполняется при выполненнии функции createTree.
        const expandedKeys: string[] = [...this.state.expandedKeys];

        if (selectedId) {
            selectedIds.push(selectedId);
        }

        const onlyPointsSelect = false;
        const onlyGroupsSelect = false;

        let isAllowedCheck: ((ob: Point | Group) => boolean) | undefined = undefined;

        if (this.props.filter !== undefined && (typeof this.props.filter === "string") && (this.props.filter.length > 1)) {
            isAllowedCheck = (ob: Point | Group) => {
                let result = isNodeAllowedCheck(ob, this.props.filter as string);
                return result;
            };
        }
        else if (this.props.filter !== undefined && (typeof this.props.filter !== "string")) {
            isAllowedCheck =  this.props.filter as ((ob: Point | Group) => boolean);
        }

        //console.log('createTree', this.props.groupPoints);
        const treeData = createTree(
            this.props.groups,
            this.props.groupPoints,
            selectedIds,
            this.getExpandedGroupIds(),
            value,
            ids,
            expandedKeys,
            5,
            (props: any) => {
                if (props.value.length > 0 && props.value[0] === 'g') {
                    return <GroupOutlined />
                }
                else {
                    if (props.point && props.point.persistProperties[POINT_SERIALNUMBER] && props.point.persistProperties[POINT_SERIALNUMBER].length > 0) {
                        return <HomeOutlined style={{ color: '#0f6' }} />;
                    }
                    else {
                        return <HomeOutlined style={{ color: '#20f' }} />;
                    }
                }
            },
            onlyPointsSelect,
            onlyGroupsSelect,
            null,
            isAllowedCheck,
            this.props.additionalTitle,
            this.props.adminMode);
        //console.log('treeData', treeData);

        //Обработка варианта выбора группы или точки учёта, представленных в дереве несколько раз.
        if (value.length > 1) {
            //Нужно оставить только один выбранный узел.
            let oneTreeId = undefined;
            for (let treeId of value) {
                const nodeType = getNodeType(treeId);
                const groupTreeIndex = getGroupTreeIndex(treeId);
                if (nodeType === NodeTypesEnum.Group) {
                    if (this.state.selected && this.state.selected.selectedOb !== null && groupTreeIndex === (this.state.selected.selectedOb as Group).treeId) {
                        oneTreeId = treeId;
                        break;
                    }
                }
                else if (nodeType === NodeTypesEnum.Point) {
                    if (this.state.selected && this.state.selected.parentOb !== null && groupTreeIndex === this.state.selected.parentOb.treeId) {
                        oneTreeId = treeId;
                        break;
                    }
                }
            }
            if (oneTreeId !== undefined) {
                value = [oneTreeId];
            }
        }

        this.setState({ treeData: treeData, expandedKeys: expandedKeys, treeValue: value });
    }

    render() {
        let isReady: boolean = !this.state.isGroupsLoading;
         
        if (isReady) {
            const tProps = {
                height: this.props.height,
                rootClassName: styles.treeContainer,
                treeData: this.state.treeData,
                selectedKeys: this.state.treeValue,
                defaultExpandedKeys: this.state.expandedKeys,
                //expandedKeys: this.state.expandedKeys,
                checkable: false,
                showIcon: true,
                showLine: {showLeafIcon: false},
                loadData: this.onLoadData,
                onExpand: (expandedKeys: Key[], info: { node: any; expanded: boolean; nativeEvent: MouseEvent; }) => {
                    this.setState({ expandedKeys: expandedKeys.map(k => k as string) });
                },
                onSelect: this.onSelect,
                onRightClick: this.onRightClick,
                onDoubleClick: this.onDoubleClick,
                draggable: this.props.draggable ? {icon:false, nodeDraggable: this.draggable} : false,
                onDragStart: this.onDragStart,
                onDragEnter: this.onDragEnter,
                onDragLeave: this.onDragLeave,
                onDrop: this.onDrop,
                onDragOver: this.onDragOver,
                ref: this._treeRef,
            };
            const menu = this.getContextMenu();
            const containerProps = this.props.containerStyle ?
                { ref: this._divRef, style: this.props.containerStyle } : { ref: this._divRef };

            if(menu === null){
                return <div>
                    <Tree {...tProps} />
                </div>;
            }
            else {
                return  <div {...containerProps} >
                <Dropdown menu={menu} trigger={['contextMenu']}>
                    <Tree {...tProps} />
                </Dropdown>
                {this.moveConfirm()}
                </div>;
            }
        } else {
            return <div>Ожидание загрузки</div>;
        }
    }
}
