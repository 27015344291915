import * as React from 'react';
import ResizableTable from '../Helper/ResizableTable';
import { Button, Checkbox, DatePicker, Input, Select, Space, Tooltip, message } from 'antd';
import moment from 'moment';
import { sendRequestToBackend } from '../../utils/AuthUtils';
import { getModalZIndex, initDates, makeTime, toDate } from '../../utils/Common';
import { Point } from '../../decl';
import { IPollingDicts, ITaskInfoDicts, STaskDTO, STaskInfoDTO } from '../../store/AdminStore';
import styles from '../../resources/Page.module.less';
import adminStyles from '../../resources/AdminPage.module.less';
import { LoadingOutlined, SyncOutlined, SketchOutlined } from '@ant-design/icons';
import SimpleTreeSelect from '../Helper/SimpleTreeSelect';
import TasksForm from './TasksForm';
import CollapsedButton from '../Helper/CollapsedButton';
import ShowTextForm from '../Helper/ShowTextForm';

type Props =
    {
        windowHeight: number,
        setIsLoading: any,
        points: Point[] | null,
        windowWidth: number,
        setActiveLogTask: (queryItemId: string | undefined, taskId: number) => void
    }

interface State {
    tasksTime: Date,
    tasks: STaskInfoDTO[] | null,
    dicts: ITaskInfoDicts | null,
    pollingDicts: IPollingDicts | null,

    selectedStatusIds: string[] | null,
    selectedQueryTypes: string[] | null,
    selectedConnectionTypeIds: string[] | null,
    selectedScheduleTypeIds: string[] | null,
    searchText: string,

    showTaskPoint: Point | null,
    showTask: STaskInfoDTO | null,
    modalZIndex: number,

    tasksQueueCount: number | null,
    showText: string | null
}

export default class PollingInfo extends React.PureComponent<Props, State> {

    constructor(props: Props) {
        super(props);

        this.state = {
            tasksTime: toDate(new Date()),
            dicts: null,
            tasks: null,
            selectedStatusIds: null,
            selectedQueryTypes: null,
            selectedConnectionTypeIds: null,
            searchText: '',
            pollingDicts: null,
            showTaskPoint: null,
            showTask: null,
            modalZIndex: 0,
            selectedScheduleTypeIds: null,
            tasksQueueCount: null,
            showText: null
        }
    }
    componentDidMount() {
        this.refresh();
    }

    componentDidUpdate(prevProps: Props, prevState: State) {

    }

    componentWillUnmount(): void {

    }

    rowColor = (attempts: number, status: number) => {
        let res = '';
        if (attempts > 0) res = adminStyles.taskInfoRowBlanchedAlmond; // попытки, розовый
        if (status === 4) res = adminStyles.taskInfoRowLightSteelBlue; // голубой
        if (status === 7) res = adminStyles.taskInfoRowPlum; // сиреневый
        if (status === 5) res = adminStyles.taskInfoRowLightGreen; // зеленый
        if (status === 8) res = adminStyles.taskInfoRowLightPink; // еще розовый

        return res;
    }

    initDicts = () => {
        if (this.state?.pollingDicts == undefined) {
            sendRequestToBackend(
                null,
                'admin/taskinfodictionaries',
                (response: any) => {
                    if (response != null) {
                        this.setState({ dicts: response });
                    } else {
                        message.warning("Не удалось получить словари");
                    }
                },
                undefined,
                (error: any) => {
                    message.warning("Ошибка при получении словарей");
                    console.log(error);
                    message.error(error);
                }
            );
        }

        if (this.state?.dicts == undefined) {
            sendRequestToBackend(
                null,
                'admin/pollingdictionaries',
                (response: any) => {
                    if (response != null) {
                        this.setState({pollingDicts: response});                    
                    } else {
                        message.warning("Не удалось получить словари");
                    }
                },
                this.props.setIsLoading,
                (error: any) => {
                    message.warning("Ошибка при получении словарей");
                    console.log(error);
                    message.error(error);
                }
            );
        }
    }

    private dataSource: any = undefined;
    private dataSourceFiltered: any = undefined;

    setDatasource = (tasks: STaskInfoDTO[] | undefined) => {
        if (tasks != undefined) {
            this.dataSource = tasks.map((x, index) => ({
                key: x.task.id,
                id: x.task.id,
                number: x.abonentNumber,
                name: x.abonentName,
                start: x.task.startTime,
                end: x.task.stopTime,
                type: x.task.queryTypeId,
                status: x.task.taskStatusId,
                attempts: x.task.retriesCount,
                now: x.executeNow,
                active: x.enabled,
                connection: x.connectionTypeId,
                scheduleId: x.task.sheduleId,
                num: index + 1,
            }));
        } else {
            this.dataSource = undefined;
        }

        this.applyFilter(this.state?.selectedStatusIds ?? null, this.state?.selectedQueryTypes ?? null, this.state?.selectedConnectionTypeIds ?? null, this.state?.selectedScheduleTypeIds ?? null, this.state?.searchText ?? '');
    }

    refresh = () => {
        if (this.state.dicts == null) {
            this.initDicts();
        }
        sendRequestToBackend(
            this.state.tasksTime,
            'admin/tasksinfo',
            (response: any) => {
                if (response != null) {
                    this.setDatasource(response);
                    (response as STaskInfoDTO[]).forEach((x) => initDates(x.task, ['startTime', 'stopTime']));
                    this.setState({tasks: response});
                } else {
                    message.warning("Не удалось получить задания");
                }
            },
            this.props.setIsLoading,
            (error: any) => {
                message.warning("Ошибка при получении заданий");
                console.log(error);//.format('DD.MM.YYYY HH:mm:ss')
                message.error(error);
            }
        );

        sendRequestToBackend(
            null,
            'admin/tasksqueuecount',
            (response: any) => {
                if (response != null) {
                    this.setState({tasksQueueCount: response});
                } else {
                    message.warning("Не удалось получить размер очереди заданий");
                }
            },
            this.props.setIsLoading,
            (error: any) => {
                message.warning("Ошибка при получении размера очереди заданий");
                console.log(error);//.format('DD.MM.YYYY HH:mm:ss')
                message.error(error);
            }
        );
    }

    showServersInfo = () => {
        this.setState({ showText: "Next", modalZIndex: getModalZIndex() });

    }

    private columns: any[] =
        [
            { title: '', dataIndex: 'num', width: 45, ellipsis: true, resizable: true, render: (val: any, rec: any, index: any) => val},
            { title: 'Номер', dataIndex: 'number', width: 140, ellipsis: true, resizable: true,},
            { title: 'Наименование', dataIndex: 'name', width: 100, ellipsis: true, resizable: true,},
            { title: 'Начало', dataIndex: 'start', width: 100, ellipsis: true, 
                render: (value: any) => value == null ? null : <><span>{moment(value).format('DD.MM.YYYY')}</span> <span>{moment(value).format('HH:mm')}</span></>
            },
            { title: 'Конец', dataIndex: 'end', width: 100, ellipsis: true,
                render: (value: any) => value == null ? null : <><span>{moment(value).format('DD.MM.YYYY')}</span> <span>{moment(value).format('HH:mm')}</span></>
            },
            { title: 'Тип', dataIndex: 'type', width: 80, ellipsis: true, render: (value: any) => this.state?.dicts == undefined || value == null ? null : this.state.dicts?.queryTypes[Number(value)]},
            { title: 'Статус', dataIndex: 'status', width: 75, ellipsis: true, render: (value: any) => this.state?.dicts == undefined || value == null ? null : this.state.dicts?.taskStatuses[Number(value)]},
            { title: 'Попыток', dataIndex: 'attempts', width: 60, align: 'center' as 'center', ellipsis: true },
            { title: 'Актив', dataIndex: 'active', width: 54, ellipsis: true, align: 'center' as 'center', render: (value: any) => <Checkbox checked={value ?? false}></Checkbox>},
            { title: 'Связь', dataIndex: 'connection', width: 100, ellipsis: true, render: (value: any) => this.state?.dicts == undefined || value == null ? null : this.state.dicts?.connectionTypes[Number(value)]},
        ]

    applyFilter = (statusIds: string[] | null, connTypeIds: string[] | null, queryTypeIds: string[] | null, scheduleTypeIds: string[] | null, text: string) => {
        if (this.dataSource != undefined) {
            let res = [...this.dataSource];

            if (statusIds != null) {
                res = res.filter((x: any) => statusIds.includes(String(x.status)));
            }

            if (connTypeIds != null) {
                res = res.filter((x: any) => connTypeIds.includes(String(x.connection)));
            }

            if (queryTypeIds != null) {
                res = res.filter((x: any) => queryTypeIds.includes(String(x.type)));
            }

            if (scheduleTypeIds != null) {
                res = res.filter((x: any) => scheduleTypeIds.includes(String(x.scheduleId)));
            }

            if (text != '') {
                res = res.filter((x: any) => x.number.toLowerCase().includes(text.toLowerCase()) || x.name.toLowerCase().includes(text.toLowerCase()));
            }

            //console.log('applyFilter', res, devTypesIds);
            this.dataSourceFiltered = res;
        } else {
            this.dataSourceFiltered = undefined;
        }
        this.forceUpdate();
    }

    limitStr = (s: string | null | undefined, len: number): string => {
        let res = '';
        if (s != null) {
            if (s.length > len) {
                res = s.substring(0, (len - 3) < 0 ? 0 : len - 3) + '...'
            } else {
                res = s;
            }
        }
        
        return res;
    }

    //findPoint = (number: string): Point

    //===============================================
    render() {
        const clientHeight = this.props.windowHeight - 230;
        const colapsed = this.props.windowWidth < 1500 ? true : false;

        return ( 
            <div style={{ height: clientHeight, padding: 2, backgroundColor: '#cfcfcf' }}>
                {this.state.tasks == null ? null :
                <>
                <Space direction="vertical" style={{marginBottom: '5px', marginTop: '5px'}}>
                <div className={styles.flexcontainer}>
                    <CollapsedButton tooltipPlacement='top' type="primary" isCollapsed={colapsed} icon={<SyncOutlined/>} onClick={() => {this.refresh()}}>Обновить</CollapsedButton>

                    <div key='fi2' className={styles.flexsmallitem}>
                        <DatePicker
                            value={moment(this.state.tasksTime)}
                            onChange={(date) => this.setState({tasksTime: date?.toDate() ?? toDate(new Date())})}
                            allowClear={false}
                            style={{width: '123px'}}
                        />
                    </div>

                    <div key='fi3' className={styles.flexsmallitem}>
                        <SimpleTreeSelect 
                            items={Object.keys(this.state.dicts?.taskStatuses ?? {}).map(x => ({id: x, name: this.state.dicts?.taskStatuses[Number(x)] ?? '', shortName: this.state.dicts?.taskStatuses[Number(x)] ?? ''}))}
                            constAllSelected='all'
                            rootItemShortTitle='Все статусы'
                            rootItemTitle='Все статусы'
                            selectedIds={this.state.selectedStatusIds == null ? ['all'] : this.state.selectedStatusIds}
                            onChange={(value) => {
                                const ids = value.indexOf('all') >= 0 ? null : value;
                                this.setState({ selectedStatusIds: ids });
                                this.applyFilter(ids, this.state.selectedConnectionTypeIds, this.state.selectedQueryTypes, this.state.selectedScheduleTypeIds, this.state.searchText); 
                            }}
                            style={{width: '208px'}}
                        />
                    </div>
                    <div key='fi4' className={styles.flexsmallitem}>
                        <SimpleTreeSelect 
                            items={Object.keys(this.state.dicts?.connectionTypes ?? {}).map(x => ({id: x, name: this.state.dicts?.connectionTypes[Number(x)] ?? '', shortName: this.limitStr(this.state.dicts?.connectionTypes[Number(x)], 20)}))}
                            constAllSelected='all'
                            rootItemShortTitle='Все соединения'
                            rootItemTitle='Все соединения'
                            selectedIds={this.state.selectedConnectionTypeIds == null ? ['all'] : this.state.selectedConnectionTypeIds}
                            onChange={(value) => {
                                const ids = value.indexOf('all') >= 0 ? null : value;
                                this.setState({ selectedConnectionTypeIds: ids });
                                this.applyFilter(this.state.selectedStatusIds, ids, this.state.selectedQueryTypes, this.state.selectedScheduleTypeIds, this.state.searchText); 
                            }}
                            style={{width: '245px'}}
                        />
                    </div>
                    <div key='fi5' className={styles.flexsmallitem}>
                        <SimpleTreeSelect 
                            items={Object.keys(this.state.dicts?.schedules ?? {}).map(x => ({id: x, name: this.state.dicts?.schedules[Number(x)] ?? '', shortName: this.limitStr(this.state.dicts?.schedules[Number(x)], 12)}))}
                            constAllSelected='all'
                            rootItemShortTitle='Все расписания'
                            rootItemTitle='Все расписания'
                            selectedIds={this.state.selectedScheduleTypeIds == null ? ['all'] : this.state.selectedScheduleTypeIds}
                            onChange={(value) => {
                                const ids = value.indexOf('all') >= 0 ? null : value;
                                this.setState({ selectedScheduleTypeIds: ids });
                                this.applyFilter(this.state.selectedStatusIds, this.state.selectedConnectionTypeIds, this.state.selectedQueryTypes, ids, this.state.searchText); 
                            }}
                            style={{width: '198px'}}
                        />
                    </div>
                    <div key='fi6' className={styles.flexsmallitem}>
                        <Input value={this.state.searchText} placeholder="Поиск" 
                            onChange={(ev: any) => {
                                this.setState({searchText: ev.target.value});
                                this.applyFilter(this.state.selectedStatusIds, this.state.selectedConnectionTypeIds, this.state.selectedQueryTypes, this.state.selectedScheduleTypeIds, ev.target.value); 
                            }}
                            style={{width: '80px'}}
                        />
                    </div>
                    <div key='fi7' className={styles.flexsmallitem} style={{display: 'flex', alignItems: 'center'}}>
                        <div style={{width: '90px'}}>
                            <span style={{color: 'green'}}>({this.dataSourceFiltered?.length ?? null}) </span>
                            <Tooltip title={'Очередь'}>{this.state?.tasksQueueCount ?? null}</Tooltip>
                        </div>
                    </div>
                    <CollapsedButton tooltipPlacement='top' type="primary" isCollapsed={colapsed} icon={<SketchOutlined />} onClick={
                        () => { this.showServersInfo() }
                    }>Серверы</CollapsedButton>

                </div>
                </Space>
                
                <ResizableTable
                    scroll={{y: clientHeight-130}} size='small'
                    rowClassName={(record: any, index) => {return this.rowColor(record.attempts, record.status)}}
                    columns={this.columns}
                    dataSource={this.dataSourceFiltered}
                    bordered
                    pagination={{ showSizeChanger: true, pageSizeOptions: ["7", "15", "100", "500", "1000"], defaultPageSize: 15, locale: { items_per_page: " / страницу" }}}
                    onRow={(record: any) => {
                        return {
                            onDoubleClick: () => {
                                let task = this.state.tasks?.find(x => x.task.id === record.id);
                                let point: Point | undefined = undefined;
                                if (task != undefined) {
                                    point = this.props.points?.find(x => x.number === task?.abonentNumber);
                                
                                    if (point == undefined) {
                                        message.warning("Ошибка, точка учета не найдена.");
                                        console.log("Ошибка, точка учета не найдена.", task, this.props.points);
                                    } else {
                                        this.setState({showTask: task, showTaskPoint: point, modalZIndex: getModalZIndex()});
                                    }
                                }
                            }
                        }
                    }}
                />
                </>}
                
                {
                    this.state?.pollingDicts == undefined ? null :
                    <TasksForm
                            taskStartTime={toDate(this.state?.showTask?.task.startTime ?? new Date())}
                            dicts={this.state.pollingDicts}
                            onHide={() => { this.setState({ showTask: null, showTaskPoint: null }) }}
                            point={this.state.showTaskPoint}
                            setIsLoading={this.props.setIsLoading}
                            commStatus={''}
                            devTypeId={Number(this.state.showTaskPoint?.persistProperties['devicetypeid']) ?? -1}
                            windowWidth={this.props.windowWidth}
                            zIndex={this.state.modalZIndex}
                            setActiveLogTask={this.props.setActiveLogTask}
                    />
                }
                {
                    <ShowTextForm
                        onHide={() => this.setState({ showText: null })}
                        text={this.state?.showText ?? null}
                        zIndex={this.state.modalZIndex}
                        title={"Серверы опроса"}
                        width={800}
                    />
                }

            </div>
        );
    }
}


