import * as React from 'react';
import {Button, Form, Tooltip} from 'antd';
import {connect} from 'react-redux';
import Login from '../Login2';
import {Periods} from '../PageHeader';
import styles from "../../resources/Page.module.less";
import {ApplicationState} from '../../store';
import * as ChartsStore from '../../store/Charts';
import {RouteComponentProps} from 'react-router';
import * as PageStore from '../../store/Page';
import * as UserProfileStore from '../../store/UserProfile';
import {SPointsFilter, Point, ICommand, PeriodsEnum, BaseProfile, SChart, SDataRecord} from "../../decl";
import Groups from "../Groups";
import { message, canReadData } from  "../../utils/Common";
import * as Const from "../../utils/Const";
import {FileZipOutlined, DownloadOutlined} from "@ant-design/icons";
import {IChartsState} from "../../store/Charts";
import {MiddleContentMessageBlock} from "../HeaderForm";

const actionCreators = {
  ...PageStore.actionCreators,
  ...UserProfileStore.actionCreators,
  ...ChartsStore.actionCreators
};
type HeaderProps =
  {
	userProfile: UserProfileStore.UserProfileState,
	windowWidth: number,
	isMenuCollapsed: boolean,
	charts: IChartsState,
	page: PageStore.PageState,
	profile: BaseProfile,
  } &
  typeof actionCreators &
  RouteComponentProps<{}>

//
interface HeaderState extends SPointsFilter {
  pointId: string;
}


//<HeaderProps, HeaderState>
class ChartPageHeader extends React.PureComponent<HeaderProps, HeaderState> {

  private isRequestData: boolean;


  constructor(props: HeaderProps) {
	super(props);

	let pointIdInit = this.props.page?.pointId ?? '';
	let period: PeriodsEnum = (this.props?.profile?.Common?.ArchivePeriod ?? PeriodsEnum.day) as PeriodsEnum;

	this.state = {
	  groups: (props?.userProfile?.profile?.Common?.Groups) ?? null,
	  points: (props?.userProfile?.profile?.Common?.Points) ?? '',
	  resources: (props?.userProfile?.profile?.Common?.Resources) ?? null,
	  systemNumbers: null,
	  text: '',
	  pointId: pointIdInit,
	}
	this.onSelectPoint = this.onSelectPoint.bind(this);
	this.onPeriodsChange = this.onPeriodsChange.bind(this);

	this.requestDeviceData = this.requestDeviceData.bind(this);

	this.isRequestData = false;
  }
  componentWillUnmount() {
	this.isRequestData = false;
  }

  onSelectPoint = (pointId: string) => {
	const point: Point | undefined = this.props.userProfile.allPoints[pointId];
	if (point == undefined) {
	  message.error('Не найдена ТУ в кеше.');
	} else {

	  this.props.setChartPoint(pointId);
	  this.props.setPoint(point.id, point.number);

	  this.setState({
		...this.state,
		pointId: pointId
	  });
	}
  };
  onPeriodsChange = (value: PeriodsEnum) => {
	this.props.changeProfile(Const.PROFILE_COMMON, {ArchivePeriod: value});
	this.props.setChartPeriod(value);

	this.props.setChartNeedRefresh();
  };

  /**
   * Действие по кнопке сбор данных
   */
  requestDeviceData() {

	if (this.isRequestData)
	  return;

	this.isRequestData = true;

	if (this.state.pointId == '') {
	  message.warn('Выберите точку учета', 2);
	  return;
	}

	message.loading({
	  key: 'chartsGetDataLoading',
	  content: 'Запрос на сбор данных отправлен',
	  duration: 180
	});

	  const period: PeriodsEnum = ((this.props?.userProfile?.profile?.Common?.ArchivePeriod) ?? 0) as PeriodsEnum;

	this.props.readData(
	  	period,
	  	[this.state.pointId],
	  	100,
	  	false,
	  	(code: number, ids: any) => {
			this.isRequestData = false;
			message.destroy('chartsGetDataLoading');

			if (code === 0) {
			message.success('Запрос на получение данных из приборов отправлен.');
			}
			if (code === 2) {
			message.success('Данные из приборов получены.');
			}
			if (code === 4) {
			message.warning('Не все данные из приборов удалось получить.');
			}
	  	},
	  	(error: string) => {
			this.isRequestData = false;
			message.destroy('chartsGetDataLoading');
			message.error(error);
		});
  }

  render() {
		const selectedPointIds = [this.state.pointId];

		const period: PeriodsEnum = ((this.props?.userProfile?.profile?.Common?.ArchivePeriod) ?? 0) as PeriodsEnum;

		const PeriodsProps = {
			value: period,
		  onChange: this.onPeriodsChange
		};

	const GroupsProps = {
	  groups: (this.props.userProfile) ? this.props.userProfile.userGroups : [],
	  groupPoints: (this.props.userProfile) ? this.props.userProfile.groupPoints : {},
	  selectedGroupIds: [],//selectedGroupIds,
	  selectedPointIds: selectedPointIds,
	  getPoints: this.props.requestGroupPoints,
	  getPointsParentGroups: null,
	  // onChange: this.onGroupsChange,
	  onSelectPoint: this.onSelectPoint,
	  // onSelectPoint: (id: string) => {
	  // console.log(id);
	  // },
	  setIsLoading: this.props.setIsLoading,
	  // className: chartsStyles.width100,
	  treeCheckable: false,
	  // onHideDropDown: this.onRefresh
	  style: {maxWidth: '100%'}
	};

	  const buttonAsIcon: boolean = this.props.isMenuCollapsed ? this.props.windowWidth <= 1900 : this.props.windowWidth <= 1980;
	let middleMaxSize: number;
	let onlyIcon: boolean = false;
	let hideEmail: boolean;
	let isSplit: boolean = true;
	if (buttonAsIcon) {
	  middleMaxSize = 1280;//this.props.isMenuCollapsed ? 1280 : 1400;
	  onlyIcon = true;
	  hideEmail = true;
	  isSplit = false;
	}
	else {
	  middleMaxSize = 1900;//this.props.isMenuCollapsed ? 1580 : 1600;
	  hideEmail = false;
	}

	return (
	  <Form
		autoComplete={"off"}
		className={styles.flexcontainer}
	  >
		<div className={styles.comboblock}>
		  <div className={styles.flexitem} style={{margin: '0 20px 0 0'}}>
			<Groups {...GroupsProps} />
		  </div>
		  <div className={styles.flexitem} style={{margin: '0 0 0 0'}}>
			<Periods {...PeriodsProps}  />
		  </div>
		</div>
		<div className ={styles.buttonsblock}>
		  <Button
			type="primary"
			shape="round"
			icon={<DownloadOutlined/>}
					style={{ marginRight: '15px' }}
					disabled={!canReadData(this.props.userProfile?.rights, period)}
			onClick={() => {
			  this.requestDeviceData();
			}}
		  >
			Сбор данных
		  </Button>
		  <Tooltip placement="right" title={'Печать'}>
			{/*AdminHeaderForm.tsx (exportChart)*/}
			<Button
			  type="primary"
			  shape="round"
			  icon={<FileZipOutlined/>}
			  onClick={() => this.props.chartsExport(Date.now())}
			>
			  Печать
			</Button>
		  </Tooltip>
		</div>
		<div className={styles.mbitem}>
		  <MiddleContentMessageBlock
			windowSize={this.props.windowWidth}
			maxWindowSize={middleMaxSize}
			onlyIcon={onlyIcon}
			hideEmail={hideEmail}
			isSplit={isSplit}
		  />
		</div>
		<div className={styles.flexuseritem}>
		  <Login />
		</div>

	  </Form>
	);
  }


}

export default connect(
  (state: ApplicationState) => {
	return {
	  userProfile: state.userProfile,
	  windowWidth: state.page.windowWidth,
	  charts: state.charts,
	  page: state.page,
	  isMenuCollapsed: state.siderMenu?.collapsed
	}
  },
  actionCreators
)(ChartPageHeader as any);
