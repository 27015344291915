import * as React from 'react';

import * as SettingsStore from '../store/SettingsStore';

import { Button, Checkbox, Col, Input, InputNumber, Modal, Row, Select, Table } from 'antd';

import pageStyles from '../resources/Page.module.less';
import { EventType, UserContact } from '../store/SettingsStore';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import SelectForm from './Admin/SelectForm';
import { Command, Group, Point, Resource } from '../decl';
import * as Const from '../utils/Const';
import { getModalZIndex } from '../utils/Common';
import ContactPointDaySendMaxCountForm from './ContactPointDaySendMaxCountForm';
import { SelectPeriods } from './Helper/SelectPeriods'; 

const { Option } = Select;

type Props = {
    userName: string,
    userAllPoints: string[],

    eventTypes: EventType[],
    messageSendTypes: {[messageSendTypeId: number]: string},

    contact: SettingsStore.UserContact | undefined,
    visible: boolean,
    zIndex: number,
    writeContact: (contact: UserContact) => any,
    onClose: () => void,

    userGroups: Group[],
    groupPoints: {[groupId: string] : Point[]},
    requestGroupPoints: (iGroupId: string, resolve: any, reset:Boolean) => any,
    requestMultipleGroupPoints?: (iGroupIds: string[], resolve: any, reset:Boolean) => any,
    resources: Resource[],
    setIsLoading: any,
    smallWindow: boolean,
}

type State = {
    contact: SettingsStore.UserContact;

    showAbonentsTree: boolean,
    showEventTypeId: number | undefined,

    selectedGroupsIds: string[],
    selectedAbonentsIds: string[],
    showContactPointDaySendMaxCountForm: boolean,
    modalZIndex: number
}

//Модальное окно добавления нового контакта
class ContactForm extends React.Component<Props, State> {
    constructor(props: Props, state: State) {
        super(props);

        this.state = {
            contact: {
                clientId: '',
                count: 5,
                periods: 0,
                eventsList: '',
                id: '',
                sendMask: 0,
                sendTypeId: -1,
                sendTypeName: '',
                userName: this.props.userName,
                messageSendTypesAbonents: {},
                abonentsDaySendMaxCount: {},
            },
            selectedAbonentsIds: [],
            selectedGroupsIds: [],
            showAbonentsTree: false,
            showEventTypeId: -1,
            showContactPointDaySendMaxCountForm: false,
            modalZIndex: 0
        }
    }    
    
    componentDidUpdate(prevProps: Props) {
        if (prevProps.visible === false && this.props.visible === true) {
            let contact: UserContact;
            if (this.props.contact === undefined) {
                contact = {
                    clientId: '',
                    count: 5,
                    periods: 0,
                    eventsList: '',
                    id: '',
                    sendMask: 0,
                    sendTypeId: parseInt(this.getFirstFieldKey(this.props.messageSendTypes)) ?? -1,
                    sendTypeName: this.props.messageSendTypes[parseInt(this.getFirstFieldKey(this.props.messageSendTypes))] ?? '',
                    messageSendTypesAbonents: {},
                    userName: this.props.userName,
                    abonentsDaySendMaxCount: {},
                }
                
                this.setState({contact, showAbonentsTree: false})
            } else {
                let cnt = {...this.props.contact};
                cnt.messageSendTypesAbonents = {...cnt.messageSendTypesAbonents};
                cnt.abonentsDaySendMaxCount =  ContactForm.makeActualAbonentsDaySendMaxCount(cnt.abonentsDaySendMaxCount, cnt.messageSendTypesAbonents);
                this.setState({contact: cnt, showAbonentsTree: false})
            }
        }
    }

    findPoint = (id: string) => {
        let res = false; 
        for (const e in this.state.contact.messageSendTypesAbonents) {
            if (this.state.contact.messageSendTypesAbonents[Number(e)]?.includes(id)) {
                res = true;
                break;
            }
        }
        return res;
    }

    write = () => {
        //console.log('contact write', this);
        let contact = {...this.state.contact};
        //Общая маска
        let mask: number = 0;
        for (const m in contact.messageSendTypesAbonents) {
            mask |= this.props.eventTypes.find(x => x.id === Number(m))?.typeMask ?? 0;
        }
        contact.sendMask = mask;
        //Количество сообщений на ТУ
        let count: number = 0;
        for (const p in contact.abonentsDaySendMaxCount) {
            if (this.findPoint(p)) {
                count += contact.abonentsDaySendMaxCount[p];
            }
        }

        if (contact.count < count) {
            Modal.confirm({
                title: 'Не соответствие числа отправляемых сообщений',
                wrapClassName: Const.MODAL_WRAP_CLASSNAME,
                zIndex: getModalZIndex(),
                content: <span>{`Суммарное число отправляемых сообщений по точкам учета ${count} меньше максимального числа сообщений для указанного контакта ${contact.count}.`}<br/> {`Установить максимальное число сообщений в сутки для указанного контакта в ${count}`}</span>,
                width: 600,
                okText: 'Да',
                cancelText: 'Нет',
                onOk: () => {
                    contact.count = count;
                    this.props.writeContact(contact);
                    this.props.onClose();
                },
                onCancel: () => {
                    this.props.writeContact(contact);
                    this.props.onClose();
                }
            });
        } else {
            this.props.writeContact(contact);
            this.props.onClose();
        }
    }

    //вызов окна дерева выбора точек учета
    showAbonentsTree(eventTypeId: number) {
        this.setState({
            showAbonentsTree: true,
            modalZIndex: getModalZIndex(),
            showEventTypeId: eventTypeId,
            selectedAbonentsIds: this.state.contact.messageSendTypesAbonents[eventTypeId ?? -1] == null ? [] : this.state.contact.messageSendTypesAbonents[eventTypeId ?? -1] ?? [],
            selectedGroupsIds: this.state.contact.messageSendTypesAbonents[eventTypeId ?? -1] === null ? [Const.ALL_GROUP_ID] : []
        });
    }
    
    getFirstFieldKey = (obj: any): any => {
        let res: any;
        
        if (obj !== undefined && Object.keys(obj).length > 0) {
            res = Object.keys(obj)[0];
        }

        return res;
    }

    //приводит abonentsDaySendMaxCount в соответствие со стиском ТУ который задействован в messageSendTypesAbonents что бы не отображать лишние ТУ
    static makeActualAbonentsDaySendMaxCount = (abonentsDaySendMaxCount: any, msta: {[eventId: number]: string[]}) => {
        //Собираем все задействованые ТУ
        let points: string[] = [];
        for (const k in msta) {
            for (const p of msta[Number(k)] ?? []) {
                if (!points.includes(p)) {
                    points.push(p);
                }
            }
        }
        //Приводим словарь abonentsDaySendMaxCount в соответсвии с задействованными ТУ
        let adsmc: {[pointId: string]: number} = {};
        for (const p of points) {
            if (abonentsDaySendMaxCount[p] == undefined) {
                adsmc[p] = Const.ABONENTS_DAY_SEND_MAX_COUNT_DEFAULT;
            } else {
                adsmc[p] = abonentsDaySendMaxCount[p];
            }
        }

        return adsmc;
    }

    render() {
        let typeLabel = 'Номер телефона';
        if (this.state.contact.sendTypeId === Const.SEND_TYPE_ID_EMAIL) {
            typeLabel = 'Электронный адрес';
        } else if (this.state.contact.sendTypeName === Const.SEND_TYPE_NAME_TELEGRAM) {
            typeLabel = 'Telegram ИД';
        }

        const modalStyles = this.props.smallWindow ? { top: '3px' } : {}
        const selectFormHeight = this.props.smallWindow ? 300 : 500;
        return (
            <>
                <Modal
                    style={modalStyles}
                    title={this.props.contact === undefined ? "Добавление нового контакта" : "Редактирование контакта"}
                    wrapClassName={Const.MODAL_WRAP_CLASSNAME}
                    zIndex={this.props.zIndex}
                    open={this.props.visible}
                    onOk={() => this.write()}
                    onCancel={this.props.onClose}
                    okText= {this.props.contact === undefined ? "Добавить" : "Изменить"}
                    cancelText="Отмена"
                    bodyStyle={{height: this.props.smallWindow == true ? 475 : 600, overflow: 'auto'}}
                >
                    <div className={pageStyles.FormWrapper} >
                        <Input.Group>
                            <Row className={pageStyles.FormRowWrapper} gutter={8}>
                                <Col span={12}>
                                    Тип оповещения
                                </Col>
                                <Col span={12}>
                                    <Select
                                        className={pageStyles.Width100}
                                        dropdownMatchSelectWidth={false}
                                        value={this.props.messageSendTypes[this.state.contact.sendTypeId]} 
                                        size="small"
                                        onChange={(value: any) => this.setState({contact: {...this.state.contact, sendTypeId: value, sendTypeName: this.props.messageSendTypes[value]}})}
                                    >
                                        {Object.keys(this.props.messageSendTypes ?? {}).map(k => <Option key={Number(k)} value={Number(k)}>{this.props.messageSendTypes?.[Number(k)]}</Option>)}
                                    </Select>
                                </Col>
                            </Row>
                            <Row className={pageStyles.FormRowWrapper} gutter={8}>
                                <Col span={12}>
                                    { typeLabel }
                                </Col>
                                <Col span={this.state.contact.sendTypeName !== Const.SEND_TYPE_NAME_TELEGRAM ? 12 : 6}>
                                    <Input className={pageStyles.Width100} size="small" value={this.state.contact.clientId} onChange={(e: any) => this.setState({contact: {...this.state.contact, clientId: e.target.value}})} />
                                </Col>
                                {
                                    this.state.contact.sendTypeName !== Const.SEND_TYPE_NAME_TELEGRAM ? null :
                                    <Col span={6}>
                                        <Button className={pageStyles.Width100} href='https://t.me/Teplo_inform_bot' target='_blank' size='small' type='primary'>Получить ИД</Button>
                                    </Col>
                                }
                            </Row>
                            <Row className={pageStyles.FormRowWrapper} gutter={8}>
                                <Col span={12}>
                                    Периоды архивирования
                                </Col>
                                <Col span={12}>
                                    <SelectPeriods
                                        periods={this.state.contact.periods}
                                        onChange={(periods) => { this.setState({ contact: { ...this.state.contact, periods: periods } })  }}
                                    />
                                </Col>
                            </Row>
                            <Row className={pageStyles.FormRowWrapper} gutter={8}>
                                <Col span={12}>
                                    Максимальное количество сообщений в сутки
                                </Col>
                                <Col span={12}>
                                    <InputNumber className={pageStyles.Width100} size="small" min={0} value={this.state.contact?.count} onChange={(value: any) => this.setState({contact: {...this.state.contact, count: value}})}/>
                                </Col>
                            </Row>
                        </Input.Group>
                    
                        <Table
                            bordered={true}
                            size={'small'}
                            className={pageStyles.FormRowWrapper}
                            dataSource={
                                this.props.eventTypes.map(item => (
                                {
                                    key: item.id,
                                    col1: 
                                        <Checkbox 
                                            value={item.id} 
                                            checked={this.state.contact.messageSendTypesAbonents[item.id] !== undefined}
                                            onChange={(e: CheckboxChangeEvent) => {
                                                    let msta = {...this.state.contact.messageSendTypesAbonents};
                                                    if (e.target.checked) {
                                                        msta[item.id] = [...this.props.userAllPoints];
                                                    } else {
                                                        delete msta[item.id];
                                                    }

                                                    const adsmc = ContactForm.makeActualAbonentsDaySendMaxCount(this.state.contact.abonentsDaySendMaxCount, msta);

                                                    this.setState({contact: {...this.state.contact, messageSendTypesAbonents: msta, abonentsDaySendMaxCount: adsmc}});
                                                }    
                                            }
                                        >
                                            {item.name}
                                        </Checkbox>,
                                    col2: item.id !== 8 && this.state.contact.messageSendTypesAbonents[item.id] !== undefined ? 'show' : 'hide'
                                })) 
                            }
                            columns={[
                                {
                                    title: 'Контроль',
                                    dataIndex: 'col1',
                                    width: 150,
                                },
                                {
                                    title: 'Точки учёта',
                                    dataIndex: 'col2',
                                    key: 'action',
                                    render: (value, record, index) => value === 'show' ? <a onClick={() => this.showAbonentsTree(Number(record.key))}>Выбрать</a> : null,
                                    width: 50
                                }, 
                            ]}
                            pagination={false} 
                            //scroll={{ y: 360 }} 
                        />
                        <Button type='primary' onClick={() => this.setState({showContactPointDaySendMaxCountForm: true, modalZIndex: getModalZIndex()})}>Количество сообщений в сутки по ТУ</Button> 
                    </div>
                </Modal>

                <SelectForm
                    title='Выбор точек учёта' 
                    visible={this.state.showAbonentsTree && this.props.visible}
                    zIndex={this.state.modalZIndex}
                    clientHeight={selectFormHeight}
                    groups={this.props.userGroups}
                    groupPoints={this.props.groupPoints}
                    selectedGroupIds={this.state.selectedGroupsIds}
                    selectedPointIds={this.state.selectedAbonentsIds}
                    getPoints={this.props.requestGroupPoints}
                    getMultipleGroupPoints={this.props.requestMultipleGroupPoints}
                    getPointsParentGroups={null}
                    onChange={this.onValueChange}
                    onChangePoints={this.onPointsChange}
                    setIsLoading={this.props.setIsLoading}
                    onClose={() => this.setState({showAbonentsTree: false})}
                    resources = {this.props.resources}
                    onlyTree = {true}
                />
                <ContactPointDaySendMaxCountForm
                    smallWindow={this.props.smallWindow}
                    visible={this.state.showContactPointDaySendMaxCountForm && this.props.visible}
                    zIndex={this.state.modalZIndex}
                    groups={this.props.userGroups}
                    groupPoints={this.props.groupPoints}
                    getPoints={this.props.requestGroupPoints}
                    getPointsParentGroups={null}
                    onOk={this.onCountOk}
                    setIsLoading={this.props.setIsLoading}
                    onHide={() => this.setState({showContactPointDaySendMaxCountForm: false})}
                    abonentsDaySendMaxCount={this.state?.contact?.abonentsDaySendMaxCount ?? {}}
                />
            </>
        );
    }

    onCountOk = (abonentsDaySendMaxCount: {[pointId: string]: number}) => {
        this.setState({contact: {...this.state.contact, abonentsDaySendMaxCount: abonentsDaySendMaxCount}, showContactPointDaySendMaxCountForm: false});
    }

    onValueChange = (groups: any, points: string[]) => {
        this.setState({selectedAbonentsIds: [...points], selectedGroupsIds: [...groups]});
    };
    onPointsChange = (points: Point[]) => {
        let tmp = {...this.state.contact.messageSendTypesAbonents};
        if (this.state.showEventTypeId) {
            tmp[this.state.showEventTypeId] = points.map(item => item.id);
        };
        const adsmc = ContactForm.makeActualAbonentsDaySendMaxCount(this.state.contact.abonentsDaySendMaxCount, tmp);
        this.setState({contact: {...this.state.contact, messageSendTypesAbonents: tmp, abonentsDaySendMaxCount: adsmc}, showAbonentsTree: false});
    };
}


export default ContactForm;