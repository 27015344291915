import * as React from 'react';
import { Form, Button, Input, Tooltip } from 'antd';
import { connect } from 'react-redux';

import Login from './Login2';
import PageTitle from './PageTitle';
import Resources from './Resources';
import {Periods} from './PageHeader';
import Groups from './Groups';
import FilterField from './FilterField';
import SearchInput from './SearchInput';

import { ApplicationState } from '../store';
import * as PageStore from '../store/Page';
import * as UserProfileStore from '../store/UserProfile';
import * as KadrStore from '../store/Kadr';
import { RouteComponentProps } from 'react-router';
import styles from '../resources/Page.module.less';
import { SPointsFilter, Point, ICommand, PeriodsEnum, BaseProfile, USER_RIGHTS } from "../decl"
import * as Const from '../utils/Const';
import * as Common from '../utils/Common';
import { canReadData} from '../utils/Common';
import {netiExecute, message} from '../utils/Common';
import {MiddleContentMessageBlock} from "./HeaderForm";

const {Search} = Input;

import {
    SyncOutlined,
    TableOutlined,
    DownloadOutlined,
    SettingOutlined
} from '@ant-design/icons';

import {  
  UserOutlined,
  FullscreenOutlined,
  MenuOutlined,
  FileZipOutlined,
  HomeOutlined,
  GroupOutlined,
  PhoneOutlined,
  WhatsAppOutlined,
  SendOutlined,
  MessageOutlined,
  ControlOutlined
} from '@ant-design/icons';

const actionCreators = { ...PageStore.actionCreators, ...UserProfileStore.actionCreators, ...KadrStore.actionCreators };

type HeaderProps =
    { userProfile: UserProfileStore.UserProfileState, kadr: KadrStore.IKadrState, windowWidth: number, isMenuCollapsed: boolean } &
    typeof actionCreators &
    RouteComponentProps<{}>

interface HeaderState {
    filter: SPointsFilter;
    waitData: boolean;
}

class KadrHeaderForm extends React.PureComponent<HeaderProps, HeaderState>{
    constructor(props: HeaderProps) {

        super(props);
        this.state = {
            filter: {
                groups: (props?.userProfile?.profile?.Common?.Groups) ?? null,
                points: (props?.userProfile?.profile?.Common?.Points) ?? '',
                resources: (props?.userProfile?.profile?.Common?.Resources) ?? null,
                systemNumbers: null,
                text: props.kadr.textFilter
            },
            waitData: false
        }
        this.onValueChange = this.onValueChange.bind(this);
        this.onTextChange = this.onTextChange.bind(this);
        this.onResourcesChange = this.onResourcesChange.bind(this);
        this.onRefresh = this.onRefresh.bind(this);
        this.onPeriodsChange = this.onPeriodsChange.bind(this);
        this.onAdditional = this.onAdditional.bind(this);
        this.onReadData = this.onReadData.bind(this);
        this.onExcel = this.onExcel.bind(this);
        this.execute = this.execute.bind(this);
    }
    execute(cmd: any) {
        if (cmd.Name === Const.REFRESH_COMMAND) {
            this.props.setNeedRefresh(true);
        }
        else {
            netiExecute(cmd, this.props.userProfile, this.props.changeProfile, this.props.setIsLoading, this.props.setActiveQueries, null, this.props.showModal);
        }
    }

    onRefresh = () => {
        this.props.changeProfile(
            Const.PROFILE_COMMON,
            {Groups: this.state.filter.groups, Points: this.state.filter.points, Resources: this.state.filter.resources});
        this.props.setTextFilter(this.state.filter.text);
        this.props.setNeedRefresh(true);
    };

    onValueChange = (groups: any, points: any) => {
        this.setState({
            filter: {
                ...this.state.filter,
                groups: (groups.indexOf(Const.ALL_GROUP_ID) >= 0) ? null : Common.arrayToString(groups),
                points: Common.arrayToString(points)
            }
        });
    };
    onResourcesChange = (value: any) => {
        this.setState({
            filter: {
                 ...this.state.filter,
                resources: (value.indexOf(Const.ALL_RESOURCE_ID) >= 0) ? null : Common.arrayToString(value)
             }
        });
    };

    onPeriodsChange = (value: PeriodsEnum) => {
        this.props.changeProfile(Const.PROFILE_COMMON, {ArchivePeriod: value});
        this.onRefresh();
    };

    onTextChange = (ev: any) => {
        const strValue = ev.target.value;
        this.setState({ filter: { ...this.state.filter, text: strValue } });
    }
    onExcel = () => {
        const cmd: ICommand = {
            Source: null,
            Name: 'showExcel',
            Params: 'excelkadr',
            Callback: null,
            ExceptionCallback: null
        };
        this.props.setCommandToExecute(cmd);
    };

    onAdditional = () => {
        const setIsLoading: any = this.props.setIsLoading;
        const netiSource: any = new (window.NetiSource as any)(this.execute);
        setIsLoading(true);
        this.props.showAdditionalDialog(
            netiSource,
            () => {
                setIsLoading(false, 500)
            },
            () => {
                setIsLoading(false, 500)
            }
        );
    };

    onReadData = () => {
        const period = this?.props?.userProfile?.profile?.Common?.ArchivePeriod;
        
        if(period !== undefined){
            this.props.readData(
                period,
                this.props.kadr.pointIds,
                100,
                false,
                (code: number, ids: any) => {
                    if(code === 0) {
                        message.success('Запрос на получение данных из приборов отправлен.');
                        this.setState({waitData: true});
                    }
                    if(code === 2) {
                        message.success('Данные из приборов получены.');
                        this.setState({waitData: false});
                    }
                    if(code === 4) {
                        message.warning('Не все данные из приборов удалось получить.');
                        this.setState({waitData: false});
                    }
                },
                (error: string) => {
                    message.error(error);
                    console.log(error);
                    this.setState({waitData: false});
                });
        }
    };

    render() {
        const selectedGroupIds = (this.state.filter.groups != null) ?
            Common.stringToArray(this.state.filter.groups) :
            [Const.ALL_GROUP_ID];
        const selectedPointIds = (this.state.filter.points != null) ?
            Common.stringToArray(this.state.filter.points) :
            [];
        const selectedResourceIds = (this.state.filter.resources != null) ?
            Common.stringToArray(this.state.filter.resources) :
            [Const.ALL_RESOURCE_ID];

        const GroupsProps = {
            groups: (this.props.userProfile) ? this.props.userProfile.userGroups : [],
            groupPoints: (this.props.userProfile) ? this.props.userProfile.groupPoints : {},
            selectedGroupIds: selectedGroupIds,
            selectedPointIds: selectedPointIds,
            getPoints: this.props.requestGroupPoints,
            getPointsParentGroups: null,
            onChange: this.onValueChange,
            setIsLoading: this.props.setIsLoading,
            onHideDropDown: this.onRefresh
        };

        const ResourcesProps = {
            resources: (this.props.userProfile) ? this.props.userProfile.resources : [],
            selectedResourceIds: selectedResourceIds,
            onChange: this.onResourcesChange,
            onHideDropDown: this.onRefresh
        };

        const period: PeriodsEnum = ((this.props?.userProfile?.profile?.Common?.ArchivePeriod) ?? 0) as PeriodsEnum;

        const PeriodsProps = {
            value: period,
            onChange: this.onPeriodsChange
        };

 //                       <div className={styles.flexitem}>
 //                           <WeatherInformer latitude="55.776279" longitude="37.474776"/>
 //                       </div>

        const buttonAsIcon = this.props.windowWidth < 1900;
        const middleMaxSize = this.props.isMenuCollapsed ? 1360 : 1500;
        if(buttonAsIcon){
            return (
                <div className={styles.flexcontainer}>
                    <div className={styles.comboblock}>
                        <div className={styles.flexitem}>
                            <Groups {...GroupsProps} />
                        </div>
                        <div className={styles.flexitem}>
                            <Resources {...ResourcesProps} />
                        </div>
                        <div className={styles.flexitem}>
                            <Tooltip placement="bottomRight" title={'Поиск точки учёта'}>
                                <Search  className ={styles.searchText} value={this.state.filter.text} placeholder="Поиск" onChange={this.onTextChange} onSearch={this.onRefresh}/>
                            </Tooltip>
                        </div>
                        <div className={styles.flexitem}>
                            <Periods {...PeriodsProps} />
                        </div>
                    </div>
                    <div className ={styles.buttonsblock}>
                        <div className={styles.iconflexitem}>
                            <Tooltip placement="bottomRight" title={'Обновить данные'}>
                                <Button type="primary" shape="circle" icon={<SyncOutlined/>}  onClick={this.onRefresh}/>
                            </Tooltip>
                        </div>
                        <div className={styles.iconflexitem}>
                            <Tooltip placement="bottomRight" title={'Дополнительные настройки'}>
                                <Button type="primary" shape="circle" icon={<SettingOutlined/>}  onClick={this.onAdditional}/>
                            </Tooltip>
                        </div>
                        <div className={styles.iconflexitem}>
                            <Tooltip placement="bottomRight" title={'Получить данные с приборов'}>
                                <Button type="primary" shape="circle" icon={<DownloadOutlined />} disabled={this.state.waitData || !canReadData(this.props.userProfile?.rights, period)} onClick={this.onReadData}/>
                            </Tooltip>
                        </div>
                        <div className={styles.iconflexitem}>
                            <Tooltip placement="bottomRight" title={'Выгрузить таблицу'}>
                                <Button type="primary" shape="circle" icon={<TableOutlined/>}  onClick={this.onExcel}/>
                            </Tooltip>
                        </div>
                    </div>
                    <div className={styles.mbitem}>
                        <MiddleContentMessageBlock windowSize={this.props.windowWidth} maxWindowSize={middleMaxSize} onlyIcon={true}/>
                    </div>
                    <div className={styles.flexuseritem}>
                        <Login />
                    </div>
                </div>
            );
        }
        else{//Кнопки с надписями.
            const maxWindowSize = this.props.isMenuCollapsed ? 1920 : 2035;
            const messageAsIcon = this.props.windowWidth < (this.props.isMenuCollapsed ? 2320 : 2435);//this.props.windowWidth < (this.props.isMenuCollapsed ? 2130 : 2245);
            const hideEmail = false;//this.props.windowWidth < (this.props.isMenuCollapsed ? 2320 : 2435);
            const isSplit = this.props.windowWidth >= (this.props.isMenuCollapsed ? 2510 : 2625);
            return (
                <div className={styles.flexcontainer}>
                    <div className={styles.comboblock}>
                        <div className={styles.flexitem}>
                            <Groups {...GroupsProps} />
                        </div>
                        <div className={styles.flexitem}>
                            <Resources {...ResourcesProps} />
                        </div>
                        <div className={styles.flexitem}>
                            <Tooltip placement="bottomRight" title={'Поиск точки учёта'}>
                                <Search className ={styles.searchText} value={this.state.filter.text} placeholder="Поиск" onChange={this.onTextChange} onSearch={this.onRefresh}/>
                            </Tooltip>
                        </div>
                        <div className={styles.flexitem}>
                            <Periods {...PeriodsProps} />
                        </div>
                    </div>
                    <div className ={styles.buttonsblock}>
                        <div className={styles.flexitem}>
                            <Tooltip placement="bottomRight" title={'Обновить данные'}>
                                <Button type="primary" shape="round" className={styles.headerbutton} icon={<SyncOutlined/>}  onClick={this.onRefresh}>Обновить</Button>
                            </Tooltip>
                        </div>
                        <div className={styles.flexitem}>
                            <Tooltip placement="bottomRight" title={'Дополнительные настройки'}>
                                <Button type="primary" shape="round" className={styles.headerbutton} icon={<SettingOutlined/>}  onClick={this.onAdditional}>Дополнительно</Button>
                            </Tooltip>
                        </div>
                        <div className={styles.flexitem}>
                            <Tooltip placement="bottomRight" title={'Получить данные с приборов'}>
                                <Button type="primary" shape="round" className={styles.headerbutton} icon={<DownloadOutlined />} disabled={this.state.waitData || !canReadData(this.props.userProfile?.rights, period)} onClick={this.onReadData}>Сбор данных</Button>
                            </Tooltip>
                        </div>
                        <div className={styles.flexitem}>
                            <Tooltip placement="bottomRight" title={'Выгрузить таблицу'}>
                                <Button type="primary" shape="round" className={styles.headerbutton} icon={<TableOutlined/>}  onClick={this.onExcel}>Выгрузить таблицу</Button>
                            </Tooltip>
                        </div>
                    </div>
                    <div className={styles.mbitem}>
                        <MiddleContentMessageBlock windowSize={this.props.windowWidth} maxWindowSize={maxWindowSize} onlyIcon={messageAsIcon} hideEmail={hideEmail} isSplit={isSplit}/>
                    </div>
                    <div className={styles.flexuseritem}>
                        <Login />
                    </div>
                </div>
            );
        }
    }
}


export default connect(
    (state: ApplicationState) => {
        return {
            userProfile: state.userProfile,
            kadr: state.kadr,
            windowWidth: state.page.windowWidth,
            isMenuCollapsed: state.siderMenu?.collapsed
        }
    },
    actionCreators
)(KadrHeaderForm as any);
