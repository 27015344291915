import { initDates, message } from '../utils/Common';
import { defaultPrefixCls } from 'antd/lib/config-provider';
import { request } from 'http';
import { getgid } from 'process';
import { Action, Reducer } from 'redux';
import LayerView from '../components/Map/LayerView';
import { ResponseStatusesEnum, SResponseDTO } from '../decl';
import { AppThunkAction } from '.';
import { BaseProfile } from '../decl';
import { CloseSessionAction } from './Page';
import { getHeaders, BACKEND_URL } from '../utils/AuthUtils';

//Главный класс
export interface ISettingsState {
    isUserContactsLoading: boolean;
    isSendingReportsLoading: boolean;
    userContacts: {[contactId: string] : UserContact} | undefined;
    sendingReports: {[userContactId: string] : SendingReport[]} | undefined;
    isTypesLoading: boolean;
    eventTypes: EventType[] | undefined;
    messageSendTypes: {[messageSendTypeId: number]: string} | undefined;
    schedulesTypes: {[scheduleId: number]: string} | undefined;
    reportsTypes: {[reportId: number]: string} | undefined;
    periodTypes: {[period: number]: string} | undefined;
    reportingPeriodTypes: {[ReportingPeriodTypeId: number]: string} | undefined;
    fileNameTypes: {[SendingReportFileNameFormingTypeId: number]: string} | undefined;
};

export interface EventType {
    id: number;
    name: string;
    typeMask: number;
}

export interface UserContact {
    // ID контакта
    id: string;
    // Тип отправки
    sendTypeId: number;
    // Название отправки
    sendTypeName: string; // ??? Возможно удалить
    // Номер телефона, адрес эл.почты и т.д.
    clientId: string;
    // Пользователь
    userName: string;
    // Маска событий отправки
    // Определяется как поразрядное или событий типа UserEventType, поле TypeMask
    sendMask: number;
    // Список событий
    eventsList: string; // ??? Возможно удалить
    // Количество сообщений за сутки
    count: number;
    // Периоды архивирования.
    periods: number;
    // Идентификаторы SendingReportType со списком идентификаторов ТУ
    messageSendTypesAbonents: {[eventId: number]: string[]};
    //Количество сообщений в сутки по точкам учёта.
    abonentsDaySendMaxCount: {[pointId: string]: number};
}

export interface SendingReport
{
    /// <summary>
    /// Идентификатор.
    /// </summary>
    id: number;
    /// <summary>
    /// Идентификатор контакта.
    /// </summary>
    userContactId: string;
    /// <summary>
    /// Идентификатор отчёта.
    /// </summary>
    reportId: number;
    /// <summary>
    /// Период.
    /// </summary>
    period: number;
    /// <summary>
    /// Идентификатор расписания.
    /// </summary>
    scheduleId: number;
    /// <summary>
    /// Описание.
    /// </summary>
    description: string;
    /// <summary>
    /// Время начала.
    /// </summary>
    startDateTimeInterval: Date | null;
    /// <summary>
    /// Время конца.
    /// </summary>
    endDateTimeInterval: Date | null;
    /// <summary>
    /// Использовать Интервал по умолчанию.
    /// </summary>
    useDefaultDateTimeInterval: boolean;
    /// <summary>
    /// Тип периода.
    /// </summary>
    reportingPeriodTypeId: number;
    /// <summary>
    /// Тип названия файла.
    /// </summary>
    fileNameTypeId: number;

    sendingReportInfos: string[];
}

//============================================ Функции ============================================
//Запрос точек учета
interface RequestContacts {
    type: 'REQUEST_CONTACTS';
}
//Получение ТУ
interface ReceiveContacts {
    type: 'RECEIVE_CONTACTS';
    contacts: UserContact[];
}
interface WriteContacts {
    type: 'WRITE_CONTACTS';
    contacts: UserContact[];
}
interface DeleteContact {
    type: 'DELETE_CONTACT';
    contacts: UserContact;
}

//Запрос отсылаемых отчетов
interface RequestSendingReports {
    type: 'REQUEST_SENDING_REPORT';
}
//Получение отсылаемых отчетов
interface ReceiveSendingReports{
    type: 'RECEIVE_SENDING_REPORTS';
    sendingReports: SendingReport[];
}
//Запись отсылаемого отчета
interface WriteSendingReport{
    type: 'WRITE_SENDING_REPORT';
    sendingReport: SendingReport;
}

interface DeleteSendingReport{
    type: 'DELETE_SENDING_REPORT';
    sendingReport: SendingReport;
}

interface SetState {
    type: 'SET_SETTINGS_STATE';
    value: Partial<ISettingsState>;
}

interface RequestSettingsTypes {
    type: 'REQUEST_SETTINGS_TYPES';
}

interface ReceiveSettingsTypes {
    type: 'RECEIVE_SETTINGS_TYPES';
    eventTypes: EventType[];
    messageSendTypes: {[messageSendTypeId: number]: string};
    schedulesTypes: {[scheduleId: number]: string};
    reportsTypes: {[reportId: number]: string};
    periodTypes: {[period: number]: string};
    reportingPeriodTypes: {[ReportingPeriodTypeId: string]: string};
    fileNameTypes: {[SendingReportFileNameFormingTypeId: string]: string};
}

type KnownAction = RequestContacts | ReceiveContacts | SetState | RequestSettingsTypes | ReceiveSettingsTypes | RequestSendingReports | ReceiveSendingReports | 
                    WriteSendingReport | WriteContacts | DeleteSendingReport | DeleteContact | CloseSessionAction;

export const actionCreators = {
    //ReceiveContacts: (textFilter: string) => ({type: 'RECEIVE_CONTACTS'} as ReceiveContacts),

    requestContacts: (userName: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        dispatch({type: 'SET_SETTINGS_STATE', value: {isUserContactsLoading: true}});
        //console.log('cn lg', filter);
        if (appState) {
            const requestOptions = {
                method: 'POST',
                headers: getHeaders(),
                body: JSON.stringify(userName)
            };
            //console.log('cn lg', requestOptions.body);
            fetch(BACKEND_URL + 'Settings/contacts', requestOptions)
                .then(response => response.json() as Promise<SResponseDTO>)
                .then(data => {
                    if (data.bodyStatus && data.bodyStatus == ResponseStatusesEnum.SessionClosed) {
                        dispatch({ type: 'CLOSE_SESSION', message: data.message })
                    }
                    else if (data.bodyStatus && data.bodyStatus == ResponseStatusesEnum.Ok) {
                        console.log('Settings/contacts', (data.body));
                        dispatch({type: 'RECEIVE_CONTACTS', contacts: data.body})
                    }
                    else if (data.bodyStatus && data.bodyStatus == ResponseStatusesEnum.Error) {
                        message.error(data.message);
                    }
                    else {
                        message.error('Ответ не получен.');
                    }
                    dispatch({type: 'SET_SETTINGS_STATE', value: {isUserContactsLoading: false}});
                })
                .catch(error => {
                    message.error('Ошибка:' + error);
                    console.log(error);
                    dispatch({type: 'SET_SETTINGS_STATE', value: {isUserContactsLoading: false}});
                });
            
        }
    },
    writeContact: (contact: UserContact): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        dispatch({type: 'SET_SETTINGS_STATE', value: {isUserContactsLoading: true}});
        console.log('Settings/write_contact', contact);
        if (appState) {
            const requestOptions = {
                method: 'POST',
                headers: getHeaders(),
                body: JSON.stringify(contact)
            };
            //console.log('cn lg', requestOptions.body);
            fetch(BACKEND_URL + 'Settings/write_contact', requestOptions)
                .then(response => response.json() as Promise<SResponseDTO>)
                .then(data => {
                    if (data.bodyStatus && data.bodyStatus == ResponseStatusesEnum.SessionClosed) {
                        dispatch({ type: 'CLOSE_SESSION', message: data.message })
                    }
                    else if (data.bodyStatus && data.bodyStatus == ResponseStatusesEnum.Ok) {
                        console.log('Settings/write_contact', (data.body));
                        contact.id = String(data.body);
                        dispatch({type: 'SET_SETTINGS_STATE', value: {userContacts: {...appState.settingsStore?.userContacts, [contact.id]: contact}}})
                    }
                    else if (data.bodyStatus && data.bodyStatus == ResponseStatusesEnum.Error) {
                        message.error(data.message);
                    }
                    else {
                        message.error('Ответ не получен.');
                    }
                    dispatch({type: 'SET_SETTINGS_STATE', value: {isUserContactsLoading: false}});
                })
                .catch(error => {
                    message.error('Ошибка:' + error);
                    console.log(error);
                    dispatch({type: 'SET_SETTINGS_STATE', value: {isUserContactsLoading: false}});
                });
            
        }
    },
    deleteContact: (contact: UserContact): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        dispatch({type: 'SET_SETTINGS_STATE', value: {isUserContactsLoading: true}});
        console.log('Settings/delete_contact', contact);
        if (appState) {
            const requestOptions = {
                method: 'POST',
                headers: getHeaders(),
                body: JSON.stringify(contact)
            };
            //console.log('cn lg', requestOptions.body);
            fetch(BACKEND_URL + 'Settings/delete_contact', requestOptions)
                .then(response => response.json() as Promise<SResponseDTO>)
                .then(data => {
                    if (data.bodyStatus && data.bodyStatus == ResponseStatusesEnum.SessionClosed) {
                        dispatch({ type: 'CLOSE_SESSION', message: data.message })
                    }
                    else if (data.bodyStatus && data.bodyStatus == ResponseStatusesEnum.Ok) {
                        console.log('Settings/write_contact', (data.body));
                        let contacts = appState.settingsStore?.userContacts;
                        delete contacts?.[contact.id];
                        dispatch({type: 'SET_SETTINGS_STATE', value: {userContacts: contacts}})
                    }
                    else if (data.bodyStatus && data.bodyStatus == ResponseStatusesEnum.Error) {
                        message.error(data.message);
                    }
                    else {
                        message.error('Ответ не получен.');
                    }
                    dispatch({type: 'SET_SETTINGS_STATE', value: {isUserContactsLoading: false}});
                })
                .catch(error => {
                    message.error('Ошибка:' + error);
                    console.log(error);
                    dispatch({type: 'SET_SETTINGS_STATE', value: {isUserContactsLoading: false}});
                });
            
        }
    },
    requestSendingReports: (userName: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        dispatch({type: 'SET_SETTINGS_STATE', value: {isSendingReportsLoading: true}});
        //console.log('cn lg', filter);
        if (appState) {
            const requestOptions = {
                method: 'POST',
                headers: getHeaders(),
                body: JSON.stringify(userName)
            };
            //console.log('cn lg', requestOptions.body);
            fetch(BACKEND_URL + 'Settings/sending_reports', requestOptions)
                .then(response => response.json() as Promise<SResponseDTO>)
                .then(data => {
                    if (data.bodyStatus && data.bodyStatus == ResponseStatusesEnum.SessionClosed) {
                        dispatch({ type: 'CLOSE_SESSION', message: data.message })
                    }
                    else if (data.bodyStatus && data.bodyStatus == ResponseStatusesEnum.Ok) {
                        console.log('Settings/sending_reports', (data.body));
                        (data.body as SendingReport[]).forEach(x => initDates(x, ['startDateTimeInterval', 'endDateTimeInterval']));
                        dispatch({type: 'RECEIVE_SENDING_REPORTS', sendingReports: data.body})
                    }
                    else if (data.bodyStatus && data.bodyStatus == ResponseStatusesEnum.Error) {
                        message.error(data.message);
                    }
                    else {
                        message.error('Ответ не получен.');
                    }
                    dispatch({type: 'SET_SETTINGS_STATE', value: {isSendingReportsLoading: false}});
                })
                .catch(error => {
                    message.error('Ошибка:' + error);
                    console.log(error);
                    dispatch({type: 'SET_SETTINGS_STATE', value: {isSendingReportsLoading: false}});
                });
            
        }
    },
    writeSendingReport: (sendingReport: SendingReport): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        dispatch({type: 'SET_SETTINGS_STATE', value: {isSendingReportsLoading: true}});
        console.log('writeSendingReport', sendingReport);
        if (appState) {
            const requestOptions = {
                method: 'POST',
                headers: getHeaders(),
                body: JSON.stringify(sendingReport)
            };
            //console.log('cn lg', requestOptions.body);
            fetch(BACKEND_URL + 'Settings/write_sending_report', requestOptions)
                .then(response => response.json() as Promise<SResponseDTO>)
                .then(data => {
                    if (data.bodyStatus && data.bodyStatus == ResponseStatusesEnum.SessionClosed) {
                        dispatch({ type: 'CLOSE_SESSION', message: data.message })
                    }
                    else if (data.bodyStatus && data.bodyStatus == ResponseStatusesEnum.Ok) {
                        sendingReport.id = Number(data.body);
                        console.log('Settings/writeSendingReport', (data.body));
                        let srs: SendingReport[] = appState.settingsStore?.sendingReports?.[sendingReport.userContactId] ?? [];
                        const ind = srs.findIndex(item => item.id === sendingReport.id);
                        if (ind !== -1) {
                            srs[ind] = sendingReport;
                        } else {
                            srs.push(sendingReport);
                        }
                        dispatch({type: 'SET_SETTINGS_STATE', value: {sendingReports: {...appState.settingsStore?.sendingReports, [sendingReport.userContactId]: srs}}})
                    }
                    else if (data.bodyStatus && data.bodyStatus == ResponseStatusesEnum.Error) {
                        message.error(data.message);
                    }
                    else {
                        message.error('Ответ не получен.');
                    }
                    dispatch({type: 'SET_SETTINGS_STATE', value: {isSendingReportsLoading: false}});
                })
                .catch(error => {
                    message.error('Ошибка:' + error);
                    console.log(error);
                    dispatch({type: 'SET_SETTINGS_STATE', value: {isSendingReportsLoading: false}});
                });
            
        }
    },
   deleteSendingReport: (sendingReport: SendingReport): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        dispatch({type: 'SET_SETTINGS_STATE', value: {isSendingReportsLoading: true}});
        console.log('deleteSendingReport', sendingReport);
        if (appState) {
            const requestOptions = {
                method: 'POST',
                headers: getHeaders(),
                body: JSON.stringify(sendingReport)
            };
            //console.log('cn lg', requestOptions.body);
            fetch(BACKEND_URL + 'Settings/delete_sending_report', requestOptions)
                .then(response => response.json() as Promise<SResponseDTO>)
                .then(data => {
                    if (data.bodyStatus && data.bodyStatus == ResponseStatusesEnum.SessionClosed) {
                        dispatch({ type: 'CLOSE_SESSION', message: data.message })
                    }
                    else if (data.bodyStatus && data.bodyStatus == ResponseStatusesEnum.Ok) {
                        let srs: SendingReport[] = appState.settingsStore?.sendingReports?.[sendingReport.userContactId] ?? [];
                        const ind = srs.findIndex(item => item.id === sendingReport.id);
                        if (ind !== -1) {
                            srs.splice(ind, 1);
                        }
                        dispatch({type: 'SET_SETTINGS_STATE', value: {sendingReports: {...appState.settingsStore?.sendingReports, [sendingReport.userContactId]: srs}}})
                    }
                    else if (data.bodyStatus && data.bodyStatus == ResponseStatusesEnum.Error) {
                        message.error(data.message);
                    }
                    else {
                        message.error('Ответ не получен.');
                    }
                    dispatch({type: 'SET_SETTINGS_STATE', value: {isSendingReportsLoading: false}});
                })
                .catch(error => {
                    message.error('Ошибка:' + error);
                    console.log(error);
                    dispatch({type: 'SET_SETTINGS_STATE', value: {isSendingReportsLoading: false}});
                });
            
        }
    },
    requestSettingsTypes: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        dispatch({type: 'SET_SETTINGS_STATE', value: {isTypesLoading: true}});
        //console.log('cn lg', filter);
        if (appState) {
            const requestOptions = {
                method: 'POST',
                headers: getHeaders()
            };
            //console.log('cn lg', requestOptions.body);
            fetch(BACKEND_URL + 'Settings/types', requestOptions)
                .then(response => response.json() as Promise<SResponseDTO>)
                .then(data => {
                    if (data.bodyStatus && data.bodyStatus == ResponseStatusesEnum.SessionClosed) {
                        dispatch({ type: 'CLOSE_SESSION', message: data.message })
                    }
                    else if (data.bodyStatus && data.bodyStatus == ResponseStatusesEnum.Ok) {
                        dispatch({
                            type: 'RECEIVE_SETTINGS_TYPES', 
                            eventTypes: data.body.eventTypes,
                            messageSendTypes: data.body.messageSendTypes,
                            schedulesTypes: data.body.schedulesTypes,
                            reportsTypes: data.body.reportsTypes,
                            periodTypes: data.body.periodTypes,
                            reportingPeriodTypes: data.body.reportingPeriodTypes,
                            fileNameTypes: data.body.fileNameTypes,
                        })
                    }
                    else if (data.bodyStatus && data.bodyStatus == ResponseStatusesEnum.Error) {
                        message.error(data.message);
                    }
                    else {
                        message.error('Ответ не получен.');
                    }
                    dispatch({type: 'SET_SETTINGS_STATE', value: {isTypesLoading: false}});
                })
                .catch(error => {
                    message.error('Ошибка:' + error);
                    console.log(error);
                    dispatch({type: 'SET_SETTINGS_STATE', value: {isTypesLoading: false}});
                });
            
        }
    },

};

const unloadedState: ISettingsState = {
    fileNameTypes: undefined,
    isTypesLoading: false,
    periodTypes: undefined,
    reportingPeriodTypes: undefined,
    reportsTypes: undefined,
    schedulesTypes: undefined,
    sendingReports: undefined,
    userContacts: undefined,
    isUserContactsLoading: false,
    eventTypes: undefined,
    messageSendTypes: undefined,
    isSendingReportsLoading: false,
};

export const reducer: Reducer<ISettingsState> = (state: ISettingsState | undefined, incomingAction: Action): ISettingsState => {
    if (state === undefined) {
        return unloadedState;
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'SET_SETTINGS_STATE':
            return {
                ...state,
                ...action.value
            };
        case 'RECEIVE_CONTACTS':
            return {
                ...state,
                userContacts: Object.assign({}, ...action.contacts.map( item => ({[item.id]: item}))),
            };
        case 'RECEIVE_SETTINGS_TYPES':
            return {
                ...state,
                ...action,
            };
        case 'RECEIVE_SENDING_REPORTS':
            let srs: {[userContactId: string] : SendingReport[]} = {};
            for (let sr of action.sendingReports) {
                if (srs[sr.userContactId] === undefined) {
                    srs[sr.userContactId] = [];
                }
                srs[sr.userContactId].push(sr);
            }
            return {
                ...state,
                sendingReports: srs,
            };
        default:
            return state;
    }
};
