import * as React from 'react';
import { Form, Button, Input, Tooltip } from 'antd';
import { connect } from 'react-redux';

import Login from './Login2';
import PageTitle from './PageTitle';
import Resources from './Resources';
import Groups from './Groups';
import {Periods, CommunicationMissedTimes} from './PageHeader';

import { ApplicationState } from '../store';
import * as PageStore from '../store/Page';
import * as UserProfileStore from '../store/UserProfile';
import * as MapDataStore from '../store/MapDataStore';
import { RouteComponentProps } from 'react-router';
import styles from '../resources/Page.module.less';
import { SPointsFilter, Point, ICommand, PeriodsEnum, CommunicationMissedTimesEnum, USER_RIGHTS } from "../decl"
import * as Const from '../utils/Const';
import * as Common from '../utils/Common';
import { netiExecute, message, canReadData } from '../utils/Common';
import {MiddleContentMessageBlock} from "./HeaderForm";

const {Search} = Input;

import {
    SyncOutlined,
    TableOutlined,
    DownloadOutlined,
    FileZipOutlined
} from '@ant-design/icons';


const actionCreators = { ...PageStore.actionCreators, ...UserProfileStore.actionCreators, ...MapDataStore.actionCreators };

type HeaderProps =
    { userProfile: UserProfileStore.UserProfileState, mapDataStore: MapDataStore.IMapDataState, windowWidth: number, isMenuCollapsed: boolean } &
    typeof actionCreators &
    RouteComponentProps<{}>

interface HeaderState {
    filter: SPointsFilter;
    waitData: boolean;
    forceUpdateKey: number;
}

class MapHeaderForm extends React.PureComponent<HeaderProps, HeaderState>{
    constructor(props:HeaderProps) {

        super(props);
        this.state = {
            filter: {
                groups: (props?.userProfile?.profile?.Common?.Groups) ?? null,
                points: (props?.userProfile?.profile?.Common?.Points) ?? '',
                resources: (props?.userProfile?.profile?.Common?.Resources) ?? null,
                systemNumbers: null,
                text: props.mapDataStore.textFilter,
            },
            waitData: false,
            forceUpdateKey: Math.random(),
        }
        this.execute = this.execute.bind(this);
    }

    onRefresh = () => {
        if (this.props.userProfile) {
            const common: any = { 
                Groups: this.state.filter.groups,
                Points: this.state.filter.points,
                Resources: this.state.filter.resources
            };
            this.props.changeProfile(Const.PROFILE_COMMON, common);
        }
        this.props.SetTextFilter(this.state.filter.text);
        this.props.setStartToUpdateAllDataFlag(true);

        this.setState({forceUpdateKey: Math.random()});
    };

    onValueChange = (groups: any, points: any) => {
        this.setState({
            filter: {
                ...this.state.filter,
                groups: (groups.indexOf(Const.ALL_GROUP_ID) >= 0) ? null : Common.arrayToString(groups),
                points: Common.arrayToString(points)
            }
        });
    };

    onResourcesChange = (value: any) => {
        this.setState({
            filter: {
                 ...this.state.filter,
                resources: (value.indexOf(Const.ALL_RESOURCE_ID) >= 0) ? null : Common.arrayToString(value)
             }
        });
    };
    onPeriodsChange = (value: PeriodsEnum) => {
        this.props.changeProfile(Const.PROFILE_COMMON, {ArchivePeriod: value});
        this.onRefresh();
    };

    onTextChange = (ev: any) => {
        const strValue = ev.target.value;
        this.setState({ filter: { ...this.state.filter, text: strValue } });
    }

    onCommunicationMissedTimeChange = (value: CommunicationMissedTimesEnum) => {
        this.props.setCommunicationMissedTimeFilter(value);
    };

    onReadData = () => {
        const period = this?.props?.userProfile?.profile?.Common?.ArchivePeriod;
        const ids = Object.keys(this.props.mapDataStore.points);

        if(period !== undefined){
            this.props.readData(
                period,
                ids,
                100,
                false,
                (code: number, ids: any) => {
                    if(code === 0) {
                        message.success('Запрос на получение данных из приборов отправлен.');
                        this.setState({ waitData: true });
                    }
                    if(code === 2) {
                        message.success('Данные из приборов получены.');
                        this.setState({ waitData: true });
                    }
                    if(code === 4) {
                        message.warning('Не все данные из приборов удалось получить.');
                        this.setState({ waitData: false });
                    }
                },
                (error: string) => {
                    message.error(error);
                    this.setState({ waitData: false });
                    console.log(error);
                });
        }
    }

    execute(cmd:any) {
        netiExecute(cmd, null, null, this.props.setIsLoading, this.props.setActiveQueries, null, this.props.showModal);
    }

    render() {
        const selectedGroupIds = (this.state.filter.groups != null) ?
            Common.stringToArray(this.state.filter.groups) :
            [Const.ALL_GROUP_ID];
        const selectedPointIds = (this.state.filter.points != null) ?
            Common.stringToArray(this.state.filter.points) :
            [];
        const selectedResourceIds = (this.state.filter.resources != null) ?
            Common.stringToArray(this.state.filter.resources) :
            [Const.ALL_RESOURCE_ID];

        const GroupsProps = {
            groups: (this.props.userProfile) ? this.props.userProfile.userGroups : [],
            groupPoints: (this.props.userProfile) ? this.props.userProfile.groupPoints : {},
            selectedGroupIds: selectedGroupIds,
            selectedPointIds: selectedPointIds,
            getPoints: this.props.requestGroupPoints,
            getPointsParentGroups: null,
            onChange: this.onValueChange,
            setIsLoading: this.props.setIsLoading,
            onHideDropDown: this.onRefresh,
            disabled: this.props.mapDataStore.isPointsLoading,
        };

        const ResourcesProps = {
            resources: (this.props.userProfile) ? this.props.userProfile.resources : [],
            selectedResourceIds: selectedResourceIds,
            onChange: this.onResourcesChange,
            onHideDropDown: this.onRefresh,
            disabled: this.props.mapDataStore.isPointsLoading,

        };

        const period: PeriodsEnum = ((this.props?.userProfile?.profile?.Common?.ArchivePeriod) ?? 0) as PeriodsEnum;
        const readDisabled: boolean = this.state.waitData ||
            !canReadData(this.props.userProfile?.rights, period) ||
            this.props.mapDataStore.isPointsLoading;

        const PeriodsProps = {
            value: period,
            onChange: this.onPeriodsChange,
            disabled: this.props.mapDataStore.isPointsLoading,
        };

        const CommunicationMissedTimesProps = {
            value: this.props.mapDataStore.communicationMissedTimeFilter,
            onChange: this.onCommunicationMissedTimeChange,
            disabled: this.props.mapDataStore.isPointsLoading,
        }        

        const buttonAsIcon = this.props.windowWidth < 1800;
        if(buttonAsIcon){
            const maxWindowSize = (this.props.isMenuCollapsed ? 1440 : 1555);
            return (
                <div className={styles.flexcontainer}>
                    <div className={styles.comboblock}>
                        <div>
                            <Groups {...GroupsProps} />
                        </div>
                        <div className={styles.flexitem}>
                            <Resources {...ResourcesProps} />
                        </div>
                        <div className={styles.flexitem}>
                            <Tooltip placement="bottomRight" title={'Поиск точки учёта'}>
                                <Search className ={styles.searchText} value={this.state.filter.text} placeholder="Поиск" onChange={this.onTextChange} onSearch={this.onRefresh}
                                    disabled={this.props.mapDataStore.isPointsLoading}
                                />
                            </Tooltip>
                        </div>
                        <div className={styles.flexitem}>
                            <Periods {...PeriodsProps} />
                        </div>
                        <div className={styles.flexitem}>
                            <CommunicationMissedTimes {...CommunicationMissedTimesProps} />
                        </div>
                    </div>
                    <div className ={styles.buttonsblock}>
                        <div className={styles.iconflexitem}>
                            <Tooltip placement="bottomRight" title={'Обновить данные'} key={this.state.forceUpdateKey}>
                                <Button disabled={this.props.mapDataStore.isPointsLoading || this.props.mapDataStore.isPointsValuesLoading} type="primary" shape="circle" icon={<SyncOutlined/>}  onClick={this.onRefresh}/>
                            </Tooltip>
                        </div>
                        <div className={styles.iconflexitem}>
                            <Tooltip placement="bottomRight" title={'Получить данные с приборов'}>
                                <Button disabled={readDisabled} type="primary" shape="circle" icon={<DownloadOutlined/>}  onClick={this.onReadData}/>
                            </Tooltip>
                        </div>
                    </div>
                    <div className={styles.mbitem}>
                        <MiddleContentMessageBlock windowSize={this.props.windowWidth} maxWindowSize={maxWindowSize} onlyIcon={true}/>
                    </div>
                    <div className={styles.flexuseritem}>
                        <Login />
                    </div>
                </div>
            );
        }
        else{//Кнопки с надписями 
            const messageAsIcon = this.props.windowWidth < (this.props.isMenuCollapsed ? 2140 : 2255);//this.props.windowWidth < 2048;
            const hideEmail =  false; //this.props.windowWidth < (this.props.isMenuCollapsed ? 2140 : 2255);
            const isSplit = this.props.windowWidth >= (this.props.isMenuCollapsed ? 2300 : 2415);
           
            return (
                <div className={styles.flexcontainer}>
                    <div className={styles.comboblock}>
                        <div>
                            <Groups {...GroupsProps} />
                        </div>
                        <div className={styles.flexitem}>
                            <Resources {...ResourcesProps} />
                        </div>
                        <div className={styles.flexitem}>
                            <Tooltip placement="bottomRight" title={'Поиск точки учёта'}>
                                <Search className={styles.searchText} value={this.state.filter.text} placeholder="Поиск" onChange={this.onTextChange} onSearch={this.onRefresh} disabled={this.props.mapDataStore.isPointsLoading}/>
                            </Tooltip>
                        </div>
                        <div className={styles.flexitem}>
                            <Periods {...PeriodsProps} />
                        </div>
                        <div className={styles.flexitem}>
                            <CommunicationMissedTimes {...CommunicationMissedTimesProps} />
                        </div>
                    </div>
                    <div className ={styles.buttonsblock}>
                        <div className={styles.flexitem}>
                            <Tooltip placement="bottomRight" title={'Обновить данные'} key={this.state.forceUpdateKey}>
                                <Button type="primary" shape="round" icon={<SyncOutlined />} onClick={this.onRefresh} disabled={this.props.mapDataStore.isPointsLoading }>Обновить</Button>
                            </Tooltip>
                        </div>
                        <div className={styles.flexitem}>
                            <Tooltip placement="bottomRight" title={'Получить данные с приборов'}>
                                <Button type="primary" shape="round" icon={<DownloadOutlined/>} onClick={this.onReadData} disabled={readDisabled}>Сбор данных</Button>
                            </Tooltip>
                        </div>
                    </div>
                    <div className={styles.mbitem}>
                        <MiddleContentMessageBlock windowSize={this.props.windowWidth} maxWindowSize={1600} onlyIcon={messageAsIcon} hideEmail={hideEmail}  isSplit={isSplit}/>
                    </div>
                    <div className={styles.flexuseritem}>
                        <Login />
                    </div>
                </div>
            );
        }
    }
}


export default connect(
    (state: ApplicationState) => {
        return {
            userProfile: state.userProfile,
            mapDataStore: state.mapDataStore,
            windowWidth: state.page.windowWidth,
            isMenuCollapsed: state.siderMenu?.collapsed
        }
    },
    actionCreators
)(MapHeaderForm as any);
