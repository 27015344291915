import * as React from 'react';
import { TreeSelect, Tooltip } from 'antd';

import { Resource } from "../decl";
import * as Const from '../utils/Const';

import styles from '../resources/Page.module.less';

const { SHOW_PARENT } = TreeSelect;

interface IResourcesProps {
    resources: Resource[];
    selectedResourceIds: string[] | undefined;
    onChange: any;
    onHideDropDown?: any;
    size?: 'small' | 'middle' | 'large' | undefined;
    className?: string;
    disabled?: boolean;
}

function createTree(resources: Resource[]): any {
    const result: any = [];
    result.push(
        {
            title: 'Все ресурсы',
            shortTitle: 'Все ресурсы',
            value: Const.ALL_RESOURCE_ID,
            key: Const.ALL_RESOURCE_ID,
            children: resources.map(r => {
                        const strId = r.id.toString();
                        return {
                            title: r.name,
                            shortTitle: r.shortName,
                            value: strId,
                            key: strId
                        }
                    })
        }
    );
    return result;
}


export default class Resources extends React.PureComponent<IResourcesProps> {
    _changed: boolean;
    constructor(props: IResourcesProps) {
        super(props);
        this._changed = false;
        console.log('RESOURCES CONSTRUCTOR');
    }

    onChange = (value: any) => {
        this.props.onChange(value);
        this._changed = true;
        console.log('RESOURCES ONCHANGE: ', value);
    };
    onDropDownOpen = (open: boolean) => {
        if(open){
            this._changed = false;
            console.log('RESOURCES OPEN');
        }
        else if(this._changed && this.props.onHideDropDown){
            console.log('RESOURCES BEFORE CLOSE');
            this.props.onHideDropDown();
            this._changed = false;
            console.log('RESOURCES AFTER CLOSE');
        }
    };

    render() {
        const treeData = createTree(this.props.resources);
        const maxTagCount = 1;
        const tProps = {
            treeData,
            value: this.props.selectedResourceIds,
            treeDefaultExpandedKeys: [Const.ALL_RESOURCE_ID],
            onChange: this.onChange,
            treeCheckable: true,
            showCheckedStrategy: SHOW_PARENT,
            placeholder: 'Выберите ресурсы',
            size: this.props.size,
            maxTagCount: maxTagCount,
            //maxTagPlaceholder: '...',
            treeNodeLabelProp: 'shortTitle',
            dropdownMatchSelectWidth: false,
            onDropdownVisibleChange: this.onDropDownOpen,
            disabled: this.props.disabled,
        };
        return <TreeSelect className={this.props.className == undefined ? styles.resourcesTree : this.props.className} {...tProps} />;
    }
}


