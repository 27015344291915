import { message } from '../utils/Common';
import internal from 'assert';
import { Action, Reducer } from 'redux';
import { AppThunkAction } from '.';
import { Resource, ResponseStatusesEnum, SParameterDTO, SPointsFilter, SResponseDTO } from '../decl';
import { BACKEND_URL, getHeaders } from '../utils/AuthUtils';
import { CloseSessionAction } from './Page';
import { notification } from 'antd';

export interface IAdminState {
    isPointAllInfoLoading: boolean,
    isQueryItemTypesLoading: boolean,
    isConnParsLoading: boolean,
    isPointWriting: boolean,
    isPointWriteComplete: boolean,
    pointAllInfo: PointAllInfoDTO | undefined,
    pointDicts: IPointDictionaries | undefined,
    queryItemTypes: QueryItemTypes | undefined,
    queryItem: SQueryItemDTO | undefined,
}

export interface QueryItemTypes
{
    scheduleTypes: SScheduleDTO[],
    queryTypes: SQueryType[],
}

export interface SDeviceTypeDTO
{
    id: number,
    name: string,
    deviceParentTypeId: number,
    devicePrameters: SParameterDTO[],
}

export enum ValidationResultEnum {
    ok = 0,
    warning,
    error,
}

export interface SValudationResultDTO
{
    result: ValidationResultEnum,
    message: string,
}

export interface STaskDTO
{
    id: number,
    queryItemId: string,
    queryTypeId: number,
    sheduleId: number,
    taskTime: Date,
    startTime: Date,
    stopTime: Date,
    taskStatusId: number,
    retriesCount: number,
    lastRetryTime: Date | null,
}

export interface STaskInfoDTO
{
    task: STaskDTO,
    abonentNumber: string,
    abonentName: string,
    executeNow: boolean,
    enabled: boolean,
    connectionTypeId: number,
}

export interface SAbonentConnectionStatisticDTO
{
    pointId: string,
    beginDate: Date,
    endDate: Date,
    commonStatusId: number,
    dayStatuses: SDayStatusesDTO[],
}

export interface SDayStatusesDTO
{
    dayPollingStatusId: number,
    clarifyStatusDay: string,
    dateStatus: Date,
    tasksOfDayInfo: string,
}

export interface PointAllInfoDTO
{
    pointInfo: PointInfo;
    deviceInfo: PointDeviceInfo;
    connectionInfo: PointConnectionInfo;
}

export interface PointInfo
{
    id: string | null;
    name: string;
    number: string;
    devices_Id: string | null;
    comment: string;
    address: string;
    contactPerson: string;
    gmax_Agreement: string;
    signalLevel: string;
    heatCompanyId: number;
    qtv: string;
    hasAddAgreement: boolean;
    commDevTypeId: number;
    reportId: number;
    currentsReportId: number | null;
    latitude: number | null;
    longitude: number | null;
    regionId: number | null;
    kt: string;
    resourceTypeId: number;
    performerId: number | null;
    temperatureChartId: number | null;
    weatherStationId: number | null;
    fiasHouseId: string;
}

export interface PointDeviceInfo
{
    id: string | null;
    typeId: number;
    serialNumber: string;
    description: string;
    deviceType: string;
    softwareVersion: string;
    //SParameter[] vParameters;
    hmMontageId: number;
    raMontageId: number;
    installDate: string;
    raInstallDate: Date;// = new Date();
    nextTestDate: Date;
    closeDate: Date | null;
    baseDeviceId: string | null;// = null;
    ownerDeviceId: string | null;// = null;
    systemType: number | null;
    typeOfHeatSupply: number | null;
    //SDriverContext DriverContext;
    isFlexibleMeasurement: boolean;// = false;
    isWriteEnable: boolean;// = false;
    vParameters: SParameterDTO[];
    measurementDescriptions: SMeasurementDescriptionDTO[];
}

export interface PointConnectionInfo
{
    id: string | null;
    typeId: number;
    retryQuery: number;
    retrySession: number;
    retrySessionOrder: boolean;
    responseTimeout: number;
    description: string;
    //public SParameter[] vParameters;
    sessionTimeout: number;
    lockId: number;
    lockName: string;
    extraParameters: string;
    vParameters: SParameterDTO[];
}

export interface SMeasurementDescriptionDTO {
    id: number,
    name: string,
    sign: string,
    caption: string,
    isIntegral: boolean,
    unitName: string,
    unitCaption: string,
    channelSign: string,
    minValue: number | null,
    maxValue: number | null,
    isRemoved: boolean,
}

//--------

export interface SQueryType {
    id: number,
    name: string,
    description: string,
}

export const IS_DAY_SCHEDULE_ID: number = 2;
export const IS_REQUEST_SCHEDULE_ID: number = 6;

export enum QueryTypesEnum {
    current = 1,
    archive = 2,
    archive_hour = 3,
    configurator = 4,
    current_settings = 5,
    archive_current = 6,
    connection_test = 7,
    calculate_archive_from_current = 8
}


export interface SScheduleDTO {
    id: number,
    name: string,
    shdType_Id: number
    startTime: Date,
    stopTime: Date,
    description: string,
    useExpTimeout: boolean,
    parameters: SParameterDTO[],
}

export interface SQueryItemDTO {
    id: string | null,
    name: string,
    pointId: string,
    shedules_Id: number,
    queryTypes_Id: number,
    priority: number,
    enabled: boolean,
    retriesMax: number,
}

//----------

export interface IPointDictionaries {
    organizations: {[key: number] : string},
    regions: {[key: number] : string},
    weatherStations: {[key: number] : string},
    reports: {[key: number] : string},
    reportsCurrent: {[key: number] : string},
    performers: {[key: number] : string},
    deviceParentTypes: {[key: number] : string},
    deviceTypes: SDeviceTypeDTO[],
    systemTypes: {[key: number] : string},
    typesOfHeatSupply: {[key: number] : string},
    montageCompanies: {[key: number] : string},
    connectionTypes: {[key: number] : SConnectionTypeDTO},
    commDevTypes: {[key: number] : string},
    temperatureChartType: {[key: number] : string},
    temperatureCharts: {[key: number] : {name: string, parentId: number}},
    useGeoServices: boolean,
}

export interface SConnectionTypeDTO
{
    description: string,
    extensionTable_Con: string,
}

export interface STaskAttemptDTO
{
    taskId: number,
    attemptNumber: number,
    connectionBeginTime: Date,
    connectionEndTime: Date | null,
    connectionResultId: number,
    pollingBeginTime: Date | null,
    pollingEndTime: Date | null,
    pollingResultId: number,
    logTime: Date | null,
    pollingEventCodeId: number,
    message: string,
    data: string,
    systemNode: string,
    source: string,
    threadId: number,
    archiveDataBase64: string | null,
    eventString: string,
    device: string,
}

export interface IPollingDicts {
    queryTypes: any, 
    taskStatuses: any, 
    commonConnectionStatuses: any,
    connectionResult: any,
    pollingResult: any,
    connectionTypes: {[id: number]: string},
    deviceTypes: {[id: number]: string},
}

export interface ITaskInfoDicts {
    connectionTypes: {[id: number]: string},
    taskStatuses: {[id: number]: string},
    queryTypes: {[id: number]: string},
    schedules: {[id: number]: string},
}

export interface STaskAttemptInfoDTO
{
    dateTime: Date,
    data: string,
    node: string,
    threadId: number,
    eventString: string,
    device: string,
}

interface SetState { type: 'SET_ADMIN_STATE'; value: Partial<IAdminState> };
interface ReceivePointAllInfo { type: 'RECEIVE_POINT_ALL_INFO'; value: PointAllInfoDTO };
interface ReceivePointDctionaries { type: 'RECEIVE_POINT_DICTIONARIES'; value: IPointDictionaries };
interface ReceiveQueryItemTypes { type: 'RECEIVE_QUERY_ITEM_TYPES'; value: { scheduleTypes: SScheduleDTO[], queryTypes: SQueryType[] } };
interface ReceiveConnPars { type: 'RECEIVE_CONN_PARS'; value: SParameterDTO[] };

export type KnownAction = CloseSessionAction | SetState | ReceivePointAllInfo | ReceivePointDctionaries | ReceiveQueryItemTypes | ReceiveConnPars;

export const actionCreators = {
    setPointAllInfo: (val: PointAllInfoDTO | undefined) => ({ type: 'SET_ADMIN_STATE', value: {pointAllInfo: val} } as SetState),
    setQueryItem: (val: SQueryItemDTO | undefined) => ({ type: 'SET_ADMIN_STATE', value: {queryItem: val} } as SetState),

    requestPointAllInfo: (pointId: string | null, createNewCopy: boolean, success?: () => void): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        dispatch({type: 'SET_ADMIN_STATE', value: {isPointAllInfoLoading: true, pointAllInfo: undefined}});
        if (appState) {
            const requestOptions = {
                method: 'POST',
                headers: getHeaders(),
                body: JSON.stringify({pointId, createNewCopy})
            };
            fetch(BACKEND_URL + 'admin/pointallinfo', requestOptions)
                .then(response => response.json() as Promise<SResponseDTO>)
                .then(data => {
                    if (data.bodyStatus && data.bodyStatus == ResponseStatusesEnum.SessionClosed) {
                        dispatch({ type: 'CLOSE_SESSION', message: data.message })
                    }
                    else if (data.bodyStatus && data.bodyStatus == ResponseStatusesEnum.Ok) {
                        dispatch({type: 'RECEIVE_POINT_ALL_INFO', value: data.body})
                        success ? success() : null;
                    }
                    else if (data.bodyStatus && data.bodyStatus == ResponseStatusesEnum.Error) {
                        message.error(data.message);
                    }
                    else {
                        message.error('Ответ не получен.');
                    }
                    dispatch({type: 'SET_ADMIN_STATE', value: {isPointAllInfoLoading: false}});
                })
                .catch(error => {
                    message.error('Ошибка:' + error);
                    console.log(error);
                    dispatch({type: 'SET_ADMIN_STATE', value: {isPointAllInfoLoading: false}});
                });
            
        }
    },
    requestPointDicts: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        dispatch({type: 'SET_ADMIN_STATE', value: {isPointAllInfoLoading: true}});
        if (appState) {
            const requestOptions = {
                method: 'POST',
                headers: getHeaders(),
            };
            fetch(BACKEND_URL + 'admin/pointdictionaries', requestOptions)
                .then(response => response.json() as Promise<SResponseDTO>)
                .then(data => {
                    if (data.bodyStatus && data.bodyStatus == ResponseStatusesEnum.SessionClosed) {
                        dispatch({ type: 'CLOSE_SESSION' ,message: data.message })
                    }
                    else if (data.bodyStatus && data.bodyStatus == ResponseStatusesEnum.Ok) {
                        dispatch({type: 'RECEIVE_POINT_DICTIONARIES', value: data.body})
                    }
                    else if (data.bodyStatus && data.bodyStatus == ResponseStatusesEnum.Error) {
                        message.error(data.message);
                    }
                    else {
                        message.error('Ответ не получен.');
                    }
                    dispatch({type: 'SET_ADMIN_STATE', value: {isPointAllInfoLoading: false}});
                })
                .catch(error => {
                    message.error('Ошибка:' + error);
                    console.log(error);
                    dispatch({type: 'SET_ADMIN_STATE', value: {isPointAllInfoLoading: false}});
                });
            
        }
    },
    requestQueryItemTypes: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        dispatch({type: 'SET_ADMIN_STATE', value: {isQueryItemTypesLoading: true, queryItemTypes: undefined}});
        if (appState) {
            const requestOptions = {
                method: 'POST',
                headers: getHeaders(),
            };
            fetch(BACKEND_URL + 'admin/queryitemtypes', requestOptions)
                .then(response => response.json() as Promise<SResponseDTO>)
                .then(data => {
                    if (data.bodyStatus && data.bodyStatus == ResponseStatusesEnum.SessionClosed) {
                        dispatch({ type: 'CLOSE_SESSION', message: data.message })
                    }
                    else if (data.bodyStatus && data.bodyStatus == ResponseStatusesEnum.Ok) {
                        dispatch({type: 'RECEIVE_QUERY_ITEM_TYPES', value: data.body})
                    }
                    else if (data.bodyStatus && data.bodyStatus == ResponseStatusesEnum.Error) {
                        message.error(data.message);
                    }
                    else {
                        message.error('Ответ не получен.');
                    }
                    dispatch({type: 'SET_ADMIN_STATE', value: {isQueryItemTypesLoading: false}});
                })
                .catch(error => {
                    message.error('Ошибка:' + error);
                    console.log(error);
                    dispatch({type: 'SET_ADMIN_STATE', value: {isQueryItemTypesLoading: false}});
                });
            
        }
    },
    requestConnPars: (connId: string, connTypeId: number): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        dispatch({type: 'SET_ADMIN_STATE', value: {isConnParsLoading: true}});
        console.log('admin/requestConnPars request');
        if (appState) {
            const requestOptions = {
                method: 'POST',
                headers: getHeaders(),
                body: JSON.stringify({connectionId: connId, connectionTypeId: connTypeId}),
            };
            fetch(BACKEND_URL + 'admin/connectionparameters', requestOptions)
                .then(response => response.json() as Promise<SResponseDTO>)
                .then(data => {
                    if (data.bodyStatus && data.bodyStatus == ResponseStatusesEnum.SessionClosed) {
                        dispatch({ type: 'CLOSE_SESSION', message: data.message })
                    }
                    else if (data.bodyStatus && data.bodyStatus == ResponseStatusesEnum.Ok) {
                        console.log('RECEIVE_CONN_PARS', data.body)
                        dispatch({type: 'RECEIVE_CONN_PARS', value: data.body})
                    }
                    else if (data.bodyStatus && data.bodyStatus == ResponseStatusesEnum.Error) {
                        message.error(data.message);
                    }
                    else {
                        message.error('Ответ не получен.');
                    }
                    dispatch({type: 'SET_ADMIN_STATE', value: {isConnParsLoading: false}});
                })
                .catch(error => {
                    message.error('Ошибка:' + error);
                    console.log(error);
                    dispatch({type: 'SET_ADMIN_STATE', value: {isConnParsLoading: false}});
                });
            
        }
    },
    requestPointWrite: (point: PointAllInfoDTO, finish?: (success: boolean, point: PointAllInfoDTO) => void): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        dispatch({type: 'SET_ADMIN_STATE', value: {isPointWriting: true, isPointWriteComplete: false}});
        console.log('admin/requestPointWrite request');
        if (appState) {
            const requestOptions = {
                method: 'POST',
                headers: getHeaders(),
                body: JSON.stringify(point)
            };
            fetch(BACKEND_URL + 'admin/pointallinfowrite', requestOptions)
                .then(response => response.json() as Promise<SResponseDTO>)
                .then(data => {
                    if (data.bodyStatus && data.bodyStatus == ResponseStatusesEnum.SessionClosed) {
                        dispatch({ type: 'CLOSE_SESSION', message: data.message })
                    }
                    else if (data.bodyStatus && data.bodyStatus == ResponseStatusesEnum.Ok) {
                        dispatch({type: 'SET_ADMIN_STATE', value: {isPointWriteComplete: true, pointAllInfo: data.body}})
                        finish ? finish(true, data.body) : null;
                    }
                    else if (data.bodyStatus && data.bodyStatus == ResponseStatusesEnum.Error) {
                        message.error(data.message);
                        notification['error']({message: 'Ошибка. Не удалось сохранить ТУ.', placement: 'top'});
                        finish ? finish(false, point) : null;
                    }
                    else {
                        message.error('Ответ не получен.');
                        notification['error']({message: 'Ошибка. Не удалось сохранить ТУ.', placement: 'top'});
                        finish ? finish(false, point) : null;
                    }
                    dispatch({type: 'SET_ADMIN_STATE', value: {isPointWriting: false}});
                })
                .catch(error => {
                    message.error('Ошибка:' + error);
                    console.log(error);
                    dispatch({type: 'SET_ADMIN_STATE', value: {isPointWriting: false}});
                    finish ? finish(false, point) : null;
                    notification['error']({message: 'Ошибка. Не удалось сохранить ТУ.', placement: 'top'});
                });
            
        }
    },
    requestPointCreate: (point: PointAllInfoDTO, groupId: string | undefined, queryItem: SQueryItemDTO | undefined, success?: (point: PointAllInfoDTO) => void): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        dispatch({type: 'SET_ADMIN_STATE', value: {isPointWriting: true, isPointWriteComplete: false}});
        console.log('admin/requestPointWrite request');
        if (appState) {
            const requestOptions = {
                method: 'POST',
                headers: getHeaders(),
                body: JSON.stringify({point, groupId, queryItem})
            };
            fetch(BACKEND_URL + 'admin/pointallinfocreate', requestOptions)
                .then(response => response.json() as Promise<SResponseDTO>)
                .then(data => {
                    if (data.bodyStatus && data.bodyStatus == ResponseStatusesEnum.SessionClosed) {
                        dispatch({ type: 'CLOSE_SESSION', message: data.message })
                    }
                    else if (data.bodyStatus && data.bodyStatus == ResponseStatusesEnum.Ok) {
                        dispatch({type: 'SET_ADMIN_STATE', value: {pointAllInfo: data.body, isPointWriteComplete: true}});
                        success ? success(data.body) : null;
                    }
                    else if (data.bodyStatus && data.bodyStatus == ResponseStatusesEnum.Error) {
                        message.error(data.message);
                        notification['error']({message: 'Ошибка. Не удалось создать ТУ.', placement: 'top'});
                    }
                    else {
                        message.error('Ответ не получен.');
                        notification['error']({message: 'Ошибка. Не удалось создать ТУ.', placement: 'top'});
                    }
                    dispatch({type: 'SET_ADMIN_STATE', value: {isPointWriting: false}});
                })
                .catch(error => {
                    message.error('Ошибка:' + error);
                    notification['error']({message: 'Ошибка. Не удалось создать ТУ.', placement: 'top'});
                    console.log(error);
                    dispatch({type: 'SET_ADMIN_STATE', value: {isPointWriting: false}});
                });
            
        }
    },
}

const setConPars = (state: IAdminState, pars: SParameterDTO[]) => {
    let res: Partial<IAdminState> = {};
    if (state.pointAllInfo != undefined) {
        let pointAllInfo: PointAllInfoDTO = {...state.pointAllInfo};
        res = { isConnParsLoading: false, pointAllInfo: {...state.pointAllInfo}};
        if (res.pointAllInfo !== undefined) {
            res.pointAllInfo.connectionInfo.vParameters = [...pars];
        }
    }
    return res;
}

export const reducer: Reducer<IAdminState> = (state: IAdminState | undefined, incomingAction: Action): IAdminState => {
    if (state === undefined) {
        return {
            isPointAllInfoLoading: false, pointAllInfo: undefined, pointDicts: undefined, isPointWriting: false, queryItem: undefined,
            queryItemTypes: undefined, isQueryItemTypesLoading: false, isConnParsLoading: false, isPointWriteComplete: false,
        };
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {
        case "SET_ADMIN_STATE":
            return {
                ...state,
                ...action.value
            };
        case "RECEIVE_POINT_ALL_INFO":
            return {
                ...state,
                pointAllInfo: action.value,
            }
        case "RECEIVE_POINT_DICTIONARIES":
            return {
                ...state,
                pointDicts: action.value,
            }
        case "RECEIVE_QUERY_ITEM_TYPES":
            return {
                ...state,
                queryItemTypes: action.value,
            }
        case "RECEIVE_CONN_PARS":
            return {
                ...state,
                ...setConPars(state, action.value),
            }
        default:
            return state;
    }
};
