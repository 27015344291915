import React from 'react';
import {Dropdown, Table, Menu, Typography, Tooltip, Button, Row, Col, Input, Space, Tag, Modal } from 'antd';
import { UserAddOutlined, EditTwoTone, DeleteTwoTone, PlusCircleTwoTone, QuestionCircleTwoTone, SettingOutlined } from '@ant-design/icons';
import styles from '../../resources/Page.module.less';
import settingsStyles from '../../resources/Settings.module.less';
import { SUserDTO, SRoleDTO, SUserInfoDTO, Group, Point, Resource, SRoleInfoDTO } from '../../decl';
import { toDateString, message, initDates } from '../../utils/Common';
import * as Const from '../../utils/Const';
import {UserForm, UserFormProps, UserValues} from './UserForm';
import {SetPasswordForm, SetPasswordFormProps, SetPasswordValues} from './SetPasswordForm';
import {getModalZIndex } from '../../utils/Common';
import { sendRequestToBackend } from '../../utils/AuthUtils';
import {UserContact, EventType, SendingReport} from '../../store/SettingsStore';
import ContactForm from '../ContactForm';
import SendingReportForm from '../SendingReportForm'
import {RoleSelectForm, IRoleSelectFormProps} from './RoleSelectForm';
import {ContactsTable} from './ContactsTable';
import { removeValue } from '../../utils/TreeUtils';
import { ProfileManagerForm, ProfileManagerFormProps, ProfileManagerValues } from './ProfileManagerForm';
import { DraggableModal } from 'ant-design-draggable-modal';
import { UserProfileState } from '../../store/UserProfile';

const { Column } = Table;

const DEFAULT_ROLE_NAME = "Everyone";

type SUserChangeRequest = {
    user: SUserDTO,
    groupIds: string[],
    roleIds: string[],
    contacts: UserContact[]
}

export type UsersTableProps = {
  windowHeight: number,
  parentHeight: number,
  parentWidth: number,
  selectedUser: SUserDTO | undefined,
  userGroups: Group[] | undefined,
  groupPoints: {[groupId: string] : Point[]},
  eventTypes: EventType[],

  messageSendTypes: {[messageSendTypeId: number]: string},
  schedulesTypes: {[scheduleId: number]: string} | undefined,
  reportsTypes: {[reportId: number]: string} | undefined,
  periodTypes: {[period: number]: string} | undefined,
  reportingPeriodTypes: {[ReportingPeriodTypeId: number]: string} | undefined,
  fileNameTypes: {[SendingReportFileNameFormingTypeId: number]: string} | undefined,

  userProfile: UserProfileState,
  //allResources: Resource[],
  //allGroups: Group[],
  //allGroupPoints: {[groupId: string] : Point[]},
  allPoints: {[pointId: string] : Point},
  requestAllGroupPoints: any,
  requestGroupPoints: any,
  requestPoints: any,
  onSelectUser: any,
  onChangeUser: any,
  setIsLoading: any,
  closeSession: any,
}

type UserModal ={
  isOpen: boolean,
  isNew: boolean,
  values: UserValues
}

type SetPasswordModal ={
  isOpen: boolean,
  values: any
}

type ContactModal = {
  isOpen: boolean,
  contact: UserContact | undefined
}

type SendingReportModal = {
  isOpen: boolean,
  contact: UserContact | undefined,
  sendingReport: SendingReport | undefined
}

type UsersTableState = {
  users: SUserInfoDTO[],
  roles: SRoleInfoDTO[],
  filter: string,
  userModal: UserModal,
  setPasswordModal: SetPasswordModal,
  contactModal: ContactModal,
  sendingReportModal: SendingReportModal,
  roleSelectIsOpen: boolean,
  selectedUserInfo: SUserInfoDTO | undefined,
  userSendingReports: {[userContactId: string] : SendingReport[]} | undefined,
  contactsVisible: boolean;
  profileManagerFormIsOpen: boolean;
  modalZIndex: number;
}

interface Command{
  key: string,        //Ключ команды - д.б. уникальным для данного типа узла.
  caption: string,    //Описание команды.
  action: any     //(ob: Point | Group | null, parent: Group | null)=>void
}

//Поиск контакта по шаблону.
const findContact = (userInfo: SUserInfoDTO, template: string):UserContact|undefined=>{
  let result: UserContact | undefined = undefined;
  const templateLowerCase = template.toLocaleLowerCase();
  for(let i = 0;  i < userInfo.contacts.length; i++){
    const c = userInfo.contacts[i];
    if(c.clientId.toLocaleLowerCase().includes(templateLowerCase)) {
      result = c;
      break;
    }
  }
  return result;
}

class UsersTable extends React.PureComponent <UsersTableProps, UsersTableState > {
  _commands: Command[] = [];

  constructor(props: UsersTableProps){
    super(props);

    this._commands = [
      {key: 'setPassword', caption: "Установить пароль", action: this.setPassword},
      {key: 'roles', caption: "Роли", action: this.showRoleSelectForm},
      {key: 'contacts', caption: "Контакты", action: this.showContactsTable},
      {key: 'unlock', caption: "Разблокировать", action: this.unLockUser},
  ];
  
    this.state = {
      users: [],
      roles: [],
      filter: '',
      userModal: {isOpen:false, isNew: false, values: {name:'', groups: undefined, selectedRoleIds: [], contacts: []}},
      setPasswordModal: {isOpen:false, values: {password:''}},
      contactModal: {isOpen: false, contact: undefined},
      sendingReportModal: {isOpen: false, contact: undefined, sendingReport: undefined},
      roleSelectIsOpen: false,
      selectedUserInfo: undefined,
      userSendingReports: undefined,
      contactsVisible: false,
      profileManagerFormIsOpen: false,
      modalZIndex: 0
    }
  }

  //Монтирование компонента.
  componentDidMount(){
    this.loadUsers();
    this.loadRoles();
  }

  //Обновление компонента.
  componentDidUpdate(prevProps: UsersTableProps, prevState: UsersTableState) {
    if(this.state.userModal.isOpen && this.props.userGroups != undefined && prevProps.userGroups === undefined){
        this.setState({userModal: {...this.state.userModal, values: {...this.state.userModal.values,  groups: this.props.userGroups}}})
    }
  }

  //Загрузка пользователей.
  loadUsers = (iUserInfo?: SUserInfoDTO | undefined)=>{
        sendRequestToBackend(
            '',
            'users/getusers',
            (response: SUserInfoDTO[]) => {
                this.setState({users: response});
                if(iUserInfo){
                  const newInfo = response.find(u=>u.user.userKey === iUserInfo.user.userKey);
                  if(newInfo) this.onSelectUser(newInfo);
                }
            },
            this.props.setIsLoading,
            (error: any) => {
                console.log(error);
            });
    }

    //Загрузка всех ролей системы.
    loadRoles = ()=>{
      sendRequestToBackend(
          null,
          'users/getallroles',
          (response: SRoleInfoDTO[]) => {
              this.setState({roles: response});
          },
          this.props.setIsLoading,
          (error: any) => {
              console.log(error);
          });
  }

  //Загрузка всех рассылок по контактам пользователя.
  loadUserSendingReports = (user:SUserDTO)=>{
    if(user){
      sendRequestToBackend(
          user.user,
          'Settings/sending_reports',
          (response: SendingReport[]) => {
            //Разбивка рассылок по контрактам.
            let srs: {[userContactId: string] : SendingReport[]} = {};
            for (let sr of response) {
                if (srs[sr.userContactId] === undefined) {
                    srs[sr.userContactId] = [];
                }
                initDates(sr, ['startDateTimeInterval', 'endDateTimeInterval']);
                srs[sr.userContactId].push(sr);
            }
            this.setState({userSendingReports: srs});
        },
          this.props.setIsLoading,
          (error: any) => {
              console.log(error);
          });
    }
  }

//Пользователь
//-------------

  //Выбор пользователя.
  onSelectUser = (userInfo: SUserInfoDTO) => {
    this.props.onSelectUser(userInfo.user);
    this.setState({selectedUserInfo: userInfo, userSendingReports:undefined});
    this.loadUserSendingReports(userInfo.user);
  }

  //Открытие формы изменения пользователя.
  editUser = (userInfo: SUserInfoDTO) =>{
    this.onSelectUser(userInfo);
    this.setState({userModal:{
      isOpen:true,
      isNew:false,
      values: {
        name: userInfo.user.user??'',
        groups: this.props.selectedUser?.userKey == userInfo.user.userKey ? this.props.userGroups : undefined,
        selectedRoleIds: userInfo.roles.map(r=>r.id),
        contacts: userInfo.contacts.map(c=>c.clientId)}
      },
      modalZIndex: getModalZIndex()
    })
  }
 
  //Создание пользователя.
  createUser = (values: UserValues)=> {
    const user: SUserDTO = {user: values.name, userKey: undefined, isLogged: false}
    const contacts: UserContact[] = [];
    values.contacts.forEach((clientId: string)=>{
      const contact: UserContact = {
        clientId: clientId,
        count: 3,
        eventsList: '',
        id: '',
        sendMask: 0,
        sendTypeId: Const.SEND_TYPE_ID_EMAIL,
        sendTypeName: '',
        messageSendTypesAbonents: {},
        userName: values.name,
        abonentsDaySendMaxCount: {},
        periods: 7
      }
      contacts.push(contact);
      })
    const request: SUserChangeRequest = {
      user: user,
      groupIds: values.groups ? values.groups.map(g=>g.id) : [],
      roleIds: values.selectedRoleIds,
      contacts: contacts
    }
    sendRequestToBackend(
      request,
      'users/changeuser',
      (response: any) => {
          message.success("Пользователь '" + values.name + "' добавлен!");
          this.loadUsers(response);
      },
      this.props.setIsLoading,
      (error: any) => {
          message.warning("Пользователя '" + values.name + "' добавить не удалось! Ошибка.");
          console.log(error);
          message.error(error);
      }
    );
  }

  //Изменение пользователя.
  changeUser = (values: UserValues)=> {
    if(this.state.selectedUserInfo){
      const contacts: UserContact[] = this.state.selectedUserInfo.contacts;
      const request: SUserChangeRequest = {
        user: {user: values.name, userKey: this.state.selectedUserInfo.user.userKey, isLogged: false},
        groupIds: values.groups ? values.groups.map(g=>g.id) : [],
        roleIds: values.selectedRoleIds,
        contacts: contacts
      }
      sendRequestToBackend(
        request,
        'users/changeuser',
        (response: any) => {
            message.success("Пользователь '" + values.name + "' изменён!");
            this.loadUsers(response);
            this.props.onChangeUser(response.user.userKey);
        },
        this.props.setIsLoading,
        (error: any) => {
            message.warning("Пользователя '" + values.name + "' изменить не удалось! Ошибка.");
            console.log(error);
            message.error(error);
        }
      );
    }
  }

  //Удаление пользователя.
  deleteUser = (user: SUserDTO) => {
    if(user.isLogged){
      message.warning("Вы пытаетесь удалить себя!");
    }
    else{
      Modal.confirm({
          title: 'Удаление пользователя',
          wrapClassName: Const.MODAL_WRAP_CLASSNAME,
          zIndex: getModalZIndex(),
              content: <div style = {{color:'red'}}>Вы собираетесь удалить пользователя '{user.user}'
              Вы уверены, что хотите УДАЛИТЬ пользователя?</div>,
          width: 400,
          okText: 'Удалить',
          cancelText: 'Отмена',
          onOk: () => {
              this.setState({selectedUserInfo: undefined});
              this.props.onSelectUser(null);
              sendRequestToBackend(
                  {user: user.user, userKey: user.userKey, isLogged: user.isLogged},
                  'users/deleteuser',
                  (response: any) => {
                      if (response != null && response === true) {
                          message.success("Пользователь '" + user.user + "' удален!");
                          this.loadUsers();
                      } else {
                          message.warning("Пользователя '" + user.user + "' удалить не удалось!");
                      }
                  },
                  this.props.setIsLoading,
                  (error: any) => {
                      message.warning("Пользователя '" + user.user + "' удалить не удалось! Ошибка.");
                      console.log(error);
                      message.error(error, 10);
                  }
              );
          },
          onCancel: () => {

          }
      });
    }

  }

  //Разблокировка пользователя.
  unLockUser = (userInfo: SUserInfoDTO) => {
    sendRequestToBackend(
      userInfo.user,
      'users/unlockuser',
      (response: any) => {
          if (response != null && response === true) {
              message.success("Пользователь '" + userInfo.user.user + "' разблокирован!");
              this.loadUsers();
          } else {
              message.warning("Пользователя '" + userInfo.user.user + "' нет необходимости разблоктровать!");
          }
      },
      this.props.setIsLoading,
      (error: any) => {
          message.warning("Ошибка при разблокировки пользователя.");
          console.log(error);
          message.error(error, 10);
      }
  );
}

  //Открытие формы установки пароля.
  setPassword = (userInfo: SUserInfoDTO) => {
    this.onSelectUser(userInfo);
    this.setState({setPasswordModal:{isOpen:true, values:{password: ''}}, modalZIndex:getModalZIndex()})
  }

  //Установка пароля.
  setPasswordConfirm = (userInfo: SUserInfoDTO | undefined, newPassword:string)=> {
    this.setState({setPasswordModal:{...this.state.setPasswordModal, isOpen: false}});
    if(userInfo){
        sendRequestToBackend(
          {user: userInfo.user.user, password: '', newPassword: newPassword },
          'users/password',
          (response: any) => {
              message.success("Пароль для пользователя установлен!");
              this.loadUsers();
          },
          this.props.setIsLoading,
          (error: any) => {
              message.warning("Новый пароль установить не удалось не удалось! Ошибка.");
              console.log(error);
              message.error(error);
          }
      );
    }
  }

//Роли
//----

  //Открытие формы выбора ролей.
  showRoleSelectForm = (userInfo: SUserInfoDTO) =>{
    this.props.onSelectUser(userInfo.user);
    this.setState({roleSelectIsOpen: true, selectedUserInfo: userInfo, modalZIndex: getModalZIndex()})
  }

  //Изменение ролей пользователя.
  changeUserRoles = (userInfo: SUserInfoDTO | undefined, roleIds: string[]) => {
    if(userInfo){
      const user: SUserDTO = userInfo.user;
      sendRequestToBackend(
        {user: user, roleIds: roleIds},
        'users/setuserroles',
        (response: any) => {
            message.success("Роли пользователя '" + user.user + "' установлены!");
            this.loadUsers();
        },
        this.props.setIsLoading,
        (error: any) => {
            message.warning("Роли пользователя '" + user.user + "' установить не удалось! Ошибка.");
            console.log(error);
            message.error(error);
        }
      );
    }
  }

  //Удаление роли.
  deleteRole = (userInfo: SUserInfoDTO, roleId: string) =>{
    this.onSelectUser(userInfo);
    const selectedRoleIds = userInfo.roles.map(r=>r.id);
    removeValue(selectedRoleIds, roleId);
    this.changeUserRoles(userInfo, selectedRoleIds);
  }

//Контакты
//--------

  //Открытие формы с контактами.
  showContactsTable = (userInfo: SUserInfoDTO) =>{
    this.props.onSelectUser(userInfo.user);
    this.setState({contactsVisible: true, selectedUserInfo: userInfo})
  }

  //Открытие формы для добавления контакта.
  addContact = (userInfo: SUserInfoDTO) =>{
    this.onSelectUser(userInfo);
    this.setState({contactModal:{isOpen:true, contact: undefined}, modalZIndex: getModalZIndex()})
  }

  //Открытие формы для изменения контакта.
  editContact = (userInfo: SUserInfoDTO, contact: UserContact) => {
    this.onSelectUser(userInfo);
    this.setState({contactModal:{isOpen:true, contact: contact}, modalZIndex: getModalZIndex()})
  }

  //Сохранение или добавление нового контакта.
  writeContact = (contact: UserContact) => {
    sendRequestToBackend(
      contact,
      'Settings/write_contact',
      (response: any) => {
          message.success((contact.id ? "Изменён контакт' " : "Добавлен контакт' ") + contact.clientId + "' для пользователя '" + contact.userName + "' !");
          this.loadUsers(this.state.selectedUserInfo);
      },
      this.props.setIsLoading,
      (error: any) => {
          message.warning("Ошибка при добавлении/измененнии конакта.");
          console.log(error);
          message.error(error);
      }
    )
  }

  //Запрос на удаление контакта.
  deleteContactConfirm = (contact: UserContact) => {
    Modal.confirm({
      title: 'Удаление контакта',
      wrapClassName: Const.MODAL_WRAP_CLASSNAME,
      zIndex: getModalZIndex(),
          content: <div style = {{color:'red'}}>Вы действительно хотите удалить контакт '{contact.clientId}'
          для пользователя '{contact.userName}'?</div>,
      width: 400,
      okText: 'Удалить',
      cancelText: 'Отмена',
      onOk: () => {
        this.deleteContact(contact);
      },
      onCancel: () => {

      }
    });
  }

  //Удаление контакта.
  deleteContact = (contact: UserContact) => {
        sendRequestToBackend(
          contact,
          'Settings/delete_contact',
          (response: any) => {
              message.success("Удалён контакт'" + contact.clientId + "' для пользователя '" + contact.userName + "' !");
              this.loadUsers(this.state.selectedUserInfo);
          },
          this.props.setIsLoading,
          (error: any) => {
              message.warning("Ошибка при удалении конакта.");
              console.log(error);
              message.error(error);
          }
        );
  }

  //Рассылка отчётов.
  //-----------------
  //Открытие формы для создания или изменения рассылки.
  editSendingReport = (contact: UserContact, sendingReport: SendingReport | undefined) => {
    this.setState({sendingReportModal: {isOpen: true, sendingReport:sendingReport, contact:contact}, modalZIndex: getModalZIndex()});
  }

  //Сохранение или добавление новой рассылки.
  writeSendingReport = (sendingReport: SendingReport) => {
    sendRequestToBackend(
      sendingReport,
      'Settings/write_sending_report',
      (response: any) => {
          message.success((sendingReport.id ? "Изменена рассылка' " : "Добавлена рассылка' ") + sendingReport.description + "'!");
          this.loadUsers(this.state.selectedUserInfo);
      },
      this.props.setIsLoading,
      (error: any) => {
          message.warning("Ошибка при добавлении/измененнии конакта.");
          console.log(error);
          message.error(error);
      }
    )
  }

  //Запрос на удаление рассылки.
  deleteSendingReportConfirm = (sendingReport: SendingReport) => {
    Modal.confirm({
      title: 'Удаление рассылки отчёта',
      wrapClassName: Const.MODAL_WRAP_CLASSNAME,
      zIndex: getModalZIndex(),
          content: 'Вы действительно хотите удалить рассылку?',
      width: 400,
      okText: 'Удалить',
      cancelText: 'Отмена',
      onOk: () => {
        this.deleteSendingReport(sendingReport);
      },
      onCancel: () => {

      }
    });
  }

  //Удаление рассылки.
  deleteSendingReport = (sendingReport: SendingReport) => {
        sendRequestToBackend(
          sendingReport,
          'Settings/delete_sending_report',
          (response: any) => {
              message.success("Удалёна рассылка '" + sendingReport.description + "' !");
          },
          this.props.setIsLoading,
          (error: any) => {
              message.warning("Ошибка при удалении рассылки.");
              console.log(error);
              message.error(error);
          }
        );
  }
//------------------------------------------------------------------------------------------------------------------
 
//Контекстное меню.
//------------------

  //Оработка клика от меню.
  onMenuClick = (info: any) => {
    console.log(info);
      const command = this._commands.find((c:Command)=>c.key===info.key);
      if(command){
          command.action(this.state.selectedUserInfo);
      }
  }

  //Создание меню.
  getContextMenu = () => {
    const items: any = this._commands.map( (c:Command)=>({label: c.caption, key: c.key}));
    const  result = (
          <Menu items = {items}  onClick = {this.onMenuClick}> </Menu>
      );
    return result;
  }
//------------------------------------------------------------------------------------------------------------------

  //Отрисовка.
  render(){
    const maxVisibleRecords = 100;
    const filterUsers: SUserInfoDTO[] = this.state.users.filter(u=>u.user.user && 
      (this.state.filter.length === 0 ||
         u.user.user.toLocaleLowerCase().includes(this.state.filter.toLocaleLowerCase()) ||
         findContact(u, this.state.filter.toLocaleLowerCase())));

    const getUsers = () => {
        const topFilterUsers: SUserInfoDTO[] = filterUsers.slice(0, maxVisibleRecords);
        return Object.values(topFilterUsers).map(item =>
          ({
              key: item.user.userKey,
              userInfo: item,
              name: item.user.user,
              billing: item.billing,
              contacts: item.contacts,
              roles: item.roles,
              date: item.lastActivityDate,
          })
      );
    }

    const scrollVisibilityTable = {y:this.props.parentHeight - 24};
    const showTags: boolean = this.props.parentWidth > 700;

    
    const userFormProps: UserFormProps = {
      open: this.state.userModal.isOpen,
      zIndex: this.state.modalZIndex,
      values: this.state.userModal.values,
      isNewUser: this.state.userModal.isNew, 

        allGroups: this.props.userProfile.userGroups,
        groupPoints: this.props.userProfile.groupPoints,
        allRoles: this.state.roles,
        allResources: this.props.userProfile.resources,
      requestGroupPoints: this.props.requestAllGroupPoints,
      setIsLoading: this.props.setIsLoading,
          
      onSave: (values: UserValues, isNew: boolean) => {
          if(isNew){
            this.createUser(values);
          }
          else{
            this.changeUser(values);
          }
          this.setState({userModal:{...this.state.userModal, isOpen: false}});
      },

      onCancel: () => {
        this.setState({userModal:{...this.state.userModal, isOpen: false}});
      },
    }

    const roleSelectFormProps: IRoleSelectFormProps = {
      visible: this.state.roleSelectIsOpen,
      zIndex: this.state.modalZIndex,
      roles: this.state.roles,
      selectedRoleIds: this.state.selectedUserInfo ? this.state.selectedUserInfo.roles.map(r=>r.id) : [],
      setIsLoading: this.props.setIsLoading,
      onChangeRoles: (roleIds: string[]) => this.changeUserRoles(this.state.selectedUserInfo, roleIds),
      onClose: ()=>this.setState({roleSelectIsOpen: false})
    }

    const setPasswordFormProps: SetPasswordFormProps = {
      visible: this.state.setPasswordModal.isOpen,
      zIndex: this.state.modalZIndex,
      values: this.state.setPasswordModal.values,
      userName: this.state.selectedUserInfo?.user.user ?? '',
      onSave: (values: SetPasswordValues) => this.setPasswordConfirm(this.state.selectedUserInfo, values.password),
      onCancel: () => {
        this.setState({setPasswordModal:{...this.state.setPasswordModal, isOpen: false}});
      },
    }
      const profileManagerFormProps: ProfileManagerFormProps = {
          open: this.state.profileManagerFormIsOpen,
          userProfile: this.props.userProfile.profile,
          onRun: (values: ProfileManagerValues) => {
              sendRequestToBackend(
                  {
                      usersTemplate: this.state.filter,
                      group: values.group,
                      parameter: values.parameter,
                      value: values.value,
                      changeCondition: values.changeCondition,
                      changeConditionValue: values.changeConditionValue
                  },
                  'profile/changevalue',
                  (response: any) => {
                      console.log(response);
                      message.success('Изменено профилей: ' + response);
                  },
                  this.props.setIsLoading,
                  (error: any) => {
                      console.log(error);
                  },
                  this.props.closeSession);
              this.setState({ profileManagerFormIsOpen: false });
          },
          onCancel: () => {
              this.setState({ profileManagerFormIsOpen: false });
          },
      }

    return(
      <>
         <div className={styles.flexcontainer}>

          <div className={styles.flexselfitem}>
          <Input 
                placeholder= {"Фильтр пользователей"} 
                value={this.state.filter}  
                onChange = {(e)=>{
                    this.setState({filter:e.target.value});
                }} 
            />
          </div>
          <div className={styles.flexselfitem}>
                {(maxVisibleRecords < filterUsers.length) ? ('Показано ' + maxVisibleRecords + ' из ' + filterUsers.length) : 'Выбрано: ' + filterUsers.length}
          </div>
          <div className={styles.flexselfitem}>
          <Tooltip placement="bottomRight" title={'Добавить пользователя'}>
                <Button type="primary" shape="round" icon={<UserAddOutlined/>}
                 onClick={
                    () => {
                        const roleIds: string[] = this.state.roles.filter(r=>r.role.name === DEFAULT_ROLE_NAME).map(r=>r.role.id);
                        this.setState({userModal:{isOpen: true, isNew:true, values: {name: '', groups: [], selectedRoleIds: roleIds, contacts: []}}, modalZIndex:getModalZIndex()});
                      }
                 }
                >Добавить пользователя</Button>
          </Tooltip>
          </div>
                <div className={styles.flexselfitem}>
                    <Tooltip placement="bottomRight" title={'Изменение профилей всех пользователей по заданным условиям.'}>
                        <Button type="primary" shape="round" icon={<SettingOutlined />}
                            onClick={
                                () => {
                                    this.setState({ profileManagerFormIsOpen: true })
                                }
                            }
                        >Изменить профиль</Button>
                    </Tooltip>
                </div>
          </div>
          {
              <UserForm {...userFormProps}/>
          }
          {
              this.state.setPasswordModal.isOpen &&
              <SetPasswordForm {...setPasswordFormProps}/>
          }
            <RoleSelectForm {...roleSelectFormProps}/>
            <ContactForm 
                visible={this.state.contactModal.isOpen}
                zIndex={this.state.modalZIndex}
                contact={this.state.contactModal.contact} 
                eventTypes={this.props.eventTypes}
                messageSendTypes={this.props.messageSendTypes}
                onClose={() => this.setState({contactModal: {isOpen: false, contact: undefined}})}
                userName={this.props.selectedUser?.user ?? ''}
                writeContact={this.writeContact} 
                groupPoints={this.props.groupPoints}
                requestGroupPoints={this.props.requestGroupPoints}
                setIsLoading={this.props.setIsLoading}
                userGroups={this.props.userGroups??[]}
                smallWindow={this.props.windowHeight < 850}
                resources={this.props.userProfile.resources}
                userAllPoints={Object.keys(this.props.allPoints)}
            />
            <SendingReportForm
                visible={this.state.sendingReportModal.isOpen}
                zIndex={this.state.modalZIndex}
                fileNameTypes={this.props.fileNameTypes ?? {}}
                messageSendTypes={this.props.messageSendTypes ?? {}}
                periodTypes={this.props.periodTypes ?? {}}
                reportingPeriodTypes={this.props.reportingPeriodTypes ?? {}}
                reportsTypes={this.props.reportsTypes ?? {}}
                schedulesTypes={this.props.schedulesTypes ?? {}}
                sendingReport={this.state.sendingReportModal.sendingReport}
                writeSendingReport={this.writeSendingReport}
                onHide={() => this.setState({sendingReportModal:{isOpen:false, contact:undefined, sendingReport:undefined}})}
                allPoints={this.props.allPoints}
                requestPoints={this.props.requestPoints}
                groupPoints={this.props.groupPoints}
                requestGroupPoints={this.props.requestGroupPoints}
                setIsLoading={this.props.setIsLoading}
                userGroups={this.props.userGroups??[]}
                userContactId={this.state.sendingReportModal.contact?.id??''}
                windowHeight={this.props.windowHeight}
            />

          <DraggableModal
            title= {"Контакты пользователя '" + this.state.selectedUserInfo?.user.user + "'"}
            open={this.state.contactsVisible}
            onCancel={()=>this.setState({contactsVisible:false})}
            footer={null}
            initialWidth={1000}
            initialHeight={600}
            wrapClassName ={Const.MODAL_WRAP_CLASSNAME}
            zIndex={this.state.modalZIndex}
          >
            <ContactsTable
              eventTypes = {this.props.eventTypes}
              contacts = {(this.state.selectedUserInfo ? this.state.selectedUserInfo.contacts : [])}
              hasEditRight = {true}
              sendingReports= {this.state.userSendingReports??{}}
              isSendingReportsLoading = {true}

              addContact={()=>this.setState({contactModal:{isOpen:true, contact: undefined}})}
              editContact={(contact:UserContact)=>this.setState({contactModal:{isOpen:true, contact: contact}})}
              editSendingReport={this.editSendingReport}
              deleteSendingReport = {this.deleteSendingReport}
              deleteContact = {this.deleteContactConfirm}
              closeSession={this.props.closeSession}
            />

          </DraggableModal>
            {
                this.state.profileManagerFormIsOpen &&
                <ProfileManagerForm {...profileManagerFormProps} />
            }
    <Table
      dataSource={getUsers()}
      pagination={false}
      scroll={scrollVisibilityTable}
      bordered
      onRow={(record, rowIndex) => {
        return {
          onClick: (event) => {this.onSelectUser(record.userInfo)}, // click row
          onDoubleClick: (event) => {this.onSelectUser(record.userInfo)}, // double click row
          onContextMenu: (event) => {this.onSelectUser(record.userInfo)}, // right button click row
          onMouseEnter: (event) => {}, // mouse enter row
          onMouseLeave: (event) => {}, // mouse leave row
        };
      }}
      rowClassName={(record,index)=>(record.userInfo.user.userKey === this.props.selectedUser?.userKey ?
         styles.selectedRow :
         record.userInfo.isLocked ? 
          styles.lockedRow : styles.simpleRow)}
    >
        <Column 
            title=""
            width={65}
            dataIndex="userInfo" 
            key="edit"
            render={(value: SUserInfoDTO, record) => (
                <Button
                    shape="circle"
                    icon={<EditTwoTone twoToneColor={Const.COLORS.SvgIconColor}/>}
                    onClick={
                      () => this.editUser(value)
                    }
                />
            )}
            
        />
        <Column 
            title=""
            width={65}
            dataIndex="userInfo"
            key="delete"
            render={(value, record: any) => (
                <Button 
                    shape="circle" 
                    icon={<DeleteTwoTone twoToneColor={Const.COLORS.SvgIconColor}/>}
                    onClick={
                      () => this.deleteUser(value.user)
                    }
                />
            )}
        />
        <Column 
            title="Логин"
            dataIndex="name" 
            key="name" 
            render={(value, record: any) => (
              <Dropdown overlay={this.getContextMenu()} trigger={['contextMenu']}>
                <div style={{cursor:'context-menu'}}>{value}</div>
              </Dropdown>
            )}
      />
        <Column 
            title="Биллинг"
            dataIndex="billing" 
            key="billing" 
        />
        <Column 
            title={
              <Space>
                  Оповещения
                  <Tooltip placement="top" title={'Контакты для рассылки сообщений по заданным событиям'}>
                      <QuestionCircleTwoTone  className={settingsStyles.Question}/>
                  </Tooltip>
              </Space>
          }
          width={showTags ? 200 : 130}
          dataIndex="contacts" 
            key="contacts" 
            render={(contacts: UserContact[], record: any) => (
              <>
                  {showTags && contacts.map(item => (
                          <Tag 
                              color="cyan" 
                              closable 
                              onClose={(e: any) => {e.preventDefault(); this.deleteContactConfirm(item);}}
                              onClick={() => this.editContact(record.userInfo, item)}
                              key={item.id}
                              className={styles.TagMargin}
                              style={{margin: '1px'}}
                          >
                              {item.clientId}
                          </Tag>
                      ))}

                  {
                      <Tooltip placement="top" title={contacts.length > 0 ? 'Изменить контакты: ' + contacts.map(c=>c.clientId).join(', ') : 'Добавить контакт'}>
                        <Button shape="circle"
                            onClick={() => contacts.length > 0 ? this.showContactsTable(record.userInfo) : this.addContact(record.userInfo)}
                            icon={<PlusCircleTwoTone twoToneColor="#52c41a" />}
                        />
                      </Tooltip>
                  }
              </>
          )}
      />
        <Column
            title={<div>Роли</div>}
            width={showTags ? 180 : 70}
            dataIndex="roles"
            key="roles"
            render={(roles: SRoleDTO[], record: any) => (
                <>
                    {showTags && roles.map(item => (
                          <Tooltip placement="top" title={'Роль: ' + item.name} key={item.id}>
                          <Tag 
                                color="cyan" 
                                closable 
                                onClose={(e: any) => {e.preventDefault(); this.deleteRole(record.userInfo, item.id);}}
                                className={styles.TagMargin}
                                key={item.id}
                                style={{margin: '1px 2px'}}
                            >
                                {item.name}
                            </Tag>
                            </Tooltip>
                        ))
                    }

                    {
                      <Tooltip placement="top" title={roles.length > 0 ? 'Изменить роли: ' + roles.map(r=>r.name).join(', ') : 'Установить роли'}>
                        <Button shape="circle"
                              onClick={() => this.showRoleSelectForm(record.userInfo)}
                              icon={<PlusCircleTwoTone twoToneColor="#52c41a" />}
                          />
                        </Tooltip>
                    }
                </>
            )}
        />
        <Column
            title="Дата входа"
            width={120}
            dataIndex="date"
            key="date"
            render={(value, record) => (toDateString(new Date(value)))}

        />
    </Table>
  </>
);
}}

export default UsersTable;