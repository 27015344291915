import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { connect } from 'react-redux';

import { ApplicationState } from '../store';
import * as PageStore from '../store/Page';
import * as UserProfileStore from '../store/UserProfile';
import * as ControlStore from '../store/ControlStore';

import styles from '../resources/Page.module.less';
import { Tabs, Table, Modal, Select, Button, Space, Tooltip, Switch, Popconfirm, Tag, Row, Progress } from 'antd';
import { AlignType } from 'rc-table/lib/interface';
import { AlertOutlined, CheckCircleTwoTone, DeleteTwoTone, EditTwoTone, ExclamationCircleOutlined, FieldTimeOutlined, FireOutlined, FireTwoTone, InfoCircleTwoTone, InteractionOutlined, LineChartOutlined, LoadingOutlined, ProfileOutlined, QuestionCircleTwoTone, ScheduleOutlined, ScheduleTwoTone, SettingOutlined, SettingTwoTone, SnippetsOutlined, StopTwoTone, TeamOutlined, ToolOutlined, UserOutlined, WarningTwoTone, WifiOutlined } from '@ant-design/icons';
import Groups from '../components/Groups';
import { SPointsFilter, USER_RIGHTS, EventTypesEnum, PeriodsEnum, SourceEventTypesEnum, SPointEventDTO, SPointWithEventsDTO } from "../decl"
import * as Const from '../utils/Const';
import * as Common from '../utils/Common';
import Resources from '../components/Resources';
import RangePickerLimit from '../components/Helper/RangePickerLimit';
import moment from 'moment';
import ReactDOM from 'react-dom';
import { EventTypeToString, netiExecute, PeriodEnumToString } from '../utils/Common';
import ConditionForm from '../components/ConditionForm';
import { SConditionDTO, SConditionsAddRequest } from '../store/ControlStore';
import {ControlData, hasData, EVENTS_FIRST_QUERY_COUNT} from '../components/Control/ControlData'

const { TabPane } = Tabs;
const { confirm } = Modal;

const actionCreators = { ...PageStore.actionCreators, ...UserProfileStore.actionCreators, ...ControlStore.actionCreators };

type ControlProps =
    {
        page: PageStore.PageState,
        userProfile: UserProfileStore.UserProfileState,
        control: ControlStore.IControlState,
        windowHeight: number,
    } &
    typeof actionCreators &
    RouteComponentProps<{}>

interface IControlState {
    startDate: Date;
    endDate: Date;
    newEventsOnly: boolean;
    //allEventsSwitchState: boolean;
    selectedAbonentsIds: string[];
    selectedEventsIds: number[];
    showConditionForm: boolean;
    modalZIndex: number,
    editingCondition: SConditionDTO | undefined;
    conditionsTableRowStyle: string;
    conditionsTableTypeFilters: string[];
    filter: SPointsFilter;
    eventsTableLoading: boolean;
    eventsTableReady: boolean;
    eventRows: any;
    eventsCurrentPage: number;
    eventsPageSize: number;
    allEventsCount: number;
    loadedEventsCount: number;
}

const DEFAULT_PAGE_SIZE: number = 5;

class Control extends React.PureComponent <ControlProps, IControlState > {
    _data: ControlData;
    constructor(props: ControlProps){
        super(props);
        this._data = new ControlData();

        this.state = {
            //startDate: moment().add(-35, 'days').toDate(),
            startDate:moment().add(-this.EVENTS_TIME_DEPTH_DAYS, 'days').toDate(),
            endDate: new Date(),
            newEventsOnly: true,
            selectedAbonentsIds: [],
            selectedEventsIds: [],
            showConditionForm: false,
            modalZIndex: 0,
            editingCondition: undefined,
            conditionsTableRowStyle: '',
            conditionsTableTypeFilters: [],
            filter: {
                groups: null,
                points: '',
                resources: null,
                systemNumbers: null,
                text: '',
            },
            eventsTableLoading: false,
            eventsTableReady: false,
            eventRows: [],
            eventsCurrentPage: 1,
            eventsPageSize: DEFAULT_PAGE_SIZE,
            allEventsCount: 0,
            loadedEventsCount:0
        }
    }

    columns1 = [
        {
            title: 'Инфо',
            width: 80,
            align:'center' as AlignType,
            dataIndex: 'select',
            render: (text: any, record: any) => 
                <Button onClick={() => this.showPointInfo(record.key, record.abonent)} size="middle" shape="circle" icon={<ScheduleTwoTone twoToneColor={Const.COLORS.SvgIconColor} />} />
        },
        { title: 'Точка учёта', dataIndex: 'abonent', key: 'abonent',  ellipsis: true},
        { title: 'Событий', width:95, align:'center' as AlignType, dataIndex: 'events', },
        {
            title: () => <Button disabled={this.props.control.isEventsAcceptRequest} onClick={() => this.onClickReadEventsByAllPoints()} size="small">Квитировать всё</Button>,
            width: 150,
            dataIndex: 'read-all',
            align: 'center' as AlignType,
            render: (text: any, record: any) => <Button disabled={this.props.control.isEventsAcceptRequest} size="small" onClick={() => this.onClickReadEventsByPoints(record.key)}>Квитировать ТУ</Button>,
        },
    ];
    
    componentDidUpdate(prevProps: ControlProps, prevState: IControlState) {  
        if (prevProps.control.points !== this.props.control.points) {
            let selIds: string[] | undefined;
            selIds = this.props.control.points?.map(item => item.id) ?? [];

            this.setState({selectedAbonentsIds: selIds});
        }

        if(!this.props.control.isLoadingPoints && prevProps.control.isLoadingPoints){
            let eventsCount = 0;
            this.props.control.points?.forEach(point => eventsCount += point.eventsCount); 
            this.eventsDataRefresh(eventsCount);

            this.setState({
                eventsCurrentPage: 1,
                allEventsCount: eventsCount,
                loadedEventsCount: 0,
                eventsTableReady: false,
                selectedEventsIds: []})
            this.requestRows(1, EVENTS_FIRST_QUERY_COUNT);
        }
        //loading={this.state.eventsTableLoading || this.props.control.isEventsAcceptRequest}
        const isLoading = this.props.control.isLoadingPoints || this.state.eventsTableLoading || this.props.control.isEventsAcceptRequest;
        const prevIsLoading = prevProps.control.isLoadingPoints || prevState.eventsTableLoading || prevProps.control.isEventsAcceptRequest;
        if(isLoading != prevIsLoading){
            this.props.setIsLoading(isLoading, 500);
        }
        // if (prevProps.control.events !== this.props.control.events){
        //     this.setState({selectedEventsIds: []})
        // }
    }

    componentDidMount() {
        this.requestEventsAction(this.state.newEventsOnly);
    }
    componentWillUnmount(){
        this._data.dispose();
    }

    readonly EVENTS_DEFAULT_PAGE_SIZE: number = 5;
    readonly EVENTS_TIME_DEPTH_DAYS: number = 35;

    sorter = (a: any, b: any) => (isNaN(a) && isNaN(b) ? (a || '').localeCompare(b || '') : a - b);

    //Инициализация провайдера данных таблицы событий.
    eventsDataRefresh = (eventsCount: number) => {
        const pointIds: string[] = this.props.control.points ? this.props.control.points.map(p=>p.id) : [];
        this._data.refresh(
            pointIds,
            eventsCount,
            this.props.control.lastEventId,
            this.state.startDate,
            this.state.endDate,
            this.state.newEventsOnly,
            (loadedCount: number, allCount: number)=>{
                this.setState({
                    eventRows: this._data.getRows(),
                    allEventsCount: allCount,
                    loadedEventsCount: loadedCount,
                    eventsTableReady: loadedCount === allCount
                });
            });
    }

    onProgressChange = (checked: boolean, event: any) => {
        if(checked){
            this._data.loadingStart();
        }
        else{
            this._data.loadingStop();
        }
    }

    //Обновление состояния таблицы событий и запрос недостающих рядов из провайдера данных.
    eventsTableRefresh = () => {
        this.setState({
            eventRows: this._data.getRows(),
            eventsTableReady: this._data.isReady()
        });

        const n1 = (this.state.eventsCurrentPage - 1) * this.state.eventsPageSize + 1;
        const n2 = n1 + this.state.eventsPageSize;
        if(!hasData(this.state.eventRows, n1, n2))
        {
            this.requestRows(n1, n2);
        }
    }

    onPointsChange= (selectedRowKeys: any[], selectedRows: any[], info: any) => {
        let eventsCount = 0;
        let selectedIds: string[] = [];
        if(info.type === 'all'){
            if(this.state.selectedAbonentsIds.length === 0 && this.props.control.points){
                selectedIds = this.props.control.points.map(p=>p.id);
            }
        }
        else{
            selectedIds = selectedRowKeys;
        }

        this.props.control.points?.filter(point => selectedIds.includes(point.id)).forEach(point => eventsCount += point.eventsCount);
        this._data.selectPoints(selectedIds, eventsCount);
    
        this.setState({
            eventsCurrentPage: 1,
            selectedAbonentsIds: selectedIds,
            eventRows: this._data.getRows(),
        });
}

    onClickReadEvents = () => {
        this.props.requestAcceptEvents(
            this.state.selectedEventsIds,
            (eventIds: number[])=>{
                const points: SPointWithEventsDTO[] = [];

                const pointCounts: { [key: string]: number } = this._data.getPointCounts(eventIds);

                this.props.control.points?.forEach(p=>{
                    if(pointCounts[p.id]){
                        p.eventsCount -= pointCounts[p.id];
                    }
                    if(p.eventsCount > 0){
                        points.push(p);
                    }
                })
                this.props.changePointsAction(points);
                this._data.acceptEvents(eventIds);

                let selectedPointEventsCount = 0;
                this.props.control.points?.filter(point => this.state.selectedAbonentsIds.includes(point.id)).forEach(point => selectedPointEventsCount += point.eventsCount);
                this._data.selectPoints(this.state.selectedAbonentsIds, selectedPointEventsCount);

                this.eventsTableRefresh();
            });
    }

    onClickReadEventsByPoints = (abonentId: string) => {
        this.props.requestAcceptEventsByPoints(
            [abonentId],
            this.state.startDate,
            this.state.endDate,
            (pointIds: string[], eventsCount: number)=>{
                this._data.acceptPoints(pointIds, eventsCount);

                let selectedPointEventsCount = 0;
                this.props.control.points?.filter(point => this.state.selectedAbonentsIds.includes(point.id)).forEach(point => selectedPointEventsCount += point.eventsCount);
                this._data.selectPoints(this.state.selectedAbonentsIds, selectedPointEventsCount);
        
                this.eventsTableRefresh();
            });
    }

    onClickReadEventsByAllPoints = () => {
        this.props.requestAcceptEventsByPoints(this.props.control.points?.map(item => item.id) ?? [], this.state.startDate, this.state.endDate,
         (pointIds: string[], eventsCount: number)=>{
            this._data.acceptPoints(pointIds, eventsCount);
            this.eventsTableRefresh();
        });
    }

    execute = (cmd: any) => {
        netiExecute(cmd, this.props.userProfile, this.props.changeProfile, this.props.setIsLoading, this.props.setActiveQueries, null, this.props.showModal);
    }

    showPointInfo = (pointId: string, pointNumber: string) =>{
        const source: any = new (window.NetiSource as any)(this.execute);
        const parent: any = ReactDOM?.findDOMNode(this)?.parentNode;
        this.props.showPointInfo(pointId, pointNumber, source, parent);
    }

    deleteConditionConfirm = (condition: SConditionDTO) => {
        confirm({
            title: 'Подтверждение',
            icon: <ExclamationCircleOutlined />,
            content: 'Вы действительно хотите удалить данное условие?',
            onOk: () => {
                this.props.requestRemoveConditionAction(condition);
            }
        });
    }

    removeAllConditionsConfirm = () => {
        this.setState({conditionsTableRowStyle: styles.TableRowRed});
        let conds = this.props.control.conditions ?? [];
        if (this.state.conditionsTableTypeFilters.length > 0) {
            
            conds = conds.filter(item => (item.forGroup === true && this.state.conditionsTableTypeFilters.includes('group'))
                                          || item.forGroup === false && this.state.conditionsTableTypeFilters.includes('abonent'));
        }
        confirm({
            title: 'Подтверждение',
            icon: <ExclamationCircleOutlined />,
            content: <span>Вы действительно хотите удалить <span style={{fontWeight: 'bold', color: 'red'}}>{conds.length}</span> условий(я) контроля?</span>,
            onOk: () => {
                this.setState({conditionsTableRowStyle: ''});
                this.props.requestRemoveConditionsAction(conds);
            },
            onCancel: () => {
                this.setState({conditionsTableRowStyle: ''});
            }
        });
    }

    requestEventsAction = (newEventsOnly: boolean) => {
        this.props.requestPointsWithEvents(
            this.state.filter,
            this.state.startDate,
            this.state.endDate,
            newEventsOnly
        );

        this.setState({newEventsOnly: newEventsOnly});
    }

    requestConditionsAction = () => {
        this.props.requestConditionsAction(this.state.filter);
    }

    onValueChange = (groups: any, points: any) => {
        this.setState({filter: {
            ...this.state.filter, 
            groups: (groups.indexOf(Const.ALL_GROUP_ID) >= 0) ? null : Common.arrayToString(groups), 
            points: Common.arrayToString(points)}
        });
    };

    onResourcesChange = (value: any) => {
        this.setState({filter: {
            ...this.state.filter, 
            resources: (value.indexOf(Const.ALL_RESOURCE_ID) >= 0) ? null : Common.arrayToString(value)}
        });
    };
    
    requestRows = (n1: number, n2: number) => {
        if(this._data.requestRows(n1, n2, (loadedCount: number, allCount: number)=>{
            this.setState({
                loadedEventsCount: loadedCount,
                allEventsCount: allCount,                    
                eventRows: this._data.getRows(),
                eventsTableLoading: false,
                eventsTableReady: this._data.isReady()
            });
        })){
            this.setState({eventRows: this._data.getRows()});
        }
        else{
            this.setState({eventsTableLoading: true});
        }
    }

    onEventsTableChange = (pagination: any, filters:any, sorter:any, extra:any) => {
        if(extra.action === "paginate"){
            this.setState({
                eventsCurrentPage: pagination.current,
                eventsPageSize: pagination.pageSize,
                eventRows: this._data.getRows(),
                eventsTableReady: this._data.isReady()
            });
    
            const n1 = (pagination.current - 1) * pagination.pageSize + 1;
            const n2 = n1 + pagination.pageSize;
            if(!hasData(this.state.eventRows, n1, n2))
            {
                this.requestRows(n1, n2);
            }

            //if(this.state.data[n1] === undefined || this.state.data[n2-1] === undefined){
            //  this.setState({...this.state, eventsTableLoading: true})
            //  setTimeout(()=>this.setState({data: this.dataLoad(this.state.data, n1, n2), loading: false}), 5000);
            //}
        }
    }

    getEventsColumns = () => {
        const columns: any = [
            { 
                title: 'Время', 
                width:160,
                dataIndex: 'time', 
                key: 'time', 
                sorter: this.state.eventsTableReady ? (a: any, b: any) => this.sorter(a.time, b.time) : false, 
                render: (item: any, record: any) => <div style={{minHeight: '32px', display: 'inline-flex', justifyContent: 'center', flexDirection: 'column'}}>{item ? moment(item).format("DD.MM.YYYY HH:mm:ss") : ""}</div>                    
            },
            { 
                title: 'Точка учёта',
                ellipsis: true,
                dataIndex: 'object',
                key: 'object',
                sorter: this.state.eventsTableReady ? (a: any, b: any) => this.sorter(a.object, b.object) : false,
             },
            { 
                title: 'Событие', 
                ellipsis: true,
                dataIndex: 'event', 
                key: 'event', 
                render: (item: any, record: any) => <a onClick={() => this.props.formReport(record.pointId, moment(record.eventTime).set({ hour:0, minute:0, second:0, millisecond:0 }).toDate(), moment(record.eventTime).set({ hour:0, minute:0, second:0, millisecond:0 }).add(1, 'days').toDate(), PeriodsEnum.hour)}>{item}</a>, 
                sorter: this.state.eventsTableReady ? (a: any, b: any) => this.sorter(a.event, b.event) : false, 
            },
            {
                title: 
                    <Tooltip placement="top" title={'Цветовое обозначение события для привлечения внимания'}>
                        <span style={{marginRight: '3px'}}>Статус</span>
                    </Tooltip>,
            width: 100,
            dataIndex: 'status',
                sorter: this.state.eventsTableReady ? (a: any, b: any) => this.sorter(a.status, b.status) : false,
                align: 'center' as AlignType,
                filters: this.state.eventsTableReady ? [
                    { text: <Space><WarningTwoTone twoToneColor="#eb2f96" style={{ fontSize: '20px'}} />Авария</Space>, value: EventTypesEnum.Alarm },
                    { text: <Space><InfoCircleTwoTone twoToneColor="#fadb14" style={{ fontSize: '20px'}} />Сообщение</Space>, value: EventTypesEnum.Message },
                    { text: <Space><CheckCircleTwoTone twoToneColor="#52c41a" style={{ fontSize: '20px'}} />Поверка</Space>, value: EventTypesEnum.Checking },
                    { text: <Space><StopTwoTone twoToneColor="#eb2f96" style={{ fontSize: '20px'}} />Предупреждение</Space>, value: EventTypesEnum.Warning },
                    { text: <Space><FireTwoTone twoToneColor="#ff7a45" style={{ fontSize: '20px'}} />НС</Space>, value: EventTypesEnum.Unstate },
                    { text: <Space><SettingTwoTone style={{ fontSize: '20px'}} />ТО</Space>, value: EventTypesEnum.Work },
                ] : undefined,
                onFilter: this.state.eventsTableReady ? (value: any, record: any) => value === record['status'] : undefined,
                render: (item: any) => {
                    const et = item as EventTypesEnum;
                    let res = <></>;
                    if (et === EventTypesEnum.Work)
                        res = <Tooltip placement="top" title={'ТО'}><SettingTwoTone style={{ fontSize: '20px'}} /></Tooltip>;
                    else if (et === EventTypesEnum.Alarm)
                        res = <Tooltip placement="top" title={'Авария'}><WarningTwoTone twoToneColor="#eb2f96" style={{ fontSize: '20px' }} /></Tooltip>;
                    else if (et === EventTypesEnum.Checking)
                        res = <Tooltip placement="top" title={'Поверка'}><CheckCircleTwoTone twoToneColor="#52c41a" style={{ fontSize: '20px' }} /></Tooltip>;
                    else if (et === EventTypesEnum.Message)
                        res = <Tooltip placement="top" title={'Сообщение'}><InfoCircleTwoTone twoToneColor="#fadb14" style={{ fontSize: '20px' }} /></Tooltip>;
                    else if (et === EventTypesEnum.Unstate)
                        res = <Tooltip placement="top" title={'НС'}><FireTwoTone twoToneColor="#ff7a45" style={{ fontSize: '20px' }} /></Tooltip>;
                    else if (et === EventTypesEnum.Warning)
                        res = <Tooltip placement="top" title={'Предупреждение'}><StopTwoTone twoToneColor="#eb2f96" style={{ fontSize: '20px' }} /></Tooltip>;
                    return res;
                },
            },
            {
                title: 'Тип',
                width: 100,
                dataIndex: 'sourceType',
                key: 'sourceType',
                align:'center' as AlignType,
                render: (item: any) => {
                    const et = item as SourceEventTypesEnum;
                    let res = <></>;
    
                    if (et === SourceEventTypesEnum.ConnectionEvents)
                        res = <Tooltip placement="top" title={'Отсутствие связи с прибором'}><WifiOutlined style={{ fontSize: '20px' }}/></Tooltip>;
                    else if (et === SourceEventTypesEnum.DeviceParametersChanged)
                        res = <Tooltip placement="top" title={'Изменение параметров прибора'}><InteractionOutlined style={{ fontSize: '20px' }}/></Tooltip>;
                    else if (et === SourceEventTypesEnum.DeviceTimeIncorrect)
                        res = <Tooltip placement="top" title={'Неверное время прибора'}><FieldTimeOutlined style={{ fontSize: '20px' }}/></Tooltip>;
                    else if (et === SourceEventTypesEnum.DeviceVerificationMessage)
                        res = <Tooltip placement="top" title={'Поверка'}><ToolOutlined style={{ fontSize: '20px' }}/></Tooltip>;
                    else if (et === SourceEventTypesEnum.DriverEvents)
                        res = <Tooltip placement="top" title={'Условия прибора'}><ProfileOutlined style={{ fontSize: '20px' }}/></Tooltip>;
                    else if (et === SourceEventTypesEnum.GroupCondition)
                        res = <Tooltip placement="top" title={'Групповое условие'}><TeamOutlined style={{ fontSize: '20px' }}/></Tooltip>;
                    else if (et === SourceEventTypesEnum.MinMaxCheck)
                        res = <Tooltip placement="top" title={'ПДЗ'}><LineChartOutlined style={{ fontSize: '20px' }}/></Tooltip>;
                    else if (et === SourceEventTypesEnum.MonthReport)
                        res = <Tooltip placement="top" title={'Месячный отчёт'}><SnippetsOutlined style={{ fontSize: '20px' }}/></Tooltip>;
                    else if (et === SourceEventTypesEnum.PointCondition)
                        res = <Tooltip placement="top" title={'Индивидуальное условие'}><UserOutlined style={{ fontSize: '20px' }}/></Tooltip>;
                    else if (et === SourceEventTypesEnum.SensorEvents)
                        res = <Tooltip placement="top" title={'Срабатывание датчиков'}><AlertOutlined style={{ fontSize: '20px' }}/></Tooltip>;
                    return res;
                },
                sorter: this.state.eventsTableReady,
                //sorter: (a: any, b: any) => this.sorter(a, b),
                onFilter: this.state.eventsTableReady ? (value: any, record: any) => value === record['sourceType'] : undefined,
                filters: this.state.eventsTableReady ? [
                    { text: <Space><WifiOutlined style={{ fontSize: '20px'}}/>Отсутствие связи с прибором</Space>, value: SourceEventTypesEnum.ConnectionEvents },
                    { text: <Space><InteractionOutlined style={{ fontSize: '20px'}}/>Изменение параметров прибора</Space>, value: SourceEventTypesEnum.DeviceParametersChanged },
                    { text: <Space><FieldTimeOutlined style={{ fontSize: '20px'}}/>Неверное время прибора</Space>, value: SourceEventTypesEnum.DeviceTimeIncorrect },
                    { text: <Space><ToolOutlined style={{ fontSize: '20px'}}/>Сообщения о поверке</Space>, value: SourceEventTypesEnum.DeviceVerificationMessage },
                    { text: <Space><ProfileOutlined style={{ fontSize: '20px'}}/>Условия прибора</Space>, value: SourceEventTypesEnum.DriverEvents },
                    { text: <Space><TeamOutlined style={{ fontSize: '20px'}}/>Групповые условия</Space>, value: SourceEventTypesEnum.GroupCondition },
                    { text: <Space><LineChartOutlined style={{ fontSize: '20px'}}/>ПДЗ</Space>, value: SourceEventTypesEnum.MinMaxCheck },
                    { text: <Space><SnippetsOutlined style={{ fontSize: '20px'}}/>Месячные отчеты</Space>, value: SourceEventTypesEnum.MonthReport },
                    { text: <Space><UserOutlined style={{ fontSize: '20px'}}/>Индивидуальное условие</Space>, value: SourceEventTypesEnum.PointCondition },
                    { text: <Space><AlertOutlined style={{ fontSize: '20px'}}/>Срабатывания датчиков</Space>, value: SourceEventTypesEnum.SensorEvents },
                ] : undefined,
            },
        ];
        return columns;
    }

    public render() {
        //Эта переменная добавлена сюда из-за переменной filteredValue которая не имеет функции обратного вызова но должна формироватся динамически
        const columns3 = [
            {
                dataIndex:'edit',
                key:'editCondition',
                render: (text: any, record: any) => 
                        <Space size="middle">
                        <Button shape="circle" onClick={() => this.setState({ showConditionForm: true, modalZIndex: Common.getModalZIndex(), editingCondition: record.conditionItem })} icon={<EditTwoTone twoToneColor={Const.COLORS.SvgIconColor} />} />
                        </Space>
            },
            {
                title: 
                    <Space size="middle">
                        <Button shape="circle" onClick={() => this.removeAllConditionsConfirm()} icon={<DeleteTwoTone twoToneColor={Const.COLORS.SvgIconColor} />} />
                    </Space>,
                dataIndex:'delete',
                key:'deleteCondition',
                render: (text: any, record: any) => 
                        <Space size="middle">
                        <Button shape="circle" onClick={() => this.deleteConditionConfirm(record.conditionItem)} icon={<DeleteTwoTone twoToneColor={Const.COLORS.SvgIconColor} />} />
                        </Space>
            },
            {
                key: 'type_condition',
                dataIndex: 'type_condition',
                width: 15,
                sorter: true,
                filters: [
                    { text: <Space><TeamOutlined twoToneColor="#eb2f96" style={{ fontSize: '20px'}} />Групповые</Space>, value: 'group' },
                    { text: <Space><UserOutlined twoToneColor="#fadb14" style={{ fontSize: '20px'}} />Индивидуальные</Space>, value: 'abonent' },
                ],
                filteredValue: this.state?.conditionsTableTypeFilters ?? [],
                onFilter: (value: any, record: any) => record.type_condition.indexOf(value) === 0,
                render: (item: any) => {
                    if (item === 'group')
                        return <Tooltip placement="top" title={"Групповое условие"}><TeamOutlined twoToneColor="#eb2f96" style={{ fontSize: '20px'}} /></Tooltip>
                    else
                        return <Tooltip placement="top" title={"Индивидуальное условие"}><UserOutlined twoToneColor="#fadb14" style={{ fontSize: '20px'}} /></Tooltip>
                },
            },
            { title: 'Группа/ТУ', dataIndex: 'group_tu', key:'group_tu', sorter: (a: any, b: any) => this.sorter(a.group_tu, b.group_tu) },
            { title: 'Условие', dataIndex: 'condition', key: 'condition', sorter: (a: any, b: any) => this.sorter(a.condition, b.condition)},
            { title: 'Описание', dataIndex: 'discription', key:'discription', sorter: (a: any, b: any) => this.sorter(a.discription, b.discription) },
            { title: 'Сообщение', dataIndex: 'message', key:'message', sorter: (a: any, b: any) => this.sorter(a.message, b.message)},
            { title: 'Тип события', dataIndex: 'type_event', key: 'type_event', sorter: (a: any, b: any) => this.sorter(a.type_event, b.type_event) },
            { title: 'Тип ресурса', dataIndex: 'type_resource', key: 'type_resource'},
            { title: 'Период', dataIndex: 'period', key: 'period', sorter: (a: any, b: any) => this.sorter(a.period, b.period) },
        ];

        const selectedGroupIds = (this.state.filter.groups != null) ?
            Common.stringToArray(this.state.filter.groups) :
            [Const.ALL_GROUP_ID];

        const selectedPointIds = (this.state.filter.points != null) ?
            Common.stringToArray(this.state.filter.points) :
            [];

        const selectedResourceIds = (this.state.filter.resources != null) ?
            Common.stringToArray(this.state.filter.resources) :
            [Const.ALL_RESOURCE_ID];
    
        const GroupsProps = {
            groups: (this.props.userProfile) ? this.props.userProfile.userGroups : [],
            groupPoints: (this.props.userProfile) ? this.props.userProfile.groupPoints : {},
            selectedGroupIds: selectedGroupIds,
            selectedPointIds: selectedPointIds,
            getPoints: this.props.requestGroupPoints,
            getPointsParentGroups: null,
            onChange: this.onValueChange,
            setIsLoading: this.props.setIsLoading,
        };

        const ResourcesProps = {
            resources: (this.props.userProfile) ? this.props.userProfile.resources : [],
            selectedResourceIds: selectedResourceIds,
            onChange: this.onResourcesChange,
        };
        const canAddCondition: boolean = (this.props.userProfile) ?
            this.props.userProfile.rights.includes(USER_RIGHTS.Admin) :
            false;

        const pointsStyle = this.props.page.windowWidth < 1800 ?
             {flexGrow: 0,flexBasis: 480, minWidth: 480} :
             {flexGrow: 0,flexBasis: 600, minWidth: 600};
        const eventsStyle = this.props.page.windowWidth < 1800 ?
            {flexGrow: 0,flexBasis: 700, minWidth: 580} :
            {flexGrow: 0,flexBasis: 1100, minWidth: 900};
        return (
            <div className={styles.container}>
                <Tabs type="card" tabBarStyle={{marginBottom: '0px'}} onChange={tabKey => tabKey === '2' && this.props.control.conditions == undefined ? this.requestConditionsAction() : null } 
                    items={
                        [
                            {
                                label: "Сообщения контроля", key: "1", className: styles.TabBorder,
                                children:
                                    <div className={styles.TabContainer} style={{overflow: 'auto', height: this.props.windowHeight - 200}}>
                                        {
                                            this.props.control.points === undefined || this.props.control.isLoadingPoints === true //|| this.props.control.events === undefined
                                            ? null :
                                            <Space direction="vertical">
                                                <Space direction="horizontal">
                                                    <Switch
                                                        unCheckedChildren=  "Все сообщения"
                                                        checkedChildren="Новые сообщения"
                                                        style={{width: '135px'}}
                                                        checked={this.state.newEventsOnly}
                                                        onChange={checked => {this.requestEventsAction(checked)}}
                                                    />
                                                    <RangePickerLimit 
                                                        value={[this.state.startDate, this.state.endDate]} 
                                                        limitDays={this.EVENTS_TIME_DEPTH_DAYS} 
                                                        onChange={(val) => {this.setState({startDate: val?.[0] ?? new Date(), endDate: val?.[1] ?? new Date()});}}
                                                    />
                                                    <Groups {...GroupsProps} />
                                                    <Resources {...ResourcesProps} />
                                                    <Button disabled={this.props.control.isEventsAcceptRequest} onClick={() => { this.requestEventsAction(this.state.newEventsOnly) }} type='primary'> Выбрать </Button>
                                                </Space>
                                                
                                                <div style={{display: 'flex'}}>
                                                    <div style={pointsStyle}>
                                                        <Table
                                                            pagination={{ showSizeChanger: true, pageSizeOptions: ["5", "10", "20", "50", "100"], defaultPageSize: 5, locale: { items_per_page: " / страницу" }}}
                                                            columns={this.columns1.filter((item) => item.dataIndex === 'read-all' && this.state.newEventsOnly !== true ? false : true)}
                                                            dataSource={this.props.control.points.map( item => {
                                                                return ({
                                                                    key: item.id,
                                                                    abonent: item.number,
                                                                    events: item.eventsCount
                                                                })
                                                            })}
                                                            
                                                            rowSelection={{
                                                                type: "checkbox",
                                                                selectedRowKeys: this.state.selectedAbonentsIds,
                                                                onChange: this.onPointsChange,
                                                            }}
                                                            bordered
                                                            title={() => 'Контроль по выбранным точкам учёта'}
                                                            tableLayout={'fixed'}
                                                        />
                                                    </div>
                                                    <div style={eventsStyle}>
                                                        <Table
                                                            style={{marginLeft: '10px'}}
                                                            rowSelection={this.state.newEventsOnly === false ? undefined : {
                                                                type: "checkbox",
                                                                selectedRowKeys: this.state.selectedEventsIds,
                                                                onChange: (selectedRowKeys: any[], selectedRows: any[]) => {
                                                                    this.setState({selectedEventsIds: selectedRowKeys});
                                                                }
                                                            }}
                                                            pagination={{
                                                                showSizeChanger: true, 
                                                                pageSizeOptions: ["5", "10", "20", "50", "100"], 
                                                                defaultPageSize: this.EVENTS_DEFAULT_PAGE_SIZE,
                                                                current: this.state.eventsCurrentPage,
                                                                locale: { items_per_page: " / страницу" }}
                                                            }
                                                            columns={this.getEventsColumns()}
                                                            showSorterTooltip={false}
                                                            dataSource={ this.state.eventRows }
                                                            onChange={this.onEventsTableChange}
                                                            bordered
                                                            title={() => 
                                                                <Space>
                                                                    {this.state.newEventsOnly ? 
                                                                        <Button 
                                                                            type="primary"
                                                                            disabled={this.state.selectedEventsIds.length > 0 && !this.props.control.isEventsAcceptRequest ? false : true} 
                                                                            size={'small'} 
                                                                            onClick={this.onClickReadEvents}>
                                                                                Квитировать
                                                                        </Button> 
                                                                        : null
                                                                    }
                                                                    Сообщения контроля по выбранным точкам учёта
                                                                    <Tooltip title="Идёт загрузка всех событий. Не дожидаясь её завершения, Вы можете просматривать и квитировать события.
                                                                     После завершения загрузки, появится возможность фильтровать и сортировать события. Загрузку можно остановить.">
                                                                    <div style={{marginLeft: 10, width:60}}>
                                                                    <Progress
                                                                        type="line"
                                                                        size="small"
                                                                        percent={Math.round(this.state.allEventsCount == 0 ? 100 : this.state.loadedEventsCount* 100/this.state.allEventsCount)}
                                                                    />
                                                                    </div>
                                                                    </Tooltip>
                                                                    <Tooltip title="Остановка загрузки событий.">
                                                                        <Switch size="small" defaultChecked onChange = {this.onProgressChange} disabled={this.state.eventsTableReady}/>
                                                                    </Tooltip>
                                                                </Space>
                                                            }
                                                            tableLayout={'fixed'}
                                                        />
                                                    </div>
                                                </div>
                                            </Space>
                                        }
                                    </div>
                            },
                            {
                                label: "Условия контроля", key: "2", className: styles.TabBorder,
                                children:
                                    <div className={styles.TabContainer} style={{overflow: 'auto', height: this.props.windowHeight - 200}}>
                                    {
                                        this.props.control.isLoadingConditions ? <LoadingOutlined/> :
                                        <Space direction="vertical">
                                            <Space direction="horizontal">
                                                <Groups {...GroupsProps} />
                                                <Resources {...ResourcesProps} />
                                                <Button onClick={() => this.requestConditionsAction()} type='primary'>Выбрать</Button>
                                                {canAddCondition && <Button onClick={() => this.setState({ showConditionForm: true, modalZIndex: Common.getModalZIndex() })} type='primary'>Добавить условие</Button>}
                                            </Space>
            
                                            <div style={{display: 'flex', color: 'red'}}>
                                                <Space direction='vertical'>
                                                    <Table
                                                        columns={columns3}
                                                        rowClassName={this.state.conditionsTableRowStyle}
                                                        dataSource={this.props.control.conditions?.map(item => {
                                                            return ({
                                                                key: item.forGroup ? 'grp_' + item.id : 'abn_' + item.id,
                                                                conditionItem: item,
            
                                                                type_condition: item.forGroup === true ? 'group' : 'abonent',
                                                                group_tu: item.targetName,
                                                                condition: item.conditionText,
                                                                discription: item.description,
                                                                message: item.messageText,
                                                                type_event: EventTypeToString(item.eventType),
                                                                type_resource: item.resources.map((item1, index) => index < 3 ? <Tag key={item1.shortName} style={{margin: '3px'}} color='blue'>{item1.shortName}</Tag> 
                                                                    : (index === 3 ? <Tag key='more' style={{margin: '3px'}} color='blue'>...</Tag> : null)),
                                                                period: PeriodEnumToString(item.period)
                                                            })
                                                        })}
                                                        bordered
                                                        pagination={{ showSizeChanger: true, pageSizeOptions: ["5", "10", "20", "50", "100"], defaultPageSize: 5, locale: { items_per_page: " / страницу" }}}
                                                        onChange={(pagination, filters, sorter, extra) => { this.setState({conditionsTableTypeFilters: filters['type_condition']?.map(x => String(x)) ?? []}) }}
                                                    />
                                                </Space>
                                                <ConditionForm
                                                    allResources={this.props.userProfile.resources} 
                                                    condition={this.state.editingCondition}
                                                    onHide={() => {this.setState({showConditionForm: false, editingCondition: undefined})}}
                                                    visible={this.state.showConditionForm}
                                                    zIndex={this.state.modalZIndex}
                                                    writeNewConditions={(request: SConditionsAddRequest) => {this.props.requestAddConditionsAction(request), this.setState({editingCondition: undefined})}}
                                                    writeCondition={(condition: SConditionDTO) => {this.props.requestWriteConditionAction(condition), this.setState({editingCondition: undefined})}}
            
                                                    groupPoints={(this.props.userProfile) ? this.props.userProfile.groupPoints : {}}
                                                    requestGroupPoints={this.props.requestGroupPoints}
                                                    setIsLoading={this.props.setIsLoading}
                                                    userGroups={(this.props.userProfile) ? this.props.userProfile.userGroups : []}
                                                    groups={(this.props.userProfile) ? this.props.userProfile.userGroups : []}
                                                />
                                            </div>
                                        </Space>
                                    }
                                    </div>
                            }
                        ]
                    }
                />
            </div>
        );
    }
}

export default connect(
    (state: ApplicationState) => {
        return {
            page: state.page,
            userProfile: state.userProfile,
            control: state.control,
            windowHeight: state.page.windowHeight,
        }
    },
    actionCreators
)(Control as any);
