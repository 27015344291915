import * as React from 'react';
import {RouteComponentProps} from 'react-router';
import {connect} from 'react-redux';
import {message} from "../utils/Common";

import {
  BaseProfile,
  SAnalysisIntervalDTO,
  SAnalysisTypeDTO,
  // SResponseDTO,
  SPointsFilter
} from '../decl';
import {ApplicationState} from '../store';
import * as PageStore from '../store/Page';
import * as UserProfileStore from '../store/UserProfile';
import styles from '../resources/Page.module.less';
import {BUTTON_START_ANALIZE_TEXT} from '../components/Analysis/AnalysisHeaderForm';
import {
  AnalysisForm,
  // AnalysisHeaderForm,
  AnalysisResults
} from '../components/Analysis';
import anStyles from '../resources/Analysis.module.less';

import {BACKEND_URL, downloadFile, getHeaders, sendRequestToBackend} from "../utils/AuthUtils";
import PointsTable from "../components/Tables/PointsTable";

const actionCreators = {...PageStore.actionCreators, ...UserProfileStore.actionCreators};


// тут верстка https://stackblitz.com/edit/react-ffguy6-uhqncn?file=index.js
type AnalysisProps =
  {
	page: PageStore.PageState,
	profile: BaseProfile,
	userProfile: UserProfileStore.UserProfileState,
  } &
  typeof actionCreators &
  RouteComponentProps<{}>

// export interface IAnalysisPoint {
//   id: string;
//   number: string;
//   name: string;
//   value: number;
//   key?: any;
// }

// interface IAnalysisInterval {
//   code: string;
//   caption: string;
//   points: SAnalysisPoint[];
// }
export interface AnalysisCondition {
  code: number;
  name: string,
  valueHeader: string
}

export interface IAnalysisState {
  groups: string | null,
  points: string,
  resources: string,
  condition: string,
  dates: [any, any],
  showTuNames: boolean,
  resultData: SAnalysisIntervalDTO[],
  conditions: SAnalysisTypeDTO[],
  needTableRefresh: boolean
}

export type TSetFormType = {
  param: string,
  value: any
};

interface SAnalysisRequest {
  pointsFilter: SPointsFilter;
  from: any;
  to: any;
  analysisCode: string;
}

//для сохранения файла
interface SAnalysisExportRequest {
  analysisType: SAnalysisTypeDTO
  analysisInterval: SAnalysisIntervalDTO
}
// export interface IExportToFileProps {
//   (conditionCode: string,
//   intervalCode: string,
//   intervalCaption: string,
//   points?: any[]): void
// }

class Analysis extends React.PureComponent <AnalysisProps, IAnalysisState> {

  constructor(props: AnalysisProps) {
	super(props);

	// console.dir(props);
	this.state = {
	  //фильтр
	  groups: props.profile.Common?.Groups ?? null,//null,
	  points: props.profile.Common?.Points ?? "",
	  resources: props.profile.Common?.Resources ?? "",
	  condition: "",
	  dates: [null, null],

	  //относятся к разделу
	  showTuNames: false,
	  resultData: [],
	  conditions: [],
	  needTableRefresh: false
	};

	this.setFormState = this.setFormState.bind(this);
	this.sendForm = this.sendForm.bind(this);
	this.exportToFile = this.exportToFile.bind(this);

  }

  componentDidMount() {

	// console.log('debug', window.debug);

	sendRequestToBackend(
	  {},
	  'points/analysistypes',
	  (response: SAnalysisTypeDTO[]) => {
		// console.log(response);

		this.setState({
		  ...this.state,
		  conditions: response
		});
	  },
		this.props.setIsLoading,
		null,
		this.props.closeSession);
  }

  // setFormState(param: string, value: any): void {
	// // console.clear();
	// console.log('Analysis setFormState', param, value);
	// this.setState({
	//   ...this.state,
	//   [param]: value
	// });
  // }
  setFormState(params: object): void {
	// @ts-ignore
	// console.dir(params.dates);
	const needTableRefresh = params.hasOwnProperty('groups') || params.hasOwnProperty('points') || params.hasOwnProperty('resources');

	this.setState({
	  ...this.state,
	  ...params,
	  resultData: [],
	  needTableRefresh: needTableRefresh
	});
  }

  sendForm(): void {
	// console.clear();
	// console.log('sendForm');
	// console.dir(this.state);

	let errors: string[] = [];
	// if (this.state.groups == '' && this.state.points == '')
	//   errors.push('Выберите точки учета');
	if (!this.state.condition)
	  errors.push('Выберите условия анализа');

	if (!this.state.dates.length || this.state.dates[0] === undefined || this.state.dates[1] === undefined) {
	  errors.push('Выберите период анализа');
	}

	if (!errors.length) {

	  let from: any = this.state.dates[0].clone();
	  let to: any = this.state.dates[1].clone();
	  const request: SAnalysisRequest = {
		pointsFilter: {
		  groups: this.state.groups,
		  points: this.state.points,
		  resources: this.state.resources,
		  systemNumbers: '',
		  text: ''
		},
		from: from.startOf('day').toDate(),
		to: to.add(1, 'days').startOf('day').toDate(),
		analysisCode: this.state.condition.toString(),
	  }
	  console.dir(request.from, request.to);
	  // return;
	  sendRequestToBackend(
		request,
		'points/analysismake',
		(response: SAnalysisIntervalDTO[]) => {
		  // console.log(response);
		  this.setState({
			...this.state,
			resultData: response
		  });
		},
		  this.props.setIsLoading,
		  null,
		  this.props.closeSession);
	} else {
	  console.dir(errors);
	  errors.map(err => message.warning(err).then(null));
	}
  }

  exportToFile(conditionCode: string, intervalCaption: string, points: any[]): void {

	let condName = '';
	let valueHeader = '';
	if (this.state.conditions.length) {
	  this.state.conditions.map((cond) => {
		if (conditionCode == cond.code) {
		  condName = cond.name;
		  valueHeader = cond.valueHeader;
		}

		return null;
	  });
	}

	const request: SAnalysisExportRequest = {
	  //SAnalysisTypeDTO
	  analysisType: {
		code: this.state.condition,
		name: condName,
		valueHeader: valueHeader,
	  },
	  //SAnalysisIntervalDTO
	  analysisInterval: {
		caption: intervalCaption,
		points: points
	  }
	}

	downloadFile(
	  request,
	  'points/analysisexport',
	  'analise.csv',
	  null,
	  this.props.setIsLoading
	);

  }


  public render() {

	if (!this.state.conditions.length)
	  return null;

	// console.log(typeof this.props.requestGroupPoints);
	// console.dir(this.props.userProfile);

	const filter: SPointsFilter = {
		groups: this.state.groups,
		points: this.state.points,
		resources: this.state.resources,
		systemNumbers: '',
		text: ''
	  };


	return (
	  <div className={styles.container + ' ' + anStyles.analysisContainer}>

		<AnalysisForm
		  userProfile={this.props?.userProfile}
		  requestGroupPoints={this.props.requestGroupPoints}
		  setIsLoading={this.props.setIsLoading}
		  setFormState={this.setFormState}
		  sendForm={this.sendForm}
		  conditions={this.state.conditions}
		/>

		{
		  this.state.resultData.length
			? <AnalysisResults
			  {...this.state}
			  exportToFile={this.exportToFile}
			  vedomostiAction={this.props.formReport}
			/>
			: <div className={anStyles.analysisEmpty}>
				<PointsTable
					filter={filter}
					needRefresh={this.state.needTableRefresh}
					className = {styles.fullSizeTable}
					titleClassName={styles.fullSizeTableTitle}
					scrollHeight={this.props.page.windowHeight -430}
					showPointInfo={this.props.showPointInfo}
					setIsLoading ={(isLoading: boolean)=>{
						if(!isLoading){
							this.setState({needTableRefresh: false})
						}
						this.props.setIsLoading(isLoading, 1000);
						}}/>
			</div>
		}


	  </div>
	);
  }
}

export default connect(
  (state: ApplicationState) => {
	return {
	  page: state.page,
	  userProfile: state.userProfile,
	  profile: state.userProfile?.profile,
	}
  },
  actionCreators
)(Analysis as any);
