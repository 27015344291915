import { Tooltip } from 'antd';
import { Group, Point, SResponseDTO, ResponseStatusesEnum, POINT_SERIALNUMBER } from "../decl";
import * as Const from './Const';
import { getHeaders, BACKEND_URL } from './AuthUtils';
import { message } from './Common';
import { DisabledContextProvider } from 'antd/lib/config-provider/DisabledContext';

const ROOT_TREE_NODE_ID = 'g0';


//Типы узлов.
export enum NodeTypesEnum
{
    // Корень.
    Root = 0,
    // Группа.
    Group = 1,
    // Точка учёта.
    Point = 2
}

export type SelectedNodeInfo = {
    parentOb: Group | null;
    selectedOb: Point | Group | null;
    nodeType: NodeTypesEnum;
}


//Создание дерева.
export function createTree(
    groups: Group[],
    points: any,
    selectedIds: string[],                          //Идентификаторы выбранных точек учёта, групп или Const.ALL_GROUP_ID.
    expandedIds: string[],                          //Идентификаторы раскрытых групп.
    value: string[],
    ids: string[],
    expandedKeys: string[],                         //Идентификаторы УЗЛОВ групп которые нужно раскрыть или оставить раскрытыми.
    maxTagLength: number,
    fIcons: any,
    onlyPointsSelect: boolean,
    onlyGroupsSelect: boolean,
    resources: string[] | null | undefined,
    isAllowedCheck?: ((ob:Point | Group)=>boolean),
    additionalTitle?: ((ob:Point | Group | null)=>string),
    showRoot?: boolean | undefined): any {

    const enableGroups: Group[] = [];

    //Если имеется только одна группа, то она ставится в корень дерева и раскрывается.
    if(groups.length === 1 && showRoot !== true){
        const rootGroupId = groups[0].id;
        if(!expandedIds.includes(rootGroupId)){
           expandedIds.push(rootGroupId);
        }
        if (selectedIds.includes(Const.ALL_GROUP_ID)) {
            selectedIds.splice(0, selectedIds.length);
            selectedIds.push(rootGroupId);
        }

        const root = createNodes(null, groups, points, selectedIds, expandedIds, value, ids, expandedKeys, maxTagLength, fIcons, onlyPointsSelect, onlyGroupsSelect, resources, isAllowedCheck, additionalTitle, enableGroups)
        return root;
    }
    else{
        const id = ROOT_TREE_NODE_ID;
        ids[0] = Const.ALL_GROUP_ID;

        if (selectedIds.includes(Const.ALL_GROUP_ID)) {
            value.push(id);
        }

        //Корневой узел, представляющий все корневые группы должен быть раскрыт.
        expandedKeys.push(id);

        const childGroups = createNodes(null, groups, points, selectedIds, expandedIds, value, ids, expandedKeys, maxTagLength, fIcons, onlyPointsSelect, onlyGroupsSelect, resources, isAllowedCheck, additionalTitle, enableGroups);
        const children: any[] = (childGroups.filter((n:any)=>n.isAllowed));
        const title  = 'Все группы' + (additionalTitle !== undefined ? additionalTitle(null) : '');  
        const root = [{
            title: 'Все группы',
            shortTitle: 'Все группы',
            value: id,
            key: id,
            children: children
        }];
        return root;
    }
}

//Определение типа узла по идентификатору дерева.
export function getNodeType(treeId: string) : NodeTypesEnum{
    let result: NodeTypesEnum;
    if (treeId[0] == 'g') {
        result = result = NodeTypesEnum.Group;
    }
    else if (treeId[0] == 'p') {
        result = result = NodeTypesEnum.Point;
    }
    else{
        result = result = NodeTypesEnum.Root;
    }
    return result;
} 

//Получение идентификатора объекта из идентификатора дерева.
export function getId(treeId: string, ids: string[]): string {
    let result: string = '';
    if (treeId[0] == 'g') {
        const index: number = Number.parseInt(treeId.substring(1));
        result = ids[index];
    }
    else if (treeId[0] == 'p') {
        const parts = treeId.split('.');
        if (parts.length == 2) result = parts[1];
    }
    return result;
}

//Получение идентификатора родительской группы из идентификатора дерева для точки учёта.
export function getParentId(treeId: string, ids: string[]): string {
    let result: string = '';
    if (treeId[0] === 'p') {
        const parts = treeId.split('.');
        if (parts.length == 2) {
            const index = Number.parseInt(parts[0].substring(2));
            result = ids[index];
        }
    }
    return result;
}
//Получение тндекса группы из идентификатора дерева для точки учёта или группы.
export function getGroupTreeIndex(treeId: string): number {
    let result: number = 0;
    if (treeId[0] == 'g') {
        result = Number.parseInt(treeId.substring(1));
    }
    if (treeId[0] === 'p') {
        const parts = treeId.split('.');
        if (parts.length == 2) {
            result = Number.parseInt(parts[0].substring(2));
        }
    }
    return result;
}


//Создание узлов дерева.
function createNodes(
    parent: Group | null,
    groups: Group[],
    points: any,
    selectedIds: string[],
    expandedIds: string[],
    value: string[],
    ids: string[],
    expandedKeys: string[],
    maxTagLength: number,
    fIcons: any,
    onlyPointsSelect: boolean,
    onlyGroupsSelect: boolean,
    resources: string[] | null | undefined,
    isAllowedCheck?: ((ob:Point | Group)=>boolean),
    additionalTitle?: ((ob:Point | Group | null)=>string),
    enabledGroups?: Group[]): any {
    if (groups) {
        return groups.map(g => {
            const shortTitle = g.name.length > maxTagLength ? g.name.substring(0, maxTagLength) + '...' : g.name;

            const id = 'g' + g.treeId;
            ids[g.treeId] = g.id;
            const childEnabledGroups: Group[] = [];
            const childGroups: any = createNodes(
                g,
                g.childGroups,
                points,
                selectedIds,
                expandedIds,
                value,
                ids,
                expandedKeys,
                maxTagLength,
                fIcons,
                onlyPointsSelect,
                onlyGroupsSelect,
                resources,
                isAllowedCheck,
                additionalTitle,
                childEnabledGroups);

            const groupPoints: any = points[g.id];
            const enabledPoints: Point[] = [];
            const childPoints = groupPoints ? 
                createPointNodes(
                    id,
                    g,
                    groupPoints,
                    selectedIds,
                    value,
                    maxTagLength,
                    fIcons,
                    !onlyGroupsSelect,
                    resources,
                    isAllowedCheck,
                    additionalTitle,
                    enabledPoints) :
                [];
  
            if (selectedIds.includes(g.id)) {
                value.push(id);
            }
            if (expandedIds.includes(g.id)) {
                expandedKeys.push(id);
            }
           
            let isAllowed: boolean;
            let children: any[];
            if(isAllowedCheck){
                const allowedChildGroups: any[] = (childGroups.filter((n:any)=>n.isAllowed));
                children = allowedChildGroups.concat(childPoints.filter((n:any)=>n.isAllowed));
                const childrenAllowed = (!onlyGroupsSelect && children.length > 0) || ((onlyPointsSelect !== true) && allowedChildGroups.length > 0);
                const selfAllowed = isAllowedCheck(g) && (onlyPointsSelect !== true);
                isAllowed = childrenAllowed || selfAllowed;

                //Раскрыть группу, которая  имеет подходящих детей.
                if(childrenAllowed && expandedKeys.indexOf(id) < 0){
                    expandedKeys.push(id);
                }
            }
            else{
                children = childGroups.concat(childPoints);
                isAllowed = true;
            }

            //console.log("Group: ", isAllowed, g, groupPoints, children, childPoints);
            const title  = g.name + (additionalTitle !== undefined ? additionalTitle(g) : '');  

            return {
                title: title,
                shortTitle: shortTitle,
                selectable: !onlyPointsSelect,
                //disableCheckbox: disabled,
                value: id,
                key: id,
                children: children,
                icon: fIcons,
                isLeaf: g.childGroups.length == 0 && g.numberOfPoints == 0,
                group: g,
                parent: parent,
                isAllowed: isAllowed
            }
        });
    }
    else {
        return [];
    }
}

//Создание терминальных узлов дерева для точек учёта. 
function createPointNodes(
    iGroupTreeId: string,
    group: Group,
    groupPoints: Point[],
    selectedIds: string[],
    value: string[],
    maxTagLength: number,
    fIcons: any,
    checkable: boolean,
    resources: string[] | null | undefined,
    isAllowedCheck? : ((ob:Point|Group)=>boolean),
    additionalTitle?: ((ob:Point | Group | null)=>string),
    enabledPoints?: Point[]): any {
    if (groupPoints) {
        //console.log('createPointNodes', groupPoints);
        return groupPoints.map(p => {
            const shortTitle = p.number.length > maxTagLength ? p.number.substring(0, maxTagLength) + '...' : p.number;
            const id = 'p' + iGroupTreeId + '.' + p.id;

            if (selectedIds.includes(p.id)) {
                value.push(id);
            }
            // const allResources = resources == null || (resources.length > 0 && resources[0] === 'all');
            // const disabled: boolean = !allResources && !resources.includes(p.resourceTypeId.toString());

            // let title;
            // if(!disabled) {
            //     enabledPoints.push(p);
            //     title = p.number;
            // }
            // else{
            //     title = <Tooltip placement="right" title={ 'Не выбран ресурс ' + p.persistProperties['resourceshortname'] ?? '' }>
            //                 <span style={{width: "100%"}}> {p.number}</span>
            //            </Tooltip>;
            // }

            const serialNumber: string = p.persistProperties[POINT_SERIALNUMBER] ?? '';

            const titleText  = p.number + (additionalTitle !== undefined ? additionalTitle(p) : '');  

            let title;
            if(serialNumber.length === 0) {
                title = titleText;
            }
            else{
                title = <Tooltip placement="right" title={'Номер прибора: '  + serialNumber }>
                            <span style={{width: "100%"}}> {titleText}</span>
                       </Tooltip>;
            }

            return {
                title: title,
                shortTitle: shortTitle,
                value: id,
                key: id,
                //disableCheckbox: disabled,
                icon: fIcons,
                isLeaf: true,
                group: group,
                point: p,
                checkable: checkable,
                isAllowed: (isAllowedCheck == undefined || !checkable) ? true : isAllowedCheck(p)
            }
        });
    }
    else {
        return [];
    }
}

//Проверка наличия объекта в массиве с заданным идентификатором.
function containsItemWithId(iArray: any[], iId: string) : boolean{
    return (iArray.findIndex(item => item.id === iId)) >= 0;
}

//Удаление объекта с заданным идентификатором из массива.
function removeValueById(iArray: any[], iId: string) : boolean{
    const index = iArray.findIndex(item => item.id === iId);
    let result: boolean = false;
    if (index >= 0) {
       iArray.splice(index, 1);
        result = true;
    }
    return result;
} 

//Удаление из массива заданного значения.
export function removeValue(iArray: string[], iValue: string) : boolean{
    const index = iArray.indexOf(iValue);
    let result: boolean = false;
    if (index >= 0) {
       iArray.splice(index, 1);
        result = true;
    }
    return result;
} 

//Копирование массива с исключением заданного значения.
function copyWithoutValue(iArray: string[], iValue: string) : string[] { 
    return iArray.filter(function(el: string){ 
        return el != iValue; 
    });
}

export function changeTreeValue(
    iGroupsValue: string[],                             //Идентификаторы выбранных групп.
    iPointsValue: string[],                             //Идентификаторы выбранных точек.
    iGroups: Group[],                                   //Все группы.
    iPoints: {[groupId: string] : Point[]},             //Словарь точек по идентификаторам групп.
    iNodeGroup: Group,                                  //Изменяемая группа или родительская группа изменяемой точки или null(корневой узел).
    iNodePoint: Point,                                  //Изменяемая точка или null.
    iChecked: boolean){                                 //Состояние выбора точки или группы.

        //console.log('CHANGE TREE VALUE: ', iGroupsValue, iPointsValue, iGroups, iPoints, iNodeGroup, iNodePoint, iChecked);
    if(!iNodeGroup){
        //Если группа не задана - изменение выбора корневого узла.

        //Очистить массивы выбранных групп и точек.    
        iGroupsValue.splice(0);
        iPointsValue.splice(0);

        //Вставить в массив выбранных групп идентификатор корневого узла, если нужно.
        if(iChecked){
            iGroupsValue.push( Const.ALL_GROUP_ID);
        }
    }
    else{    
        //Получение нормального значения чекнутых узлов.
        const groupIds: string[] = [];
        const pointIds: string[] = [];

        getChildCheckValues(iGroups, iPoints, iGroupsValue, iPointsValue, groupIds, pointIds);
        //console.log('NORMAL groupIds: ', groupIds, 'pointIds: ', pointIds);

        //Очистить массивы выбранных групп и точек.    
        iGroupsValue.splice(0);
        iPointsValue.splice(0);
        for(let id of groupIds){
            if(!iGroupsValue.includes(id)) iGroupsValue.push(id);
        }
        for(let id of pointIds){
            if(!iPointsValue.includes(id)) iPointsValue.push(id);
        }

        let isGroupCheckChanged: boolean = false;
        if(iNodePoint){
            const groupPoints: Point[] = iPoints[iNodeGroup.id];
            if(iChecked){
                iPointsValue.push(iNodePoint.id);
                if (isAllIdsSelected(iGroupsValue, iNodeGroup.childGroups.map(g => g.id)) &&
                    (!groupPoints || isAllIdsSelected(iPointsValue, groupPoints.map(p => p.id)))) {
                    isGroupCheckChanged = true;
                }
            }
            else{
                if(!removeValue(iPointsValue, iNodePoint.id)){
                    iPointsValue.push(...copyWithoutValue(groupPoints.map(p => p.id), iNodePoint.id));
                    isGroupCheckChanged = true;
                }
            }
        }
        else{
            isGroupCheckChanged = true;
        }

        if(isGroupCheckChanged){
            //Взять путь от группы до корня.
            const path: Group[] = getGroupPath(iGroups, iNodeGroup.id);

            //Точка не задана - изменение выбора группы.
            let topCheckGroup: Group | null = null;

            for (let i = 0; i < path.length; i++) {
                //Группа.
                const group = path[i];

                let parentChildren: Group[];
                let parentPoints: Point[] | undefined;

                if (i !== path.length - 1) {
                    const parent: Group = path[i + 1];
                    parentChildren = parent.childGroups;
                    parentPoints = iPoints[parent.id];
                }
                else {
                    parentChildren = iGroups;
                    parentPoints = undefined;
                }

                if (iChecked) {
                    //Добавить в значение выбираемую группу. 
                    iGroupsValue.push(group.id);

                    //Если в родительской группе остались невыделенные точки или группы, то это верхняя чекнутая группа.
                    if (!isAllIdsSelected(iGroupsValue, parentChildren.map(g => g.id)) ||
                        (parentPoints && !isAllIdsSelected(iPointsValue, parentPoints.map(p => p.id)))) {
                        topCheckGroup = group;
                        break;
                    }
                }
                else {
                    //console.log('Uncheck: ', group, iGroupsValue, iPointsValue);
                    //Убрать из значения идентификатор группы и проверить на листовую группу.
                    if (!removeValue(iGroupsValue, group.id) || iNodePoint) {
                        //topCheckGroup = group;

                        iGroupsValue.push(...copyWithoutValue(parentChildren.map(g => g.id), group.id));

                        //Вставить в массив выбранных точек все точки из удаляемой группы.
                        if (parentPoints) {
                            iPointsValue.push(...parentPoints.map(p => p.id));
                        }
                        //Вставить в массив выбранных групп все подгруппы группы, у которой сбрасывается чекбокс.
                        iGroupsValue.push(...group.childGroups.map(g=>g.id));
                        //console.log('Uncheck new: ', group, iGroupsValue);
                    }
                    else {
                        topCheckGroup = group;
                        break;
                    }
                }
            }
            if (iChecked) {
                //Удалить из массивов выбора все дочерние группы и точки.
                removeAllSubNodes(iGroupsValue, iPointsValue, topCheckGroup, iPoints);

                //Вставить корень, если дошли до самого верха.
                if (topCheckGroup == null) {
                    iGroupsValue.push(Const.ALL_GROUP_ID);
                }
            }
            else {
                if(topCheckGroup == null){
                    removeValue(iGroupsValue, Const.ALL_GROUP_ID);
                }
            }
        }
    }
}

function getChildCheckValues(
    iGroups: Group[],
    iPoints: {[groupId: string] : Point[]},
    iGroupsValue: string[], //Идентификаторы выбранных групп.
    iPointsValue: string[], //Идентификаторы выбранных точек.
    iCheckeGroupIds: string[],
    iCheckedPointIds: string[]
){
    for(let group of iGroups){
        if(isGroupChecked(group, iPoints, iGroupsValue, iPointsValue)){
            iCheckeGroupIds.push(group.id);
        }
        else{
            getChildCheckValues(group.childGroups, iPoints, iGroupsValue, iPointsValue, iCheckeGroupIds, iCheckedPointIds);
 
            if(group.numberOfPoints > 0 && iPoints[group.id]){
                const childPoints = iPoints[group.id];
                for(let childPoint of childPoints){
                    if(iPointsValue.indexOf(childPoint.id) >= 0){
                        iCheckedPointIds.push(childPoint.id);
                    }
                }
            }
        }
    }
}

function isGroupChecked(
    iGroup: Group,
    iPoints: {[groupId: string] : Point[]},
    iGroupsValue: string[], //Идентификаторы выбранных групп.
    iPointsValue: string[], //Идентификаторы выбранных точек.
): boolean{
    let result = false;
    if(iGroupsValue.indexOf(iGroup.id) >= 0){
        result = true;
    }
    else{
        if(iGroup.childGroups.length > 0){
            result = true;
            for(let childGroup of iGroup.childGroups){
                if(!isGroupChecked(childGroup, iPoints, iGroupsValue, iPointsValue)){
                    //Одна из нижних групп не выбрана.
                    result = false;
                    break;
                }
            }
        }

        if((result || iGroup.childGroups.length === 0) && iGroup.numberOfPoints > 0 && iPoints[iGroup.id]){
            //Все группы выбраны и есть точки учёта.
            result = true;
            const childPoints = iPoints[iGroup.id];
            for(let childPoint of childPoints){
                if(iPointsValue.indexOf(childPoint.id) < 0){
                    result = false;
                    break;
                }
            }
            //!!!!! не учитывается случай присутствия точки в другой чекнутой группе.
        }
    }


    return result;
}

//Поиск в словаре точек учёта по группам всех родительских групп для точки учёта.
export function getPointGroups(
    groups: Group[],    //Корневые группы пользователя, содержащие дочерние группы.
    groupPoints: any,   //Точки учёта по группам.
    id:string) :        //Идентификатор точки учёта.
        Group[]{            //Массив групп, содержащих точку учёта.
    const result: Group[] = [];
    findPointGroups(groups, groupPoints, id, result);
    return result;
}

//Рекурсия для поиска групп в словаре точек учёта. 
function findPointGroups(groups: Group[], groupPoints: {[groupId: string] : Point[]}, id:string, pointGroups: Group[]){
    for(let g of groups){
        const points: Point[] = groupPoints[g.id];
        if(points && points.filter(p => p.id === id).length > 0) {
            pointGroups.push(g);
        }
        findPointGroups(g.childGroups, groupPoints, id, pointGroups);
    }
}

//Формирование полного пути для группы по идентификатору.
export function getGroupPath(groups: Group[], id:string) : Group[]{
    const result: Group[] = [];
    findGroup(groups, id, result);
    return result;
}

//Рекурсия для формирования пути в дереве групп.
function findGroup(groups: Group[], id: string, path: Group[]) : Group | null{
    let result = null;
    for(let g of groups){
        if(g.id === id){
            result = g;
        }
        else{
            result = findGroup(g.childGroups, id, path);
        }
        if(result != null){
            if(path){
                path.push(g);
            }
            break;
        }
    }
    return result;
}

//Проверка случая выбора всех значений.
function isAllIdsSelected(value: string[], ids: string[]){
    let result: boolean = true;
    for(let id of ids){
        if(value.indexOf(id) < 0){
            result = false;
            break;
        }
    }
    return result;
}

//Проверка случая выбора не менее одного значения.
function isAnyIdSelected(value: string[], ids: string[]){
    let result: boolean = false;
    for(let id of ids){
        if(value.indexOf(id) >= 0){
            result = true;
            break;
        }
    }
    return result;
}


//Удаление из массивов значений всех дочерних групп и точек для заданной группы.
function removeAllSubNodes(
    iGroupsValue: string[], //Идентификаторы выбранных групп.
    iPointsValue: string[], //Идентификаторы выбранных точек.
    iGroup: Group | null,   //Группа.
    iPoints: any) {         //Словарь точек по идентификаторам групп.

    if (iGroup != null) {
        //Все точки группы.
        const groupPoints: any = iPoints[iGroup.id];
        if (groupPoints) {
            for (let p of groupPoints) {
                removeValue(iPointsValue, p.id);
            }
        }

        //Все подгруппы группы.
        for (let g of iGroup.childGroups) {
            removeValue(iGroupsValue, g.id);
            removeAllSubNodes(iGroupsValue, iPointsValue, g, iPoints);
        }
    }
    else {
        //Очистить массивы выбранных групп и точек.    
        iGroupsValue.splice(0);
        iPointsValue.splice(0);
    }
}

//Получение групп для точек учёта.
export function getGroupsByPoints(iPointIds: string[], resolve: any){
    const requestOptions = {
        method: 'POST',
        headers: getHeaders({"Request-Packet-ID": "back"}),
        body: JSON.stringify(iPointIds)
    };

    //console.log('FETCH groupsbypoints');
    fetch(BACKEND_URL + 'points/groupsbypoints', requestOptions)
        .then(response => response.json() as Promise<SResponseDTO>)
        .then(data => {
            if (data.bodyStatus && data.bodyStatus == ResponseStatusesEnum.Ok) {
                resolve(data.body);
                //console.log('RECEIVE groupsbypoints');
            }
            else if (data.bodyStatus && data.bodyStatus == ResponseStatusesEnum.Error) {
                message.error(data.message);
                resolve();
            }
            else if (data.bodyStatus && data.bodyStatus == ResponseStatusesEnum.SessionClosed) {
                message.error(data.message);
                resolve();
            }
            else {
                message.error('Ответ не получен.');
                resolve();
            }
        })
        .catch(error => {
            message.error('Ошибка:' + error);
            resolve();
        });
}

function wait(interval: number, waitCycles: number, condition: any, done: any, cancel: any) {
    setTimeout(
        () => {
            //console.log('WAITCYCLES: ' + waitCycles);
            if(waitCycles == 0) cancel();
            else if (condition()) done();
            else wait(interval, --waitCycles, condition, done, cancel);
    }, interval);
}

export function getAllSelectedPoints(groups: Group[], groupIds: string[], pointIds: string[], getPoints: any) : Promise<Point[]> {
    return new Promise(resolve => {
        const result: Point[] = [];
        if(groupIds.length > 0 || pointIds.length > 0) {
            const isAllChecked: boolean = groupIds.indexOf(Const.ALL_GROUP_ID) >= 0;
            const waitLoading: string[] = [];
            getAllGroupsPoints(groups, groupIds, pointIds, result, isAllChecked, getPoints, waitLoading);

            wait(1000, 300, () => (waitLoading.length == 0),
                () => { 
                    resolve(result);
                },
                () => { 
                    console.log('TIMEOUT');
                    resolve(result);
                })
        }
        else{
            resolve(result);
        }
    });
}

async function getAllGroupsPoints(groups: Group[], groupIds: string[], pointIds: string[], selectedPoints: Point[], isParentChecked: boolean, getPoints: any, waitLoading: string[]){
    for(let group of groups){
        const isChecked = isParentChecked || groupIds.indexOf(group.id) >= 0;
        if (group.numberOfPoints > 0) {
            waitLoading.push(group.id);
            //console.log('wait (' + waitLoading.length + '):' + group.id + ' = ' + group.numberOfPoints);
            getPoints(group.id, (points: Point[]) => {
                //console.log('get(' + waitLoading.length + '):' + group.id + ' = ' + points.length);
                removeValue(waitLoading, group.id);

                for(let point of points){
                    if( !containsItemWithId(selectedPoints, point.id) && (isChecked || pointIds.indexOf(point.id) >= 0)){
                        selectedPoints.push(point);
                    }
                }
            });
        }
        getAllGroupsPoints(group.childGroups, groupIds, pointIds, selectedPoints, isChecked, getPoints, waitLoading);      
    } 
}

//Получение идентификаторов групп, отсутствующих  в дереве.
export function getFreeIds(treeGroups:Group[], checkedGroupIds: string[]){
    const result: string[] = checkedGroupIds.slice();

    const removeIds = (childGroups:Group[]) => {
        for(let i = 0; i < childGroups.length; i++){
            const group = childGroups[i];
            removeValue(result, group.id);

            removeIds(group.childGroups);
        }
    }

    if( result.indexOf(Const.ALL_GROUP_ID) >= 0){
        removeValue(result, Const.ALL_GROUP_ID);
    }

    removeIds(treeGroups);

    return result;
}


//Рекурсивное получение всех выбранных групп в дереве и жополнительных.
export function getParentSelectedGroups(treeGroups:Group[], allGroups: Group[], checkedGroupIds: string[]){
    const result: Group[] = [];
    const ids: string[] = checkedGroupIds.slice();

    const getGroupsFromTree = (childGroups:Group[], isParentChecked: boolean) => {
        for(let i = 0; i < childGroups.length; i++){
            const group = childGroups[i];
            const isChecked = ids.includes(group.id);
            if(!isParentChecked && isChecked){
                if(!result.find(g=>g.id===group.id)){
                    result.push(group);
                    removeValue(ids, group.id);
                }
            }
            else if(isParentChecked && isChecked){
                removeValue(ids, group.id);
            }

            getGroupsFromTree(group.childGroups, isParentChecked || isChecked);
        }
    }

    if( ids.indexOf(Const.ALL_GROUP_ID) >= 0){
        removeValue(ids, Const.ALL_GROUP_ID);
        treeGroups.forEach(g=>{if(!ids.includes(g.id))ids.push(g.id)});
    }

    getGroupsFromTree(treeGroups, false);

    ids.forEach(id=>{
        const group = allGroups.find(g=>g.id === id); 
        if(group){
            result.push(group);
        }
    })

    return result.sort((g1,g2)=>g1.name.localeCompare(g2.name));
}


//Рекурсивное получение всех выбранных групп.
export function getAllSelectedGroups(groups:Group[], checkedGroupIds: string[]){
    const result: Group[] = [];

    const isAllChecked: boolean = checkedGroupIds.indexOf(Const.ALL_GROUP_ID) >= 0;
    getAllGroupsGroups(groups, checkedGroupIds, isAllChecked, result);
    return result.sort((g1,g2)=>g1.name.localeCompare(g2.name));
}

//Рекурсивное получение всех выбранных подгрупп.
function getAllGroupsGroups(groups:Group[], checkedGroupIds: string[], isParentChecked: boolean, selectedGroups: Group[]){
    for(let group of groups){
        const isChecked = isParentChecked || checkedGroupIds.indexOf(group.id) >= 0;
        if(isChecked){
            for(let group of groups){
                if(!selectedGroups.find(g=>g.id === group.id)){
                    selectedGroups.push(group);
                }
            }
        }

        getAllGroupsGroups(group.childGroups, checkedGroupIds, isChecked, selectedGroups);      
    } 
}

//Поиск первой попавшейся группы в дереве по названию.
export function findGroupByName(groups: Group[], groupName: string):Group | undefined{
    let result: Group| undefined = undefined;
    for(let i=0; i<groups.length;i++){
        const group = groups[i];
        if(group.name === groupName){
            result = group;
            break;
        }
        const child = findGroupByName(group.childGroups, groupName);
        if(child !== undefined){
            result = child;
            break;
        }
    }
    return result;
}

//Проверка соответствия узла заданному фильтру.
export function isNodeAllowedCheck(ob: Point | Group, filter: string | undefined): boolean{
    let result = true;
    if ((ob as Point).number !== undefined && filter) {
        const number = (ob as Point).number.toLowerCase();
        result = number.includes(filter.toLowerCase());
    }
    else if ((ob as Group).name !== undefined && filter) {
        const name = (ob as Group).name.toLowerCase();
        result = name.includes(filter.toLowerCase());
    }
    return result;
}





