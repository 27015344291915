import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { connect } from 'react-redux';

import { ApplicationState } from '../store';
import * as PageStore from '../store/Page';
import * as UserProfileStore from '../store/UserProfile';
import { BaseProfile, Point } from '../decl';
import styles from '../resources/Page.module.less';
import billingStyles from '../resources/Billing.module.less';
import * as SiteLogStore from '../store/SiteLogStore';
import * as BillingStore from '../store/BillingStore';
import { Button, Checkbox, Col, ConfigProvider, Input, Modal, Result, Row, Space, Table, Tooltip, message } from 'antd';
import { CheckCircleTwoTone, DeleteTwoTone, EditTwoTone, LoadingOutlined, ScheduleTwoTone, SearchOutlined, StopTwoTone } from '@ant-design/icons';
import { MaximizeTwoTone, UnfoldLess } from '@material-ui/icons';
import moment from 'moment';
import { convertBase64ToBinary, deepCopyObj, getModalZIndex, initDates, makeCSVfromTable, netiExecute, saveFile, toDate, toDateString, unicodeToWin1251 } from '../utils/Common';
import Title from 'antd/lib/typography/Title';
import Search from 'antd/lib/input/Search';
import ContractForm from '../components/Billing/ContractForm';
import { sendRequestToBackend } from '../utils/AuthUtils';
import { SBilBillCompositionDTO, SBilBillDTO as SBilBillDTO, SBilContactDTO, SBillStatusDTO, SBillingCompanyInfoDTO, SBilContractDTO, SBillingTariffDTO, SContractPointDTO, SBilColorsDTO } from '../components/Billing/BillingDecls';
import CompaniesForm from '../components/Billing/CompaniesForm';
import * as Const from '../utils/Const';
import BillEditForm from '../components/Billing/BillEditForm';
import TariffsForm from '../components/Billing/TariffsForm';

import '../resources/billing.css';
import ReactDOM from 'react-dom';
import StatisticsForm from '../components/Billing/StatisticsForm';
import PromisedPaymentForm from '../components/Billing/PromisedPaymentForm';
import ParsePaymentsForm from '../components/Billing/ParsePaymentsForm';
import ActiveContractsForm from '../components/Billing/ActiveContractsForm';

const actionCreators = { ...PageStore.actionCreators, ...UserProfileStore.actionCreators, ...SiteLogStore.actionCreators, ...BillingStore.actionCreators };

type Props =
    {
        page: PageStore.PageState,
        userProfile: UserProfileStore.UserProfileState,
        siteLog: SiteLogStore.ISiteLogState,
        //allPoints: {[pointId: string] : Point},
        billingStore: BillingStore.IBillingState,
    } &
    typeof actionCreators &
    RouteComponentProps<{}>

interface IState {
    selectedContractId: number | undefined,
    selectedPointId: string | undefined, 
    
    editBill: {bill: SBilBillDTO, composition: SBilBillCompositionDTO} | null,
    editContract: SBilContractDTO | null,

    contracts: SBilContractDTO[] | undefined,
    currentContractPoints: SContractPointDTO[] | undefined,
    currentBills: SBilBillDTO[] | undefined,
    currentBillCompositions: SBilBillCompositionDTO[] | undefined,

    billStatuses: {[id: number]: string} | undefined,
    tariffs: SBillingTariffDTO[] | undefined,
    statuses: SBillStatusDTO[] | undefined,
    paymentTypes: {[id: number]: string} | undefined,
    colors: SBilColorsDTO[] | undefined,

    showContractEditForm: boolean,
    filterContracts: string,
    filterBills: string,
    allPoints: {[pointId: string] : Point},
    //Добавлено для поиска для всех ТУ
    contractsPoints: SContractPointDTO[] | undefined,
}

class Billing extends React.PureComponent<Props, IState> {

    constructor(props: Props) {
        super(props);

        this.state = {
            contracts: [],
            selectedContractId: undefined,
            currentContractPoints: undefined,
            editContract: null,
            statuses: undefined,
            tariffs: undefined,
            paymentTypes: undefined,
            showContractEditForm: false,
            selectedPointId: undefined,
            currentBills: undefined,
            currentBillCompositions: undefined,
            editBill: null,
            billStatuses: undefined,
            colors: undefined,
            filterContracts: '',
            filterBills: '',
            allPoints: {},
            contractsPoints: [],
        }
    }

    private readonly CONTRACT_DEFAULT_STATUS = 1;

    componentDidMount(): void {
        sendRequestToBackend(
            null,
            'billing/dicts',
            (response: any) => {
                if (response != null) {
                    this.setState({tariffs: response['tariffs'], statuses: response['statuses'], paymentTypes: response['paymentTypes'], billStatuses: response['billStatuses']});
                } else {
                    message.error('Запрос завершился неудачей');
                }
            },
            this.props.setIsLoading,
            (error: any) => {
                console.log(error);
                message.error(error);
            }
        );

        sendRequestToBackend(
            {},
            'points/allpoints',
            (response: any) => {
                if (response != null) {
                    this.setState({allPoints: (response as Point[]).reduce((obj, cur) => ({...obj, [cur.id]: cur}), {}) });
                } else {
                    message.warning("Не удалить получить все точки учёта!");
                }
            },
            this.props.setIsLoading,
            (error: any) => {
                message.warning("Ошибка при получении всех точек учёта.");
                console.log(error);
            }
        );

        this.props.requestCompanies(() => {
            this.initContracts();
        });
    }

    requestBillingColors = (success?: (colors: SBilColorsDTO[]) => void) => {
        sendRequestToBackend(
            null,
            'billing/billingcolors',
            (response: SBilColorsDTO[]) => {
                if (response != null) {
                    this.setState({colors: response});

                    if (this.state.contracts) {
                        this.setContractsDatasource(this.state.contracts, this.state.filterContracts, response);
                    }
                    
                    if (this.state.currentContractPoints != undefined && this.state.allPoints != undefined) {
                        this.setContractPointsDatasource(this.state.currentContractPoints, response);
                    }

                    if (success) {
                        success(response);
                    }
                } else {
                    message.error('Запрос завершился неудачей');
                }
            },
            this.props.setIsLoading,
            (error: any) => {
                console.log(error);
                message.error(error);
            }
        );
    }

    initContracts = () => {
        sendRequestToBackend(
            null,
            'billing/contracts',
            (response: SBilContractDTO[]) => {
                if (response != null) {
                    initDates(response, ['date']);
                    this.setContractsDatasource(response, this.state.filterContracts, this.state.colors);
                    this.setState({contracts: response});

                    this.requestBillingColors(() => {
                        //Выбрать первый контракт после сотировки
                        if (this.contractsDataSource.length > 0) {
                            this.setState({selectedContractId: this.contractsDataSource[0].id});
                            this.requestContractPoints(this.contractsDataSource[0].id);
                        }
                    });
                } else {
                    message.error('Запрос завершился неудачей');
                }
            },
            this.props.setIsLoading,
            (error: any) => {
                console.log(error);
                message.error(error);
            }
        );

        this.requestContractsPoints();
    }

    requestContractsPoints = () => {
        //Эти данные нужны для поиска для контрактов с учетом точек учета. Можно было использовать эти данные вместо currentContractPoints но чтобы не тратить время оставлено как есть.
        sendRequestToBackend(
            null,
            'billing/allcontractpoints',
            (response: SContractPointDTO[]) => {
                if (response != null) {
                    this.setState({contractsPoints: response});
                } else {
                    message.error('Запрос завершился неудачей');
                }
            },
            this.props.setIsLoading,
            (error: any) => {
                console.log(error);
                message.error(error);
            }
        );
    }

    sorter = (a: any, b: any) => (isNaN(a) && isNaN(b) ? (a || '').localeCompare(b || '') : a - b);

    private noWrap = (str: string) => {
        return <span style={{whiteSpace: 'nowrap'}}>{str}</span>
    }

    setContractsDatasource = (arr: SBilContractDTO[], filter: string, colors: SBilColorsDTO[] | undefined) => {
        this.contractsDataSource = arr
        .filter(x => {
            let findedPointFlag = false;
            for (const point of this.state.contractsPoints ?? []) {
                if (point.contractId === x.id) {
                    const p = this.state.allPoints?.[point.pointId];
                    if (p != undefined) {
                        if (p.number.toLowerCase().includes(filter.toLowerCase()) || p.persistProperties['address']?.toLowerCase().includes(filter.toLowerCase()) === true) {
                            findedPointFlag = true;
                            break;
                        }
                    }
                }
            }

            return findedPointFlag === true || x.number.toLowerCase().includes(filter) || x.userName.toLowerCase().includes(filter) 
                || (this.props.billingStore.companies?.find(y => y.id === x.companyId)?.name.toLowerCase().includes(filter) ?? true)
        })
        .map(x => {
            const c = colors?.find(y => y.contractId === x.id)?.contractColor;
            let ci = 4;
            if (c === 'Red') ci = 1
            else if (c === 'Yellow') ci = 2
            else if (c === 'Brown') ci = 3;

            return ({
                key: x.id,
                id: x.id,
                number: x.number,
                date: x.date,
                user: x.userName,
                companyName: this.props.billingStore.companies?.find(y => y.id === x.companyId)?.name,
                position: ci, 
            })
        })
        .sort((a, b) => a.position - b.position);

        this.forceUpdate();
    }

    setContractPointsDatasource = (arr: SContractPointDTO[], colors: SBilColorsDTO[] | undefined) => {
        const bc = colors?.find(y => y.contractId === this.state.selectedContractId);

        this.contractPointsDataSource = arr.map(x => {
            const c = bc?.pointsColor[x.pointId];
            let ci = 4;
            if (c === 'Red') ci = 1
            else if (c === 'Yellow') ci = 2
            else if (c === 'Brown') ci = 3;

            return ({
                key: x.pointId,
                id: x.pointId,
                info: x.pointId,
                number: this.state.allPoints?.[x.pointId]?.number ?? '',
                address: this.state.allPoints?.[x.pointId]?.persistProperties['address'] ?? '',
                tariff: x.tariffId,
                price: x.price,
                isActive: x.isActive,
                position: ci,
            })
        })
        .sort((a, b) => a.position - b.position);

        this.forceUpdate();

        return this.contractPointsDataSource;
    }
    
    componentDidUpdate(prevProps: Props) {

    }

    requestContractPoints(contractId: number) {
        this.setState({currentContractPoints: undefined});
        sendRequestToBackend(
            contractId,
            'billing/contractpoints',
            (response: SContractPointDTO[]) => {
                if (response != null) {
                    this.setState({currentContractPoints: response});
                    const ds = this.setContractPointsDatasource(response, this.state.colors);
                    if (ds?.length > 0) {
                        this.onSelectPoint(ds[0].id);
                    }
                } else {
                    message.error('Запрос завершился неудачей');
                }
            },
            this.props.setIsLoading,
            (error: any) => {
                console.log(error);
                message.error(error);
            }
        );
    }

    //Один счет на одну ТУ
    requestBills(contract: SBilContractDTO, pointId: string) {
        this.setState({currentBills: undefined});
        sendRequestToBackend(
            {contractId: contract.id, pointId},
            'billing/bills',
            (response: any) => {
                if (response != null) {
                    for (const x of (response.bills as SBilBillDTO[])) {
                        initDates(x, ['billDate', 'billPaymentDate']);
                    }
                    for (const x of (response.compositions as SBilBillCompositionDTO[])) {
                        initDates(x, ['beginDate', 'endDate']);
                    }

                    this.setState({currentBills: response.bills, currentBillCompositions: response.compositions});
                    this.setBillCompositionsDatasource(response.bills, response.compositions, this.state.filterBills);
                } else {
                    message.error('Запрос завершился неудачей');
                }
            },
            this.props.setIsLoading,
            (error: any) => {
                console.log(error);
                message.error(error);
            }
        );
    }

    onContractWrite = (contract: SBilContractDTO, points: SContractPointDTO[], contacts: SBilContactDTO[], contactDeleteIds: number[]) => {
        sendRequestToBackend(
            {contract: contract, points: points, contacts: contacts, contactDeleteIds: contactDeleteIds},
            'billing/contractwrite',
            (response: any) => {
                if (response != null) {
                    initDates(response.contract, ['date']);

                    let cts;
                    const i = this.state.contracts?.findIndex(x => x.id === response.contract.id) ?? -1;
                    if (i == -1) {
                        cts = [response.contract, ...(this.state.contracts ?? [])];
                    } else {
                        cts = [...(this.state.contracts ?? [])];
                        cts[i] = response.contract;
                    }

                    this.setContractsDatasource(cts,  this.state.filterContracts, this.state.colors);
                    this.setContractPointsDatasource(response.contractPoints, this.state.colors);
                    this.setState({contracts: cts, selectedContractId: ((response.contract) as SBilContactDTO).id, currentContractPoints: response.contractPoints, editContract: null});

                    this.requestContractsPoints();
                    this.requestBillingColors();
                } else {
                    message.error('Запрос завершился неудачей');
                }
            },
            this.props.setIsLoading,
            (error: any) => {
                console.log(error);
                message.error(error);
            }
        );
    }

    private contractsDataSource: any = undefined;

    private contractsColumns = [
        {
            dataIndex: "num",
            title: "№",
            render: (text: any, record: any, index: any) => (
                <>{index + 1}</>
            ),
            width: 60,
        },
        {
            dataIndex: "number",
            title: "Номер",
            sorter: (a: any, b: any) => this.sorter(a.number, b.number),
        },
        {
            dataIndex: "date",
            title: "Дата",
            width: 100,
            render: (val: any, record: any, index: any) => (toDateString(new Date(val))),
            sorter: (a: any, b: any) => this.sorter(a.date, b.date),
        },
        {
            dataIndex: "user",
            width: 140,
            title: this.noWrap("Пользователь"),
            sorter: (a: any, b: any) => this.sorter(a.user, b.user),
        },
        {
            dataIndex: "companyName",
            title: this.noWrap("Контрагент"),
            sorter: (a: any, b: any) => this.sorter(a.companyName, b.companyName),
        },
    ];

    private contractPointsDataSource: any = undefined;

    private billCompositionsDataSource: any = undefined;

    private contractPointsColumns = [
        {
            dataIndex: "num",
            title: "№",
            render: (text: any, record: any, index: any) => (
                <>{index + 1}</>
            ),
            width: 40,
        },
        {
            dataIndex: "info",
            title: "Инфо",
            width: 70,
            render: (text: any, record: any) => 
                <Button onClick={() => this.showPointInfo(record.info, record.number)} size="middle" shape="circle" icon={<ScheduleTwoTone twoToneColor={Const.COLORS.SvgIconColor} />} />
        },
        {
            dataIndex: "number",
            sorter: (a: any, b: any) => this.sorter(a.number, b.number),
            title: this.noWrap("Наименование"),
        },
        
        {
            dataIndex: "address",
            sorter: (a: any, b: any) => this.sorter(a.number, b.number),
            title: this.noWrap("Адрес"),
        },
        {
            dataIndex: "tariff",
            title: "Тариф",
            width: 90,
            sorter: (a: any, b: any) => this.sorter(a.number, b.number),
            render: (val: any, record: any, index: any) => this.state.tariffs?.find(x => x.id === val)?.name,
        },
        {
            dataIndex: "price",
            title: "Аб. плата",
            width: 80,
        },
        {
            dataIndex: "isActive",
            title: "Активен",
            width: 80,
            render: (val: any, record: any, index: any) => {
                if (val === true) {
                    return <CheckCircleTwoTone style={{ fontSize: '20px'}} twoToneColor={Const.COLORS.SvgIconColor}/>
                } else {
                    return <StopTwoTone style={{ fontSize: '20px'}} twoToneColor="#8c8c8c" />
                }
            },
            align: 'center' as 'center',
        },
    ];

    onNewContract = () => {
        let c: SBilContractDTO = {
            actPeriod: 3,
            comment: '',
            companyId: -1,
            date: new Date(),
            details: '',
            id: -1,
            isDenounced: false,
            number: '',
            paymentPeriod: 3,
            paymentTypeId: -1,
            statusId: this.CONTRACT_DEFAULT_STATUS,
            userId: '',
            userName: '',
        };

        this.contractPointsDataSource = undefined;
        this.billCompositionsDataSource = undefined;
        this.setState({
            editContract: c, 
            showContractEditForm: true, 
            currentContractPoints: [], 
            selectedContractId: undefined, 
            selectedPointId: undefined, 
            currentBills: undefined, 
            currentBillCompositions: undefined,
        });
        this.props.setContacts([]);
    }

    onNewBill = () => {
        if (this.state.selectedContractId == undefined) return;
        if (this.state.selectedPointId == undefined) return;

        let b: SBilBillDTO = {
            billAmmount: 0,
            billDate: new Date(),
            billDescription: '',
            billId: -1,
            billNumber: '',
            billPaymentDate: null,
            billStatusId: -1,
            contractId: this.state.selectedContractId,
        }

        let c: SBilBillCompositionDTO = {
            pointId: this.state.selectedPointId,
            beginDate: toDate(new Date()),
            endDate: toDate(new Date()),
            billCompositionId: -1,
            billId: -1,
        }

        this.setState({editBill: {bill: b, composition: c}});
    }

    onWriteBill = (bill: SBilBillDTO, composition: SBilBillCompositionDTO, allPointsFlag: boolean) => {
        sendRequestToBackend(
            {bill, billComposition: composition, allPointsFlag},
            'billing/billwrite',
            (response: any) => {
                if (response != null) {
                    let i = this.state.contracts?.findIndex(x => x.id === bill.contractId);
                    if (i !== -1 && i != undefined && this.state.contracts != undefined && this.state.selectedPointId != undefined) {
                        this.requestBills(this.state.contracts[i], this.state.selectedPointId);
                    }
                    this.setState({editBill: null});
                    this.requestBillingColors();
                } else {
                    message.error('Запрос завершился неудачей');
                }
            },
            this.props.setIsLoading,
            (error: any) => {
                console.log(error);
                message.error(error);
            }
        );

        sendRequestToBackend(
            bill.contractId,
            'billing/billingcontractcolors',
            (response: any) => {
                if (response != null) {
                    if (this.state?.colors != undefined) {
                        let col = this.state.colors.filter(x => x.contractId !== bill.contractId);
                        col.push(...response);
                        this.setState({colors: col});
                        this.forceUpdate();
                    }
                } else {
                    message.error('Запрос завершился неудачей');
                }
            },
            this.props.setIsLoading,
            (error: any) => {
                console.log(error);
                message.error(error);
            }
        );
    }

    setBillCompositionsDatasource = (bills: SBilBillDTO[], arr: SBilBillCompositionDTO[], filter: string) => {
        this.billCompositionsDataSource = arr.map(x => {
            let bill = bills.find(y => y.billId === x.billId);
            return ({
                key: x.billCompositionId,
                id: x.billCompositionId,
                billNumber: bill?.billNumber,
                amount: bill?.billAmmount,
                billDate: bill?.billDate,
                description: bill?.billDescription,
                startDate: x.beginDate,
                endDate: x.endDate,
                billId: bill?.billId,
            })
        })
        this.billCompositionsDataSource = this.billCompositionsDataSource.filter((x: any) => x.billNumber.toLowerCase().includes(filter));

        this.forceUpdate();
    }

    onEditContractClick = () => {
        this.setState({editContract: this.state.contracts?.find(x => x.id === this.state.selectedContractId) ?? null, showContractEditForm: true}); 
        if (this.props.billingStore.companies == undefined) {
            this.props.requestCompanies(() => {});
        };
        if (this.state.contracts != undefined && this.state.selectedContractId != undefined) {
            let tmp = this.state.contracts.find(x => x.id === this.state.selectedContractId)?.companyId;
            if (tmp != undefined) {
                this.props.requestContacts(tmp, () => {});
            }
        };
    }

    onDeleteBillClick = (val: number) => {
        const tmp = this.state.currentBillCompositions?.find(x => x.billCompositionId === val);
        const tmp2 = this.state.currentBills?.find(x => x.billId === tmp?.billId);

        Modal.confirm({
            title: 'Удаление счета',
            wrapClassName: Const.MODAL_WRAP_CLASSNAME,
            zIndex: getModalZIndex(),
            content: 'Вы действительно хотите удалить счет ' + tmp2?.billNumber + '?',
            width: 600,
            okText: 'Да',
            cancelText: 'Нет',
            onOk: () => {
                if (tmp && tmp2) {
                    sendRequestToBackend(
                        tmp.billId,
                        'billing/billdelete',
                        (response: any) => {
                            if (response != null) {
                                let i = this.state.contracts?.findIndex(x => x.id === tmp2.contractId);
                                if (i !== -1 && i != undefined && this.state.contracts != undefined && this.state.selectedPointId != undefined) {
                                    this.requestBills(this.state.contracts[i], this.state.selectedPointId);
                                }
                                this.requestBillingColors();
                            } else {
                                message.error('Запрос завершился неудачей');
                            }
                        },
                        this.props.setIsLoading,
                        (error: any) => {
                            console.log(error);
                            message.error(error);
                        }
                    );
                }
            },
            onCancel: () => {
            }
        });
    }

    private billCompositionsColumns = [
        {
            dataIndex: "id",
            title: "",
            width: 90,
            align: 'center' as 'center',
            render: (val: any, record: any, index: any) => {
                return (
                    <Space>
                        <Tooltip placement="top" title={'Редактировать'}>
                            <Button shape="circle" 
                                onClick={() => {
                                    const tmp = this.state.currentBillCompositions?.find(x => x.billCompositionId === val);
                                    const tmp2 = this.state.currentBills?.find(x => x.billId === tmp?.billId);
                                    if (tmp && tmp2) {
                                        this.setState({editBill: {bill: tmp2, composition: tmp}});
                                    }
                                }} 
                                icon={<EditTwoTone twoToneColor={Const.COLORS.SvgIconColor}/>} 
                            />
                        </Tooltip>
                        <Tooltip placement="top" title={'Удалить'}>
                            <Button shape="circle" onClick={() => this.onDeleteBillClick(val)} icon={<DeleteTwoTone twoToneColor={Const.COLORS.SvgIconColor}/>} />
                        </Tooltip>
                    </Space>
                )
            },
        },
        {
            dataIndex: "billNumber",
            title: <>Номер счета</>,
            sorter: (a: any, b: any) => this.sorter(a.billNumber, b.billNumber),
        },
        {
            dataIndex: "amount",
            width: 100,
            title: "Сумма",
            sorter: (a: any, b: any) => this.sorter(a.amount, b.amount),
        },
        {
            dataIndex: "billDate",
            title: <>Дата счета</>,
            width: 100,
            render: (val: Date, record: any, index: any) => (moment(val).format('DD.MM.YYYY')),
            defaultSortOrder: 'descend' as 'descend',
            sorter: (a: any, b: any) => this.sorter(a.billDate, b.billDate),
        },
        {
            dataIndex: "description",
            title: "Описание",
            sorter: (a: any, b: any) => this.sorter(a.description, b.description),
        },
        {
            dataIndex: "startDate",
            title: <>Нач. дата</>,
            width: 100,
            render: (val: Date, record: any, index: any) => (moment(val).format('DD.MM.YYYY')),
            sorter: (a: any, b: any) => this.sorter(a.startDate, b.startDate),
        },
        {
            dataIndex: "endDate",
            title: <>Кон. дата</>,
            width: 100,
            render: (val: Date, record: any, index: any) => (moment(val).format('DD.MM.YYYY')),
            sorter: (a: any, b: any) => this.sorter(a.endDate, b.endDate),
        },
    ];

    onSelectPoint = (pointId: string) => {
        this.setState({selectedPointId: pointId});
        const tmp = this.state.contracts?.find(x => x.id === this.state.selectedContractId);
        if (tmp != undefined) { 
            this.requestBills(tmp, pointId); 
        }
    }
    
    onDeleteContractClick = () => {
        const tmp = this.state.contracts?.find(x => x.id === this.state.selectedContractId);

        if (tmp != undefined) {
            Modal.confirm({
                title: 'Удаление договора',
                wrapClassName: Const.MODAL_WRAP_CLASSNAME,
                zIndex: getModalZIndex(),
                content: 'Вы действительно хотите удалить договор ' + tmp.number + '?',
                width: 600,
                okText: 'Да',
                cancelText: 'Нет',
                onOk: () => {
                    if (tmp) {
                        sendRequestToBackend(
                            tmp.id,
                            'billing/contractdelete',
                            (response: any) => {
                                if (response != null) {
                                    let i = this.state.contracts?.findIndex(x => x.id === tmp.id);
                                    if (i !== -1 && i != undefined && this.state.contracts != undefined) {
                                        let cts = [...this.state.contracts];
                                        cts.splice(i, 1);
                                        this.setState({contracts: cts});
                                        this.setContractsDatasource(cts, this.state.filterContracts, this.state.colors);

                                        this.setState({currentBillCompositions: undefined, currentBills: undefined, selectedPointId: undefined, currentContractPoints: undefined});
                                        this.setContractPointsDatasource([], this.state.colors);
                                        this.setBillCompositionsDatasource([], [], '');
                                    }
                                } else {
                                    message.error('Запрос завершился неудачей');
                                }
                            },
                            this.props.setIsLoading,
                            (error: any) => {
                                console.log(error);
                                message.error(error);
                            }
                        );
                    }
                },
                onCancel: () => {
                }
            });
        }
    }

    rowColorContracts = (record: any, index: any) => {
        let rowClassName = '';
        
        if (this.state.colors != undefined) {
            for (let c of this.state.colors) {
                if (c.contractId === record.id) {
                    if (c.contractColor == 'Brown') {
                        rowClassName = billingStyles.rowBrown
                    }
                    if (c.contractColor == 'Yellow') {
                        rowClassName = billingStyles.rowYellow
                    }
                    if (c.contractColor == 'Red') {
                        rowClassName = billingStyles.rowRed
                    }
                }
            }
        }

        return rowClassName;
    }

    rowColorPoints = (record: any, index: any) => {
        let rowClassName = '';
        
        if (this.state.colors != undefined) {
            const pointId = record.id;
            const c = this.state.colors.find(x => x.contractId === this.state.selectedContractId)?.pointsColor[pointId];
            if (c != undefined) {
                if (c === 'Brown') {
                    rowClassName = billingStyles.rowBrown
                }
                if (c === 'Yellow') {
                    rowClassName = billingStyles.rowYellow
                }
                if (c === 'Red') {
                    rowClassName = billingStyles.rowRed
                }
            }
        }

        return rowClassName;
    }

    contractsReport = () => {
        const filter = this.state.filterContracts;
        sendRequestToBackend(
            //this.state.contracts?.filter(x => x.number.toLowerCase().includes(filter) || x.userName.toLowerCase().includes(filter)).map(x => x.id),
            this.state.contracts?.filter(x => x.number.toLowerCase().includes(filter) || x.userName.toLowerCase().includes(filter) 
                || (this.props.billingStore.companies?.find(y => y.id === x.companyId)?.name.toLowerCase().includes(filter) ?? true )).map(x => x.id),
            'billing/contractsreport',
            (response: any) => {
                const fileName = 'Отчет по договорам' + '.xls';
                message.success('Отчет по договорам получен');
                saveFile(fileName, convertBase64ToBinary(response));
            },
            this.props.setIsLoading,
            (error: any) => {
                message.error('Ошибка:' + error);
                console.log(error);
            },
            this.props.closeSession
        );
    }

    execute = (cmd: any) => {
        netiExecute(cmd, this.props.userProfile, this.props.changeProfile, this.props.setIsLoading, this.props.setActiveQueries, null, this.props.showModal);
    }

    showPointInfo = (pointId: string, pointNumber: string) =>{
        const source: any = new (window.NetiSource as any)(this.execute);
        const parent: any = ReactDOM?.findDOMNode(this)?.parentNode;
        this.props.showPointInfo(pointId, pointNumber, source, parent);
    }

    billsReport = () => {
        if (this.state.currentBills != undefined) {
            let rows: any[] = [];

            this.billCompositionsDataSource.forEach((element: any) => {
                
                let bill = this.state.currentBills?.find(y => y.billId === element.billId);
                let contract = this.state.contracts?.find(x => x.id === this.state.selectedContractId);
                let company = this.props.billingStore.companies?.find(x => x.id === contract?.companyId);

                if (bill != undefined && contract != undefined && company != undefined) {
                    let row = contract.number + ';' + contract.userName + ';' + company.name + ';' + bill.billNumber + ';' + bill.billAmmount.toString() + ';' 
                        + moment(bill.billDate).format('DD.MM.YYYY HH:mm:ss') + ';' + bill.billDescription + ';' + moment(element.startDate).format('DD.MM.YYYY') + ';' 
                        + moment(element.endDate).format('DD.MM.YYYY') + ';';

                    rows.push(row);
                }
            });

            const data = rows.join('\r\n');

            const data2 = unicodeToWin1251(data);

            const blob = new Blob([data2], {
                type: 'text/csv',
            });

            let url = window.URL.createObjectURL(blob);
            let a = document.createElement('a');
            a.href = url;
            a.download = 'Отчет по счетам.csv';
            a.click();

            this.props.setSiteLogMakeCSV(false);
        }
    }

    public render() {
        return (
            <div style={{minHeight: '500px'}} className={styles.container}>
                {
                    <div className='billing-page' style={{ height: '100%', width: '100%', display: 'flex', justifyContent: 'space-between' }}>
                        <div style={{width: '45%', padding: '4px', border: '2px solid #c8c8c8'}}>
                            <div style={{display: 'flex', flexDirection: 'column'}}>
                                <Space style={{justifyContent: 'center'}}><Title level={4}>Договоры</Title></Space>
                                <div style={{display: 'inline-flex', gap: '8px'}}>
                                    <Button type='primary' onClick={this.onNewContract}>
                                        Новый договор
                                    </Button>
                                    <Button 
                                        onClick={this.onEditContractClick}
                                        type='primary'
                                        disabled={this.state.selectedContractId == undefined}
                                    >
                                        Изменить
                                    </Button>
                                    <Button type='primary' onClick={this.onDeleteContractClick} disabled={this.state.selectedContractId == undefined}>Удалить</Button>
                                    <Search style={{width: '150px', marginLeft: 'auto'}}
                                        value={this.state.filterContracts}
                                        onChange={(val) => {this.setState({filterContracts: val.target.value}); this.setContractsDatasource(this.state?.contracts ?? [], val.target.value, this.state.colors);}}
                                    />
                                    <Button onClick={this.contractsReport} type='primary'>Сформировать</Button>
                                </div>
                                {/* -----------------Таблица с контрактами ------------------ */}
                                <Table 
                                    rowClassName={this.rowColorContracts} showSorterTooltip={false}
                                    pagination={false} scroll={{y: 'calc(100vh - 260px)'}} dataSource={this.contractsDataSource} columns={this.contractsColumns} style={{paddingTop: '4px'}}  size='small'
                                    rowSelection={{
                                        type: "radio",
                                        selectedRowKeys: this.state?.selectedContractId != undefined ? [this.state.selectedContractId] : undefined,
                                        onChange: (selectedRowKeys: any[], selectedRows: any[]) => {
                                            this.billCompositionsDataSource = undefined;
                                            this.setState({selectedContractId: selectedRowKeys[0], selectedPointId: undefined, currentBills: undefined, currentBillCompositions: undefined});
                                            this.requestContractPoints(Number(selectedRowKeys[0]));
                                        }
                                    }}
                                    onRow={(record, rowIndex) => {
                                        return {
                                            onClick: event => { 
                                                if (record.id !== this.state.selectedContractId) {
                                                    this.billCompositionsDataSource = undefined;
                                                    this.setState({selectedContractId: record.id, selectedPointId: undefined, currentBills: undefined, currentBillCompositions: undefined}); 
                                                    this.requestContractPoints(Number(record.id)); 
                                                } 
                                            }, // click row
                                            onDoubleClick: this.onEditContractClick,
                                            style: { cursor: 'pointer' },
                                        };
                                    }}
                                />
                            </div>
                        </div>
                        <div style={{width: '54.5%'}}>
                            <div style={{display: 'flex', width: '100%', flexDirection: 'column'}}>
                                <div style={{height: '40%', width: '100%'}}>
                                    <div style={{display: 'inline-flex', flexDirection: 'column', width: '100%'}}>
                                        <div style={{display: 'flex', flexDirection: 'column', padding: '4px', border: '2px solid #c8c8c8'}}>
                                            <div style={{display: 'flex', justifyContent: 'center'}}><Title level={4}>Точки учета</Title></div>
                                            {/* -----------------Таблица с ТУ контракта ------------------ */}
                                            <Table
                                                rowClassName={this.rowColorPoints} showSorterTooltip={false}
                                                style={{paddingTop: '4px'}} scroll={{y: 'calc(40vh - 180px)' }} pagination={false} size='small'
                                                dataSource={this.contractPointsDataSource} columns={this.contractPointsColumns} 
                                                rowSelection={{
                                                    type: "radio",
                                                    selectedRowKeys: this.state?.selectedPointId != undefined ? [this.state.selectedPointId] : undefined,
                                                    onChange: (selectedRowKeys: any[], selectedRows: any[]) => {
                                                        this.onSelectPoint(selectedRowKeys[0]);
                                                    }
                                                }}
                                                onRow={(record, rowIndex) => {
                                                    return {
                                                        onClick: event => { 
                                                            if (record.id !== this.state.selectedPointId) { 
                                                                this.onSelectPoint(record.id);
                                                            } 
                                                        },
                                                        style: { cursor: 'pointer' },
                                                    };
                                                }}
                                            />
                                        </div>
                                    
                                        <div style={{display: 'flex', justifyContent: 'center', marginTop: '20px'}}><Title level={4}>Счета</Title></div>
                                        <Space align='center' style={{marginBottom: '5px'}}>
                                            <Button 
                                                type='primary' style={{width: '150px'}} 
                                                onClick={this.onNewBill}
                                                disabled={this.state.selectedPointId == undefined}
                                            >
                                                Новый счет
                                            </Button>
                                            <Search 
                                                value={this.state.filterBills} 
                                                onChange={(val) => { 
                                                    if (this.state.currentBills != undefined && this.state.currentBillCompositions != undefined) {
                                                        this.setBillCompositionsDatasource(this.state.currentBills, this.state.currentBillCompositions, val.target.value); 
                                                    }
                                                    this.setState({filterBills: val.target.value});
                                                }}
                                            />
                                            <Button onClick={this.billsReport}>Excel</Button>
                                        </Space>
                                        {/* -----------------Таблица со счетами ------------------ */}
                                        <Table size='small' scroll={{y: 'calc(40vh - 80px)' }} showSorterTooltip={false}
                                            columns={this.billCompositionsColumns} 
                                            dataSource={this.billCompositionsDataSource}
                                            onRow={(record, rowIndex) => {
                                                return {
                                                    onDoubleClick: () => {
                                                        const tmp = this.state.currentBillCompositions?.find(x => x.billCompositionId === record.id);
                                                        const tmp2 = this.state.currentBills?.find(x => x.billId === tmp?.billId);
                                                        if (tmp && tmp2) {
                                                            this.setState({editBill: {bill: tmp2, composition: tmp}});
                                                        }
                                                    },
                                                    style: { cursor: 'pointer' },
                                                };
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }
                
                <ContractForm 
                    visible={this.state.showContractEditForm === true && this.state.editContract != null && this.state.currentContractPoints != undefined}
                    contract={this.state.editContract} 
                    contractPoints={this.state.currentContractPoints ?? []}
                    onWrite={this.onContractWrite}
                    contacts={this.props.billingStore.contacts ?? []}

                    windowHeight={this.props.page.windowHeight} 
                    onHide={() => {this.setState({showContractEditForm: false});}}
                    tariffs={this.state.tariffs ?? []}
                    paymentTypes={this.state.paymentTypes ?? []}
                    allPoints={this.state.allPoints}
                    
                    companies={this.props.billingStore.companies ?? []}
                    setIsLoading={this.props.setIsLoading}
                    requestContacts={this.props.requestContacts}

                    changeProfile={this.props.changeProfile}
                    setActiveQueries={this.props.setActiveQueries}
                    showModal={this.props.showModal}
                    showPointInfo={this.props.showPointInfo}
                    userProfile={this.props.userProfile}
                />
                <CompaniesForm
                    visible={this.props.billingStore.showCompaniesForm && this.props.billingStore.companies != undefined}
                    onHide={() => { this.props.setShowCompaniesForm(false); }}
                    companies={this.props.billingStore.companies}
                    contacts={this.props.billingStore.contacts}
                    requestContacts={this.props.requestContacts}
                    selectMode={false}
                    onSelect={() => {}}
                    selectedCompanyId={-1}
                    changeCompanies={(companies: SBillingCompanyInfoDTO[]) => this.props.setCompanies(companies)}
                    setIsLoading={this.props.setIsLoading}
                />
                <BillEditForm 
                    bill={this.state.editBill?.bill}
                    billComposition={this.state.editBill?.composition ?? null}
                    onHide={() => {this.setState({editBill: null})}}
                    onWrite={this.onWriteBill}
                    billStatuses={this.state.billStatuses ?? []}
                    contractNumber={this.state?.contracts?.find(x => x.id === this.state.selectedContractId)?.number}
                    pointNumber={this.state?.selectedPointId != undefined ? this.state.allPoints[this.state.selectedPointId].number : undefined}
                    pointsCount={this.state?.currentContractPoints?.length ?? 1}
                    setIsLoading={this.props.setIsLoading}
                />
                <TariffsForm 
                    changeTariffs={(tariffs) => {this.setState({tariffs: tariffs})}}
                    setIsLoading={this.props.setIsLoading}
                    onHide={() => {this.props.setShowTariffsForm(false)}}
                    tariffs={this.state.tariffs ?? []}
                    visible={this.props.billingStore.showTariffsForm}
                />
                <StatisticsForm
                    onHide={() => this.props.setShowStatisticsForm(false)}
                    setIsLoading={this.props.setIsLoading}
                    tariffs={this.state.tariffs ?? []}
                    visible={this.props.billingStore.showStatisticsForm}
                />
                <PromisedPaymentForm
                    allPoints={this.state.allPoints ?? {}}
                    companies={this.props.billingStore.companies ?? []}
                    contracts={this.state.contracts ?? []}
                    tariffs={this.state.tariffs ?? []}
                    setIsLoading={this.props.setIsLoading}
                    onHide={() => this.props.setShowPromisedPaymentForm(false)}
                    visible={this.props.billingStore.showPromisedPaymentForm}
                    paymentTypes={this.state.paymentTypes ?? {}}
                    resources={this.props.userProfile.resources}
                />
                <ParsePaymentsForm
                    onHide={() => this.props.setShowParsePaymentsForm(false)}
                    setIsLoading={this.props.setIsLoading}
                    visible={this.props.billingStore.showParsePaymentsForm}
                />
                {
                    this.props.billingStore.companies == undefined || this.state.contracts == undefined || this.state.contractsPoints == undefined 
                        || this.state.tariffs == undefined || this.props.userProfile?.resources == undefined || this.state.allPoints == undefined ? null :
                    <ActiveContractsForm
                        onOk={() => {this.requestContractsPoints(); if (this.state.selectedContractId != undefined) this.requestContractPoints(this.state.selectedContractId );}}
                        allPoints={this.state.allPoints}
                        companies={this.props.billingStore.companies}
                        contracts={this.state.contracts}
                        contractsPoints={this.state.contractsPoints}
                        onHide={() => this.props.setShowActiveContractsForm(false)}
                        setIsLoading={this.props.setIsLoading}
                        showPointInfo={this.showPointInfo}
                        tariffs={this.state.tariffs}
                        visible={this.props.billingStore.showActiveContractsForm}
                        resources={this.props.userProfile.resources}
                    />
                }
            </div>
        );
    }
}

export default connect(
    (state: ApplicationState) => {
        return {
            page: state.page,
            userProfile: state.userProfile,
            allPoints: state.userProfile?.allPoints,
            siteLog: state.siteLog,
            billingStore: state.billingStore,
        }
    },
    actionCreators
)(Billing as any);
