import * as React from 'react';
import ReactDOM from 'react-dom';

import { RouteComponentProps } from 'react-router';
import { connect } from 'react-redux';

import { Button, DatePicker, Select, Tabs } from 'antd';

import { ApplicationState } from '../store';
import * as PageStore from '../store/Page';
import * as UserProfileStore from '../store/UserProfile';
import * as SettingsStore from '../store/SettingsStore';
import * as AdminStore from '../store/AdminStore';
import { BaseProfile, Group, MenuItemDesc, Point, USER_RIGHTS, Command } from '../decl';
import styles from '../resources/Page.module.less';
import adminStyles from '../resources/AdminPage.module.less';
import * as Const from '../utils/Const';
import { netiExecute, getModalZIndex, message } from '../utils/Common';

import PointsManager from '../components/Admin/PointsManager';
import UsersManager from '../components/Admin/UsersManager';
import { group } from 'console';
import RolesAndRights from '../components/Admin/RolesAndRights';
import TCPIP from '../components/Admin/TCPIP';
import PointEditForm from '../components/Admin/PointEditForm';
import { PointAllInfoDTO } from '../store/AdminStore';
import PollingInfo from '../components/Admin/PollingInfo';
import PollingForm from '../components/Admin/PollingForm';

const { TabPane } = Tabs;

const actionCreators = { ...PageStore.actionCreators, ...UserProfileStore.actionCreators, ...AdminStore.actionCreators, ...SettingsStore.actionCreators };

type AdminProps =
    {
        page: PageStore.PageState,
        profile: BaseProfile,
        userProfile: UserProfileStore.UserProfileState,
        pointId: string,
        adminStore: AdminStore.IAdminState,
    } &
    typeof actionCreators &
    RouteComponentProps<{}>

// type SelectObjectsModalType = {
//     visible: boolean,
//     group: Group | null,
//     onlyGroupsSelect: boolean,
//     selectedPointsIds: string[],
//     selectedGroupsIds: string[],
// }

interface IAdminState {
    activeTabKey: string,
    initTab: boolean
    showPointEditForm: boolean,
    pointGroupId: string | undefined,
    modalZIndex: number
}

class Admin extends React.PureComponent<AdminProps, IAdminState> {

    constructor(props: AdminProps) {
        super(props);

        this.state = {
            activeTabKey: '',
            initTab: false,
            showPointEditForm: false,
            pointGroupId: undefined,
            modalZIndex: 0
        }
    }
    componentDidMount() {
        this.props.requestSettingsTypes();
    }

    componentWillUnmount() {

    }

    componentDidUpdate(prevProps: AdminProps) {
        //console.log("Admin", "DidUpdate");
        // if (prevProps.adminStore.isPointWriteComplete === false && this.props.adminStore.isPointWriteComplete === true && this.props.adminStore.pointAllInfo !== undefined) {
        //     this.updateTree(this.props.adminStore.pointAllInfo.pointInfo.id, this.props.adminStore.pointAllInfo.pointInfo.number);
        //     this.setState({showPointEditForm: false});
        // }
    }

    tabCallback = (key: any) => {
        this.setState({ activeTabKey: key, initTab: true });
    }

    execute = (cmd: any) => {
        netiExecute(cmd, this.props.userProfile, this.props.changeProfile, this.props.setIsLoading, this.props.setActiveQueries, null, this.props.showModal);
    }

    showPointInfo = (pointId: string, pointNumber: string) => {
        const source: any = new (window.NetiSource as any)(this.execute);
        const parent: any = ReactDOM?.findDOMNode(this)?.parentNode;
        this.showPointInfoDirect(pointId, pointNumber, source, parent);
    }

    showPointInfoDirect = (pointId: string, pointNumber: string, source: any, parentNode: any, tabName?: string) => {
        //console.time('XXX');
        this.props.setIsLoading(true);
        window.call_execute({ Source: source, Name: Const.REGISTER_NETIROOT_COMMAND });
        window.call_execute_withcreate(
            source,
            "infoShowFromModal",
            { id: pointId, number: pointNumber, parent: parentNode, tabName: tabName },
            () => {
                //console.timeEnd('XXX');
                this.props.setIsLoading(false);
            },
            () => {
                this.props.setIsLoading(false);
            });
    }

    //Редактирование точки учёта.
    editPoint = (pointId: string, groupId: string | undefined) => {
        this.setState({ showPointEditForm: true, pointGroupId: groupId, modalZIndex: getModalZIndex() });
        this.props.requestPointDicts();
        this.props.requestPointAllInfo(pointId, false);
    }

    onPointWrite = (close: boolean, finish: (success: boolean, point: PointAllInfoDTO) => void) => {
        if (this.props.adminStore.pointAllInfo !== undefined) {
            if (this.props.adminStore.pointAllInfo.pointInfo.id === null) {
                this.props.requestPointCreate(this.props.adminStore.pointAllInfo, this.state.pointGroupId, this.props.adminStore.queryItem, (point: PointAllInfoDTO) => {
                    finish ? finish(true, point) : null;
                    if (close) {
                        this.setState({ showPointEditForm: false });
                    }
                });
            } else {
                this.props.requestPointWrite(this.props.adminStore.pointAllInfo, (s: boolean, point: PointAllInfoDTO) => {
                    finish ? finish(s, point) : null;
                    if (s === true && close) {
                        this.setState({ showPointEditForm: false });
                    }
                });
            }
        }
    }

    readPointSettings = (pointId: string | undefined | null, success?: () => void) => {
        if (pointId) {
            this.props.setIsLoading(true, 200, "Сбор данных с прибора, это может занять несколько минут.");

            this.props.readData(
                0,
                [pointId],
                100,
                true,
                (code: number, ids: any) => {
                    if (code === 0) {
                        message.success('Запрос на получение данных из приборов отправлен.');
                    }
                    if (code === 2) {
                        this.props.setIsLoading(false);
                        message.success('Данные из приборов получены.');
                        success ? success() : null;
                    }
                    if (code === 4) {
                        message.warning('Не все данные из приборов удалось получить.');
                        this.props.setIsLoading(false);
                        success ? success() : null;
                    }
                },
                (error: string) => {
                    this.props.setIsLoading(false);

                    message.error(error);
                });
        }
        else {
            message.error('Точка учёта для сбора настроек не определена');
        }
    }

    public render() {
        return (
            <div className={styles.container}>
                <Tabs defaultActiveKey="1" onChange={this.tabCallback} className={adminStyles.tabs}>
                    <TabPane tab="Точки учёта" key="1" className={adminStyles.tabPane}>
                        <div className={adminStyles.tabContainer}>
                            <PointsManager />
                        </div>
                    </TabPane>
                    {
                        this.props.userProfile.rights.indexOf(USER_RIGHTS.Admin) < 0 ? null :
                            <TabPane tab="Пользователи" key="2" className={adminStyles.tabPane}>
                                <div className={adminStyles.tabContainer}>
                                    <UsersManager />
                                </div>
                            </TabPane>
                    }
                    {
                        this.props.userProfile.rights.indexOf(USER_RIGHTS.Admin) < 0 ? null :
                            <TabPane tab="Права и роли" key="4" className={adminStyles.tabPane}>
                                <div className={adminStyles.tabContainer}>
                                    <RolesAndRights
                                        history={this.props.history}
                                        setBeforeLeavePage={this.props.setBeforeLeavePage}
                                        removeBeforeLeavePage={this.props.removeBeforeLeavePage}
                                        setIsLoading={this.props.setIsLoading}
                                        windowHeight={this.props.page.windowHeight}
                                    />
                                </div>
                            </TabPane>
                    }
                    {
                        this.props.userProfile.rights.indexOf(USER_RIGHTS.Admin) < 0 ? null :
                            <TabPane tab="История TCP IP" key="5" className={adminStyles.tabPane}>
                                <div className={adminStyles.tabContainer}>
                                    <TCPIP
                                        tcolumns={this.props.userProfile.profile.Admin?.TCPIPColumnsLayout}
                                        windowHeight={this.props.page.windowHeight}
                                        showPointInfo={this.showPointInfo}
                                        showPointEditForm={this.editPoint}
                                        setIsLoading={this.props.setIsLoading}
                                        showModal={this.props.showModal}
                                        changeProfile={this.props.changeProfile}
                                        init={this.state.activeTabKey === "5" && this.state.initTab}
                                        initDone={() => this.setState({ initTab: false })}
                                    />
                                </div>
                            </TabPane>
                    }
                    {
                        this.props.userProfile.rights.indexOf(USER_RIGHTS.Admin) < 0 ? null :
                            <TabPane tab="Опрос приборов" key="6" className={adminStyles.tabPane}>
                                <div className={adminStyles.tabContainer}>
                                    <PollingInfo
                                        windowHeight={this.props.page.windowHeight}
                                        points={Object.values(this.props.userProfile?.allPoints ?? [])}
                                        setIsLoading={this.props.setIsLoading}
                                        windowWidth={this.props.page.windowWidth}
                                        setActiveLogTask={this.props.setActiveLogTask}
                                    />
                                </div>
                            </TabPane>
                    }
                </Tabs>
                {
                    this.props.adminStore == undefined ? null :
                        <PointEditForm
                            visible={this.state.showPointEditForm}
                            onHide={() => { this.setState({ showPointEditForm: false }); this.props.setPointAllInfo(undefined) }}
                            onWrite={this.onPointWrite}
                            changeQueryItem={this.props.setQueryItem}

                            isConnParsLoading={this.props.adminStore.isConnParsLoading}
                            isPointAllInfoLoading={this.props.adminStore.isPointAllInfoLoading}
                            isQueryItemTypesLoading={this.props.adminStore.isQueryItemTypesLoading}
                            pointAllInfo={this.props.adminStore.pointAllInfo}
                            pointDicts={this.props.adminStore.pointDicts}
                            queryItemTypes={this.props.adminStore.queryItemTypes}
                            queryItem={this.props.adminStore.queryItem}

                            allResources={this.props.userProfile.resources}
                            changePoint={this.props.setPointAllInfo}
                            requestConnPars={this.props.requestConnPars}
                            setIsLoading={this.props.setIsLoading}
                            baseMapLayer={this.props.profile.Map?.BaseLayer}
                            changeBaseMapLayer={(val: string) => this.props.changeProfileValue(Const.PROFILE_MAP, 'BaseLayer', val)}
                            readSettings={this.readPointSettings}
                            requestPointAllInfo={(success: () => void) => this.props.requestPointAllInfo(this.props.adminStore.pointAllInfo?.pointInfo.id ?? null, false, success)}

                            changeProfile={this.props.changeProfile}
                            setActiveQueries={this.props.setActiveQueries}
                            showModal={this.props.showModal}
                            showPointInfo={this.props.showPointInfo}
                            userProfile={this.props.userProfile}
                            zIndex={this.state.modalZIndex}
                            windowHeight={this.props.page.windowHeight}
                            activeTasks={this.props.page.activeTasks}
                            setActiveLogTask={this.props.setActiveLogTask}
                            getActiveTasks={this.props.getActiveTasks}
                            readData={this.props.readData}
                            showPointLog={
                                (pointId: string) => {
                                    if (this.props.page.editPoint)
                                        this.props.addPointLog(this.props.page.editPoint);
                                }
                            }
                        />
                }

            </div>
        );
    }
}

export default connect(
    (state: ApplicationState) => {
        return {
            adminStore: state.adminStore,
            page: state.page,
            profile: state.userProfile?.profile,
            userProfile: state.userProfile,
            pointId: state.page.pointId,

        }
    },
    actionCreators
)(Admin as any);
