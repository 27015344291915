import * as React from 'react';
import { RouteComponentProps, Switch } from 'react-router';
import { connect } from 'react-redux';

import { ApplicationState } from '../store';
import * as PageStore from '../store/Page';
import * as UserProfileStore from '../store/UserProfile';
import * as NsiStore from '../store/NsiStore';
import { BaseProfile, SParameterDTO, SParameterTypesEnum, SParameterValTypes, SParameterValuesStatusEnum } from '../decl';
import styles from '../resources/Page.module.less';
import stylesNsi from '../resources/Nsi.module.less';
import { LoadingOutlined } from '@ant-design/icons';
import { Tabs, Space, Button, Table, Tag, Modal, Row, Col, Input, Checkbox, DatePicker, InputNumber, Select } from 'antd';

import { toDate } from '../utils/Common';
import { JsxAttributeLike, validateLocaleAndSetLanguage } from 'typescript';
import moment from 'moment';
import { SFieldChangeRequest, SNsiAreaDTO, SNsiAreasBlockDTO, SNsiDTO} from '../store/NsiStore';

const actionCreators = { ...PageStore.actionCreators, ...UserProfileStore.actionCreators, ...NsiStore.actionCreators };

type NsiProps =
    {
        page: PageStore.PageState,
        profile: BaseProfile,
        nsi: NsiStore.INsiState,
        pointId: string,
    } &
    typeof actionCreators &
    RouteComponentProps<{}>

interface INsiState {
    pages: SNsiDTO | undefined;
    changes: {[key: string]: SFieldChangeRequest};
}

class Nsi extends React.PureComponent <NsiProps, INsiState > {
    constructor(props: NsiProps){
        super(props);
        
        this.state = {
            pages: undefined,
            changes: {},
        }
    }

    componentDidMount () {
        if (this.props.pointId !== "") {
            this.props.requestNsi(this.props.pointId);
        }
        this.copyNsi();
    }

    componentDidUpdate (prevProps: NsiProps) {
        if ((prevProps.pointId !== this.props.pointId && this.props.pointId !== "")
            || (prevProps.nsi.pages !== this.props.nsi.pages && this.props.nsi.pages == undefined)) 
        {
            this.props.requestNsi(this.props.pointId);
        }

        if (prevProps.nsi.requestChangeFlag !== this.props.nsi.requestChangeFlag) {
            if (this.props.nsi.requestChangeFlag === true) {
                this.props.requestChangeNsi(this.props.pointId, Object.values(this.state.changes));
                this.props.setIsLoading(true);
            } else {
                this.props.setIsLoading(false);
            }
        }

        if (prevProps.nsi.editEnabled !== this.props.nsi.editEnabled && this.props.nsi.editEnabled === false) {
            this.copyNsi();
        }

        if (prevProps.nsi.pages !== this.props.nsi.pages) {
            this.copyNsi();
        }
        if(this.props.nsi.isLoadingNsi != prevProps.nsi.isLoadingNsi){
            this.props.setIsLoading(this.props.nsi.isLoadingNsi);
        }
    }

    copyNsi = () => {
        if (this.props.nsi.pages != undefined) {
            let res = JSON.parse(JSON.stringify(this.props.nsi.pages));
            //Так как сериализация через JSON преобразует Date в string нужно вернуть его обратно
            (res as SNsiDTO).blocks.forEach((b, bi) => b.areas.forEach((a, ai) => a.fields.forEach((f, fi) => {
                if ((f.valueType === SParameterTypesEnum.Date || f.valueType === SParameterTypesEnum.DateTime) && f.value != null) {
                    (res as SNsiDTO).blocks[bi].areas[ai].fields[fi].value = new Date(f.value as string);
                }
            })))

            this.setState({pages: res, changes: {}});
        } else {
            this.setState({pages: undefined, changes: {}});
        }
    }

    private changeField(b: SNsiAreasBlockDTO, a: SNsiAreaDTO, f: SParameterDTO, newVal: SParameterValTypes) {
        if (this.state.pages != undefined) {
            let res = {...this.state.pages};
            f.value = newVal;
            this.setChangedVal(b, a, f);
            this.setState({pages: res});
        }
    }

    setChangedVal = (b: SNsiAreasBlockDTO, a: SNsiAreaDTO, f: SParameterDTO,) => {
        const k = b.channelCode + ' ' + a.groupCode + ' ' + f.parameterCode;

        let res: SFieldChangeRequest = {
            channelCode: b.channelCode,
            groupCode: a.groupCode,
            parameterCode: f.parameterCode,
            newValue: f.value,
        }

        this.state.changes[k] = res;
    }

    private renderField = (b: SNsiAreasBlockDTO, a: SNsiAreaDTO, f: SParameterDTO) => {
        let res = <></>;
        const disabled = f.readOnly || !this.props.nsi.editEnabled;
        let st = '';

        if (f.valueStatus === SParameterValuesStatusEnum.Alarm) {
            if (f.valueType === SParameterTypesEnum.Date || f.valueType === SParameterTypesEnum.DateTime) {
                st = ' ' +  styles.fieldAlarmDatePicker;
            } else {
                st = ' ' +  styles.fieldAlarm;
            }
        } else if (disabled)  {
            if (f.valueType === SParameterTypesEnum.Date || f.valueType === SParameterTypesEnum.DateTime) {
                st = ' ' +  styles.fieldReadOnlyDatePicker;
            } else {
                st = ' ' +  styles.fieldReadOnly;
            }
        }

        if (f.valueList != undefined) {
            res = (
                <Row className={styles.FormRowWrapper} key={f.parameterCode}>
                    {f.title}
                    <Select className={styles.Width100} value={f.value} onChange={(value) => {}} size="small" >
                        {f.valueList.map(item => 
                            <Select.Option key={String(item)} value={item}
                                onChange={(val: any) => this.changeField(b, a, f, val)}
                            >
                                {String(item)}
                            </Select.Option>)
                        }
                    </Select>
                </Row>
            )
        } else if (f.valueType === SParameterTypesEnum.Text) {
            res = (
                <Row className={styles.FormRowWrapper} key={f.parameterCode}>
                    <div style={{fontWeight: 'bold'}}>{f.title}</div>
                    <Input.TextArea disabled={disabled} className={styles.Width100 + st} rows={5} value={f.value as any}
                        onChange={val => val.target.value !== '' ? this.changeField(b, a, f, val.target.value) : this.changeField(b, a, f, null)}
                    />
                </Row>
            )
        } else if(f.valueType === SParameterTypesEnum.String) {
            res = (
                <Row className={styles.FormRowWrapper} key={f.parameterCode}>
                    <Col span={12}>
                        <span>{f.title}</span>
                    </Col>
                    <Col span={12}>
                        <Input disabled={disabled} className={styles.Width100 + st} value={(f.value as any) ?? ''} size='small' 
                            onChange={val => val.target.value !== '' ? this.changeField(b, a, f, val.target.value) : this.changeField(b, a, f, null)}
                        />
                    </Col>
                </Row>
            )
        } else if(f.valueType === SParameterTypesEnum.Boolean) {
            res = (
                <Row className={styles.FormRowWrapper} key={f.parameterCode}>
                    <Col span={12}>
                        <span>{f.title}</span>
                    </Col>
                    <Col span={12}>
                        <Checkbox disabled={disabled} className={styles.Width100 + st} checked={f.value as any} 
                            onChange={val => this.changeField(b, a, f, val.target.checked)}
                        />
                    </Col>
                </Row>
            )
        } else if(f.valueType === SParameterTypesEnum.Date) {
            res = (
                <Row className={styles.FormRowWrapper} key={f.parameterCode}>
                    <Col span={12}>
                        <span>{f.title}</span>
                    </Col>
                    <Col span={12}>
                        <DatePicker disabled={disabled} className={styles.Width100 + st} value={f.value != null ? moment(f.value as any) : undefined} size='small'
                            format={'DD.MM.YYYY'}
                            onChange={val => this.changeField(b, a, f, val ? toDate(val?.toDate()) : null)}
                        />
                    </Col>
                </Row>
            )
        } else if(f.valueType === SParameterTypesEnum.DateTime) {
            res = (
                <Row className={styles.FormRowWrapper} key={f.parameterCode}>
                    <Col span={12}>
                        <span>{f.title}</span>
                    </Col>
                    <Col span={12}>
                        <DatePicker disabled={disabled} className={styles.Width100 + st} value={f.value != null ? moment(f.value as any) : undefined} showTime size='small'
                            format={'DD.MM.YYYY HH:mm:ss'}
                            onChange={val => this.changeField(b, a, f, val?.toDate() ?? null)}
                        />
                    </Col>
                </Row>
            )
        } else if(f.valueType === SParameterTypesEnum.Number) {
            res = (
                <Row className={styles.FormRowWrapper} key={f.parameterCode}>
                    <Col span={12}>
                        <span>{f.title}</span>
                    </Col>
                    <Col span={12}>
                        <InputNumber disabled={disabled} className={styles.Width100 + st} value={f.value as any} size='small' 
                            onChange={(val) => this.changeField(b, a, f, val)}
                        />
                    </Col>
                </Row>
            )
        }


        return res;
    }

    public render() {
        const clientHeight = this.props.page.windowHeight - 200; 
        return (
            <div className={styles.container}>
                {
                    this.props.nsi.isLoadingNsi === true || this.props.nsi == undefined ? null :
                    <Tabs type="card" tabBarStyle={{marginBottom: '0px'}} onChange={tabKey => tabKey === '2' ? () => {} : null }
                        items= {
                            this.state.pages?.blocks.map(b => {
                                return (
                                    { label: b.title, key: b.channelCode === '' ? 'k1' : b.channelCode, className: styles.TabBorder, children:
                                        <div className={styles.TabContainer} style={{height: clientHeight}}>
                                        {
                                            <div className={stylesNsi.container}>
                                                <div style={{fontWeight: 'bold', marginLeft: '5px'}}>{'Наименование точки: ' + this.state.pages?.pointName}</div>
                                                <div className={stylesNsi.areaBlock}>
                                                    <div className={stylesNsi.areaBlockCol} key={'col1'}>
                                                        {
                                                            b.areas.filter((x, index) => index === 0).map(a => {
                                                                return (
                                                                    <div className={stylesNsi.area} key={a.groupCode === '' ? 'k1' : a.groupCode}>
                                                                        <Input.Group>
                                                                        {
                                                                            <>
                                                                            {
                                                                                <Row className={styles.FormRowWrapper + ' ' + stylesNsi.areaTitle}>{a.title}</Row>
                                                                            }
                                                                            {                                                                
                                                                                a.fields.map(f => this.renderField(b, a, f))
                                                                            }
                                                                            </>
                                                                        }
                                                                        </Input.Group>
                                                                    </div>
                                                                )
                                                            })
                                                        }
                                                    </div>
                                                    <div className={stylesNsi.areaBlockCol} key={'col2'}>
                                                        {
                                                            b.areas.filter((x, index) => index > 0).map(a => {
                                                                return (
                                                                    <div className={stylesNsi.area} key={a.groupCode === '' ? 'k1' : a.groupCode}>
                                                                        <Input.Group>
                                                                        {
                                                                            <>
                                                                            {
                                                                                <Row key={'k1'} className={styles.FormRowWrapper + ' ' + stylesNsi.areaTitle}>{a.title}</Row>
                                                                            }
                                                                            {                                                                
                                                                                a.fields.map(f => this.renderField(b, a, f))
                                                                            }
                                                                            </>
                                                                        }
                                                                        </Input.Group>
                                                                    </div>
                                                                )
                                                            })
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                        </div>
                                    }
                                )
                            })
                        }
                        
                    />
                }
            </div>
        );
    }
}

export default connect(
    (state: ApplicationState) => {
        return {
            page: state.page,
            profile: state.userProfile?.profile,
            nsi: state.nsi,
            pointId: state.page.pointId,
        }
    },
    actionCreators
)(Nsi as any);
