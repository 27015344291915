export const RESPONSE_STATUS_NONE = 0;
export const RESPONSE_STATUS_OK = 1;
export const RESPONSE_STATUS_NO = 2;
export const RESPONSE_STATUS_ERROR = 3;

export const PROFILE_COMMON = 'Common';
export const PROFILE_CABINET = 'Cabinet';
export const PROFILE_ABONENT_OPERATIONS = 'AbonentOperations';
export const PROFILE_KADR = 'Kadr';
export const PROFILE_MNEMOSCHEMA = 'Mnemoschema';
export const PROFILE_REPORTS = 'Reports';
export const PROFILE_CHART = 'Chart';
export const PROFILE_MAP = 'Map';
export const PROFILE_METROLOGY = 'Metrology';
export const PROFILE_DEVICELOG = 'DeviceLog';
export const PROFILE_STAT = 'Stat';
export const PROFILE_SENSORS = 'Sensors';
export const PROFILE_MONTHLY_REPORTS = 'MonthlyReports';
export const PROFILE_ADMIN = 'Admin';

export const SET_IS_LOADING_COMMAND = "setisloading";
export const PROFILE_GET_COMMAND = "profileget";
export const PROFILE_SET_COMMAND = "profileset";
export const DATA_RESET_DONE_COMMAND = "dataresetdone";
export const CLOSE_SESSION_COMMAND = "CloseSession";
export const MESSAGE_SHOW_COMMAND = "MessageShow";
export const INVALIDATE_COMMAND = "Invalidate";
export const REFRESH_COMMAND = "Refresh";
export const REGISTER_NETIROOT_COMMAND = "NetiRootSet";
export const SET_ACTIVE_QUERIES_COMMAND = "ActiveQueriesSet";
export const ON_COLUMNS_REORDER_COMMAND = "OnColumnsReorder";
export const ON_COLUMNS_SELECT_COMMAND = "OnColumnsSelect";
export const ON_KADRCOLUMNS_REORDER_COMMAND = "OnKadrColumnsReorder";
export const POINT_SELECT_COMMAND = "abonentselect";
export const RIGHTS_GET_COMMAND = "rightsget";
export const SET_RESOURCES_COMMAND = "setresources";
export const SET_PAYMENTTYPES_COMMAND = "setpaymenttypes";
export const SET_PACKET_MODE_COMMAND = "setpacketmode";
export const IMAGE_LOADER_SHOW_COMMAND = "ImageLoaderShow";
export const POINTEDITOR_SHOW_COMMAND = "PointEditorShow";
export const SHOW_TASK_LOG_COMMAND = "showtasklog";

export const ALL_GROUP_ID = "all";
export const ALL_RESOURCE_ID = "all";

export const SEND_TYPE_ID_EMAIL = 3;
export const SEND_TYPE_NAME_TELEGRAM = 'TelegramBot';
export const SENDING_REPORT_REPORTING_INTERVAL = 2;

export const ABONENTS_DAY_SEND_MAX_COUNT_DEFAULT = 3;

export const PROPERTY_COLUMNS_LAYOUT = "CulumnsLayout";

export const MODAL_WRAP_CLASSNAME =  "ui-front";

export const CONNECTIONS_TCPCLIENT = 'Connections_TCPClient';
export const CONNECTIONS_TCPIP = 'Connections_TCPIP';
export const CONNECTIONS_TCPIP_BYPORT = 'Connections_TCPIPbyPort';

export let ClientMode = 'TTT';
export let COLORS = {
    PrimaryColor: '#0C569B',
    SvgIconColor: '#0C569B'
}

export let showMessagers = true;
export let phone = {
    ref: 'tel:+74956727213',
    title: '+7(495)672-72-13'
};
export let email = {
    ref: 'mailto:info@teplo-inform.ru?Subject=General',
    title: 'info@teplo-inform.ru'
};
export const setClientMode = () => {
    ClientMode = window.glob.ClientMode;
    if(ClientMode === 'PM'){

        showMessagers = false;

        phone = {
            ref: 'tel:+74957756008',
            title: '+7(495)775-60-08'
        };

        email = {
            ref: 'mailto:support@promodem.ru',
            title: 'support@promodem.ru'
        };

        COLORS = {
            PrimaryColor: '#231F20',
            SvgIconColor: '#2DA44A'
        };
    }
    else{
        showMessagers = true;

        phone = {
            ref: 'tel:+74956727213',
            title: '+7(495)672-72-13'
        };

        email = {
            ref: 'mailto:info@teplo-inform.ru?Subject=General',
            title: 'info@teplo-inform.ru'
        };

        COLORS = {
            PrimaryColor: '#0C569B',
            SvgIconColor: '#0C569B'
        };
    }
}

