//SelectForm
// Форма для выбора групп или точек учёта из дерева текущено пользователя или из списка
// групп по заданному фильтру.
//onChange: (groupIds: string[], pointIds: string[]) => void;
//onChangePoints?: any;
//onChangeGroups?: any;

import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { Input, Col, Row, Space, Switch, List, Checkbox, Divider, InputNumber, Modal } from 'antd';
import { LoadingOutlined,SettingTwoTone } from '@ant-design/icons';
import type { CheckboxChangeEvent } from 'antd/es/checkbox';

import { Group, Point, Resource, Command } from "../../decl";
import { removeValue, getAllSelectedPoints, getParentSelectedGroups, getFreeIds, NodeTypesEnum } from "../../utils/TreeUtils";
import {message } from '../../utils/Common';
import PointsTree from "../PointsTree";
import Resources from '../Resources';
import * as Const from '../../utils/Const';
import { sendRequestToBackend } from '../../utils/AuthUtils';

interface ISelectFormProps{
    title?: string,
    visible: boolean;
    zIndex: number,
    clientHeight?: number,
    groups: Group[];
    groupPoints: {[groupId: string] : Point[]};
    selectedGroupIds: string[];
    selectedPointIds: string[];
    getPoints: any;
    getMultipleGroupPoints?: any, 
    getPointsParentGroups: any;
    setIsLoading: any;
    onlyGroupsSelect? : boolean;
    onChange: (groupIds: string[], pointIds: string[]) => void;
    onChangePoints?: (points: Point[]) => void;
    onChangeGroups?: (groups: Group[]) => void;
    onClose: any,
    resources?: Resource[],
    onlyTree? : boolean,
    commands?: {[nodeType in NodeTypesEnum] : Command[]};    //Описание команд контекстного меню.
    additionalNodeTitle?: ((ob:Point | Group | null)=>string)
}

type CheckAllState = {
    checkAll: boolean,
    immediate: boolean
}

interface ISelectFormState{
    filter: string,
    selectedResourceIds: string[],
    showList: boolean,
    allGroups: Group[],
    allPoints: Point[],
    isReadyList: boolean,
    maxListItems: number,
    checkAll: CheckAllState

}

//Дерево точек учёта.
export default class SelectForm extends React.PureComponent<ISelectFormProps, ISelectFormState> {
    constructor(props: ISelectFormProps) {
        super(props);

        this.state = {
            filter: '',
            selectedResourceIds: [Const.ALL_RESOURCE_ID],
            showList: false,
            allGroups: [],
            allPoints: [],
            isReadyList: false,
            maxListItems: 40,
            checkAll: {checkAll: false, immediate: false}
        }

    }
    componentDidMount() {
        //console.log('SelectedForm: ', 'DidMount');
    }
    componentDidUpdate(prevProps: ISelectFormProps) {
        if(this.props.visible && !prevProps.visible){
            this.setState({showList: false});
            this.reloadCache();
        }
        if(this.props.selectedGroupIds != prevProps.selectedGroupIds || this.props.selectedPointIds != prevProps.selectedPointIds){
            this.setState({checkAll: this.getCheckAllState(this.state.maxListItems)});
        }
    }

    componentWillUnmount() {
        //console.log('SelectedForm: ', 'DidUnmount');
    }

    reloadCache = ()=>{
        this.setState({allGroups: [], allPoints: [], isReadyList: false});
        if(this.props.onlyGroupsSelect){

            sendRequestToBackend(
                {},
                'points/allgroups',
                (response: any) => {
                    if (response != null) {
                        this.setState({allGroups: response});
                    } else {
                        message.warning("Не удалить получить все доступные группы пользователя!");
                    }
                },
                (isLoading: boolean) => {
                    this.setState({isReadyList: !isLoading})
                },
                (error: any) => {
                    message.warning("Ошибка при получении всех групп.");
                    console.log(error);
                }
            );
        }
        else {
            sendRequestToBackend(
                {},
                'points/allpoints',
                (response: any) => {
                    if (response != null) {
                        this.setState({allPoints: response});
                    } else {
                        message.warning("Не удалить получить все точки учёта!");
                    }
                },
                (isLoading: boolean) => {
                    this.setState({isReadyList: !isLoading})
                },
                (error: any) => {
                    message.warning("Ошибка при получении всех точек учёта.");
                    console.log(error);
                }
            );
        }
    }

    getList = (items: Point[] | Group[]) => {
        if(this.props.onlyGroupsSelect && this.state.showList){
            const allGroups: Group[] = items as Group[];
            return  (this.state.isReadyList ?  
                <List
                    grid={{ gutter: 16, column: 4 }}
                    dataSource={allGroups}
                    renderItem = {
                        (item)=>(<List.Item><Checkbox checked={this.props.selectedGroupIds.indexOf(item.id)>=0} onChange={
                            (e:CheckboxChangeEvent)=>{this.onCheckGroup(item.id, e.target.checked)}
                        }>{item.name} </Checkbox></List.Item>)
                    }
                /> :
                <LoadingOutlined />);
        }
        else if(this.state.showList){
            const allPoints: Point[] = items as Point[];
            return  (this.state.isReadyList ?  
                <List
                    grid={{ gutter: 16, column: 4 }}
                    dataSource={allPoints}
                    renderItem = {
                        (item)=>(<List.Item><Checkbox checked={this.props.selectedPointIds.indexOf(item.id)>=0} onChange={
                            (e:CheckboxChangeEvent)=>{this.onCheckPoint(item.id, e.target.checked)}
                        }>{item.number} </Checkbox></List.Item>)
                    }
                /> :
                <LoadingOutlined />);
        }
    }

    getVisibleItems = (maxItems: number) => {
        const lowFilter = this.state.filter ? this.state.filter.toLowerCase() : '';
        const result = this.props.onlyGroupsSelect ? 
            this.state.allGroups.filter(g=>g.name.toLowerCase().includes(lowFilter)).slice(0, maxItems) :
            this.state.allPoints.filter(
            p=>p.number.toLowerCase().includes(lowFilter) && 
                (this.state.selectedResourceIds.includes(Const.ALL_RESOURCE_ID) ||
                this.state.selectedResourceIds.includes(p.resourceTypeId.toString()))
        ).slice(0, maxItems);
        return result;
    }

    getCheckAllState = (maxItems: number) => {
        const selectedIds: string[] = this.props.onlyGroupsSelect ? this.props.selectedGroupIds : this.props.selectedPointIds;
        const all = this.getVisibleItems(maxItems);

        let existChecked = false;
        let existUnchecked = false; 
        all.forEach(item=>{
            const checked = selectedIds.includes(item.id);
            if(checked){
                existChecked = true;
            }
            else{
                existUnchecked = true;
            }
        });

        return {
            checkAll: existChecked,
            immediate: existChecked && existUnchecked
        }
    }

    onOk = () => {
        let groupIds: string[] = this.props.selectedGroupIds.slice();
        let pointIds: string[] = this.props.selectedPointIds.slice();

        if(this.props.onlyGroupsSelect && this.props.onChangeGroups){
            const groups: Group[] = getParentSelectedGroups(this.props.groups, this.state.allGroups, groupIds);

            this.props.onChangeGroups(groups);
        }
        else if(!this.props.onlyGroupsSelect && this.props.onChangePoints){
            //this.props.onChangePoints(this.props.selectedPointIds);
            this.props.setIsLoading(true);
            getAllSelectedPoints(this.props.groups, groupIds, pointIds, this.props.getPoints)
            .then(allPoints => {
                this.props.setIsLoading(false);
                if(this.props.onChangePoints){
                    //Добавить точки из списка, если их нет в дереве.
                    pointIds.forEach(id=>{
                        if(allPoints.find(p=>p.id === id) === undefined){
                            const point = this.state.allPoints.find(p=>p.id===id);
                            if(point !== undefined) allPoints.push(point);
                        }
                    });
                    this.props.onChangePoints(allPoints);
                }
            });
        }
    };
    onCancel = () => {
        this.props.onClose();
    };

    //Изменение чекбокса в дереве групп или точек учёта.
    onChangeTree = (groupIds: string[], pointIds: string[])=> {
        if(this.props.onlyGroupsSelect){
            const freeIds: string[] = getFreeIds(this.props.groups, this.props.selectedGroupIds);
            this.props.onChange(groupIds.concat(...freeIds), pointIds);
        }
        else{
            this.props.onChange(groupIds, pointIds);
        }
    }

    //Изменение чекбокса в списке групп.
    onCheckGroup = (id: string, check: boolean)=>{
        const selectedIds = [...this.props.selectedGroupIds];
        if(check && selectedIds.indexOf(id) < 0){
            selectedIds.push(id);
        }
        else{
            removeValue(selectedIds, id);
        }
        this.props.onChange(selectedIds, []);
    }

    //Изменение чекбокса в списке точек учёта.
    onCheckPoint = (id: string, check: boolean)=>{
        const selectedIds = [...this.props.selectedPointIds];
        if(check && selectedIds.indexOf(id) < 0){
            selectedIds.push(id);
        }
        else{
            removeValue(selectedIds, id);
        }
        this.props.onChange([], selectedIds);
    }
    onCheckAll = (check: boolean) =>{
        const all = this.getVisibleItems(this.state.maxListItems);
        if(this.props.onlyGroupsSelect){
            const selectedIds = [...this.props.selectedGroupIds];
            all.forEach(item=>{
            if(check && selectedIds.indexOf(item.id) < 0){
                selectedIds.push(item.id);
            }
            else{
                removeValue(selectedIds, item.id);
            }
                this.props.onChange(selectedIds, []);
            });
        } else{
            const selectedIds = [...this.props.selectedPointIds];
            all.forEach(item=>{
            if(check && selectedIds.indexOf(item.id) < 0){
                selectedIds.push(item.id);
            }
            else{
                removeValue(selectedIds, item.id);
            }
                this.props.onChange([], selectedIds);
            });
        }
    }

    render() {
        const ResourcesProps = {
            resources: this.props.resources ?? [],
            selectedResourceIds: this.state.selectedResourceIds,
            onChange:  (value: any) => {
                console.log('SELECTFORM ONCHANGE:', value);

                this.setState({
                    selectedResourceIds: value
                });
            }
        };

        const checkResources = (this.state.selectedResourceIds.indexOf(Const.ALL_RESOURCE_ID) < 0);
        const isAllowedCheck: ((ob: Point | Group) => boolean) | undefined = (this.state.filter || checkResources) ?
             (ob: Point | Group)=>{
                let result = true;
                if((ob as Point).number !== undefined){
                    const number = (ob as Point).number.toLowerCase();
                    result = ( number.includes(this.state.filter.toLowerCase()) && (!checkResources ||
                    this.state.selectedResourceIds.indexOf((ob as Point).resourceTypeId.toString()) >= 0));
                }
                else if((ob as Group).name !== undefined && this.state.filter){
                    const name = (ob as Group).name.toLowerCase();
                    result = name.includes(this.state.filter.toLowerCase());
                }
                return result;
             }
             :
            undefined;
        
        const bodyHeight = (this.props.clientHeight??500);
        const treeHeight = bodyHeight - 80;
        const listItems = this.state.showList ? this.getVisibleItems(this.state.maxListItems) : [];
        const allCount = this.props.onlyGroupsSelect ? this.state.allGroups.length : this.state.allPoints.length;
        return <Modal
            title= {<span><SettingTwoTone twoToneColor={Const.COLORS.SvgIconColor} /><span style={{marginLeft:10}}>{this.props.title??"Дерево точек учёта"}</span></span>}
            open={this.props.visible}
            onOk={this.onOk}
            onCancel={this.onCancel}
            wrapClassName ={Const.MODAL_WRAP_CLASSNAME}
            zIndex={this.props.zIndex}
            bodyStyle={{height: bodyHeight}}
            centered
            >
            <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
            <Row>
                <Col span = {8}>
                    <Input 
                        placeholder= {this.props.onlyGroupsSelect ? "Фильтр для групп" : "Фильтр для точек учёта"} 
                        value={this.state.filter}  
                        onChange = {(e)=>{
                            this.setState({filter:e.target.value, checkAll:{checkAll:false, immediate:false}});
                        }} 
                    />
                </Col>
                <Col span = {2}/>
                <Col span = {6}>
                    {!this.props.onlyGroupsSelect && <Resources {...ResourcesProps} />}
                </Col>
                <Col span = {2}/>
                <Col span= {6}>
                    {(this.props.onlyTree === undefined || this.props.onlyTree === false) &&
                        <Switch
                            unCheckedChildren=  "Дерево"
                            checkedChildren="Список"
                            style={{width: '135px'}}
                            checked={this.state.showList}
                            onChange={checked => {this.setState({showList:checked})}}
                        />
                    }
                </Col>
            </Row>
            {this.state.showList ?
                <>
                    <Row gutter={8}>
                        <Col span={8}>
                            <Checkbox
                                indeterminate={this.state.checkAll.immediate} 
                                checked={this.state.checkAll.checkAll}
                                onChange={(e:CheckboxChangeEvent)=>this.onCheckAll(e.target.checked)}
                            >
                                {'Выбрать все '  + listItems.length.toString() + ' из ' + allCount.toString()}
                            </Checkbox>
                        </Col>
                        <Col span={11}>
                            {'Макс. число отображаемых ' + (this.props.onlyGroupsSelect ? 'групп:' : 'точек учёта:')}
                        </Col>
                        <Col span={5}>
                            <InputNumber size="small" min={10} max={200} step={10} defaultValue={this.state.maxListItems} onChange={(value:number|null)=>{
                                const maxItems = value??10
                                const allState = this.getCheckAllState(maxItems);
                                this.setState({maxListItems:maxItems, checkAll: allState});
                            }}/>
                        </Col>
                    </Row>

                    <Divider />
                    <div style={{overflowY: 'auto', overflowX: 'hidden', height:treeHeight-100}}>
                    {this.getList(listItems)}
                    </div>
                </> 
                : 
                <PointsTree
                    height={treeHeight}
                    groups={this.props.groups}
                    groupPoints={this.props.groupPoints}
                    selectedGroupIds={this.props.selectedGroupIds}
                    selectedPointIds={this.props.selectedPointIds}
                    getPoints={this.props.getPoints}
                    getMultipleGroupPoints={this.props.getMultipleGroupPoints}
                    getPointsParentGroups={this.props.getPointsParentGroups}
                    onChange={ this.onChangeTree}
                    setIsLoading={this.props.setIsLoading}
                    onlyGroupsSelect={this.props.onlyGroupsSelect}
                    onClose={this.props.onClose}
                    isAllowedCheck = {isAllowedCheck}
                    additionalTitle = {this.props.additionalNodeTitle}
                    fullLoading = {true}
                    commands = {this.props.commands}
                />
            }
            </Space>
         </Modal> 
    }
}
