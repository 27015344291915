import { message } from "./Common";
import jwt_decode from 'jwt-decode';
import { ResponseStatusesEnum, SResponseDTO } from "../decl"

import {
    RESPONSE_STATUS_OK
} from "./Const";

export const BACKEND_URL = window.configTI.URL_BACKEND;

//Флаг ожидания ответа на запрос обновления токена для предотвращения отправки повторного запроса.
let refreshInProgress = false;

export const getHeaders = (custom) => {
    return window.glob.getHeaders(custom);
}

//Получение информации о пользователе.
export const getUser = async() => {
    const response = await fetch(
        BACKEND_URL + 'login', {
            headers: getHeaders(),
            credentials: 'include'
        }
    );
    const json = await response.json();
    console.log(json);
    return json;
}

//Вход в личный кабинет.
//inputCredentials: {User: <userName>, Password: <password>}
export const login = async(inputCredentials) => {
    var winService = glob.control_get('WinServiceId');

    var promize = new Promise((resolve, reject) => {
        const ok = (name, body) => {
            if (body.Token !== null) {
                setToken(body.Token, body.Code);
            } else {
                removeToken();
            }
            resolve(body.Done ? { ok: true, message: 'Вход выполнен.' } : { ok: false, message: body.Message });
        };
        const err = (name, error) => {
            console.log(error);
            removeToken();
            reject({ ok: false, message: error });
        };

        var cmd = new sc_command(
            null,
            'EnterExt',
            inputCredentials,
            ok,
            err
        );
        winService.Execute(cmd);
    });

    var result = await promize;

    return result;
}

const getShareID = () => {
    let result = undefined;
    const url = window.location.href;
    console.log("href=", url);
    //href= http://localhost:55257/?u=MzdWNzE0aWNyVWlKS2ZlTTdVK2xBQT09
    const nP = url.indexOf('/?u=');
    if (nP > 0) {
        result = url.substring(nP + 4);
    }

    return result;
}

//Попытка входа при наличии идентификатора разделяемого идентификатора.
//После выполнения попытки вызывается колбэк с параметром boolean - true, если вход выполнен.
export const loginByShareID = (fCallback) => {
    const shareID = getShareID();
    console.log('ShareID: ', shareID);
    if (shareID) {
        const requestOptions = {
            method: 'POST',
            headers: getHeaders(),
            body: JSON.stringify({ shareID: shareID })
        };

        fetch(BACKEND_URL + 'profile/sharelogin', requestOptions)
            .then(response => response.json())
            .then(data => {
                if (data.bodyStatus == ResponseStatusesEnum.Ok) {
                    const token = data.body;
                    if (!token) {
                        console.log('TOKEN IS NULL');
                        fCallback(false);
                    } else {
                        setToken(token, null);
                        fCallback(true);
                    }
                } else {
                    console.log("Не удалось подключиться к серверу", data);
                    message.error("Не удалось подключиться к серверу. Выполните вход.");
                    fCallback(false);
                }
            })
            .catch(error => {
                message.error('Ошибка:' + error);
                console.log(error);
                fCallback(false);
            });
    } else {
        fCallback(false);
    }
}

const asyncNetiCommand = (name, params, resolve, reject) => {

}



export const exit = async() => {
    var winService = glob.control_get('WinServiceId');

    var promize = new Promise((resolve, reject) => {
        const ok = (name, body) => {
            removeToken();
            resolve(body.Done ? { ok: true, message: 'Выход выполнен.' } : { ok: false, message: body.Message });
        };
        const err = (name, error) => {
            console.log(error);
            reject({ ok: false, message: error });
        };

        var cmd = new sc_command(
            null,
            'ExitExt',
            null,
            ok,
            err
        );
        winService.Execute(cmd);
    });

    var result = await promize;

    return result;
}


export const exitToUrl = (setIsLoading, closeSession, url) => { 
    setIsLoading(true);
    exit().then(
        result => {
            if (result.ok) {
                closeSession("Сессия закрыта.");
                window.location.assign(url);
            }
            else {
                message.error("Ошибка при попытке выполнить выход из системы.Сообщите администратору.");
                setIsLoading(false);
                console.log(result.message);
            }
        },
        error => {
            setIsLoading(false);
            message.error("Ошибка при попытке выполнить выход  из системы. Сообщите администратору.");
            console.log(error);
        }
    );
};

//Выполнение запроса к серверу с таймаутом.
export async function fetchWithTimeout(resource, options = {}) {
    const { timeout = 8000 } = options;
    
    const controller = new AbortController();
    const id = setTimeout(() => controller.abort(), timeout);
    const response = await fetch(resource, {
      ...options,
      signal: controller.signal  
    });
    clearTimeout(id);
    return response;
  }

//Получение данных из бакэнда.
//  request - Запрос.
//  url - Относительный адрес.
//  fOnOk - Функция получения результатов f(response) или undefined или null.
//  fSetIsLoading - Функция включения/отключения ожидания выполнения запроса f(boolean, waitDelay | undefined)
//                     или undefined или null.
//  fOnError - Функция обработки ошибки при обработке запроса f(message)  или undefined  или null.
//            Если функция не задана выдаётся сообщение об ошибке, если задана то в случае отказа
//            сервера выполнить запрос, вызывается с сообщением об ошибке. В случае ошибки на клиенте,
//            вызывается функция onError, если задана и пишется сообщение в консоль.
//  waitDelay - Время задержки включения/отключения режима ожидания выполнения запроса или undefined.
export const sendRequestToBackend = (request, url, fOnOk, fSetIsLoading, fOnError, fCloseSession, waitDelay, isAutoRequest) => {
    //Функция отображения ошибки.
    const fShowError = (errMessage) => {
        if (fOnError == null) {
            message.error(errMessage);
        } else {
            fOnError(errMessage);
        }
    }

    //Опции запроса.
    const requestOptions = (request != null) ?  {
        method: 'POST',
        headers: isAutoRequest ? getHeaders({ "Auto-Request": "true" }) : getHeaders(),
        body: JSON.stringify(request)
    } :
    {
        method: 'POST',
        headers: isAutoRequest ? getHeaders({ "Auto-Request": "true" }) : getHeaders(),
    };

    //Установка ожидания выполнения запроса.
    if (fSetIsLoading != null) {
        fSetIsLoading(true, waitDelay);
    }
    fetch(BACKEND_URL + url, requestOptions)
        .then(response => response.json())
        .then(data => {
            if (data.bodyStatus && data.bodyStatus == ResponseStatusesEnum.Ok && fOnOk != null) {
                fOnOk(data.body);
            } else if (data.bodyStatus && data.bodyStatus == ResponseStatusesEnum.Error) {
                fShowError(data.message);
            } else if (data.bodyStatus && data.bodyStatus == ResponseStatusesEnum.SessionClosed && fCloseSession) {
                fCloseSession(data.message);
            } else {
                console.log(data);
                fShowError('Ответ не получен.');
            }
            //Сброс ожидания выполнения запроса.
            if (fSetIsLoading != null) {
                fSetIsLoading(false, waitDelay);
            }
        })
        .catch(error => {
            //Сброс ожидания выполнения запроса.
            if (fSetIsLoading != null) {
                fSetIsLoading(false, waitDelay);
            }
            fShowError('Ошибка:' + error);
            console.log(error);
        });

}

    export const downloadFile = (request, url, fileName, fOnOk, fSetIsLoading, fOnError, waitDelay) => {
    //Опции запроса.
    const requestOptions = {
        method: 'POST',
        headers: getHeaders(),
        body: JSON.stringify(request)
    };
    //Установка ожидания выполнения запроса.
    if (fSetIsLoading != null) {
        fSetIsLoading(true, waitDelay);
    }

        fetch(BACKEND_URL + url, requestOptions)
        .then(response => {
            response.blob().then(blob => {
                let url = window.URL.createObjectURL(blob);
                let a = document.createElement('a');
                a.href = url;
                a.download = fileName;
                a.click();
            });
            if(fOnOk){
                fOnOk();
            }
            //Сброс ожидания выполнения запроса.
            if (fSetIsLoading != null) {
                fSetIsLoading(false, waitDelay);
            }

        }).catch(error => {
            //Сброс ожидания выполнения запроса.
            if (fSetIsLoading != null) {
                fSetIsLoading(false, waitDelay);
            }
            if(fOnError){
                fOnError(error);
            }
            else{
                message.error('Ошибка:' + error);
            }
            console.log(error);
        });
    }


//POST запрос.
export const postRequest = async(url, request, okMessage, errorMessage) => {
    //console.log("request: ", request);

    const response = await fetch(
        BACKEND_URL + url, {
            method: "POST",
            mode: "cors",
            //credentials: 'include',
            headers: getHeaders(),
            //headers: {
            //    'Accept': 'application/json',
            //    'Content-Type': 'application/json',
            //},
            body: JSON.stringify(request)
        }
    );
    const result = await response.json();
    console.log(result);

    if (result.status === undefined) {
        message.error('Ошибка при выполнении запроса. ' + JSON.stringify(result));
    } else if (result.status === RESPONSE_STATUS_OK) {
        message.success(okMessage ? okMessage : 'Операция выполнена.');
    } else {
        message.error(errorMessage ? errorMessage : 'Операция не выполнена. ' + result.message);
    }

    return result;
}

//GET запрос.
export const getRequest = async(url) => {
    const response = await fetch(
        BACKEND_URL + url, {
            headers: getHeaders(),
            credentials: 'include'
        }
    );
    const json = await response.json();
    //console.log(json);
    return json;
}

export const formatNumber = (value, n) => {
    let output;
    if (value == null) {
        output = '-';
    } else {
        if (typeof value === 'string') {
            let number = parseFloat(value);
            output = number.toFixed(n);
        } else if (typeof value === 'number') {
            output = value.toFixed(n);
        } else {
            output = value;
        }
    }
    return output;
}

export function formatTime(time) {
    var d = new Date(time);
    let date = formatDate(d);
    let hours = '' + d.getHours();
    let minutes = '' + d.getMinutes();

    if (hours.length < 2)
        hours = '0' + hours;
    if (minutes.length < 2)
        minutes = '0' + minutes;

    return date + ' ' + hours + ':' + minutes;
}

export function formatDate(date) {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2)
        month = '0' + month;
    if (day.length < 2)
        day = '0' + day;

    return [year, month, day].join('.');
}

export function gotoASPSite(fOk, fError) {
    //Получить идентификатор перехода.
    const requestOptions = {
        method: 'GET',
        headers: getHeaders()
    };

    fetch(BACKEND_URL + 'profile/share', requestOptions)
        .then(response => response.json())
        .then(data => {
            if (data.bodyStatus && data.bodyStatus == ResponseStatusesEnum.Ok) {
                var url;
                if (window.configTI.URL_ASPSITE && window.configTI.URL_ASPSITE.length > 0) {
                    url = window.configTI.URL_ASPSITE + "?s=" + data.body;
                }
                if (url) {
                    console.log('url', url);
                    //if(fOk) fOk();
                    window.location.assign(url);
                    //window.location.replace(url);
                }
                else {
                    if(fError) fError();
                }
            } else {
                console.log(data);
                if(fError) fError(data);
            }
        })
        .catch(error => {
            if(fError) fError(error);
            console.log(error);
        });

}



export function getOldSiteUrl() {
    let result = '';
    const urlOld = window.configTI.URL_ASPSITE;
    const code = getUserCode();
    if (urlOld && urlOld.length > 0 && code) {
        result = urlOld + "Login.aspx?u=" + code;
    }
    return result;
}


const setToken = (token, code) => {
    window.glob.setToken(token, code);
}

const getToken = (fExit) => {
    return window.glob.getToken(fExit);
}

const getUserCode = () => {
    return window.glob.getUserCode();
}

const removeToken = () => {
    window.glob.removeToken();
}

export function refreshTokenQuery(fRefresh, fExit) {
    const savedTokens = getToken(fExit);
    if (!refreshInProgress && savedTokens !== null) {
        if (savedTokens === null) {
            console.log('Токен удалён или изменён!');
            removeToken();
            fExit('Токен пользователя удалён или изменён!');
        }
        else {
            refreshInProgress = true;
            //console.log('REFRESH', Date.now()/1000);
            const requestOptions = {
                method: 'POST',
                headers: getHeaders({ "Free-Cache": "true" }),
                body: JSON.stringify({ token: savedTokens.access_token, refreshToken: savedTokens.refresh_token })
            };
            fetch(BACKEND_URL + 'profile/refresh', requestOptions)
                .then(response => response.json())
                .then(data => {
                    refreshInProgress = false;
                    if (data.bodyStatus == ResponseStatusesEnum.Ok) {
                        const token = data.body;
                        const code = data.code;
                        if (!token) {
                            console.log('TOKEN_IS_NULL');
                        }
                        setToken(token, code);
                        fRefresh(token.login, token.lifeMinute);
                    } else {
                        console.log('Токен удалён!');
                        removeToken();
                        fExit();
                    }
                })
                .catch(error => {
                    refreshInProgress = false;
                    removeToken();
                    console.log(error);
                    fExit();
                });;
        }
    }
}


function isAuthenticated() {
    let result = false;
    const token = getToken();
    if (token) {
        result = !isTokenExpired(token.access_token);
    }
    return result;
}

function isTokenExpired(token) {
    try {
        const decoded = jwt_decode(token);
        if (decoded.exp < Date.now() / 1000) { // Checking if token is expired.N
            return true;
        } else
            return false;
    } catch (err) {
        return false;
    }
}
