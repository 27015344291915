//Получение данных для таблицы событий.

import { SResponseDTO, ResponseStatusesEnum, SPointsFilter, SPointEventDTO, Point, ICommand } from "../../decl"
import { getHeaders, BACKEND_URL } from '../../utils/AuthUtils';

export const EVENTS_FIRST_QUERY_COUNT = 200;
export const EVENTS_QUERY_COUNT = 10000;

const getDataFromBase = (request: any, url:string, setData: any, done: any) => {
    const requestOptions = {
        method: 'POST',
        headers: getHeaders(),
        body: JSON.stringify(request)
    };

    fetch(BACKEND_URL + url, requestOptions)
        .then(response => response.json() as Promise<SResponseDTO>)
        .then(data => {
            if (data.bodyStatus && data.bodyStatus == ResponseStatusesEnum.Ok) {
                setData(data.body);
            }
            else if (data.bodyStatus && data.bodyStatus == ResponseStatusesEnum.Error) {
            }
            else {
            }
            done();
        })
        .catch(error => {
            console.log(error);
            done();
        });

}

export const hasData = (data: any, n1: number, n2: number) =>{
    let result = true;
    for(let i = n1-1; i < n2 && i < data.length; i++){
        if(data[i] === undefined){
            result = false;
            break;
        }
    }
    return result;
}

export class ControlData{
    timer: any = undefined;
    rows: any = [];
    allCount: number = 0;
    _pointIds: string[] = [];
    _lastEventId: number = 0;
    _from: any;
    _to: any;
    _onlyNewEvents: boolean | undefined;

    nextLoadIndex: number = 0;
    isLoading: boolean = false;
    onLoaded: any = undefined;
    restartLoading = false;

    selectedPointIds: string[] = [];
    selectedPointsRows: any = [];
    selectedPointsCount: number = 0;

    constructor(){

    }

    dispose(){
        if (this.timer) {
            clearInterval(this.timer);
            this.timer = undefined;
        }
    }

    refresh = (pointIds: string[], allCount: number, lastEventId: number, from: any, to: any, onlyNewEvents: boolean, onLoaded: any) => {
        this.allCount = allCount;
        this.rows = new Array(allCount);
        this._pointIds = pointIds;
        this._lastEventId = lastEventId;
        this._from = from;
        this._to = to;
        this._onlyNewEvents = onlyNewEvents;

        this.selectedPointIds = pointIds;

        this.nextLoadIndex = 0;
        this.onLoaded = onLoaded;
        this.restartLoading = false;

        this.loadingStart();
    }

    loadingStart = () => {
        if (!this.timer && this.nextLoadIndex < this.rows.length) {
            const _this = this;
            this.timer = setInterval(() => {
                _this.loadNextData();
            }, 2000);
        }
    } 
    loadingStop = () => {
        clearInterval(this.timer);
        this.timer = undefined;
    } 

    selectPoints =(selectedPointIds: string[], selectedPointCount: number) => {
        console.log("selectPoints", selectedPointIds, selectedPointCount)
        this.selectedPointIds = selectedPointIds;
        this.selectedPointsCount = selectedPointCount;
        this.selectedPointsRows = new Array(selectedPointCount);

        let n: number = 0;
        this.rows.forEach((r:any)=>{
            if(r && selectedPointIds.includes(r.pointId)){
                this.selectedPointsRows[n] = r;
                n++;
            }
        })
    }

    reBuild = (acceptedEventIds: number[]) => {
        const newRows = new Array(this.allCount)
        let nextLoadIndex = 0;
        this.rows.every((event: any)=>{
            if(event === undefined){
                return false;
            } 
            else{
                if(this._pointIds.includes(event.pointId) && !acceptedEventIds.includes(event.key)){
                    newRows[nextLoadIndex] = event;
                    nextLoadIndex++;
                }
                return true;
            }
        })
        this.rows = newRows;
        this.nextLoadIndex = nextLoadIndex;
    }

    acceptEvents = (eventIds: number[]) => {
        this.allCount -= eventIds.length;
        this.reBuild(eventIds);
        if(this.selectedPointIds.length < this._pointIds.length){
        }
    }
    acceptPoints = (pointIds: string[], acceptEventsCount: number) => {
        this.allCount -= acceptEventsCount;
        this._pointIds = this._pointIds.filter((id => !pointIds.includes(id)));
        this.reBuild([]);
    }
    
    requestRows = (n1: number, n2:number, onLoad: any) => {
        if(this.selectedPointIds.length < this._pointIds.length){
            if(hasData(this.selectedPointsRows, n1, n2)){
                return true;
            }
            else{
                this.loadData(this.selectedPointIds, n1, n2, this.selectedPointsRows, () => {
                    onLoad(this.nextLoadIndex >= this.rows.length ? this.rows.length : this.nextLoadIndex, this.rows.length  );
                });                
            }
    
            return false;
        }
        else{
            if(hasData(this.rows, n1, n2)){
                return true;
            }
            else{
                this.loadData(this._pointIds, n1, n2, this.rows, () => {
                    if(this.nextLoadIndex + 1 >= n1 && this.nextLoadIndex < n2){
                        this.nextLoadIndex = n2;
                    }
                    onLoad(this.nextLoadIndex >= this.rows.length ? this.rows.length : this.nextLoadIndex, this.rows.length  );
                });                
            }
    
            return false;
    
        }
    }

    getRows = () =>{
        if(this.selectedPointIds.length < this._pointIds.length){
            return [...(this.selectedPointsRows)];
        }
        else{
            return [...(this.rows)];
        }
    }

    isReady = () => {
        return this.nextLoadIndex >= this.rows.length;
    }

    getPointCounts = (eventIds: number[]) => {
        const result: { [key: string]: number }  = {};
        eventIds.forEach(id=>{
            const event = this.rows.find((row:any)=>row.key === id);
            if(result[event.pointId]){
                result[event.pointId]++;
            }
            else{
                result[event.pointId] = 1;
            }
        });
        return result;
    }

    loadNextData = () => {
        if(!this.isLoading && this.nextLoadIndex < this.rows.length){
            const n1 = this.nextLoadIndex + 1;
            const n2 = this.nextLoadIndex + EVENTS_QUERY_COUNT;
            this.loadData(this._pointIds, n1, n2, this.rows, ()=>{
                if(this.nextLoadIndex + 1 >= n1 && this.nextLoadIndex < n2){
                    this.nextLoadIndex = n2;
                }
                this.onLoaded(this.nextLoadIndex >= this.rows.length ? this.rows.length : this.nextLoadIndex, this.rows.length  );
            });
        }
        if(this.nextLoadIndex >= this.rows.length){
            this.loadingStop();
        }
    }

    //Запрос данных с сервера для всех точек или только для выбранных.
    loadData = (pointIds: string[], n1: number, n2:number, target: any, onLoad: any) => {
        const request = {
            pointIds: pointIds,
            from: this._from,
            to: this._to,
            onlyNewEvents: this._onlyNewEvents,
            lastEventId: this._lastEventId,
            n1: n1,
            n2: n2

        };
        const _this = this;
        this.isLoading = true;
        getDataFromBase(
            request,
             'points/pointsevents3',
             (events: SPointEventDTO[])=>{
                if(_this.restartLoading){
                    
                }
                else{
                    let n = 0;
                    for(let i = n1-1; i < n2; i++){
                        const event: SPointEventDTO = events[n];
                        if(event){
    
                            target[i] = {
                                key: event.eventId,
                                time: event.eventTime,
                                object: event.pointNumber,
                                event: event.message,
                                status: event.eventType,
                                sourceType: event.sourceEventType,
                                eventTime: event.eventTime,
                                pointId: event.pointId
                            };
                        }
                        n++;
                    }
                    onLoad();
                }
             },
             ()=>{
                this.isLoading = false;
             });
    }
}