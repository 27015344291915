import * as React from 'react';

import { Modal, Input, Button } from 'antd';
import moment from 'moment';
import Draggable from 'react-draggable';

import { SQueryItemDTO } from '../../store/AdminStore';
import { MODAL_WRAP_CLASSNAME } from '../../utils/Const';
import { sendRequestToBackend } from '../../utils/AuthUtils';
import { message } from '../../utils/Common';

import { TaskStatusesEnum } from "./TasksForm";
import { supportsGoWithoutReloadUsingHash } from 'history/DOMUtils';

//Периодичность обращения к серверу.
const TIMER_INTERVAL: number = 500;

type SPollerInfoDTO = {
    serverName: string,
    serverIP: string,
    serverPort: number,
    threadId: number,
    taskId: number,
    taskStatus: TaskStatusesEnum,
    lastRetryTime: Date
}

type STaskLogDTO = {
    taskId: number,
    taskStatus: TaskStatusesEnum,
    log: string
}


type Props = {
    onHide(): void,
    queryItemId?: string | undefined;
    taskId: number
    title?: string,
    width?: number,
    zIndex: number
}

type State = {
    text: string,
    disabled: boolean,
    bounds: any,
    pollerInfo: SPollerInfoDTO | null,
    active: boolean,
    connected: boolean,
    waitServerAnswer: boolean
}

class OnlineLogForm extends React.Component<Props, State> {
    _timer: any;
    _textareaRef: any;

    constructor(props: Props, state: State) {
        super(props);
        this._textareaRef = React.createRef();
        this.state = {
            text: '',
            disabled: true,
            bounds: {
                left: 0, top: 0, bottom: 0, right: 0,
            },
            pollerInfo: null,
            active: false,
            connected: false,
            waitServerAnswer: false
        };

        this._timer = undefined;
    }

    componentDidMount() {
        if (!this._timer) {
            this._timer = setInterval(this.onTimer, TIMER_INTERVAL);
        }
    }

    componentWillUnmount() {
        if (this._timer) {
            clearInterval(this._timer);
            this._timer = undefined;
        }
    }

    componentDidUpdate(prevProps: Props, prevState: State) {
        //console.log('UPDATE: ', this.props, this.state);
        if (this.props.queryItemId !== prevProps.queryItemId) {
            this.setState({ pollerInfo: null, active: this.props.queryItemId !== undefined, connected: false });
        }
        else if (this.props.taskId !== prevProps.taskId) {
            this.setState({ pollerInfo: null, active: this.props.taskId > 0, connected: false });
        }

        if (this.state.text !== prevState.text) {
            this.scrollToBottom();
        }
    }

    onStart = (event: any, uiData: any) => {
        const { clientWidth, clientHeight } = window?.document?.documentElement;
        const targetRect = uiData.node?.getBoundingClientRect();

        this.setState({
            bounds:
            {
                left: -targetRect.left + uiData.x,
                right: clientWidth - (targetRect.right - uiData.x),
                top: -targetRect.top + uiData.y,
                bottom: clientHeight - (targetRect.bottom - uiData.y),
            }
        });
    }

    onTimer = () => {
        if (this.state.active && !this.state.waitServerAnswer) {
            if (this.state.connected) {
                this.updateLog();
            }
            else if (this.state.pollerInfo === null ||
                this.state.pollerInfo.taskStatus === TaskStatusesEnum.Processing) {
                this.connectToPoller();
            }
            else if (this.state.pollerInfo !== null && this.state.pollerInfo.taskId !== 0) {
                this.getTaskLog(this.state.pollerInfo.taskId, new Date(this.state.pollerInfo.lastRetryTime));
            }
        }
    }

    connectToPoller = () => {
        if (this.props.queryItemId || this.props.taskId > 0) {
            sendRequestToBackend(
                {
                    pointId: null,
                    queryId: this.props.queryItemId,
                    taskId: this.props.taskId
                },
                'admin/connecttopoller',
                (pollingInfo: SPollerInfoDTO | null) => {
                    const connected: boolean = pollingInfo !== null && pollingInfo.threadId !== 0;
                    const log = connected ? "Подключен." : pollingInfo !== null ? "Задание выполнено." : "Ожидание начала выполнения...";
                    this.setState({ pollerInfo: pollingInfo, connected: connected, text: log });
                },
                (isLoading: boolean) => {
                    this.setState({ waitServerAnswer: isLoading });
                },
                () => {
                },
                () => {

                },
                500
            );
        }

    }

    updateLog = () => {
        if (this.state.pollerInfo !== null && this.state.pollerInfo.threadId !== 0) {
            sendRequestToBackend(
                this.state.pollerInfo,
                'admin/onlinetasklogget',
                (taskLog: STaskLogDTO) => {
                    if (taskLog.taskStatus !== TaskStatusesEnum.Processing) {
                        //Поток отключился от задачи.
                        if (this.state.pollerInfo !== null) {
                            console.log(taskLog.log);
                            this.setState({
                                connected: false,
                                pollerInfo: { ...this.state.pollerInfo, taskStatus: taskLog.taskStatus },
                                text: taskLog.log === null ? this.state.text : taskLog.log,
                                active: false
                            })
                        }
                    }
                    else {
                        this.setState({ text: this.state.text + taskLog.log, });
                    }
                },
                (isLoading: boolean) => {
                    this.setState({ waitServerAnswer: isLoading });
                },
                () => {
                },
                () => {

                },
                500
            );
        }
    }

    disconnectFromPoller = () => {
        console.log('disconnect: ', this.state);
        if (this.state.pollerInfo !== null && this.state.pollerInfo.threadId !== 0 && this.state.connected) {
            sendRequestToBackend(
                this.state.pollerInfo,
                'admin/disconnectfrompoller',
                (pollingInfo: SPollerInfoDTO | null) => {
                    this.setState({ pollerInfo: pollingInfo, connected: false });
                    if (pollingInfo !== null &&
                        pollingInfo.taskId !== 0 &&
                        pollingInfo.taskStatus !== TaskStatusesEnum.None &&
                        pollingInfo.taskStatus !== TaskStatusesEnum.NotDefined) {
                        this.getTaskLog(pollingInfo.taskId, new Date(pollingInfo.lastRetryTime));
                    }
                },
                (isLoading: boolean) => {
                    this.setState({ waitServerAnswer: isLoading });
                },
                () => {
                },
                () => {

                },
                500
            );
        }
    }

    stopPoller = () => {
        if (this.state.pollerInfo !== null && this.state.pollerInfo.threadId !== 0 && this.state.connected) {
            sendRequestToBackend(
                this.state.pollerInfo,
                'admin/threadrestart',
                (pollingInfo: SPollerInfoDTO | null) => {
                    this.setState({ pollerInfo: pollingInfo, connected: false });
                },
                (isLoading: boolean) => {
                    this.setState({ waitServerAnswer: isLoading });
                },
                () => {
                },
                () => {

                },
                500
            );
        }
    }

    getTaskLog = (taskId: number, start: Date) => {
        const end: Date = moment(start).add(5, 'minute').toDate();

        sendRequestToBackend(
            { taskId, start, end },
            'admin/pollinglog',
            (response: any) => {
                if (response != null) {
                    this.setState({ text: response, active: false })
                } else {
                    this.setState({ active: false })
                    message.warning("Не удалось получить лог опроса");
                }
            },
            (isLoading: boolean) => {
                this.setState({ waitServerAnswer: isLoading });
            },
            (error: any) => {
                console.log(error);
                message.error(error);
                this.setState({ active: false })
            }
        );
    }

    scrollToBottom = () => {
        if (this._textareaRef.current) {
            this._textareaRef.current.resizableTextArea.textArea.scrollTop = this._textareaRef.current.resizableTextArea.textArea.scrollHeight;
        }
    }

    render() {
        return (
            <Modal
                bodyStyle={{ padding: '5px' }}
                title={
                    <div
                        style={{
                            width: '100%',
                            cursor: 'move',
                        }}
                        onMouseOver={() => {
                            if (this.state.disabled) {
                                this.setState({ disabled: false });
                            }
                        }}
                        onMouseOut={() => {
                            this.setState({ disabled: true });
                        }}
                        onFocus={() => { }}
                        onBlur={() => { }}
                    >
                        {this.props.title ? this.props.title : "Лог опроса."}
                    </div>
                }
                wrapClassName={MODAL_WRAP_CLASSNAME}
                zIndex={this.props.zIndex}
                open={this.props.queryItemId != undefined || this.props.taskId > 0}
                footer={[
                        <Button
                            key="1"
                            type='primary'
                        onClick={() => {
                            this.setState({ pollerInfo: null, text: "" });
                                this.props.onHide();
                            }}>
                                Закрыть
                        </Button>,
                        <Button
                            key="2"
                            type='primary'
                            disabled={this.state.pollerInfo === null || this.state.pollerInfo.threadId === 0 || !this.state.connected }
                            onClick={this.stopPoller}>
                                Остановить опрос
                        </Button>
                ]}
                onCancel={() => {
                    this.setState({ pollerInfo: null, text: "" });
                    this.props.onHide()
                }}
                modalRender={(modal) => (
                    <Draggable
                        disabled={this.state.disabled}
                        bounds={this.state.bounds}
                        onStart={(event, uiData) => this.onStart(event, uiData)}
                    >
                        <div>{modal}</div>
                    </Draggable>
                )}
            >
                {
                    <Input.TextArea readOnly={true} value={this.state.text} rows={20} ref={this._textareaRef} />
                }
            </Modal>
        );
    }
}

export default OnlineLogForm;