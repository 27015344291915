import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { connect } from 'react-redux';

import { Tabs, Checkbox, Space, Button, Table, Input, Select, DatePicker, InputNumber } from 'antd';
import { SParameterDTO, SParameterTypesEnum, SParameterValTypes, SParameterValuesStatusEnum } from '../../decl';

// import { ApplicationState } from '../store';
// import * as PageStore from '../store/Page';
// import * as UserProfileStore from '../store/UserProfile';
// import * as PointStore from '../store/AdminStore';
// import { BaseProfile, Point } from '../decl';
import styles from '../../resources/Page.module.less';
import moment from 'moment';
import { Edit } from '@material-ui/icons';
// import adminStyles from '../resources/AdminPage.module.less';
// import type { CheckboxChangeEvent } from 'antd/es/checkbox';
// import Groups from '../components/Groups';
// import * as Common from '../utils/Common';
// import * as Const from '../utils/Const';

// import PointEditForm from '../components/Admin/PointEditForm'


const { TabPane } = Tabs;


type Props = {
    pars: SParameterDTO[],
    isLoading?: boolean,
    onChangeField(parameterCode: string, newVal: SParameterValTypes): void,
    onEditFinish?(parameterCode: string): void,
    disabled?: boolean,
}

interface State {
    editingPar: string,
}

class SParametersEdit extends React.Component <Props, State > {

    constructor(props: Props){
        super(props);
        
        this.state = {
            editingPar: '',
        }
    }
    componentDidMount() {
    }

    componentDidUpdate(prevProps: Props) {

    }

    onChangeField = (parameterCode: string, newVal: SParameterValTypes) => {
        this.setState({editingPar: parameterCode});
        this.props.onChangeField(parameterCode, newVal);
    }

    onEditFinish = (parameterCode: string) => {
        if (this.props.onEditFinish !== undefined && this.state.editingPar === parameterCode) {
            this.setState({editingPar: ''});
            this.props.onEditFinish(parameterCode);
        }
    }

    private renderField = (f: SParameterDTO | undefined) => {
        if (f == undefined)
            return null;

        let res = <></>;
        const disabled = this.props.disabled ?? false;
        let st = '';

        if (f.valueStatus === SParameterValuesStatusEnum.Alarm) {
            if (f.valueType === SParameterTypesEnum.Date || f.valueType === SParameterTypesEnum.DateTime) {
                st = ' ' +  styles.fieldAlarmDatePicker;
            } else {
                st = ' ' +  styles.fieldAlarm;
            }
        } else if (f.readOnly)  {
            if (f.valueType === SParameterTypesEnum.Date || f.valueType === SParameterTypesEnum.DateTime) {
                st = ' ' +  styles.fieldReadOnlyDatePicker;
            } else {
                st = ' ' +  styles.fieldReadOnly;
            }
        }

        if (f.valueList != undefined) {
            res = (
                <Select className={styles.Width100} value={f.value} onChange={(value) => {}} size="small" >
                    {f.valueList.map(item => 
                        <Select.Option key={String(item)} value={item}
                            onChange={(val: any) => {this.onChangeField(f.parameterCode, val); this.onEditFinish(f.parameterCode);}}
                        >
                            {String(item)}
                        </Select.Option>)
                    }
                </Select>
            )
        } else if(f.valueType === SParameterTypesEnum.String || f.valueType === SParameterTypesEnum.Text 
            || (f.valueType === SParameterTypesEnum.None)) {
            res = (
                <Input disabled={disabled} className={styles.Width100 + st} value={(f.value as any) ?? ''} size='small' 
                    onChange={val => this.onChangeField(f.parameterCode, val.target.value)}
                    onBlur={() => this.onEditFinish(f.parameterCode)}
                    onPressEnter={() => this.onEditFinish(f.parameterCode)}
                />
            )
        } else if(f.valueType === SParameterTypesEnum.Boolean) {
            res = (
                <Checkbox disabled={disabled} className={styles.Width100 + st} checked={f.value as any} 
                    onChange={val => {this.onChangeField(f.parameterCode, val.target.checked); this.props.onChangeField(f.parameterCode, val.target.checked);}}
                />
            )
        } else if(f.valueType === SParameterTypesEnum.Date) {
            res = (
                <DatePicker disabled={disabled} className={styles.Width100 + st} value={f.value != null ? moment(f.value as any) : undefined} size='small'
                    format={'DD.MM.YYYY'}
                    onChange={val => {this.onChangeField(f.parameterCode, val?.toDate() ?? null); this.onEditFinish(f.parameterCode)}}
                />
            )
        } else if(f.valueType === SParameterTypesEnum.DateTime) {
            res = (
                <DatePicker disabled={disabled} className={styles.Width100 + st} value={f.value != null ? moment(f.value as any) : undefined} showTime size='small'
                    format={'DD.MM.YYYY HH:mm:ss'}
                    onChange={val => {this.onChangeField(f.parameterCode, val?.toDate() ?? null); this.onEditFinish(f.parameterCode)}}
                />
            )
        } else if(f.valueType === SParameterTypesEnum.Number) {
            res = (
                <InputNumber disabled={disabled} className={styles.Width100 + st} value={f.value as any} size='small' 
                    onChange={(val) => this.onChangeField(f.parameterCode, val)}
                    onBlur={() => this.onEditFinish(f.parameterCode)}
                    onPressEnter={() => this.onEditFinish(f.parameterCode)}
                />
            )
        }

        return res;
    }

    public render() {
        const dataSource = this.props.pars.map(x => {
            return ({
                readOnly: x.readOnly,
                id: x.parameterCode,
                name: x.parameterCode,
                value: x.value,
                description: x.description,
            })
        })

        const columns = [
            {
                dataIndex: "name",
                title: "Параметр"
            },
            {
                dataIndex: "value",
                title: "Значение",
                render: (text: any, record: any, index: any) => {
                    const t = this.renderField(this.props.pars.find(x => x.parameterCode === record.id));
                    return record.readOnly == false ? t : text
                }
            },
            {
                dataIndex: "description",
                title: "Описание",
                ellipsis: true,
            }
        ];

        return (
            <Table
                bordered={true}
                size={'small'}
                rowKey="id"
                columns={columns}
                dataSource={dataSource}
                pagination={false}
                loading={this.props.isLoading ?? false}
            />
        )
    }

}

export default SParametersEdit;
