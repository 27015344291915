import * as React from 'react';

import styles from '../../resources/Page.module.less';
import { Button, Modal, Select, Input, Row, Col, Table, Tag, DatePicker, Tabs, Tooltip, Space } from 'antd';

import { arrayToString, deepCopyObj, message } from '../../utils/Common';
import { ALL_GROUP_ID, MODAL_WRAP_CLASSNAME } from '../../utils/Const';
import { SBilContactDTO, SBillingCompanyInfoDTO } from './BillingDecls';
import moment from 'moment';
import { DeleteTwoTone, EditTwoTone } from '@ant-design/icons';
import * as Const from '../../utils/Const';
import Search from 'antd/lib/input/Search';
import CompanyEditForm from './CompanyEditForm';
import { sendRequestToBackend } from '../../utils/AuthUtils';
import { SUserDTO, SUserInfoDTO } from '../../decl';
import { UserInfo } from 'os';

const { Option } = Select;

type Props = {
    onHide(): void,
    visible: boolean,
    onSelect(userId: string, userName: string): void,
    selectedUserId: string | undefined,
    setIsLoading: any,
}

type State = {
    users: SUserDTO[] | undefined,
    selectedUserId: string | undefined,

    filterText: string,
}

export default class SelectUserForm extends React.Component<Props, State> {
    constructor(props: Props, state: State) {
        super(props);

        this.state = {
            users: undefined,
            selectedUserId: undefined,
            filterText: '',
        }
    }

    componentDidUpdate(prevProps: Props) {
        if (prevProps.visible === false && this.props.visible === true) {
            this.loadUsers();
            this.setState({selectedUserId: this.props.selectedUserId, filterText: ''})
        }
    }

    loadUsers = (iUserInfo?: SUserInfoDTO | undefined) => {
        sendRequestToBackend(
            null,
            'users/getallusers',
            (response: SUserDTO[]) => {
                this.setState({users: response});
                this.makeDatasource(response);
            },
            this.props.setIsLoading,
            (error: any) => {
                console.log(error);
                message.error(error);
            });
    }

    private dataSource: any = undefined;

    makeDatasource = (arr: SUserDTO[] | undefined) => {
        if (arr == undefined) {
            this.dataSource = undefined;
        } else {
            this.dataSource = arr.map((x, index) => {
                return ({
                    key: x.userKey,
                    id: x.userKey,
                    num: index + 1,
                    name: x.user,
                })
            });
        }
    }

    private columns = [
        {
            dataIndex: "num",
            title: "№",
            render: (text: any, record: any, index: any) => text,
            width: 60,
        },
        {
            dataIndex: "name",
            title: "Пользователь",
            render: (val: any, record: any, index: any) => val,
        },
    ];

    onOk = () => {
        this.props.onSelect(this.state.selectedUserId ?? '', this.state.users?.find(x => x.userKey === this.state.selectedUserId)?.user ?? '');
    }
    
    onSearchChange = (val: any) => {
        this.setState({filterText: val.target.value});
        let txt: string = val.target.value;
        const arr =  this.state.users?.filter(x => x.user?.toLowerCase().includes(txt.toLowerCase() ?? ''))
        this.makeDatasource(arr);
    }

    render() {
        return (
            <Modal
                title={ 'Выбор пользователя' }
                centered={true}
                wrapClassName={MODAL_WRAP_CLASSNAME}
                width={500}
                //zIndex={this.props.zIndex}
                open={this.props.visible}
                onCancel={() => { this.props.onHide() }}
                onOk={this.onOk}
                cancelText={'Закрыть'}
                okText={'Выбрать'}
            >
                <div className={styles.FormWrapper}>
                    <Search value={this.state.filterText} onChange={this.onSearchChange} style={{paddingBottom: '10px', width: '150px'}} />
                    <Table 
                        columns={this.columns} dataSource={this.dataSource} showSorterTooltip={false}
                        scroll={{ y: '300px', x: 'auto' }} bordered size='small'
                        pagination={{ showSizeChanger: true, pageSizeOptions: ["5", "10", "100", "500", "1000"], defaultPageSize: 100, locale: { items_per_page: " / страницу" }}}
                        rowSelection={{
                            type: "radio",
                            selectedRowKeys: this.state?.selectedUserId != undefined ? [this.state.selectedUserId] : undefined,
                            onChange: (selectedRowKeys: any[], selectedRows: any[]) => {
                                this.setState({selectedUserId: selectedRowKeys[0]});
                            }
                        }}
                        onRow={(record, rowIndex) => {
                            return {
                                onClick: event => { if (record.id !== this.state.selectedUserId) { this.setState({selectedUserId: record.id}) } }, // click row
                                onDoubleClick: this.onOk,
                                style: { cursor: 'pointer' },
                            };
                        }}
                    />
                </div>
            </Modal>
        );
    }
}