import * as React from 'react';

import styles from '../../resources/Page.module.less';
import { Button, Modal, Select, Input, Row, Col, Table, Tag, DatePicker, Tabs, Checkbox, Tooltip, Space, InputNumber } from 'antd';

import { arrayToString, deepCopyObj, getModalZIndex, message, netiExecute } from '../../utils/Common';
import { ALL_GROUP_ID, MODAL_WRAP_CLASSNAME } from '../../utils/Const';
import { SBilContractDTO as SBilContractDTO, SBillingTariffDTO, SContractPointDTO as SBilContractPointDTO, SBillingCompanyInfoDTO, SBilContactDTO } from './BillingDecls';
import moment from 'moment';
import { DeleteTwoTone, EditTwoTone, ScheduleTwoTone } from '@ant-design/icons';
import * as Const from '../../utils/Const';
import SelectFormSimple from '../Helper/SelectFormSimple';
import { Point } from '../../decl';
import ContactsTable from './ContactsTable';
import { debug } from 'console';
import { strict } from 'assert';
import CompaniesForm from './CompaniesForm';
import SelectUserForm from './SelectUserForm';
import { RoomSharp } from '@material-ui/icons';
import ReactDOM from 'react-dom';
import { sendRequestToBackend } from '../../utils/AuthUtils';

const { Option } = Select;

type Props = {
    visible: boolean,
    windowHeight: number,
    onHide() : void,

    contract: SBilContractDTO | null,
    contractPoints: SBilContractPointDTO[],
    companies: SBillingCompanyInfoDTO[],
    requestContacts: (companyId: number, success: () => void) => void,
    contacts: SBilContactDTO[],

    //ТУ привязанные к контракту не имеют отдельного идентификатора.
    //Каждый раз при сохранении контракта они перезаписываются, то есть отдельного списка удаленных ТУ не нужен.
    onWrite(contract: SBilContractDTO, points: SBilContractPointDTO[], contacts: SBilContactDTO[], contactDeleteIds: number[]) : void,
    allPoints: any,
    tariffs: SBillingTariffDTO[],
    paymentTypes: {[id: number]: string},
    setIsLoading: any,

    showPointInfo: any,
    userProfile: any,
    changeProfile: any,
    setActiveQueries: any,
    showModal: any,
}

type State = {
    contract: SBilContractDTO | undefined,
    contractPoints: SBilContractPointDTO[],
    contacts: SBilContactDTO[],
    zIndex: number,
    showSelectForm: boolean,
    contactDeleteIds: number[],
    contractPointsDelete: SBilContractPointDTO[],
    showCompaniesForm: boolean,
    showSelectUserForm: boolean,
}

export default class ContractForm extends React.Component<Props, State> {
    constructor(props: Props, state: State) {
        super(props);

        this.state = {
            contacts: [],
            contract: undefined,
            contractPoints: [],
            contractPointsDelete: [],
            showSelectForm: false,
            zIndex: 0,
            contactDeleteIds: [],
            showCompaniesForm: false,
            showSelectUserForm: false,
        }
    }

    componentDidUpdate(prevProps: Props) {
        if (prevProps.visible === false && this.props.visible === true) {
            const cps = deepCopyObj(this.props.contractPoints);
            const contract = deepCopyObj(this.props.contract);
            const contacts = deepCopyObj(this.props.contacts);
            this.setState({contract: contract, contractPoints: cps, contacts: contacts, contactDeleteIds: [], contractPointsDelete: []});
            this.makeContractPointsDatasource(cps);
        }

        if (prevProps.contacts !== this.props.contacts) {
            const contacts = deepCopyObj(this.props.contacts);
            this.setState({contacts: contacts, contactDeleteIds: []});
        }
    }

    onDeletePoint(id: any): void {
        Modal.confirm({
            title: 'Убрать точку учета',
            wrapClassName: Const.MODAL_WRAP_CLASSNAME,
            zIndex: getModalZIndex(),
            content: 'Вы действительно хотите убрать выбранную ТУ из договора?',
            width: 500,
            okText: 'Да',
            cancelText: 'Нет',
            onOk: () => {
                let cts = [...this.state.contractPoints];
                let c = cts.splice(cts.findIndex(x => x.pointId === id), 1);
                if (c != undefined) {
                    this.makeContractPointsDatasource(cts);
                    this.setState({contractPoints: cts});
                }
            },
            onCancel: () => {
            }
        });
    }

    private noWrap = (str: string) => {
        return <span style={{whiteSpace: 'nowrap'}}>{str}</span>
    }

    execute = (cmd: any) => {
        netiExecute(cmd, this.props.userProfile, this.props.changeProfile, this.props.setIsLoading, this.props.setActiveQueries, null, this.props.showModal);
    }

    showPointInfo = (pointId: string, pointNumber: string) =>{
        const source: any = new (window.NetiSource as any)(this.execute);
        const parent: any = ReactDOM?.findDOMNode(this)?.parentNode;
        this.props.showPointInfo(pointId, pointNumber, source, parent);
    }

    private pointsDataSource: any = undefined;

    makeContractPointsDatasource = (arr: SBilContractPointDTO[]) => {
        this.pointsDataSource = arr.map(x => {
            return ({
                key: x.contractId.toString() + x.pointId,
                info: x.pointId,
                number: this.props.allPoints[x.pointId].number,
                address: this.props.allPoints[x.pointId].persistProperties['address'],
                tariff: x.tariffId,
                price: x.price,
                isActive: x.isActive,
            })
        })
    }

    sorter = (a: any, b: any) => (isNaN(a) && isNaN(b) ? (a || '').localeCompare(b || '') : a - b);

    private pointsColumns = [
        {
            dataIndex: "num",
            title: "№",
            render: (text: any, record: any, index: any) => (
                <>{index + 1}</>
            ),
            width: 40,
        },
        {
            dataIndex: "id",
            title: "",
            width: 50,
            align: 'center' as 'center',
            render: (val: any, record: any, index: any) => {
                return (
                    <Tooltip placement="top" title={'Удалить'}>
                        <Button shape="circle" onClick={() => this.onDeletePoint(record.info)} icon={<DeleteTwoTone twoToneColor={Const.COLORS.SvgIconColor}/>} />
                    </Tooltip>
                )
            },
        },
        {
            dataIndex: "info",
            title: "Инфо",
            width: 70,
            render: (text: any, record: any) => 
                <Button onClick={() => this.showPointInfo(record.info, record.number)} size="middle" shape="circle" icon={<ScheduleTwoTone twoToneColor={Const.COLORS.SvgIconColor} />} />
        },
        {
            dataIndex: "number",
            title: this.noWrap("Наименование"),
            sorter: (a: any, b: any) => this.sorter(a.number, b.number),
        },
        
        {
            dataIndex: "address",
            title: this.noWrap("Адрес"),
            sorter: (a: any, b: any) => this.sorter(a.address, b.address),
        },
        {
            dataIndex: "tariff",
            title: "Тариф",
            width: 130,
            sorter: (a: any, b: any) => this.sorter(a.tariff, b.tariff),
            render: (val: any, record: any, index: any) => {
                return (
                    <Select style={{width: 120}} 
                        onChange={(t) => {
                            let cts = [...this.state.contractPoints];
                            let c = cts.find(x => x.pointId === record.info);
                            if (c != undefined) {
                                c.tariffId = t;
                                c.price = this.props.tariffs.find(x => x.id === t)?.defaultPrice ?? c.price;
                                this.makeContractPointsDatasource(cts);
                                this.setState({contractPoints: cts});
                            }
                        }} 
                        value={val}
                    >
                        {
                            this.props.tariffs.map(x => <Select.Option key={x.id} value={x.id}>{x.name}</Select.Option>)
                        }
                    </Select>
                )
            },
        },
        {
            dataIndex: "price",
            title: "Аб. плата",
            width: 100,
            render: (val: any, record: any, index: any) => {
                return (
                    <InputNumber
                        onChange={(t) => {
                            let cts = [...this.state.contractPoints];
                            let c = cts.find(x => x.pointId === record.info);
                            if (c != undefined) {
                                c.price = t;
                                this.makeContractPointsDatasource(cts);
                                this.setState({contractPoints: cts});
                            }
                        }} 
                        value={val}
                    />
                )
            },
        },
        {
            dataIndex: "isActive",
            title: "Активен",
            width: 80,
            render: (val: any, record: any, index: any) => (
                <Checkbox 
                    checked={val}
                    onChange={(t) => {
                        let cts = [...this.state.contractPoints];
                        let c = cts.find(x => x.pointId === record.info);
                        if (c != undefined) {
                            c.isActive = t.target.checked;
                            this.makeContractPointsDatasource(cts);
                            this.setState({contractPoints: cts});
                        }
                    }} 
                />
            ),
            align: 'center' as 'center',
        },
    ];

    private onContractChange = (field: keyof SBilContractDTO, val: any) => {
        if (this.state.contract != undefined) {
            let res = {...this.state.contract, [field]: val};
            this.setState({contract: res});
        }
    }

    private onContractChange2 = (field: keyof SBilContractDTO, val: any, field2: keyof SBilContractDTO, val2: any) => {
        if (this.state.contract != undefined) {
            let res = {...this.state.contract, [field]: val, [field2]: val2 };
            this.setState({contract: res});
        }
    }

    onPointsSelect = (points: Point[]) => {
        if (this.props.tariffs.length === 0) {
            message.error('Сначало необходимо создать хотябы один тариф');
            return;
        }

        if (this.state.contract != undefined) {
            let cps = [...this.state.contractPoints];
            let errPoints: string[] = [];
            for (const p of points) {
                if (cps.find(x => x.pointId === p.id) === undefined) {
                    let t: SBilContractPointDTO = {
                        pointId: p.id,
                        contractId: this.state.contract.id,
                        isActive: true,
                        price: this.props.tariffs[0].defaultPrice,
                        tariffId: this.props.tariffs[0].id
                    }
        
                    cps.push(t);
                } else {
                    errPoints.push(p.number);
                }
            }
            
            if (errPoints.length > 0) {
                if (errPoints.length === 1) {
                    message.warning('Точка учета "' + errPoints[0] + ' "уже добавлена.');
                } else {
                    message.warning('Точки учета "' + errPoints.join('", "') + '" уже были добавлены.');
                }
            }
            
            this.makeContractPointsDatasource(cps);
            this.setState({contractPoints: cps, showSelectForm: false});
        }
    };

    onWrite = (contract: SBilContractDTO, points: SBilContractPointDTO[], contacts: SBilContactDTO[], contactDeleteIds: number[]) : void => {
        if (contract.userId === '') {
            message.error('Необходимо выбрать пользователя сайта');
            return;
        }

        if (contract.companyId === -1) {
            message.error('Необходимо выбрать контрагента');
            return;
        }

        if (contract.paymentTypeId === -1) {
            message.error('Необходимо выбрать тип оплаты');
            return;
        }

        this.props.onWrite(contract, points, contacts, contactDeleteIds);
    }

    onOk = () => {
        if (this.state?.contract != null) {
            this.onWrite(this.state.contract, this.state.contractPoints, this.state.contacts, this.state.contactDeleteIds);
        }
    }

    onSelectCompanyClick = () => {
        if (this.state.contract?.companyId != -1) {
            Modal.confirm({
                title: 'Смена компании',
                wrapClassName: Const.MODAL_WRAP_CLASSNAME,
                zIndex: getModalZIndex(),
                content: 'Вы действительно хотите изменить контрагента? Все не сохраненные контакты будут потеряны.',
                width: 500,
                okText: 'Да',
                cancelText: 'Нет',
                onOk: () => {
                    this.setState({showCompaniesForm: true});
                },
                onCancel: () => {
                }
            });
        } else {
            this.setState({showCompaniesForm: true});
        }
    }

    onUserSelect = (userId: string, userName: string) => {
        Modal.confirm({
            title: 'Вопрос',
            wrapClassName: Const.MODAL_WRAP_CLASSNAME,
            zIndex: getModalZIndex(),
            content: 'Вы хотите заполнить список абонентов по пользователю сайта?',
            width: 600,
            okText: 'Да',
            cancelText: 'Нет',
            onOk: () => {
                sendRequestToBackend(
                    userId,
                    'points/userallpoints',
                    (response: any) => {
                        if (response != null) {
                            this.onPointsSelect(response);
                            this.setState({showSelectUserForm: false});
                            this.onContractChange2('userId', userId, 'userName', userName);
                        } else {
                            message.error('Запрос завершился неудачей');
                        }
                    },
                    this.props.setIsLoading,
                    (error: any) => {
                        console.log(error);
                        message.error(error);
                    }
                );
            },
            onCancel: () => {
                this.onContractChange2('userId', userId, 'userName', userName);
                this.setState({showSelectUserForm: false});
            }
        });
    }

    render() {
        return (
            <Modal
                title={ this.props !== undefined ? "Редактирование " + "" : 'Добавление записи' }
                wrapClassName={MODAL_WRAP_CLASSNAME}
                width={1300}
                //zIndex={this.props.zIndex}
                centered
                open={this.props.visible}
                // footer={[
                //     <div style={{ display: 'flex', alignItems: 'baseline' }}>
                //         <Button key="1" type='primary' onClick={this.props.onHide} style={{marginLeft: 'Auto'}}>Отмена</Button>
                //         <Button key="2" type='primary' onClick={this.onOk}>{this.props.operation === undefined ? "Добавить" : "Изменить"}</Button>
                //     </div>
                // ]}
                onCancel={() => { this.props.onHide() }}
                onOk={this.onOk}
            >
                <div className={styles.FormWrapper}>
                    <div style={{ overflow: 'auto', height: '75vh', display: 'flex', flexDirection: 'column' }}>
                        <div style={{ padding: '4px'}}>
                            <div style={{display: 'flex', justifyContent: 'space-between'}}>
                                <div style={{width: '27%'}}>
                                <Input.Group>
                                    <Row key={'k2'} className={styles.FormRowWrapperBig} style={{alignItems: 'center'}}>
                                        <Col key='c1' span={12}>
                                            Номер
                                        </Col>
                                        <Col span={12}>
                                            <Input value={this.state.contract?.number} onChange={val => this.onContractChange('number', val.target.value)}/>
                                        </Col>
                                    </Row>
                                    <Row className={styles.FormRowWrapperBig} style={{alignItems: 'center'}}>
                                        <Col span={12}>
                                            Дата
                                        </Col>
                                        <Col span={12}>
                                            <DatePicker format={['DD.MM.YYYY', 'DDMMYYYY']} value={moment(this.state.contract?.date)} className={styles.Width100}/>
                                        </Col>
                                    </Row>
                                    <Row className={styles.FormRowWrapperBig} style={{alignItems: 'center'}}>
                                        <Col span={12}>
                                            <a onClick={this.onSelectCompanyClick}>Контрагент</a>
                                        </Col>
                                        <Col span={12}>
                                            <Input disabled={true} value={this.props.companies.find(x => x.id === this.state.contract?.companyId)?.name ?? ''} />
                                        </Col>
                                    </Row>
                                    <Row className={styles.FormRowWrapperBig} style={{alignItems: 'center'}}>
                                        <Col span={12}>
                                            Тип оплаты
                                        </Col>
                                        <Col span={12}>
                                        <Select className={styles.Width100} onChange={val => this.onContractChange('paymentTypeId', val)} value={this.state.contract?.paymentTypeId}>
                                            <Select.Option key={-1} value={-1}> </Select.Option>
                                            { 
                                                Object.keys(this.props.paymentTypes).map(x => <Select.Option key={Number(x)} value={Number(x)}>{this.props.paymentTypes[Number(x)]}</Select.Option>) 
                                            }
                                        </Select>
                                        </Col>
                                    </Row>
                                    <Row className={styles.FormRowWrapperBig} style={{alignItems: 'center'}}>
                                        <Col span={12}>
                                            Период оплаты (мес.)
                                        </Col>
                                        <Col span={12}>
                                            <InputNumber className={styles.Width100} value={this.state.contract?.paymentPeriod} onChange={val => this.onContractChange('paymentPeriod', val)} />
                                        </Col>
                                    </Row>
                                    <Row className={styles.FormRowWrapperBig} style={{alignItems: 'center'}}>
                                        <Col span={12}>
                                            Период актов (мес.)
                                        </Col>
                                        <Col span={12}>
                                            <InputNumber className={styles.Width100} value={this.state.contract?.actPeriod} onChange={val => this.onContractChange('actPeriod', val)}/>
                                        </Col>
                                    </Row>
                                    <Row className={styles.FormRowWrapperBig} style={{alignItems: 'center'}}>
                                        <Col span={12}>
                                            <a onClick={() => this.setState({showSelectUserForm: true})}>Пользователь сайта</a>
                                        </Col>
                                        <Col span={12}>
                                            <Input disabled value={this.state.contract?.userName} />
                                        </Col>
                                    </Row>
                                </Input.Group>
                                </div>

                                <div style={{width: '72%', display: 'flex', flexDirection: 'column'}}>
                                    <Input.Group><Button onClick={() => this.setState({showSelectForm: true, zIndex: getModalZIndex()})} type='primary'>Добавить ТУ</Button></Input.Group>
                                    <Table 
                                        style={{margin: '5px 0px 0px 0px'}} size='small' pagination={false} showSorterTooltip={false}
                                        dataSource={this.pointsDataSource}
                                        columns={this.pointsColumns}
                                        scroll={{ y: 'calc(20vh)' }}
                                    />
                                    <Input.TextArea value={this.state.contract?.comment} rows={5} onChange={val => this.onContractChange('comment', val.target.value)} placeholder="" style={{margin: '5px 0px'}} maxLength={256} />
                                </div>
                            </div>
                        </div>
                        <div style={{display: 'flex'}}>
                            <ContactsTable 
                                contacts={this.state.contacts}
                                onChangeContacts={(contacts) => {
                                    this.setState({contacts: contacts});
                                }}
                                companyId={this.state.contract?.companyId ?? -1}
                                onDeleteContact={(id) => {
                                    if (this.state.contacts != null) {
                                        let arr = [...this.state.contactDeleteIds];
                                        arr.push(id);
                                        this.setState({contactDeleteIds: arr});
                                    }
                                }}
                                scrollY='calc(16vh)'
                            />
                        </div>
                    </div>
                </div>

                <SelectFormSimple 
                    onHide={() => this.setState({showSelectForm: false})}
                    onlyGroupsSelect={false}
                    visible={this.state.showSelectForm}
                    zIndex={this.state.zIndex}
                    onPointsSelect={this.onPointsSelect}
                    onlyTree={false}
                />
                <CompaniesForm
                    visible={this.state.showCompaniesForm && this.props.companies != undefined}
                    onHide={() => this.setState({showCompaniesForm: false})}
                    companies={this.props.companies}
                    changeCompanies={() => {}}
                    contacts={this.props.contacts}
                    requestContacts={() => {}}
                    selectMode={true}
                    onSelect={(companyId) => {this.onContractChange('companyId', companyId); this.props.requestContacts(companyId, () => {}); this.setState({showCompaniesForm: false, contactDeleteIds: []})}}
                    selectedCompanyId={this.state.contract?.companyId ?? -1}
                    setIsLoading={this.props.setIsLoading}
                />
                <SelectUserForm 
                    onHide={() => {this.setState({showSelectUserForm: false})}}
                    onSelect={this.onUserSelect}
                    setIsLoading={this.props.setIsLoading}
                    visible={this.state.showSelectUserForm}
                    selectedUserId={this.state.contract?.userId}
                />

            </Modal>
        );
    }
}