import * as React from 'react';

import styles from '../resources/Page.module.less';
import { DatePicker, Divider, Popover, Tabs } from 'antd';
import { Table, Tag, Space } from 'antd';
import { Checkbox, Button, Cascader, Radio, Tooltip, Modal, Popconfirm, Select, Input, Row, Col, InputNumber, Form } from 'antd';
import { SendingReport } from '../store/SettingsStore';
import { spawn } from 'child_process';
import { Group, Point } from '../decl';
import SelectForm from './Admin/SelectForm';
import { SENDING_REPORT_REPORTING_INTERVAL as REPORTING_PERIOD_TYPE_REPORTING_INTERVAL, MODAL_WRAP_CLASSNAME } from '../utils/Const';
import { compareObj, deepCopyObj, getModalZIndex } from '../utils/Common';
import moment from 'moment';
import RangePickerLimit from './Helper/RangePickerLimit';
import { ExclamationCircleOutlined, QuestionCircleTwoTone } from '@ant-design/icons';
import modal from 'antd/lib/modal';

const { Option } = Select;
const { TabPane } = Tabs;

const { Column, ColumnGroup } = Table;

type Props = {
    visible: boolean,
    zIndex: number,
    messageSendTypes: {[messageSendTypeId: number]: string};
    schedulesTypes: {[scheduleId: number]: string};
    reportsTypes: {[reportId: number]: string};
    periodTypes: {[period: number]: string};
    reportingPeriodTypes: {[ReportingPeriodTypeId: number]: string};
    fileNameTypes: {[SendingReportFileNameFormingTypeId: number]: string};

    allPoints: {[pointId: string] : Point},
    userContactId: string,
    
    userGroups: Group[],
    groupPoints: {[groupId: string] : Point[]},
    requestGroupPoints: (iGroupId: string, resolve: any, reset:Boolean) => any,
    setIsLoading: any,

    sendingReport: SendingReport | undefined,
    writeSendingReport(sendingReport: SendingReport): any,
    onHide() : void,

    requestPoints (iPointIds: string[], resolve: any): any,

    windowHeight: number,
}

type State = {
    sendingReport: SendingReport,
    sendingReportOriginal: SendingReport | null,

    showAbonentsTree: boolean,
    modalZIndex: number,

    selectedGroupsIds: string[],
    selectedAbonentsIds: string[]
}

//Модальное окно добавления рассылки ведомостей
class SendingReportForm extends React.Component<Props, State> {
    constructor(props: Props, state: State) {
        super(props);

        this.state = {
            sendingReport: {
                description: '',
                endDateTimeInterval: null,
                id: -1,
                period: -1,
                reportId: -1,
                scheduleId: -1,
                startDateTimeInterval: null,
                fileNameTypeId: -1,
                reportingPeriodTypeId: -1,
                sendingReportInfos: [],
                userContactId: '',
                useDefaultDateTimeInterval: true,
            },

            showAbonentsTree: false,
            modalZIndex: 0,
            selectedGroupsIds: [],
            selectedAbonentsIds: [],
            sendingReportOriginal: null,
        }
    }    

    componentDidUpdate(prevProps: Props) {
        if (prevProps.visible === false && this.props.visible === true) {
            if (this.props.sendingReport?.sendingReportInfos !== undefined && this.props.sendingReport?.sendingReportInfos.length > 0) {
                //console.log('requestPoints', this.props.sendingReport?.sendingReportInfos);
                this.props.requestPoints(this.props.sendingReport?.sendingReportInfos ?? [], null);
            }

            let sr: SendingReport;
            if (this.props.sendingReport === undefined) {
                sr = {
                    description: '',
                    endDateTimeInterval: null,
                    id: -1,
                    period: Number(this.getFirstFieldKey(this.props.periodTypes)),
                    reportId: Number(this.getFirstFieldKey(this.props.reportsTypes)),
                    scheduleId: Number(this.getFirstFieldKey(this.props.schedulesTypes)),
                    startDateTimeInterval: null,
                    fileNameTypeId: Number(this.getFirstFieldKey(this.props.periodTypes),),
                    reportingPeriodTypeId: Number(this.getFirstFieldKey(this.props.reportingPeriodTypes)),
                    sendingReportInfos: [],
                    userContactId: this.props.userContactId,
                    useDefaultDateTimeInterval: true,
                }
                this.setState({sendingReport: sr})
            } else {
                this.setState({sendingReport: deepCopyObj(this.props.sendingReport)});
            }

            if (this.props.sendingReport === undefined || this.props.sendingReport.id === -1) {
                this.setState({sendingReportOriginal:  null});
            } else {
                this.setState({sendingReportOriginal: deepCopyObj(this.props.sendingReport)});
            }

        }
    }

    getFirstFieldKey = (obj: any): any => {
        let res: any;
        
        if (obj !== undefined && Object.keys(obj).length > 0) {
            res = Object.keys(obj)[0];
        }

        return res;
    }

    onOk = () => {
        this.props.writeSendingReport(this.state.sendingReport);
        this.props.onHide();
        this.setState({showAbonentsTree: false});
    };

    removeAbonent = (abonentId: string) => {
        if (this.state.sendingReport !== undefined) {
            let sr = {...this.state.sendingReport};
            const ind = sr.sendingReportInfos.findIndex(item => item === abonentId);
            if (ind !== -1 && ind !== undefined) {
                sr.sendingReportInfos.splice(ind, 1);
            }
            this.setState({sendingReport: sr})
        }
    }

    SRFieldChange = (field: keyof SendingReport, value: any) => {
        this.setState({sendingReport: {...this.state.sendingReport, [field]: value}})
    }
    onValueChange = (groups: any, points: string[]) => {
        this.setState({selectedAbonentsIds: [...points], selectedGroupsIds: [...groups]})
    };
    onPointsChange = (points: Point[]) => {
        this.setState({
            sendingReport: {...this.state.sendingReport, sendingReportInfos: points.map(p=>p.id)},
            showAbonentsTree: false,
        });
    };

    onCancel = () => {
        if (compareObj(this.state.sendingReport, this.state.sendingReportOriginal) === false) {
            modal.confirm({
                title: 'Внимание',
                icon: <ExclamationCircleOutlined />,
                width: 'fit-content',
                content: this.state.sendingReportOriginal != null ? "Вы действительно хотите закрыть окно, потеряв все изменения?" : "Вы действительно хотите выйти из мастера добавления рассылки, потеряв все изменения?",
                onOk: () => {
                    this.setState({showAbonentsTree: false});
                    this.props.onHide();
                },
                zIndex: getModalZIndex(),
            });
        } else {
            this.setState({showAbonentsTree: false}); 
            this.props.onHide();
        }
    }

    render() {
        const maxHeight = (this.props.windowHeight - 160) < 485 ? 485 : this.props.windowHeight - 160;
        return (
            <>
                <Modal
                    title={this.props.sendingReport === undefined ? "Добавление рассылки ведомостей" : "Редактирование " + this.props.sendingReport.description}
                    wrapClassName={MODAL_WRAP_CLASSNAME}
                    zIndex={this.props.zIndex}
                    open={this.props.visible}
                    onOk={this.onOk}
                    onCancel={this.onCancel}
                    okText={this.props.sendingReport === undefined ? "Добавить" : "Сохранить"}
                    cancelText="Отмена"
                    bodyStyle={{maxHeight: maxHeight, overflow: 'auto'}}
                    centered
                >
                    <div className={styles.FormWrapper}>
                        <div>
                            <Input.Group>
                            <Row className={styles.FormRowWrapper}>
                                <Col span={8}>
                                    Описание
                                </Col>
                                <Col span={16}>
                                    <Input value={this.state.sendingReport.description} onChange={(value) => this.SRFieldChange('description', value.target.value)} placeholder={'Введите назваине рассылки'} className={styles.Width100} size="small" />
                                </Col>
                            </Row>
                            <Row className={styles.FormRowWrapper}>
                                <Col span={8}>
                                    Расписание
                                </Col> 
                                <Col span={16}>
                                    <Select value={this.state.sendingReport.scheduleId} onChange={(value) => this.SRFieldChange('scheduleId', value)} size="small" className={styles.Width100}>
                                        {
                                            Object.keys(this.props.schedulesTypes).map(
                                                k => <Option key={k} value={Number(k)}>{this.props.schedulesTypes?.[Number(k)]}</Option>
                                            )
                                        }
                                    </Select>
                                </Col>
                                
                            </Row>
                            <Row className={styles.FormRowWrapper}>
                                <Col span={8}>
                                    Название файла
                                </Col> 
                                <Col span={16}>
                                    <Select value={this.state.sendingReport.fileNameTypeId} onChange={(value) => this.SRFieldChange('fileNameTypeId', value)} size="small" className={styles.Width100}>
                                        {
                                            Object.keys(this.props.fileNameTypes).map(
                                                k => <Option key={k} value={Number(k)}>{this.props.fileNameTypes?.[Number(k)]}</Option>
                                            )
                                        }
                                    </Select>
                                </Col>
                                
                            </Row>
                            <Row className={styles.FormRowWrapper}>
                                <Col span={8}>
                                    Тип ведомости
                                </Col> 
                                <Col span={16}>
                                    <Select value={this.state.sendingReport.reportId} onChange={(value) => this.SRFieldChange('reportId', value)} size="small" className={styles.Width100}>
                                        {
                                            Object.keys(this.props.reportsTypes).map(
                                                k => <Option key={k} value={Number(k)}>{this.props.reportsTypes?.[Number(k)]}</Option>
                                            )
                                        }
                                    </Select>
                                </Col>
                                
                            </Row>
                            <Row className={styles.FormRowWrapper}>
                                <Col span={8}>
                                    Тип данных
                                </Col> 
                                <Col span={16}>
                                    <Select value={this.state.sendingReport.period} onChange={(value) => this.SRFieldChange('period', value)} size="small" className={styles.Width100}>
                                        {
                                            Object.keys(this.props.periodTypes).filter(x => this.props.periodTypes[Number(x)] !== 'Месяц').map(
                                                k => <Option key={k} value={Number(k)}>{this.props.periodTypes?.[Number(k)]}</Option>
                                            )
                                        }
                                    </Select>
                                </Col>
                                
                            </Row>
                            <Row className={styles.FormRowWrapper}>
                            <Col span={8}>
                                    Период
                                </Col>
                            <Col span={16}>
                                <Select value={this.state.sendingReport.reportingPeriodTypeId} onChange={(value) => this.SRFieldChange('reportingPeriodTypeId', value)} size="small" className={styles.Width100}>
                                    {
                                        Object.keys(this.props.reportingPeriodTypes).map(
                                            k => <Option key={k} value={Number(k)}>{this.props.reportingPeriodTypes?.[Number(k)]}</Option>
                                        )
                                    }
                                </Select>
                            </Col>
                                
                            </Row>
                            {this.state.sendingReport.reportingPeriodTypeId === REPORTING_PERIOD_TYPE_REPORTING_INTERVAL ? 
                                <Row className={styles.FormRowWrapper}>
                                <Col span={8}>
                                        <Checkbox 
                                            checked={this.state.sendingReport.useDefaultDateTimeInterval}
                                            onChange={(e: any) => {
                                                let sr: SendingReport; 
                                                if (e.target.checked !== true) {
                                                    sr = {...this.state.sendingReport, useDefaultDateTimeInterval: e.target.checked};
                                                } else {
                                                    sr = {...this.state.sendingReport, useDefaultDateTimeInterval: e.target.checked};
                                                }
                                                this.setState({sendingReport: sr});
                                            }}

                                        >
                                            По умолчанию
                                        </Checkbox>
                                    </Col>
                                <Col span={16}>
                                    <Space>
                                        <RangePickerLimit
                                            value={this.state.sendingReport.startDateTimeInterval != null && this.state.sendingReport.endDateTimeInterval != null ?
                                                [this.state.sendingReport.startDateTimeInterval, this.state.sendingReport.endDateTimeInterval] : null
                                            }
                                            onChange={(val) => this.setState({sendingReport: 
                                                {
                                                    ...this.state.sendingReport, 
                                                    startDateTimeInterval: val?.[0] ?? null,
                                                    endDateTimeInterval: val?.[1] ?? null,
                                                }})
                                            }
                                            disabled={this.state.sendingReport.useDefaultDateTimeInterval}
                                            size='small'
                                        />
                                        <Tooltip placement="top" title={'Для формирования отчёта необходимо указать первый день месяца (если больше последнего дня то из предыдущего месяца) и пocледний день месяца.'}>
                                            <QuestionCircleTwoTone  className={styles.Question}/>
                                        </Tooltip>
                                    </Space>
                                </Col>
                                    
                                </Row>
                            : null
                            }
                            </Input.Group>
                        </div>
                        
                        <Table
                            size='small'
                            bordered={true}
                            className={styles.FormRowWrapper}
                            columns={
                                [
                                    {
                                        title: 'Точки учёта',
                                        dataIndex: 'col1',
                                        width: 100,
                                    },
                                    
                                    {
                                        title: 'Действие',
                                        dataIndex: 'col2',
                                        key: 'action',
                                        render: (value, record, index) => <a onClick={() => this.removeAbonent(value)}>Удалить</a>,
                                        width: 50
                                    },
                                    
                                ]
                            }
                            dataSource={
                                (this.state.sendingReport?.sendingReportInfos ?? []).map(
                                    item => ({
                                        key: item, 
                                        col1: this.props.allPoints[item]?.number,
                                        col2: item
                                    })
                                )
                            } 
                            pagination={false}
                            scroll={{ y: (this.props.windowHeight - 470) < 175 ? 175 : this.props.windowHeight - 470 }}
                        />

                        <Button 
                            className={styles.FormRowWrapper} 
                            type="primary"
                            onClick={() => {
                                this.setState({
                                    showAbonentsTree: true,
                                    modalZIndex:getModalZIndex(),
                                    selectedAbonentsIds: this.state.sendingReport?.sendingReportInfos ?? [],
                                    selectedGroupsIds: [],
                                })
                            }}
                        >
                            Добавить ТУ
                        </Button>
                    </div>
                </Modal>

                <SelectForm 
                    visible={this.state.showAbonentsTree}
                    zIndex = {this.state.modalZIndex}
                    groups={this.props.userGroups}
                    groupPoints={this.props.groupPoints}
                    selectedGroupIds={this.state.selectedGroupsIds}
                    selectedPointIds={this.state.selectedAbonentsIds}
                    getPoints={this.props.requestGroupPoints}
                    getPointsParentGroups={null}
                    onChange={this.onValueChange}
                    onChangePoints={this.onPointsChange}
                    setIsLoading={this.props.setIsLoading}
                    onClose={() => this.setState({showAbonentsTree: false})}
                    onlyTree = {true}
                />
            </>
        );
    }
}

export default SendingReportForm;